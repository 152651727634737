import { Text } from 'issuix-ui-kit';
import { FC, useMemo } from 'react';

import { usePaymentContext } from '@/components/Payment/PaymentModal/context/PaymentContext';
import {
  EqualsSymbolContainer,
  PurchaseInfoFormContainer,
  StyledControlledInput,
  StyledFullDisabledField,
} from '@/components/Payment/PaymentModal/shared/PurchaseInfo/PurchaseInfo.styles';
import { useResourcePrice } from '@/components/Payment/PaymentModal/useResourcePrice';
import { PRODUCT_TYPE_ENUM } from '@/enums/ProductType';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { formatAmountWithoutRate } from '@/utils/formatAmount/FormatAmount';

type Props = {
  quantity: number;
  disabled?: boolean;
};

const PurchaseInfo: FC<Props> = ({ quantity, disabled }) => {
  const { resourceType, currency, resource } = usePaymentContext();
  const { translate } = useTranslation();

  const resourcePrice = useResourcePrice({ resource, resourceType, currency });

  const pricePerItem = useMemo<string>(() => {
    if (!currency) {
      return '-1';
    }

    return formatAmountWithoutRate(resourcePrice, currency.decimals);
  }, [currency, resourcePrice]);

  return (
    <PurchaseInfoFormContainer>
      <StyledControlledInput name={'quantity'} placeholder={translate('quantity')} disabled={disabled} />
      <StyledFullDisabledField
        value={pricePerItem}
        label={resourceType === PRODUCT_TYPE_ENUM.BOND_PAPERS ? translate('denomination') : translate('pricePerShare')}
      />
      <EqualsSymbolContainer>
        <Text content={'='} size={'large'} />
      </EqualsSymbolContainer>
      <StyledFullDisabledField value={quantity * resourcePrice} label={translate('total')} />
    </PurchaseInfoFormContainer>
  );
};

export default PurchaseInfo;
