import FirstStepForm from './FirstStepForm';
import { firstStepSchema } from './FirstStepSchema';

import ControlledForm from '@/components/Form/ControlledForm';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';

const FirstStep = () => {
  const { translate } = useTranslation();

  return (
    <ControlledForm schema={firstStepSchema(translate)}>
      <FirstStepForm />
    </ControlledForm>
  );
};

export default FirstStep;
