import { Button, Text } from 'issuix-ui-kit';
import styled from 'styled-components';

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled(Text)``;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(27, 90, 161, 0.25) 0%,
    rgba(64, 153, 232, 0.5) 100%,
    rgba(4, 134, 244, 0.5) 100%
  );
  height: 4rem;
  width: 90%;
  border-radius: 0.2rem;
  font-size: 18px;
  padding: 0 1rem;
`;

export const DocumentClose = styled(Button)`
  align-self: flex-end;
`;
