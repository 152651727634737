import { CreateBondRequest } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const createBondPath = 'bonds';

export const createBond = (build: BuildType) => {
  return build.mutation<void, CreateBondRequest>({
    query: (bond: CreateBondRequest) => ({
      url: createBondPath,
      method: 'POST',
      body: bond,
    }),
  });
};
