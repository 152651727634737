import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import ControlledInput from '@/components/Form/ControlledInput';
import { ASSET_TYPE_ENUM } from '@/enums/AssetTypeEnum';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { AssetResponse, BondTransactionResponse, ShareTransactionResponse } from '@/shared/types/api';
import { useAssignedAssetUrlMutation } from '@/store/service';
import { findAssetByType } from '@/utils/assets/findAssetByType';
import { parseFormObject } from '@/utils/getUserFieldValues/getUserFieldValues';
import { setFieldValues } from '@/utils/setFieldValues/setFieldValues';
import {
  IssuerInfoFormContainer,
  IssuerInfoWrapper,
  IssuerLogo,
  IssuerLogoWrapper,
  RowFirst,
  Column,
  IssuerInfoContainer,
} from '@/view/Admin/TransactionSingleView/IssuerInfo/IssuerInfoForm/IssuerInfoForm.styles';

interface IssuerInfoProps {
  transaction: BondTransactionResponse | ShareTransactionResponse;
  assets: AssetResponse[];
}

const IssuerInfoForm: FC<IssuerInfoProps> = ({ transaction, assets }) => {
  const { translate } = useTranslation();
  const { setValue } = useFormContext();
  const [getImageUrl, { data: imageUrl }] = useAssignedAssetUrlMutation();

  useEffect(() => {
    if (transaction && assets) {
      setFieldValues(parseFormObject(transaction.issuer, assets, translate), setValue);
    }
  }, [transaction, assets]);

  useEffect(() => {
    const picture = findAssetByType(assets, ASSET_TYPE_ENUM.PICTURE);
    if (assets && assets.length && picture) {
      getImageUrl({ assetId: picture.id });
    }
  }, [assets]);

  return (
    <IssuerInfoFormContainer>
      <IssuerInfoWrapper>
        <IssuerLogoWrapper>{<IssuerLogo src={imageUrl} />}</IssuerLogoWrapper>
        <IssuerInfoContainer>
          <RowFirst>
            <Column>
              <ControlledInput placeholder={translate('corporateName')} id={'name'} name={'name'} disabled={true} />
            </Column>
            <Column>
              <ControlledInput
                placeholder={translate('phoneNumber')}
                id={'phoneNumber'}
                name={'phoneNumber'}
                disabled={true}
              />
            </Column>
            <Column>
              <ControlledInput
                placeholder={translate('legalForm')}
                id={'legalForm'}
                name={'legalForm'}
                disabled={true}
              />
            </Column>
          </RowFirst>
          <RowFirst>
            <Column>
              <ControlledInput
                placeholder={translate('businessFocus')}
                id={'businessFocus'}
                name={'businessFocus'}
                disabled={true}
              />
            </Column>
            <Column>
              <ControlledInput placeholder={translate('address')} id={'address'} name={'address'} disabled={true} />
            </Column>
            <Column>
              <ControlledInput placeholder={translate('city')} id={'city'} name={'city'} disabled={true} />
            </Column>
          </RowFirst>
          <RowFirst>
            <Column>
              <ControlledInput placeholder={translate('email')} id={'email'} name={'email'} disabled={true} />
            </Column>
            <Column>
              <ControlledInput placeholder={translate('country')} id={'country'} name={'country'} disabled={true} />
            </Column>
            <Column>
              <ControlledInput placeholder={translate('zipCode')} id={'zipCode'} name={'zipCode'} disabled={true} />
            </Column>
          </RowFirst>
          <RowFirst>
            <Column>
              <ControlledInput placeholder={translate('CEO')} id={'ceo'} name={'ceo'} disabled={true} />
            </Column>
            <Column>
              <ControlledInput placeholder={translate('owner')} id={'owner'} name={'owner'} disabled={true} />
            </Column>
          </RowFirst>
        </IssuerInfoContainer>
      </IssuerInfoWrapper>
    </IssuerInfoFormContainer>
  );
};

export default IssuerInfoForm;
