import { TabsNavigation } from 'issuix-ui-kit';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';

import CouponsIssued from '../../Shared/CouponsIssued/CouponsIssued';

import { AccountPage } from './MyAccount.style';

import AccountNavigationItem from '@/components/AccountNavigation/AccountNavigationItem';
import ProtectedRoute from '@/components/ProtectedRoute/ProtectedRoute';
import RoleProtected from '@/components/RoleProtected/RoleProtected';
import ShareholdersLedger from '@/components/ShareholdersLedger/ShareholdersLedger';
import UserDetails from '@/components/UserDetailsView/UserDetailsView';
import UserTransactions from '@/components/UserTransactions/UserTransactions';
import { ROLE_TYPE } from '@/enums/RoleType';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import {
  useGetCorporateUserAssetsQuery,
  useGetMyCorporateUserQuery,
  useGetMyPrivateUserQuery,
  useGetPrivateUserAssetsQuery,
} from '@/store/service';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';
import SmartBondsCreatedView from '@/view/Core/SmartBondCreatedView/SmartBondCreatedView';
import SmartBondPortfolio from '@/view/Core/SmartBondPortfolio/SmartBondPortfolio';
import SmartShareLedger from '@/view/Core/SmartShareLedger/SmartShareLedger';
import SmartSharePortfolio from '@/view/Core/SmartSharePortfolio/SmartSharePortfolio';

interface AccountProps {
  isMyAccount?: boolean;
}

const Account: FC<AccountProps> = ({ isMyAccount = false }) => {
  const user = useSelector((state: any) => state.global.user);
  const location = useLocation();
  const { translate } = useTranslation();

  const { data: myPrivateUser } = useGetMyPrivateUserQuery(user.id, {
    skip: !user.id || user.role !== ROLE_TYPE.PRIVATE,
  });
  const { data: myCorporateUser } = useGetMyCorporateUserQuery(user.id, {
    skip: !user.id || user.role !== ROLE_TYPE.CORPORATE,
  });

  const userId = myPrivateUser?.id || myCorporateUser?.id;

  const { data: privateUserAssets } = useGetPrivateUserAssetsQuery(userId, { skip: !myPrivateUser });
  const { data: corporateUserAssets } = useGetCorporateUserAssetsQuery(userId, {
    skip: !myCorporateUser,
  });

  return (
    <AccountPage>
      <TabsNavigation>
        <>
          <RoleProtected allowedRoles={[ROLE_TYPE.CORPORATE, ROLE_TYPE.PRIVATE]}>
            <AccountNavigationItem
              isMyAccount={isMyAccount}
              label={myPrivateUser ? translate('userDetails') : translate('companyDetails')}
              path={''}
            />
          </RoleProtected>
          <RoleProtected allowedRoles={[ROLE_TYPE.CORPORATE]}>
            <AccountNavigationItem
              isMyAccount={isMyAccount}
              label={translate('smartBondsCreated')}
              path={ClientRoutes.MyAccount.smartBondCreated}
            />
          </RoleProtected>
          <RoleProtected allowedRoles={[ROLE_TYPE.CORPORATE, ROLE_TYPE.PRIVATE]}>
            <AccountNavigationItem
              isMyAccount={isMyAccount}
              label={translate('smartBondsPortfolio')}
              path={ClientRoutes.MyAccount.smartBondsPortfolio}
            />
          </RoleProtected>
          <RoleProtected allowedRoles={[ROLE_TYPE.CORPORATE]}>
            <AccountNavigationItem
              isMyAccount={isMyAccount}
              label={translate('couponsIssued')}
              path={ClientRoutes.MyAccount.couponIssued}
            />
          </RoleProtected>
          <RoleProtected allowedRoles={[ROLE_TYPE.CORPORATE]}>
            <AccountNavigationItem
              isMyAccount={isMyAccount}
              label={translate('smartShareLedger')}
              path={ClientRoutes.MyAccount.smartShareLedger}
            />
          </RoleProtected>
          <RoleProtected allowedRoles={[ROLE_TYPE.CORPORATE]}>
            <AccountNavigationItem
              isMyAccount={isMyAccount}
              label={translate('shareholdersLedger')}
              path={ClientRoutes.MyAccount.shareholdersLedger}
            />
          </RoleProtected>
          <RoleProtected allowedRoles={[ROLE_TYPE.CORPORATE, ROLE_TYPE.PRIVATE]}>
            <AccountNavigationItem
              isMyAccount={isMyAccount}
              label={translate('smartSharesPortfolio')}
              path={ClientRoutes.MyAccount.smartSharesPortfolio}
            />
          </RoleProtected>
          <RoleProtected allowedRoles={[ROLE_TYPE.CORPORATE, ROLE_TYPE.PRIVATE]}>
            <AccountNavigationItem
              isMyAccount={isMyAccount}
              label={translate('transactions')}
              path={ClientRoutes.MyAccount.transactions}
            />
          </RoleProtected>
        </>
      </TabsNavigation>
      <Routes location={location}>
        <Route
          path={''}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.CORPORATE, ROLE_TYPE.PRIVATE]}>
              <UserDetails
                privateUser={myPrivateUser}
                corporateUser={myCorporateUser}
                assets={privateUserAssets || corporateUserAssets}
                isMyAccount={isMyAccount}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path={ClientRoutes.MyAccount.smartBondCreated}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.CORPORATE]}>
              <SmartBondsCreatedView />
            </ProtectedRoute>
          }
        />
        <Route
          path={ClientRoutes.MyAccount.smartBondsPortfolio}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.CORPORATE, ROLE_TYPE.PRIVATE]}>
              <SmartBondPortfolio userId={userId} />
            </ProtectedRoute>
          }
        />
        <Route
          path={ClientRoutes.MyAccount.smartShareLedger}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.CORPORATE, ROLE_TYPE.PRIVATE]}>
              <SmartShareLedger userId={userId} />
            </ProtectedRoute>
          }
        />
        <Route
          path={ClientRoutes.MyAccount.couponIssued}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.CORPORATE]}>
              <CouponsIssued userId={userId} />
            </ProtectedRoute>
          }
        />
        <Route
          path={ClientRoutes.MyAccount.smartSharesPortfolio}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.CORPORATE, ROLE_TYPE.PRIVATE]}>
              <SmartSharePortfolio userId={userId} />
            </ProtectedRoute>
          }
        />
        <Route
          path={ClientRoutes.MyAccount.transactions}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.CORPORATE, ROLE_TYPE.PRIVATE]}>
              <UserTransactions userId={userId} />
            </ProtectedRoute>
          }
        />
        <Route
          path={ClientRoutes.MyAccount.shareholdersLedger}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.CORPORATE]}>
              <ShareholdersLedger userId={userId} />
            </ProtectedRoute>
          }
        />
      </Routes>
    </AccountPage>
  );
};
export default Account;
