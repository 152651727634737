import { parse, format, parseISO } from 'date-fns';

export const formatDate = (date: string) => {
  return format(new Date(date), 'dd/MM/yyyy');
};

export const formatIsoDate = (date: string) => {
  return parseISO(date);
};

export const formatDateWithTime = (date: string) => {
  return parse(date, "yyyy-M-dd'T'HH:mm:ss.SSSX", new Date());
};
