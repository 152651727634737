import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const legalFormSlice = createSlice({
  name: 'legalForm',
  initialState,
  reducers: {},
});

export default legalFormSlice.reducer;
