import { Button } from 'issuix-ui-kit';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AdminChangePasswordModalContainer, FullWidthRow, ActionContainer } from './AssignToShareModalForm.style';

import ControlledDateInput from '@/components/Form/ControlledDateInput';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledMultipleSelect from '@/components/Form/ControlledSelectMultiple';
import { Row } from '@/components/Layout/Row/Row.styled';
import { TENANT } from '@/enums/App';
import { ROLE_TYPE } from '@/enums/RoleType';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { RootState } from '@/store';
import { useAssignShareMutation, useGetAllUsersQuery } from '@/store/service';
import { getTenant } from '@/utils/getTenant/getTenant';

interface AssignToShareModalFormProps {
  setIsOpen: (isOpen: boolean) => void;
}

const AssignToShareModalForm: FC<AssignToShareModalFormProps> = ({ setIsOpen }) => {
  const { translate } = useTranslation();
  const params = useParams();
  const {
    getValues,
    formState: { isValid },
  } = useFormContext();
  const user = useSelector((state: RootState) => state.global.user);
  const [users, setUsers] = useState([]);
  const [assignShare, { isLoading }] = useAssignShareMutation();

  const tenant = getTenant();

  const { data: usersData } = useGetAllUsersQuery();

  const onAssigneShare = () => {
    const dateString = getValues().date;
    const date = new Date(dateString);
    date.setHours(date.getHours() + 9);
    const isoDate = date.toISOString();

    tenant === TENANT.GREEN_STATE
      ? assignShare({ ...getValues(), date: isoDate, userId: params.id })
      : getValues().userId.map(async (id: string) => {
          const data = { ...getValues(), userId: id };
          await assignShare(data);
        });

    setIsOpen(false);
  };

  useEffect(() => {
    if (usersData) {
      setUsers(
        usersData
          .filter((userData: any) => userData.id !== user?.id)
          .map((user: any) => {
            return {
              label: user.name,
              value: user.id,
            };
          })
      );
    }
  }, [usersData]);

  return (
    <AdminChangePasswordModalContainer>
      <FullWidthRow>
        <ControlledInput name={'quantity'} placeholder={translate('quantity')} id={'quantity'} />
      </FullWidthRow>
      <FullWidthRow>
        <ControlledDateInput
          placeholder={translate('date')}
          id="date"
          name="date"
          maxDate={moment(new Date(), 'dd.mm.yyyy').toDate()}
        />
      </FullWidthRow>
      {(tenant === TENANT.META_SWISS || user?.role !== ROLE_TYPE.ADMIN) && (
        <FullWidthRow>
          <ControlledMultipleSelect name={'userId'} items={users} placeholder={translate('selectUsers')} />
        </FullWidthRow>
      )}
      <Row>
        <ActionContainer>
          <Button text={translate('cancel')} onClick={() => setIsOpen(false)} buttonType={'secondary'} />
          <Button
            text={translate('assign')}
            onClick={() => onAssigneShare()}
            disabled={!isValid}
            buttonType={'primary'}
            isLoading={isLoading}
          />
        </ActionContainer>
      </Row>
    </AdminChangePasswordModalContainer>
  );
};

export default AssignToShareModalForm;
