import { stringify } from 'qs';

import { pagingLimit } from '@/shared/constants/Paging';
import { PageableItems } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseCasesUrl = 'case';

const getCasesForRecipientUrl = (offset: string, query: { recipientId: string }) =>
  `${baseCasesUrl}?offset=${offset}&limit=${pagingLimit}&${stringify(query)}`;

export const getCasesForRecipient = (build: BuildType) => {
  return build.query<PageableItems, any>({
    providesTags: ['GetCasesForRecipient'],
    query: ({ offset, query }) => ({
      url: getCasesForRecipientUrl(offset, query),
      method: 'GET',
    }),
  });
};
