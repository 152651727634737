import { pagingLimit } from '@/shared/constants/Paging';
import { PageableItems } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const getNftCollectionHoldersPath = 'admin/nft-collection';

const getNftCollectionHoldersUrl = (id: string, offset: number) =>
  `${getNftCollectionHoldersPath}/${id}/holders?offset=${offset}&limit=${pagingLimit}`;

export const getNftCollectionHolders = (build: BuildType) => {
  return build.query<PageableItems, any>({
    query: ({ offset, query }) => ({
      url: getNftCollectionHoldersUrl(query.id, offset),
      method: 'GET',
    }),
  });
};
