import { Button, ModalDialog, Text } from 'issuix-ui-kit';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import ControlledInput from '@/components/Form/ControlledInput';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useCreateCaseMutation } from '@/store/service';
import { ButtonsContainer } from '@/view/Admin/Requests/Requests.styles';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const CreateCaseModal: FC<Props> = ({ isOpen, setIsOpen }) => {
  const { translate } = useTranslation();
  const { id } = useParams();
  const [createCase] = useCreateCaseMutation();

  const {
    getValues,
    reset,
    formState: { errors, isValid },
  } = useFormContext();

  const onSubmitHandler = () => {
    createCase({ ...getValues(), recipientId: id });
    setIsOpen(false);
    reset();
  };

  const onCancelHandler = () => {
    reset();
    setIsOpen(false);
  };

  return (
    <ModalDialog isOpen={isOpen} close={() => setIsOpen(false)}>
      <Text content={translate('createCase')} size={'large'} />
      <ControlledInput name={'name'} placeholder={translate('name')} />
      <ControlledTextarea name={'description'} label={translate('description')} errors={errors} />
      <ButtonsContainer>
        <Button text={translate('cancel')} onClick={onCancelHandler} buttonType={'secondary'} />
        <Button text={translate('create')} onClick={onSubmitHandler} disabled={!isValid} />
      </ButtonsContainer>
    </ModalDialog>
  );
};

export default CreateCaseModal;
