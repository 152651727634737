import styled from 'styled-components';

export const VerifyContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const VerifyLogo = styled.div`
  position: absolute;
  left: 40px;
  top: 40px;
  width: 300px;

  @media (max-width: 767px) {
    width: 200px;
  }
`;

export const VerifyIcon = styled.div`
  width: 200px;
  height: 200px;
  margin-bottom: 6rem;
  display: flex;

  @media (max-width: 767px) {
    height: 150px;
    width: 150px;
  }
`;

export const WelcomeContainer = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  text-align: center;
`;
