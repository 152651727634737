import React, { createContext, useContext, FC, useState } from 'react';

interface IAuthContext {
  modalType: string | null;
  setModalType: (modalType: string | null) => void;
  closeModal: () => void;
}

interface AuthContextProviderProps {
  children: React.ReactNode;
}

export const AuthContext = createContext({} as IAuthContext);

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider: FC<AuthContextProviderProps> = ({ children }) => {
  const [modalType, setModalType] = useState<string | null>(null);

  const closeModal = () => {
    setModalType(null);
  };

  return (
    <AuthContext.Provider
      value={{
        modalType,
        setModalType,
        closeModal,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
