import { QuickRegistrationPrivateUserRequest, RegisterResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const registrationPath = 'auth/private/quick-signup';

export const quickRegistrationPrivateUser = (build: BuildType) => {
  return build.mutation<RegisterResponse, QuickRegistrationPrivateUserRequest>({
    query: (data: QuickRegistrationPrivateUserRequest) => ({
      url: registrationPath,
      method: 'POST',
      body: data,
    }),
  });
};
