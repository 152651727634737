import { Text } from 'issuix-ui-kit';
import { FC, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Documentation } from '@/components/BondAndShare/Documentation';
import ControlledDateInput from '@/components/Form/ControlledDateInput';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledSelectMultiple from '@/components/Form/ControlledSelectMultiple';
import FullDisabledField from '@/components/FullDisabledField/FullDisabledField';
import { Row } from '@/components/Layout/Row/Row.styled';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useCurrencies } from '@/hooks/useCurrencies/UseCurrencies';
import { useRegions } from '@/hooks/useRegions/UseRegions';
import { useRoundOptions } from '@/hooks/useRoundOptions/useRoundOptions';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { AssetResponse, CurrencyResponse, ShareResponse } from '@/shared/types/api';
import { formatAmountWithoutRate } from '@/utils/formatAmount/FormatAmount';
import { getFilteredCurrencies } from '@/utils/getFilteredCurrencies/GetFilteredCurrencies';
import { parseFormObject } from '@/utils/getShareFieldValues/getShareFieldValues';
import { setFieldValues } from '@/utils/setFieldValues/setFieldValues';
import { Column } from '@/view/Shared/ViewBond/SmartBondFeatures/SmartBondFeaturesForm/SmartBondFeaturesForm.styles';
import {
  RowFirst,
  SmartShareFeatureFormContainer,
} from '@/view/Shared/ViewShare/SmartShareFeatures/SmartShareFeaturesForm/SmartShareFeaturesForm.styles';

interface SmartShareFeaturesFormProps {
  share: ShareResponse;
  assets: AssetResponse[];
}

const SmartShareFeaturesForm: FC<SmartShareFeaturesFormProps> = ({ share, assets }) => {
  const { translate } = useTranslation();
  const { currencies, currenciesAsDropdown } = useCurrencies();
  const { roundOptionsAsDropdown } = useRoundOptions();
  const { regionsAsDropdown } = useRegions(translate);
  const currency = useAppSelector((state) => state.global.currency);

  const {
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext();
  const filteredCurrencies: CurrencyResponse[] = useMemo(() => getFilteredCurrencies(currencies), [currencies]);

  useEffect(() => {
    if (share) {
      setFieldValues(parseFormObject(share, assets, currency), setValue);
    }
  }, [share, assets, currency]);

  const valueWatcher = watch(['price']);

  const [val, setVal] = useState<number>(0);

  useEffect(() => {
    if (valueWatcher.length && valueWatcher[0]) {
      setVal(valueWatcher[0]);
    } else {
      setVal(0);
    }
  }, [valueWatcher]);

  const calculatedValues = useMemo(() => {
    return filteredCurrencies.map((currency) => (
      <Column key={currency.currencyCode}>
        <FullDisabledField
          key={currency.currencyCode}
          value={+formatAmountWithoutRate(val / currency.rate, currency.decimals)}
          label={currency.currencyCode}
        />
      </Column>
    ));
  }, [filteredCurrencies, val]);

  return (
    <SmartShareFeatureFormContainer>
      <RowFirst>
        <Column>
          <ControlledInput
            name={'price'}
            placeholder={translate('pricePerShare')}
            id={'price'}
            disabled
            type={'number'}
          />
        </Column>
        <Column>
          <Text content={'='} size={'large'} />
        </Column>
        {calculatedValues}
      </RowFirst>
      <RowFirst>
        <Column>
          <ControlledInput name={'totalAmount'} placeholder={translate('totalAmount')} id={'totalAmount'} disabled />
        </Column>
        <Column>
          <ControlledInput
            name={'availableForSale'}
            placeholder={translate('availableForSale')}
            id={'availableForSale'}
            disabled
          />
        </Column>
        <Column>
          <ControlledSelectMultiple
            name={'currencies'}
            items={currenciesAsDropdown}
            disabled
            placeholder={translate('sellInCurrency')}
          />
        </Column>
        <Column>
          <ControlledInput name={'isin'} placeholder={translate('isin')} id={'isin'} disabled />
        </Column>
        <Column>
          <ControlledInput name={'shareCapital'} placeholder={translate('totalEquity')} id={'shareCapital'} disabled />
        </Column>
        <Column>
          <ControlledInput name={'nominalValue'} placeholder={translate('nominalValue')} id={'nominalValue'} disabled />
        </Column>
      </RowFirst>
      <RowFirst>
        <Column>
          <ControlledDateInput name={'issuedDate'} placeholder={translate('issuingDate')} id={'issuedDate'} disabled />
        </Column>
        <Column>
          <ControlledInput name={'type'} placeholder={translate('type')} id={'type'} disabled />
        </Column>
        <Column>
          <ControlledSelect items={roundOptionsAsDropdown} placeholder={translate('round')} name={'round'} disabled />
        </Column>
        <Column>
          <ControlledInput
            name={'roundDuration'}
            placeholder={translate('roundDuration')}
            id={'roundDuration'}
            disabled
          />
        </Column>
        <Column>
          <ControlledInput
            name={'minimalInvestment'}
            placeholder={translate('minimalInvestment')}
            id={'minimalInvestment'}
            disabled
          />
        </Column>
        <Column>
          <ControlledSelectMultiple
            items={regionsAsDropdown}
            name={'regions'}
            placeholder={translate('availableTo')}
            disabled
          />
        </Column>
      </RowFirst>
      <Row>
        <Documentation assets={assets} isEditMode={false} setValue={setValue} getValues={getValues} errors={errors} />
      </Row>
    </SmartShareFeatureFormContainer>
  );
};

export default SmartShareFeaturesForm;
