import { Card } from 'issuix-ui-kit';
import styled from 'styled-components';

export const NftHoldersContainer = styled(Card).attrs(() => ({
  notFullHeight: true,
}))`
  width: 100%;
  padding: 4rem 2rem;
  position: relative;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  margin-left: 2rem;
`;

export const TableContainer = styled.div`
  height: calc(100% - 5rem);
`;
