import { BuildType } from '@/shared/types/redux/ReduxAction';

const bondPortfolioCalculations = (query: { id: string }) => `calculations/bond-portfolio?userId=${query.id}`;

export const getBondPortfolioCalculations = (build: BuildType) => {
  return build.query<any, any>({
    query: ({ query }) => ({
      url: bondPortfolioCalculations(query),
      method: 'GET',
    }),
  });
};
