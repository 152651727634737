import { FC } from 'react';
import styled from 'styled-components';

interface Props {
  text: string;
}
export const ErrorPage: FC<Props> = ({ text }) => {
  return (
    <Screen>
      <p>{text}</p>
    </Screen>
  );
};

const Screen = styled.div`
  height: 100vh;
  width: 100vw;
  background: linear-gradient(180deg, rgba(27, 90, 161, 0.8) 0%, rgba(64, 153, 232, 0.8) 100%);
  font-size: 20rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;
