import { Text } from 'issuix-ui-kit';
import { FC } from 'react';
import styled from 'styled-components';

import TermsAndConditions from '../../TermsAndConditions/TermsAndConditions';

import { useAuthContext } from '@/components/Auth/AuthContext';
import SignInForm from '@/components/AuthModal/SignInModal/SignInForm/SignInForm';
import { LogoContainer, SeparateLine, SignInContainer } from '@/components/AuthModal/SignInModal/SignInModal.styles';
import { signInSchema } from '@/components/AuthModal/SignInModal/SignInSchema';
import ControlledForm from '@/components/Form/ControlledForm';
import FullRegistrationLink from '@/components/FullRegistrationLink/FullRegistrationLink';
import Logo from '@/components/Logo/Logo';
import { AUTH_MODAL_TYPE } from '@/enums/AuthModalType';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { getTenant } from '@/utils/getTenant/getTenant';

interface SignInModalProps {
  hideFullRegistration?: boolean;
}

const SignInModal: FC<SignInModalProps> = ({ hideFullRegistration }) => {
  const { translate } = useTranslation();
  const { setModalType } = useAuthContext();
  const tenant = getTenant();

  return (
    <SignInContainer>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <Text
        content={translate('welcomeTo', { tenant: tenant.charAt(0).toUpperCase() + tenant.slice(1) ?? '' })}
        size="large"
      />
      <ControlledForm schema={signInSchema(translate)}>
        <SignInForm />
      </ControlledForm>
      {!hideFullRegistration && (
        <>
          <SignUpContainer>
            <Text size={'medium'} content={translate('dontHaveAnAccount')} />
            <SignUp onClick={() => setModalType(AUTH_MODAL_TYPE.REGISTER)}>{translate('signUp')}</SignUp>
          </SignUpContainer>

          <TermsAndConditions />
          <SeparateLine />
          <FullRegistrationLink />
        </>
      )}
    </SignInContainer>
  );
};

export default SignInModal;

const SignUpContainer = styled.div`
  margin-top: 2rem;
`;

const SignUp = styled.a`
  font-size: ${(props) => props.theme.typography.medium.fontSize};
  margin-left: 2px;
`;
