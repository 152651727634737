import { BuildType } from '@/shared/types/redux/ReduxAction';

const getTotalNftsByUserIdUrl = (userId: string) => `/admin/nft/user/${userId}/total`;

export const getTotalNftsByUserId = (build: BuildType) => {
  return build.query<{ total: number }, any>({
    query: ({ id }) => ({
      url: getTotalNftsByUserIdUrl(id),
      method: 'GET',
    }),
  });
};
