import { FieldValues } from 'react-hook-form';

export const mapFieldValuesToThirdStepRegisterData = (data: FieldValues, assetTypes: string[]) => {
  const assetsData = assetTypes.reduce((prev, current) => {
    return { ...prev, [current]: data[current].fileId };
  }, {});

  return {
    ...data,
    phoneNumberPrefix: '+00',
    firstTimeLogin: false,
    ...assetsData,
  };
};
