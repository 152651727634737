import { Button, IColumn, Table, Text } from 'issuix-ui-kit';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Download from '@/assets/core/icons/download.svg';
import DownloadDocumentsModal from '@/components/DocumentsModal/DownloadDocumentsModal';
import {
  CardHeader,
  DownloadButton,
  TableContainer,
  UserTransactionsContainer,
} from '@/components/UserTransactions/UserTransactions.styles';
import { PRODUCT_TYPE_ENUM } from '@/enums/ProductType';
import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { TransactionTable } from '@/shared/types/TransactionTable';
import { useGetTransactionsByUserIdQuery } from '@/store/service';
import { composePath } from '@/utils/composePath/ComposePath';
import { formatDate } from '@/utils/date/FormatDate';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';

interface UserTransactionsProps {
  userId?: string;
}

const UserTransactions: FC<UserTransactionsProps> = ({ userId }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const { fetch, data } = useTableWithPagination({ query: useGetTransactionsByUserIdQuery, id: userId });
  const [fileItem, setFileItem] = useState<any>();

  const onActionClick = (item: TransactionTable) => {
    switch (item.productType) {
      case PRODUCT_TYPE_ENUM.ACTIONS:
        return navigate(composePath(ClientRoutes.Transactions.shareSingleView, { id: item.id }));
      case PRODUCT_TYPE_ENUM.BOND_PAPERS:
        return navigate(composePath(ClientRoutes.Transactions.bondSingleView, { id: item.id }));
      case PRODUCT_TYPE_ENUM.NFT:
        return navigate(composePath(ClientRoutes.Transactions.nftSingleView, { id: item.id }));
    }
  };

  const tableDef: IColumn[] = [
    {
      name: 'productType',
      width: 1,
      header: {
        label: translate('productType').toUpperCase(),
      },
    },
    {
      name: 'buyerName',
      width: 2,
      header: {
        label: translate('companyName').toUpperCase(),
      },
    },
    {
      name: 'country',
      width: 2,
      header: {
        label: translate('country').toUpperCase(),
      },
    },
    {
      name: 'email',
      width: 2,
      header: {
        label: translate('email').toUpperCase(),
      },
    },
    {
      name: 'quantity',
      width: 1,
      header: {
        label: translate('quantity').toUpperCase(),
      },
    },
    {
      name: 'createdAt',
      width: 2,
      header: {
        label: translate('boughtDate').toUpperCase(),
      },
      column: {
        render: (item: TransactionTable) => {
          return <p>{item.createdAt ? formatDate(item.createdAt) : undefined}</p>;
        },
      },
    },
    {
      name: 'detectedAmount',
      width: 2,
      header: {
        label: translate('detectedAmount').toUpperCase(),
      },
    },
    {
      name: 'actions',
      width: 2,
      header: {
        label: translate('actions').toUpperCase(),
      },
      column: {
        render: (item: TransactionTable) => (
          <Button fullyRounded onClick={() => onActionClick(item)} text={translate('view')} size={'medium'} />
        ),
      },
    },
    {
      name: 'download',
      width: 1,
      header: {
        label: translate('documents').toUpperCase(),
      },
      column: {
        render: (item: TransactionTable) => {
          if (!item.assets) {
            return <Text content={'No Documents'} />;
          }
          return <DownloadButton src={Download} alt={'download'} onClick={() => onDownloadClickHandler(item)} />;
        },
      },
    },
  ];

  const onDownloadClickHandler = (item: any) => {
    if (fileItem?.id) {
      return;
    }
    setFileItem(item);
  };

  const onModalClose = () => {
    setFileItem(null);
  };

  return (
    <UserTransactionsContainer>
      {fileItem?.id && (
        <DownloadDocumentsModal isModalOpen={fileItem?.id} onModalClose={onModalClose} transaction={fileItem} />
      )}
      <CardHeader>
        <Text content={translate('transactions')} size={'large'} />
      </CardHeader>
      <TableContainer>
        <Table data={data} fetchTrigger={() => fetch()} tableDef={tableDef} />
      </TableContainer>
    </UserTransactionsContainer>
  );
};

export default UserTransactions;
