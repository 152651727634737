import styled from 'styled-components';

import { Row } from '@/components/Layout/Row/Row.styled';

export const ActionContainer = styled.div`
  display: flex;
  gap: 2rem;
  height: fit-content;
  align-self: flex-end;
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const AdminChangePasswordModalContainer = styled.div`
  width: 100%;
  padding-inline: 8rem;
  padding-bottom: 6rem;
  gap: 3rem;
  position: relative;
`;

export const FullWidthRow = styled(Row)`
  & > div {
    width: 100%;
  }
`;
