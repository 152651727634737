import { BusinessFocusResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const businessFocusPath = 'business-focus';

export const getBusinessFocus = (build: BuildType) => {
  return build.query<BusinessFocusResponse[], void>({
    query: () => businessFocusPath,
  });
};
