import { colors, GlobalSpinner } from 'issuix-ui-kit';
import { FC } from 'react';

import { PRODUCT_TYPE_ENUM } from '@/enums/ProductType';
import { useNav } from '@/hooks/useNav/UseNav';
import {
  useGetCorporateUserAssetsQuery,
  useGetPrivateUserAssetsQuery,
  useGetShareTransactionByIdQuery,
} from '@/store/service';
import BuyerInfo from '@/view/Admin/TransactionSingleView/BuyerInfo/BuyerInfo';
import IssuerInfo from '@/view/Admin/TransactionSingleView/IssuerInfo/IssuerInfo';
import TransactionInfo from '@/view/Admin/TransactionSingleView/TransactionInfo/TransactionInfo';
import { TransactionSingleViewWrapper } from '@/view/Admin/TransactionSingleView/TransactionSingleView.styles';

const ShareTransactionSingleView = () => {
  return <ShareTransactionSingleViewContent />;
};

const ShareTransactionSingleViewContent: FC = () => {
  const { params } = useNav();
  const { data, isLoading } = useGetShareTransactionByIdQuery(
    { query: { id: params.id && params.id } },
    { skip: !params.id }
  );

  const { data: assets } = useGetCorporateUserAssetsQuery(data?.issuer?.id, { skip: !data });
  const { data: buyerAssets } = useGetPrivateUserAssetsQuery(data?.buyer?.id, { skip: !data });

  return (
    <GlobalSpinner isActive={!data && isLoading} background={colors.blue.primary}>
      <TransactionSingleViewWrapper>
        <TransactionInfo transaction={data} type={PRODUCT_TYPE_ENUM.ACTIONS} />
        <IssuerInfo transaction={data} assets={assets} />
        <BuyerInfo transaction={data} assets={buyerAssets} />
      </TransactionSingleViewWrapper>
    </GlobalSpinner>
  );
};

export default ShareTransactionSingleView;
