import { Text } from 'issuix-ui-kit';
import { FC } from 'react';
import styled, { useTheme } from 'styled-components';

interface Props {
  label: string;
  value: string | number;
  className?: string;
}

const FullDisabledField: FC<Props> = ({ label, value, className }) => {
  const theme = useTheme();

  return (
    <DisabledCurrencyStyle className={className}>
      <Text size={'small'} content={label} color={theme.colors.green.primary} />
      <Text size={'medium'} content={value.toString()} />
    </DisabledCurrencyStyle>
  );
};

export default FullDisabledField;

export const DisabledCurrencyStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid white;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  height: 5rem;
  background-color: rgba(233, 233, 233, 0.5);
  border-radius: 0.5rem 0.5rem 0 0;
  margin-top: -2rem;
  margin-bottom: 2rem;
  text-align: left;
`;
