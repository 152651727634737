import { PageableItems } from '@/shared/types/PageableItems';
import { SmartShareLedgerResponse } from '@/shared/types/SmartShareLedgerResponse';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseSmartShareLedgerUrl = 'share-transaction';

const getSmartShareLedgerUrl = (query: { id: string }): string => `/${baseSmartShareLedgerUrl}?userId=${query.id}`;

export const getSmartShareLedger = (build: BuildType) => {
  return build.query<PageableItems<SmartShareLedgerResponse>, any>({
    query: ({ query }) => ({
      url: getSmartShareLedgerUrl(query),
      method: 'GET',
    }),
  });
};
