import { Button, Text } from 'issuix-ui-kit';
import { FC, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Documentation } from '@/components/BondAndShare/Documentation';
import ControlledDateInput from '@/components/Form/ControlledDateInput';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledSelectMultiple from '@/components/Form/ControlledSelectMultiple';
import FullDisabledField from '@/components/FullDisabledField/FullDisabledField';
import { Row } from '@/components/Layout/Row/Row.styled';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useAuth } from '@/hooks/useAuth/useAuth';
import { useCurrencies } from '@/hooks/useCurrencies/UseCurrencies';
import { useEditEntity } from '@/hooks/useEditEntity/useEditEntity';
import { useRegions } from '@/hooks/useRegions/UseRegions';
import { useRoundOptions } from '@/hooks/useRoundOptions/useRoundOptions';
import { useShareTypeOptions } from '@/hooks/useShareTypeOptions/useShareTypeOptions';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { AssetResponse, CurrencyResponse, ShareResponse } from '@/shared/types/api';
import { useUpdateShareMutation } from '@/store/service';
import { offeringMonthOptions } from '@/utils/constants/OfferingMonthOptions';
import { formatAmountWithoutRate } from '@/utils/formatAmount/FormatAmount';
import { getFilteredCurrencies } from '@/utils/getFilteredCurrencies/GetFilteredCurrencies';
import { parseFormObject } from '@/utils/getShareFieldValues/getShareFieldValues';
import { setFieldValues } from '@/utils/setFieldValues/setFieldValues';
import { Column } from '@/view/Shared/ViewBond/SmartBondFeatures/SmartBondFeaturesForm/SmartBondFeaturesForm.styles';
import {
  RowFirst,
  SmartShareFeatureFormContainer,
  ActionContainer,
} from '@/view/Shared/ViewShare/SmartShareFeatures/SmartShareFeaturesForm/SmartShareFeaturesForm.styles';

interface SmartShareFeaturesFormProps {
  share: ShareResponse;
  isEditMode: boolean;
  setIsEditMode: (value: boolean) => void;
  assets: AssetResponse[];
}

const SmartShareFeaturesForm: FC<SmartShareFeaturesFormProps> = ({ share, isEditMode, setIsEditMode, assets }) => {
  const { translate } = useTranslation();
  const { currencies, currenciesAsDropdown } = useCurrencies();
  const { roundOptionsAsDropdown } = useRoundOptions();
  const { regionsAsDropdown } = useRegions(translate);
  const {
    setValue,
    watch,
    getValues,
    formState: { dirtyFields, errors },
  } = useFormContext();
  const filteredCurrencies: CurrencyResponse[] = useMemo(() => getFilteredCurrencies(currencies), [currencies]);
  const currency = useAppSelector((state) => state.global.currency);
  const { updateShareBond, cancelHandler } = useEditEntity();
  const { shareTypeOptionsAsDropdown } = useShareTypeOptions(translate);

  const [updateShare] = useUpdateShareMutation();

  const { user } = useAuth();

  useEffect(() => {
    if (share) {
      setFieldValues(parseFormObject(share, assets, currency), setValue);
    }
  }, [share, currency, assets]);

  const valueWatcher = watch(['price']);

  const [val, setVal] = useState<number>(0);

  useEffect(() => {
    if (valueWatcher.length && valueWatcher[0]) {
      setVal(valueWatcher[0]);
    } else {
      setVal(0);
    }
  }, [valueWatcher]);

  const calculatedValues = useMemo(() => {
    return filteredCurrencies.map((currency) => (
      <Column key={currency.currencyCode}>
        <FullDisabledField
          key={currency.currencyCode}
          value={+formatAmountWithoutRate(val / currency.rate, currency.decimals)}
          label={currency.currencyCode}
        />
      </Column>
    ));
  }, [filteredCurrencies, val]);

  const onSubmitHandler = async () => {
    await updateShareBond({ shareId: share.id }, updateShare, user);
    setIsEditMode(false);
  };

  const onCancelHandler = () => {
    if (!currency) {
      return;
    }

    setIsEditMode(false);
    cancelHandler(parseFormObject(share, assets));
  };

  return (
    <SmartShareFeatureFormContainer>
      <RowFirst>
        <Column>
          <ControlledInput
            name={'price'}
            placeholder={translate('pricePerShare')}
            id={'price'}
            disabled={!isEditMode}
            type={'number'}
          />
        </Column>
        <Column>
          <Text content={'='} size={'large'} />
        </Column>
        {calculatedValues}
      </RowFirst>
      <RowFirst>
        <Column>
          <ControlledInput
            name={'totalAmount'}
            placeholder={translate('totalAmount')}
            id={'totalAmount'}
            disabled={!isEditMode}
            type={'number'}
          />
        </Column>
        <Column>
          <ControlledInput
            name={'availableForSale'}
            placeholder={translate('availableForSale')}
            id={'availableForSale'}
            disabled={!isEditMode}
            type={'number'}
          />
        </Column>
        <Column>
          <ControlledSelectMultiple
            name={'currencies'}
            items={currenciesAsDropdown}
            disabled={!isEditMode}
            placeholder={translate('sellInCurrency')}
          />
        </Column>
        <Column>
          <ControlledInput name={'isin'} placeholder={translate('isin')} id={'isin'} disabled={!isEditMode} />
        </Column>
        <Column>
          <ControlledInput
            name={'shareCapital'}
            placeholder={translate('totalEquity')}
            id={'shareCapital'}
            disabled={!isEditMode}
            type={'number'}
          />
        </Column>
        <Column>
          <ControlledInput name={'nominalValue'} placeholder={translate('nominalValue')} id={'nominalValue'} disabled />
        </Column>
      </RowFirst>
      <RowFirst>
        <Column>
          <ControlledDateInput
            name={'issuedDate'}
            placeholder={translate('issuingDate')}
            id={'issuedDate'}
            disabled={!isEditMode}
            minDate={new Date()}
          />
        </Column>
        <Column>
          <ControlledSelect
            items={shareTypeOptionsAsDropdown}
            name={'type'}
            placeholder={translate('type')}
            disabled={!isEditMode}
          />
        </Column>
        <Column>
          <ControlledSelect
            items={roundOptionsAsDropdown}
            placeholder={translate('round')}
            name={'round'}
            disabled={!isEditMode}
          />
        </Column>
        <Column>
          <ControlledSelect
            name={'roundDuration'}
            placeholder={translate('roundDuration')}
            disabled={!isEditMode}
            items={offeringMonthOptions}
          />
        </Column>
        <Column>
          <ControlledInput
            name={'minimalInvestment'}
            placeholder={translate('minimalInvestment')}
            id={'minimalInvestment'}
            disabled={!isEditMode}
            type={'number'}
          />
        </Column>
        <Column>
          <ControlledSelectMultiple
            items={regionsAsDropdown}
            name={'regions'}
            placeholder={translate('availableTo')}
            disabled={!isEditMode}
          />
        </Column>
      </RowFirst>
      <Row>
        <Documentation
          assets={assets}
          isEditMode={isEditMode}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
        />
      </Row>
      {isEditMode && (
        <ActionContainer>
          <Button text={translate('cancel')} onClick={onCancelHandler} buttonType={'secondary'} />
          <Button
            text={translate('save')}
            onClick={onSubmitHandler}
            disabled={!!Object.keys(errors).length || !Object.keys(dirtyFields).length}
            buttonType={'primary'}
            isLoading={false}
          />
        </ActionContainer>
      )}
    </SmartShareFeatureFormContainer>
  );
};

export default SmartShareFeaturesForm;
