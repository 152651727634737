import { BuildType } from '@/shared/types/redux/ReduxAction';

const directEmailUrl = () => {
  return `/user-share/send-emails-to-buyers`;
};

export const directEmail = (build: BuildType) => {
  return build.mutation<void, any>({
    query: (body) => ({
      url: directEmailUrl(),
      method: 'POST',
      body,
    }),
  });
};
