import camelCase from 'lodash/camelCase';
import { TFunction } from 'react-i18next';

import { getConcatValues } from '@/shared/commonUtils/getConcatValues/getConcatValues';
import { AssetResponse, BondResponse, CurrencyResponse } from '@/shared/types/api';
import { formatDate, formatDateWithTime } from '@/utils/date/FormatDate';
import { getDenomination } from '@/utils/getBondWithDenomination/getBondWithDenomination';

export const parseFormObject = (
  response: BondResponse,
  currency: CurrencyResponse,
  translate: TFunction,
  assets?: AssetResponse[]
) => {
  const assetsData = assets?.reduce((prev, current) => {
    return { ...prev, [camelCase(current.type)]: { fileId: current.id, url: current.key } };
  }, {});

  const {
    value,
    quantity,
    code,
    interest,
    couponsPaid,
    issuedDate,
    offeringDate,
    offeringMonths,
    isin,
    maturity,
    regions,
    listingOption,
    currencies,
    user,
  } = response;
  return {
    value,
    code,
    quantity,
    interest,
    couponsPaid: translate(couponsPaid.split('_').join('').toLowerCase() as keyof TFunction),
    offeringDate: offeringDate ? formatDate(offeringDate) : undefined,
    issuedDate: issuedDate ? formatDateWithTime(issuedDate) : undefined,
    offeringMonths: offeringMonths.toString(),
    isin,
    maturity,
    regionIds: getConcatValues(regions),
    listingOption: listingOption.toString(),
    sellInCurrencyIds: getConcatValues(currencies),
    denomination: getDenomination(value, quantity, currency),
    issuer: user.name,
    ...assetsData,
  };
};
