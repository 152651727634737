import { createSlice } from '@reduxjs/toolkit';

import {
  AvailabilityOptionResponse,
  CouponPaidOptionResponse,
  EndUserRoleOptionResponse,
  RatingOptionResponse,
  RoundOptionResponse,
  ShareTypeOptionResponse,
} from '@/shared/types/api';

export type OptionsState = {
  availabilityOptionsInitialState: AvailabilityOptionResponse[];
  couponPaidOptionsInitialState: CouponPaidOptionResponse[];
  endUserRoleOptionsInitialState: EndUserRoleOptionResponse[];
  ratingOptionsInitialState: RatingOptionResponse[];
  roundOptionsInitialState: RoundOptionResponse[];
  shareTypeOptionsInitialState: ShareTypeOptionResponse[];
};

const initialState: OptionsState = {
  availabilityOptionsInitialState: [],
  couponPaidOptionsInitialState: [],
  endUserRoleOptionsInitialState: [],
  ratingOptionsInitialState: [],
  roundOptionsInitialState: [],
  shareTypeOptionsInitialState: [],
};

const optionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {},
});

export default optionsSlice.reducer;
