import { AssetBondResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseBondsUrl = 'bonds';

const getBondsUrl = (query: { id: string }): string => {
  return `${baseBondsUrl}/${query.id}/assets`;
};

export const getBondAssets = (build: BuildType) => {
  return build.query<AssetBondResponse[], any>({
    query: ({ query }) => ({
      url: getBondsUrl(query),
      method: 'GET',
    }),
  });
};
