import camelCase from 'lodash/camelCase';

import { AssetResponse, PrivateUserFullResponse } from '@/shared/types/api';
import { formatIsoDate } from '@/utils/date/FormatDate';

export const parseFormObjectPrivateUser = (response: PrivateUserFullResponse, assets: AssetResponse[]) => {
  const { phoneNumber, ethWallet, country, city, btcWallet, address, privateUser, zipCode } = response;

  const assetsData = assets.reduce((prev, current) => {
    return { ...prev, [camelCase(current.type)]: { fileId: current.id, url: current.key } };
  }, {});

  return {
    firstName: privateUser.firstName || '',
    lastName: privateUser.lastName || '',
    phoneNumber: phoneNumber || '',
    zipCode: zipCode,
    ethWallet: ethWallet || '',
    country: country ? country.name : undefined,
    city: city || '',
    citizenship: country?.nationality,
    btcWallet: btcWallet || '',
    birthDate: privateUser.birthDate ? formatIsoDate(privateUser.birthDate) : undefined,
    address: address || '',
    ...assetsData,
  };
};
