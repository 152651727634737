import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const SmartSharesLedgerCalculationContainer = styled.div`
  padding-top: 3rem;
`;
