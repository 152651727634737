import { Card } from 'issuix-ui-kit';
import styled from 'styled-components';

export const NftCollectionDetailsContainer = styled(Card).attrs(() => ({
  notFullHeight: false,
}))`
  width: 100%;
  padding: 4rem 2rem;
  position: relative;
`;

export const EditButtonContainer = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: flex;
  gap: 2rem;
`;
