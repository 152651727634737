import { Button } from 'issuix-ui-kit';
import { useEffect } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { useAuthContext } from '@/components/Auth/AuthContext';
import { FormContainer } from '@/components/AuthModal/ForgotPasswordModal/ForgotPassword.styles';
import { useRegistrationContext } from '@/components/AuthModal/RegistrationContext';
import ControlledInput from '@/components/Form/ControlledInput';
import { AUTH_MODAL_TYPE } from '@/enums/AuthModalType';
import { useCheckEmailOnBlur } from '@/hooks/useCheckEmailOnBlur/useCheckEmailOnBlur';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useForgotPasswordMutation } from '@/store/service';

const ForgotPasswordForm = () => {
  const { translate } = useTranslation();
  const {
    handleSubmit,
    formState: { isValid },
    getValues,
  } = useFormContext();
  const { checkEmailOnBlur, isEmailExist } = useCheckEmailOnBlur();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const { setModalType } = useAuthContext();
  const { submitForgotPasswordData } = useRegistrationContext();

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.code === 'Enter') {
        submitHandler();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [isValid]);

  const submitHandler = () => {
    handleSubmit((data: FieldValues) => {
      forgotPassword(data);
      submitForgotPasswordData(data.email);
    })();
    return setModalType(AUTH_MODAL_TYPE.RESET_PASSWORD_WITH_CODE);
  };

  return (
    <FormContainer>
      <InputContainer>
        <ControlledInput
          name={'email'}
          placeholder={translate('emailAddress')}
          id={'email'}
          onBlur={() => checkEmailOnBlur(getValues().email)}
        />
      </InputContainer>
      <Button
        onClick={submitHandler}
        text={translate('continue')}
        isLoading={isLoading}
        disabled={!isEmailExist || !isValid}
      />
    </FormContainer>
  );
};

export default ForgotPasswordForm;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;
