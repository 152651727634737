import { CryptoPaymentResponse, PaymentRequest, PaymentResponse, StripePaymentRequest } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

export const payViaCard = (build: BuildType) =>
  build.mutation<PaymentResponse, StripePaymentRequest>({
    invalidatesTags: ['GetNftCollectionStatistics'],
    query: (data) => ({
      url: 'payment/stripe',
      method: 'POST',
      body: data,
    }),
  });

export const payViaCrypto = (build: BuildType) =>
  build.mutation<CryptoPaymentResponse, PaymentRequest>({
    query: (data) => ({
      url: 'payment/crypto',
      method: 'POST',
      body: data,
    }),
  });

export const payViaBankTransfer = (build: BuildType) =>
  build.mutation<PaymentResponse, PaymentRequest>({
    invalidatesTags: ['GetNftCollectionStatistics'],
    query: (data) => ({
      url: 'payment/bank-charge',
      method: 'POST',
      body: data,
    }),
  });
