import { Button } from 'issuix-ui-kit';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import ControlledDateInput from '@/components/Form/ControlledDateInput';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledSelect from '@/components/Form/ControlledSelect';
import { Row } from '@/components/Layout/Row/Row.styled';
import { PrivateUserDocumentation } from '@/components/UserDetailsView/components/PrivateUserDetailsForm/PrivateUserDocumentation';
import { ASSET_TYPE_ENUM } from '@/enums/AssetTypeEnum';
import { useCountries } from '@/hooks/useCountries/UseCountries';
import { useEditEntity } from '@/hooks/useEditEntity/useEditEntity';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useUploadFile } from '@/hooks/useUploadFile/useUploadFile';
import { ImageType } from '@/shared/types/Asset';
import { AssetResponse, PrivateUserFullResponse } from '@/shared/types/api';
import {
  useAssignedAssetUrlMutation,
  useUnassignedAssetUrlMutation,
  useUpdateUserDetailsMutation,
} from '@/store/service';
import { findAssetByType } from '@/utils/assets/findAssetByType';
import { parseFormObjectPrivateUser } from '@/utils/getPrivateUserFieldValues/getPrivateUserFieldValues';
import { setFieldValues } from '@/utils/setFieldValues/setFieldValues';

interface PrivateUserDetailsFormProps {
  user?: PrivateUserFullResponse;
  isEditMode: boolean;
  assets: AssetResponse[];
  setIsEditMode: (value: boolean) => void;
}

const PrivateUserDetailsForm: FC<PrivateUserDetailsFormProps> = ({ user, isEditMode, assets, setIsEditMode }) => {
  const { countriesAsDropdown } = useCountries();
  const { translate } = useTranslation();
  const {
    setValue,
    getValues,
    formState: { dirtyFields, errors },
  } = useFormContext();
  const { updateUser, cancelHandler } = useEditEntity();
  const [updateUserMutation] = useUpdateUserDetailsMutation();
  const { uploadUnassignedAsset } = useUploadFile();

  const [unassignedImage, setUnassignedImage] = useState<ImageType | null>();

  const [getSignedUrl] = useUnassignedAssetUrlMutation();
  const [getImageUrl, { data: imageUrl }] = useAssignedAssetUrlMutation();

  useEffect(() => {
    if (assets && user) {
      setFieldValues(parseFormObjectPrivateUser(user, assets), setValue);
    }
  }, [assets, user]);

  useEffect(() => {
    const picture = findAssetByType(assets, ASSET_TYPE_ENUM.PICTURE);
    if (assets && assets.length && picture) {
      getImageUrl({ assetId: picture.id });
    }
  }, [assets]);

  const onClickHandler = async () => {
    if (user) {
      await updateUser(user.id, user.role, updateUserMutation);
      setIsEditMode(false);
    }
  };

  const uploadImageHandler = async (image: File) => {
    await uploadUnassignedAsset(image, 'picture', setValue);
    const newImageAssignedUrl = await getSignedUrl({ assetId: getValues('picture').fileId }).unwrap();
    setUnassignedImage(newImageAssignedUrl);
  };

  const onCancelHandler = () => {
    setIsEditMode(false);
    cancelHandler(parseFormObjectPrivateUser(user!, assets));
    setUnassignedImage(null);
    const picture = findAssetByType(assets, ASSET_TYPE_ENUM.PICTURE);
    if (assets && assets.length && picture) {
      getImageUrl({ assetId: picture?.id });
    }
  };

  return (
    <UserDetailsFormContainer>
      <Row>
        <ControlledInput
          name={'firstName'}
          placeholder={translate('firstName')}
          id={'firstName'}
          disabled={!isEditMode}
        />
        <ControlledInput name={'lastName'} placeholder={translate('lastName')} id={'lastName'} disabled={!isEditMode} />
        <ControlledInput
          name={'phoneNumber'}
          placeholder={translate('phoneNumber')}
          id={'phoneNumber'}
          disabled={!isEditMode}
        />
        <ControlledInput
          name={'ethWallet'}
          placeholder={translate('ethWallet')}
          id={'ethWallet'}
          disabled={!isEditMode}
        />
      </Row>
      <Row>
        <ControlledInput name={'zipCode'} placeholder={translate('zipCode')} id={'zipCode'} disabled={!isEditMode} />
        <ControlledSelect
          name={'country'}
          items={countriesAsDropdown}
          placeholder={translate('country')}
          disabled={!isEditMode}
        />
        <ControlledInput name={'city'} placeholder={translate('city')} id={'city'} disabled={!isEditMode} />
        <ControlledInput
          name={'btcWallet'}
          placeholder={translate('btcWallet')}
          id={'btcWallet'}
          disabled={!isEditMode}
        />
      </Row>

      <Row>
        <Column>
          <ControlledInput
            name={'citizenship'}
            placeholder={translate('citizenship')}
            id={'citizenship'}
            disabled={!isEditMode}
          />
        </Column>
        <Column>
          <ControlledDateInput
            name={'birthDate'}
            placeholder={translate('birthDate')}
            id={'birthDate'}
            disabled={!isEditMode}
            maxDate={new Date()}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <ControlledInput name={'address'} placeholder={translate('address')} id={'address'} disabled={!isEditMode} />
        </Column>
      </Row>
      <Row>
        <PrivateUserDocumentation
          assets={assets}
          userId={user?.id}
          isEditMode={isEditMode}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          uploadImageHandler={uploadImageHandler}
          unassignedImageUrl={unassignedImage}
          assignedImageUrl={imageUrl}
          username={user?.displayName}
        />
      </Row>
      <Row>
        {isEditMode && (
          <ActionContainer>
            <Button text={translate('cancel')} onClick={onCancelHandler} buttonType={'secondary'} />
            <Button
              text={translate('save')}
              onClick={onClickHandler}
              disabled={!!Object.keys(errors).length || !Object.keys(dirtyFields).length}
              buttonType={'primary'}
              isLoading={false}
            />
          </ActionContainer>
        )}
      </Row>
    </UserDetailsFormContainer>
  );
};

export default PrivateUserDetailsForm;

const UserDetailsFormContainer = styled.div`
  position: relative;
  padding: 2rem;
  margin-top: 2rem;
  gap: 3rem;
`;

const ActionContainer = styled.div`
  margin-bottom: 2rem;
  margin-left: auto;
  display: flex;
  gap: 2rem;
  height: fit-content;
  align-self: flex-end;
`;

export const Column = styled.div`
  width: 24%;
`;
