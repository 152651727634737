import { Text } from 'issuix-ui-kit';
import styled from 'styled-components';

import { LogoContainer } from '@/components/AuthModal/ForgotPasswordModal/ForgotPassword.styles';
import ForgotPasswordForm from '@/components/AuthModal/ForgotPasswordModal/ForgotPasswordForm/ForgotPasswordForm';
import { forgotPasswordSchema } from '@/components/AuthModal/ForgotPasswordModal/ForgotPasswordSchema';
import ControlledForm from '@/components/Form/ControlledForm';
import Logo from '@/components/Logo/Logo';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';

const ForgotPasswordModal = () => {
  const { translate } = useTranslation();

  return (
    <ForgotPasswordContainer>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <Text content={translate('gettingBackIntoYourAccount')} size={'large'} />
      <br />
      <Text content={translate('textTellUsSomeInformation')} size={'medium'} />
      <ControlledForm schema={forgotPasswordSchema(translate)}>
        <ForgotPasswordForm />
      </ControlledForm>
    </ForgotPasswordContainer>
  );
};

const ForgotPasswordContainer = styled.div`
  margin-top: 2rem;
`;

export default ForgotPasswordModal;
