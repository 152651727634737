import { colors, GlobalSpinner } from 'issuix-ui-kit';
import { useParams } from 'react-router-dom';

import { PRODUCT_TYPE_ENUM } from '@/enums/ProductType';
import {
  useGetCorporateUserAssetsQuery,
  useGetNftTransactionByIdQuery,
  useGetOrderByTransactionIdQuery,
  useGetPrivateUserAssetsQuery,
} from '@/store/service';
import BuyerInfo from '@/view/Admin/TransactionSingleView/BuyerInfo/BuyerInfo';
import IssuerInfo from '@/view/Admin/TransactionSingleView/IssuerInfo/IssuerInfo';
import TransactionInfo from '@/view/Admin/TransactionSingleView/TransactionInfo/TransactionInfo';
import { TransactionSingleViewWrapper } from '@/view/Admin/TransactionSingleView/TransactionSingleView.styles';

export const NftTransactionSingleView = () => {
  return <NftTransactionSingleViewContent />;
};

const NftTransactionSingleViewContent = () => {
  const { id } = useParams();

  const { data, isLoading } = useGetNftTransactionByIdQuery({ query: { id } }, { skip: !id });

  const { data: assets } = useGetCorporateUserAssetsQuery(data?.issuer?.id, { skip: !data });
  const { data: buyerAssets } = useGetPrivateUserAssetsQuery(data?.buyer?.id, { skip: !data });

  const { data: order } = useGetOrderByTransactionIdQuery(data?.id, { skip: !data || !data?.id });

  return (
    <GlobalSpinner isActive={!data && isLoading} background={colors.blue.primary}>
      <TransactionSingleViewWrapper>
        <TransactionInfo transaction={data} type={PRODUCT_TYPE_ENUM.NFT} order={order} />
        <IssuerInfo transaction={data} assets={assets} />
        <BuyerInfo transaction={data} assets={buyerAssets} />
      </TransactionSingleViewWrapper>
    </GlobalSpinner>
  );
};
