import { AssetUnassignedNftResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const unassignedNftAssetPath = 'assets/unassigned-nft';

export const unassignedNftAsset = (build: BuildType) => {
  return build.mutation<AssetUnassignedNftResponse, FormData>({
    query: (formData) => ({
      url: unassignedNftAssetPath,
      method: 'POST',
      body: formData,
    }),
  });
};
