import { Button } from 'issuix-ui-kit';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import ControlledPassword from '@/components/Form/ControlledPassword';
import { Row } from '@/components/Layout/Row/Row.styled';
import { useIfExist } from '@/hooks/useIfExist/useIfExist';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { ChangePasswordRequest } from '@/shared/types/api';
import { useChangePasswordMutation } from '@/store/service';

interface ChangePasswordModalFormProps {
  setIsOpen: (isOpen: boolean) => void;
}

const ChangePasswordModalForm: FC<ChangePasswordModalFormProps> = ({ setIsOpen }) => {
  const { translate } = useTranslation();
  const {
    handleSubmit,
    formState: { isValid },
  } = useFormContext();
  const [changePassword, { isLoading, isError, isSuccess }] = useChangePasswordMutation();
  const submitHandler = () => {
    return handleSubmit((data) => changePassword(data as ChangePasswordRequest))();
  };

  useIfExist([isLoading, isSuccess], isSuccess, () => setIsOpen(false));

  return (
    <ChangePasswordModalContainer>
      <FullWidthRow>
        <ControlledPassword
          name={'currentPassword'}
          placeholder={translate('currentPassword')}
          id={'currentPassword'}
        />
      </FullWidthRow>
      <FullWidthRow>
        <ControlledPassword name={'newPassword'} placeholder={translate('newPassword')} id={'newPassword'} />
      </FullWidthRow>
      <FullWidthRow>
        <ControlledPassword
          name={'passwordConfirmation'}
          placeholder={translate('passwordConfirmation')}
          id={'passwordConfirmation'}
        />
      </FullWidthRow>
      <Row>
        <ActionContainer>
          <Button text={translate('cancel')} onClick={() => setIsOpen(false)} buttonType={'secondary'} />
          <Button
            text={translate('update')}
            onClick={() => submitHandler()}
            disabled={!isValid}
            buttonType={'primary'}
            isLoading={isLoading}
          />
        </ActionContainer>
        {isError && <p>{translate('enteredPasswordError')}</p>}
      </Row>
    </ChangePasswordModalContainer>
  );
};

export default ChangePasswordModalForm;

const ChangePasswordModalContainer = styled.div`
  width: 100%;
  padding-inline: 8rem;
  padding-bottom: 6rem;
  gap: 3rem;
  position: relative;
`;

const ActionContainer = styled.div`
  display: flex;
  gap: 2rem;
  height: fit-content;
  align-self: flex-end;
  position: absolute;
  right: 0;
  bottom: 0;
`;

const FullWidthRow = styled(Row)`
  & > div {
    width: 100%;
  }
`;
