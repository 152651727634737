import { RatingOptionResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const ratingOptionPath = 'rating-option';

export const getRatingOptions = (build: BuildType) => {
  return build.query<RatingOptionResponse[], void>({
    query: () => ratingOptionPath,
  });
};
