import styled from 'styled-components';

export const PaymentModalLayout = styled.div`
  padding: 1.6rem;
  height: 100%;
  width: 100%;
`;

export const PaymentStepLayout = styled.div`
  width: 100%;
  height: 100%;
  padding: 3.5rem;
  grid-gap: 2rem;
`;
