import { pagingLimit } from '@/shared/constants/Paging';
import { PageableItems } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseIssuedCouponsByUserIdUrl = 'coupons/user';

const getIssuedCouponsByUserIdUrl = (offset: number, query: { id: string }) => {
  return `${baseIssuedCouponsByUserIdUrl}/${query.id}?offset=${offset}&limit=${pagingLimit}`;
};

export const getIssuedCouponsByUserId = (build: BuildType) => {
  return build.query<PageableItems, any>({
    query: ({ offset, query }) => ({
      url: getIssuedCouponsByUserIdUrl(offset, query),
      method: 'GET',
    }),
  });
};
