import React, { createContext, FC, useContext } from 'react';

import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { PageableItems } from '@/shared/types/api';
import { useGetAllTransactionsQuery } from '@/store/service';

interface ITransactionsFromBuyersContext {
  data: (string | PageableItems)[];
  fetch: () => void;
  setFilters: (filters: { [x: string]: any } | undefined) => void;
  resetData: () => void;
}

interface TransactionsFromBuyersContextProviderProps {
  children: React.ReactNode;
}

export const TransactionsFromBuyersContext = createContext({} as ITransactionsFromBuyersContext);

export const useTransactionsFromBuyersContext = () => useContext(TransactionsFromBuyersContext);

export const TransactionsFromBuyersContextProvider: FC<TransactionsFromBuyersContextProviderProps> = ({ children }) => {
  const { fetch, resetData, data, setFilters } = useTableWithPagination({ query: useGetAllTransactionsQuery });

  return (
    <TransactionsFromBuyersContext.Provider
      value={{
        data,
        fetch,
        setFilters,
        resetData,
      }}
    >
      {children}
    </TransactionsFromBuyersContext.Provider>
  );
};
