import { UploadImage } from 'issuix-ui-kit';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  id?: string;
  edit?: boolean;
  onUploadFile: (file: File) => void;
  errors: any;
  uploadImageText: string | undefined;
  maxImageSizeText: string | undefined;
  imageSrc: string | undefined;
  username?: string;
}

const ControlledImageUpload: FC<Props> = ({
  name,
  id,
  edit,
  onUploadFile,
  errors,
  uploadImageText,
  maxImageSizeText,
  imageSrc,
  username,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      defaultValue=""
      control={control}
      render={() => {
        return (
          <UploadImage
            id={id}
            maxImageSizeText={maxImageSizeText}
            uploadImageText={uploadImageText}
            edit={edit}
            uploadImage={onUploadFile}
            errors={errors}
            imageSrc={imageSrc}
            username={username}
          />
        );
      }}
    />
  );
};

export default ControlledImageUpload;
