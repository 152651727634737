import { Button, Text } from 'issuix-ui-kit';
import { FC, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Column, RowFirst, SmartBondFeatureFormContainer } from './SmartBondFeaturesForm.styles';

import { Documentation } from '@/components/BondAndShare/Documentation';
import ControlledDateInput from '@/components/Form/ControlledDateInput';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledRadioButtonInput from '@/components/Form/ControlledRadioButtonInput';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledSelectMultiple from '@/components/Form/ControlledSelectMultiple';
import FullDisabledField from '@/components/FullDisabledField/FullDisabledField';
import { Row } from '@/components/Layout/Row/Row.styled';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useCouponPaidOptions } from '@/hooks/useCouponPaidOptions/useCouponPaidOptions';
import { useCurrencies } from '@/hooks/useCurrencies/UseCurrencies';
import { useEditEntity } from '@/hooks/useEditEntity/useEditEntity';
import { useRegions } from '@/hooks/useRegions/UseRegions';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { AssetResponse, BondResponse, CurrencyResponse } from '@/shared/types/api';
import { getSelectedCurrency } from '@/store/global/selectors';
import { useUpdateBondMutation } from '@/store/service';
import { formatAmountWithoutRate } from '@/utils/formatAmount/FormatAmount';
import { parseFormObject } from '@/utils/getBondFieldValues/GetBondFieldValues';
import { getFilteredCurrencies } from '@/utils/getFilteredCurrencies/GetFilteredCurrencies';
import { setFieldValues } from '@/utils/setFieldValues/setFieldValues';

interface ViewSmartBondFeaturesProps {
  bond: BondResponse;
  isEditMode: boolean;
  setIsEditMode: (value: boolean) => void;
  assets: AssetResponse[];
}

const SmartBondFeaturesForm: FC<ViewSmartBondFeaturesProps> = ({ bond, isEditMode, setIsEditMode, assets }) => {
  const { translate } = useTranslation();
  const { currencies, currenciesAsDropdown } = useCurrencies();
  const { couponPaidOptionsAsDropdown } = useCouponPaidOptions(translate);
  const { regionsAsDropdown } = useRegions(translate);
  const {
    setValue,
    watch,
    getValues,
    formState: { dirtyFields, errors },
  } = useFormContext();
  const filteredCurrencies: CurrencyResponse[] = useMemo(() => getFilteredCurrencies(currencies), [currencies]);
  const currency = useAppSelector((state) => getSelectedCurrency(state));
  const { updateShareBond, cancelHandler } = useEditEntity();
  const [updateBond] = useUpdateBondMutation();

  useEffect(() => {
    if (bond && currency) {
      setFieldValues(parseFormObject(bond, currency, translate, assets), setValue);
    }
  }, [bond, currency]);

  const valueWatcher = watch(['value']);

  const [val, setVal] = useState<number>(0);

  useEffect(() => {
    if (valueWatcher.length && valueWatcher[0]) {
      setVal(valueWatcher[0]);
    } else {
      setVal(0);
    }
  }, [valueWatcher]);

  const calculatedValues = useMemo(() => {
    return filteredCurrencies.map((currency) => (
      <Column key={currency.currencyCode}>
        <FullDisabledField
          key={currency.currencyCode}
          value={+formatAmountWithoutRate(val * currency.rate, currency.decimals)}
          label={currency.currencyCode}
        />
      </Column>
    ));
  }, [filteredCurrencies, val]);

  if (!currency || !bond) {
    return <></>;
  }

  const getListingOptions = () => [
    { label: translate('yes'), value: 'true' },
    { label: translate('no'), value: 'false' },
  ];

  const onSubmitHandler = async () => {
    await updateShareBond({ bondId: bond.id }, updateBond);
    setIsEditMode(false);
  };

  const onCancelHandler = () => {
    setIsEditMode(false);
    cancelHandler(parseFormObject(bond, currency, translate));
  };

  return (
    <SmartBondFeatureFormContainer>
      <RowFirst>
        <Column>
          <ControlledInput
            placeholder={translate('faceValue')}
            id={'value'}
            name={'value'}
            disabled={!isEditMode}
            type={'number'}
          />
        </Column>
        <Column>
          <Text content={'='} size={'large'} />
        </Column>
        {calculatedValues}
      </RowFirst>
      <RowFirst>
        <Column>
          <ControlledSelectMultiple
            name={'sellInCurrencyIds'}
            items={currenciesAsDropdown}
            disabled={!isEditMode}
            placeholder={translate('sellInCurrency')}
          />
        </Column>
        <Column>
          <ControlledInput
            name={'denomination'}
            placeholder={translate('denomination')}
            id={'denomination'}
            disabled={true}
            type={'number'}
          />
        </Column>
        <Column>
          <ControlledInput
            name={'interest'}
            placeholder={translate('interest')}
            id={'interest'}
            disabled={!isEditMode}
            type={'number'}
          />
        </Column>
        <Column>
          <ControlledSelect
            name={'couponsPaid'}
            placeholder={translate('couponsPaid')}
            items={couponPaidOptionsAsDropdown}
            disabled={!isEditMode}
          />
        </Column>
        <Column>
          <ControlledInput
            name={'offeringMonths'}
            placeholder={translate('offeringMonths')}
            id={'offeringMonths'}
            disabled={!isEditMode}
            type={'number'}
          />
        </Column>
        <Column>
          <ControlledDateInput
            name={'issuedDate'}
            placeholder={translate('issuedDate')}
            id={'issuedDate'}
            disabled={!isEditMode}
          />
        </Column>
        <Column>
          <ControlledInput
            name={'maturity'}
            placeholder={translate('maturity')}
            id={'maturity'}
            disabled={!isEditMode}
            type={'number'}
          />
        </Column>
        <Column>
          <ControlledSelectMultiple
            name={'regionIds'}
            placeholder={translate('availableTo')}
            items={regionsAsDropdown}
            disabled={!isEditMode}
          />
        </Column>
        <Column>
          <ControlledInput name={'issuer'} placeholder={translate('issuerName')} id={'issuer'} disabled={!isEditMode} />
        </Column>
        <Column>
          <ControlledRadioButtonInput
            name={'listingOption'}
            options={getListingOptions()}
            defaultValue={'false'}
            description={translate('listing')}
            disabled={!isEditMode}
            size={'small'}
          />
        </Column>
      </RowFirst>
      <Row>
        <Documentation
          assets={assets}
          isEditMode={isEditMode}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
        />
      </Row>
      <Row>
        {isEditMode && (
          <ActionContainer>
            <Button text={translate('cancel')} onClick={onCancelHandler} buttonType={'secondary'} />
            <Button
              text={translate('save')}
              onClick={onSubmitHandler}
              disabled={!!Object.keys(errors).length || !Object.keys(dirtyFields).length}
              buttonType={'primary'}
              isLoading={false}
            />
          </ActionContainer>
        )}
      </Row>
    </SmartBondFeatureFormContainer>
  );
};

export default SmartBondFeaturesForm;

const ActionContainer = styled.div`
  margin-bottom: 2rem;
  margin-left: auto;
  display: flex;
  gap: 2rem;
  height: fit-content;
  align-self: flex-end;
`;
