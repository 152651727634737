import { pagingLimit } from '@/shared/constants/Paging';
import { PageableItems } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const getAcceptedTransactionsPath = '/transactions/accepted';

const getAcceptedTransactionsUrl = (offset: number) =>
  `${getAcceptedTransactionsPath}?offset=${offset}&limit=${pagingLimit}`;

export const getAcceptedTransactions = (build: BuildType) => {
  return build.query<PageableItems, any>({
    query: ({ offset }) => ({
      url: getAcceptedTransactionsUrl(offset),
      method: 'GET',
    }),
  });
};
