import { BondResponse } from '@/shared/types/api';

export const parseFromObjectPaymentInfo = (response: BondResponse) => {
  const { swift, iban, bankName, bankAddress } = response;

  return {
    swift,
    iban,
    bankName,
    bankAddress,
  };
};
