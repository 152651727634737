import { Card } from 'issuix-ui-kit';
import styled from 'styled-components';

export const CouponIssuedCard = styled(Card)`
  height: 100%;
  width: 100%;
  padding: 3rem;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const TableContainer = styled.div`
  height: calc(100% - 5rem);
`;
