import { Button, Input } from 'issuix-ui-kit';
import { FC } from 'react';

import {
  ButtonContainer,
  ButtonOneContainer,
  CardContainer,
  DescriptionContainer,
  HeadingCardContainer,
  StyledCard,
  TableCellContainer,
  TableContainer,
  TableRowContainer,
  TitleAndIconContainer,
  TitleAndTableContainer,
  TitleContainer,
} from './HeadingCard.style';
import { data } from './data/data';

import Logo from '@/components/Logo/Logo';
import { useOnAuthorizeClick } from '@/hooks/useOnAutorizeClick/useOnAuthorizeClick';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';

const HeadingCard: FC = () => {
  const { translate } = useTranslation();
  const { authProtectedClick: authProtectedShareOnClick } = useOnAuthorizeClick();

  return (
    <CardContainer>
      <StyledCard>
        <HeadingCardContainer>
          <TitleAndIconContainer>
            <TitleContainer>{translate('currentlyInHYPE')} </TitleContainer>
            <Logo />
          </TitleAndIconContainer>
          <DescriptionContainer>
            <TitleAndTableContainer>
              <TitleContainer>{translate('keyFacts')}</TitleContainer>
              <TableContainer>
                <TableRowContainer>
                  <TableCellContainer>
                    <Input
                      type="text"
                      name="yes"
                      placeholder={translate('payment')}
                      value={data.Payment}
                      disabled={true}
                    />
                  </TableCellContainer>
                  <TableCellContainer>
                    <Input
                      type="text"
                      name="yes"
                      placeholder={translate('securityType')}
                      value={data.SecurityType}
                      disabled={true}
                    />
                  </TableCellContainer>
                  <TableCellContainer>
                    <Input
                      type="text"
                      name="yes"
                      placeholder={translate('headQuarters')}
                      value={data.HeadQuarters}
                      disabled={true}
                    />
                  </TableCellContainer>
                  <TableCellContainer>
                    <Input type="text" name="yes" placeholder={translate('onChain')} value={'YES'} disabled={true} />
                  </TableCellContainer>
                </TableRowContainer>
                <TableRowContainer>
                  <TableCellContainer>
                    <Input
                      type="text"
                      name="yes"
                      placeholder={translate('fundsRaised')}
                      value={data.FundsRaised.toString()}
                      disabled={true}
                    />
                  </TableCellContainer>
                  <TableCellContainer>
                    <Input
                      type="text"
                      name="yes"
                      placeholder={translate('pricePerShare')}
                      value={data.PricePerShare.toString()}
                      disabled={true}
                    />
                  </TableCellContainer>
                  <TableCellContainer>
                    <Input
                      type="text"
                      name="yes"
                      placeholder={translate('fundsRequested')}
                      value={data.FundsRequested.toString()}
                      disabled={true}
                    />
                  </TableCellContainer>
                  <TableCellContainer />
                </TableRowContainer>
              </TableContainer>
            </TitleAndTableContainer>
            <ButtonContainer>
              <ButtonOneContainer>
                <Button
                  text={translate('view')}
                  buttonType={'secondary'}
                  onClick={() => {
                    authProtectedShareOnClick();
                  }}
                />
              </ButtonOneContainer>
              <Button text={translate('buy')} onClick={() => authProtectedShareOnClick()} />
            </ButtonContainer>
          </DescriptionContainer>
        </HeadingCardContainer>
      </StyledCard>
    </CardContainer>
  );
};
export default HeadingCard;
