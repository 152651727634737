import camelCase from 'lodash/camelCase';

import { NftCollectionAssetsResponse } from '@/shared/types/api';

export const parseFormAssetNftCollectionObject = (assets: NftCollectionAssetsResponse[]) => {
  const assetsData = assets.reduce((prev, current) => {
    return { ...prev, [camelCase(current.type)]: { fileId: current.id, url: current.url } };
  }, {});

  return {
    ...assetsData,
  };
};
