import { Button, colors, GlobalSpinner, Text } from 'issuix-ui-kit';
import { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ActionWrapper, CardHeader, SmartBondContainer, ViewBondWrapper } from './ApproveBond.styles';

import { AcceptModal } from '@/components/AcceptModal/AcceptModal';
import ControlledForm from '@/components/Form/ControlledForm';
import { RejectModal } from '@/components/RejectModal/RejectModal';
import { useNav } from '@/hooks/useNav/UseNav';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import {
  useApproveBondMutation,
  useGetBondAssetsQuery,
  useGetBondByIdQuery,
  useRejectBondMutation,
} from '@/store/service';
import SmartBondFeaturesForm from '@/view/Admin/ApproveBond/SmartBondFeaturesForm/SmartBondFeaturesForm';
import { smartBondFeaturesSchema } from '@/view/Admin/ApproveBond/SmartBondFeaturesForm/SmartBondFeaturesSchema';

const ApproveBond: FC = () => {
  const { params } = useNav();
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [reason, setReason] = useState<string>('');

  const { data: bond } = useGetBondByIdQuery({ query: { id: params.id && params.id } }, { skip: !params.id });
  const { data: assets } = useGetBondAssetsQuery({ query: { id: params.id && params.id } }, { skip: !params.id });

  const [rejectBond, { isSuccess: isSuccessRejectBond, isLoading: isLoadingRejectBond }] = useRejectBondMutation();
  const [approveBond, { isSuccess: isSuccessApproveBond, isLoading: isLoadingApproveBond }] = useApproveBondMutation();

  useEffect(() => {
    if (isSuccessApproveBond || isSuccessRejectBond) {
      navigate(-1);
    }
  }, [isSuccessApproveBond, isSuccessRejectBond]);

  const onAccept = () => {
    approveBond({ id: params.id });
    setIsAcceptModalOpen(false);
  };

  const onReject = () => {
    rejectBond({ reason, id: params.id });
    setIsRejectModalOpen(false);
    setReason('');
  };

  const onAcceptModalOpen = () => {
    setIsAcceptModalOpen(true);
  };

  const onRejectModalOpen = () => {
    setIsRejectModalOpen(true);
  };

  const onRejectModalClose = () => {
    setIsRejectModalOpen(false);
  };

  const onAcceptModalClose = () => {
    setIsAcceptModalOpen(false);
  };

  return (
    <GlobalSpinner isActive={!bond && !assets} background={colors.blue.primary}>
      <ViewBondWrapper>
        <SmartBondContainer>
          <CardHeader>
            <Text content={translate('smartBondsPending')} size={'large'} />
          </CardHeader>
          <ControlledForm schema={smartBondFeaturesSchema()}>
            <SmartBondFeaturesForm bond={bond} assets={assets} />
          </ControlledForm>
          <ActionWrapper>
            <Button text={translate('accept')} onClick={onAcceptModalOpen} />
            <Button onClick={onRejectModalOpen} text={translate('reject')} buttonType="danger" />
          </ActionWrapper>
          <AcceptModal
            isAcceptModalOpen={isAcceptModalOpen}
            onAcceptModalClose={onAcceptModalClose}
            onAccept={onAccept}
            title={translate('accept')}
            subtitle={translate('areYouSureAccept')}
            isAcceptLoading={isLoadingApproveBond}
          />
          <RejectModal
            isRejectModalOpen={isRejectModalOpen}
            onRejectModalClose={onRejectModalClose}
            onReject={onReject}
            title={translate('reject')}
            subtitle={translate('areYouSureReject')}
            reason={reason}
            setReason={setReason}
            isRejectLoading={isLoadingRejectBond}
          />
        </SmartBondContainer>
      </ViewBondWrapper>
    </GlobalSpinner>
  );
};

export default ApproveBond;
