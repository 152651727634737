import { Text } from 'issuix-ui-kit';
import { FC } from 'react';

import { useRegistrationContext } from '../../RegistrationContext';

import { ConfirmationEmailText, RegistFinalModalContainer, TextWrraper } from './RegisterFinalModal.styles';

import { SeparateLine } from '@/components/AuthModal/SignInModal/SignInModal.styles';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { getResendTokenSelector } from '@/store/global/selectors';
import { useResendEmailMutation } from '@/store/service';
import { getTenant } from '@/utils/getTenant/getTenant';

const RegisterFinalModal: FC = () => {
  const { translate } = useTranslation();
  const { email } = useRegistrationContext();
  const [resendEmail] = useResendEmailMutation();

  const tenant = getTenant();

  const resendToken = useAppSelector((state) => getResendTokenSelector(state));

  return (
    <RegistFinalModalContainer>
      <TextWrraper>
        <Text content={translate('almostComplete')} size={'extraLarge'} />{' '}
      </TextWrraper>
      <TextWrraper>
        <Text
          content={translate('thanksForRegistration', { tenant: tenant.charAt(0).toUpperCase() + tenant.slice(1) })}
          size={'medium'}
        />{' '}
      </TextWrraper>
      <TextWrraper>
        <Text content={translate('verifyYourEmail1')} size={'medium'} />{' '}
        <Text color={'var(--green-300)'} size={'medium'} content={email!} />{' '}
        <Text size={'medium'} content={translate('verifyYourEmail2')} />
      </TextWrraper>
      <TextWrraper>
        <Text content={translate('followTheLink')} size={'medium'} />{' '}
      </TextWrraper>
      <TextWrraper>
        <Text content={translate('adminApproval')} size={'medium'} />{' '}
      </TextWrraper>
      <TextWrraper>
        <Text content={translate('accountWillBeVerified')} size={'medium'} />{' '}
      </TextWrraper>
      <SeparateLine />
      <TextWrraper>
        <ConfirmationEmailText onClick={() => resendEmail({ resendToken, email })}>
          {translate('resendConfirmationEmail')}
        </ConfirmationEmailText>
      </TextWrraper>
    </RegistFinalModalContainer>
  );
};

export default RegisterFinalModal;
