import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { CurrencyResponse } from '@/shared/types/api';
import { getSelectedCurrency } from '@/store/global/selectors';
import { changeCurrency } from '@/store/global/slice';
import { useGetCurrenciesQuery } from '@/store/service';
import { composeCurrencyDropdownOptions } from '@/utils/composeCurrencyDropdownOptions/composeCurrencyDropdownOptions';

export const useCurrencies = () => {
  const { data: currencies } = useGetCurrenciesQuery();
  const dispatch = useDispatch();
  const currency = useAppSelector((state) => getSelectedCurrency(state));

  const currenciesAsDropdown = useMemo(() => {
    return composeCurrencyDropdownOptions<CurrencyResponse>(
      currencies || [],
      'id',
      'currencyCode',
      'iconUrl',
      'decimals',
      'rate'
    );
  }, [currencies]);

  useEffect(() => {
    if (!currenciesAsDropdown.length) {
      return;
    }
    dispatch(changeCurrency(currency ?? currencies[0]));
  }, [currency, currenciesAsDropdown]);

  const setSelectedCurrency = (value: string) => {
    const selectedCurrency = currencies.find((currency: CurrencyResponse) => currency.id === value);
    dispatch(changeCurrency(selectedCurrency));
  };

  return {
    currenciesAsDropdown: currenciesAsDropdown || [],
    currencies: currencies || [],
    selectedCurrency: currency?.id ?? '',
    setSelectedCurrency,
  };
};
