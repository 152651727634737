import { useMemo } from 'react';
import { TFunction } from 'react-i18next';

import { RatingOptionResponse } from '@/shared/types/api';
import { useGetRatingOptionsQuery } from '@/store/service';

export const useRatingOptions = (translate: TFunction) => {
  const { data: ratingOptions } = useGetRatingOptionsQuery();

  const ratingOptionsAsDropdown = useMemo(
    () =>
      ratingOptions?.map((rating: RatingOptionResponse) => {
        return {
          value: rating.id,
          label: rating?.label === 'None' ? translate('none') : rating?.label,
        };
      }),
    [ratingOptions, translate]
  );

  return {
    ratingOptions: ratingOptions || [],
    ratingOptionsAsDropdown: ratingOptionsAsDropdown || [],
  };
};
