import { NftCard, NftHolder } from 'issuix-ui-kit';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { NftsContainer } from './NftPortfolio.styles';

import { NFT_STATUS_ENUM } from '@/enums/nft/NftStatusEnum';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { getSelectedCurrency } from '@/store/global/selectors';
import { useGetNftsByUserIdQuery, useGetTotalNftsByUserIdQuery } from '@/store/service';
import { composePath } from '@/utils/composePath/ComposePath';
import { formatAmount } from '@/utils/formatAmount/FormatAmount';
import { mapNftSerialNumber } from '@/utils/mapNftSerialNumber/mapNftSerialNumber';
import { mapNftStatusToTranslatedLabel } from '@/utils/mapNftStatusToTranslatedLabel/mapNftStatusToTranslatedLabel';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';

interface NftPortfolioProps {
  userId?: string;
}

const NftPortfolio: FC<NftPortfolioProps> = () => {
  const { id } = useParams();
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const currency = useAppSelector((state) => getSelectedCurrency(state));

  const { data: total } = useGetTotalNftsByUserIdQuery(
    { id },
    {
      skip: !id,
    }
  );
  const { data, fetch } = useTableWithPagination({ query: useGetNftsByUserIdQuery, id: id });

  if (!data || !currency) return null;

  return (
    <NftsContainer>
      <NftCard
        heading={translate('nftPortfolio')}
        totalNft={total?.total}
        totalNftText={translate('total')}
        isPortfolio
        fetchTrigger={fetch}
      >
        {data.map((nft: any) => (
          <NftHolder
            key={nft.serialNumber}
            text={mapNftStatusToTranslatedLabel(NFT_STATUS_ENUM.OWNED, translate)}
            status={NFT_STATUS_ENUM.OWNED}
            image={nft.thumbnailUrl}
            price={formatAmount(nft.price, currency.decimals, currency.rate, currency.currencyCode)}
            serialNumber={mapNftSerialNumber(nft.serialNumber)}
            onClick={() => navigate(composePath(ClientRoutes.Transactions.nftSingleView, { id: nft.transactionId }))}
          />
        ))}
      </NftCard>
    </NftsContainer>
  );
};
export default NftPortfolio;
