import styled from 'styled-components';

export const DetailsBlockLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 5rem 1fr;
  height: max-content;
  width: 100%;
`;

export const DetailsBlockLayoutTitle = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  text-align: left;
`;

export const DetailsBlockLayoutContent = styled.div`
  grid-row: 2 / 3;
  grid-column: 1 / 4;
  display: grid;
  grid-auto-rows: 5rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5rem;
  height: max-content;
  width: 100%;
`;
