import { FC, useEffect, useState } from 'react';

import NftAcceptedTransactions from '@/components/NftAcceptedTransactions/NftAcceptedTransactions';
import NftCollectionDetails from '@/components/NftCollectionDetails/NftCollectionDetails';
import NftHolders from '@/components/NftHolders/NftHolders';
import Nfts from '@/components/Nfts/Nfts';
import { useNav } from '@/hooks/useNav/UseNav';
import { NftCollectionResponse } from '@/shared/types/api';

interface NftCollectionProps {
  nftCollections: NftCollectionResponse[];
}

const NftCollection: FC<NftCollectionProps> = ({ nftCollections }) => {
  const { params } = useNav();
  const [nftCollection, setNftCollection] = useState<NftCollectionResponse | undefined>(undefined);

  useEffect(() => {
    if (nftCollections.length > 0) {
      const collection = nftCollections.find((collection: NftCollectionResponse) => collection.id === params.id);
      setNftCollection(collection);
    }
  }, [nftCollections, params.id]);

  if (!nftCollection || !params.id) {
    return null;
  }

  return (
    <>
      <NftCollectionDetails nftCollection={nftCollection} />
      <Nfts id={params.id} />
      <NftHolders />
      <NftAcceptedTransactions />
    </>
  );
};

export default NftCollection;
