import { ModalDialog, Text } from 'issuix-ui-kit';
import { FC } from 'react';

import ControlledForm from '@/components/Form/ControlledForm';
import AcceptCryptoTransactionModalForm from '@/components/TransactionModal/AcceptCryptoTransactionModal/AcceptCryptoTransactionModalForm/AcceptCryptoTransactionModalForm';
import { acceptCryptoTransactionModalFormSchema } from '@/components/TransactionModal/AcceptCryptoTransactionModal/AcceptCryptoTransactionModalForm/AcceptCryptoTransactionModalFormSchema';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';

interface AcceptCryptoTransactionModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const AcceptCryptoTransactionModal: FC<AcceptCryptoTransactionModalProps> = ({ isOpen, setIsOpen }) => {
  const { translate } = useTranslation();

  return (
    <ModalDialog isOpen={isOpen} close={() => setIsOpen(false)}>
      <Text content={translate('validate')} size={'large'} />
      <Text content={translate('validateTransaction')} size={'medium'} />
      <ControlledForm schema={acceptCryptoTransactionModalFormSchema()}>
        <AcceptCryptoTransactionModalForm setIsOpen={setIsOpen} />
      </ControlledForm>
    </ModalDialog>
  );
};

export default AcceptCryptoTransactionModal;
