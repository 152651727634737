import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Page from '@/components/Page/Page';
import ProtectedRoute from '@/components/ProtectedRoute/ProtectedRoute';
import { TENANT } from '@/enums/App';
import { ROLE_TYPE } from '@/enums/RoleType';
import { composeSmartShareLedgerPath } from '@/utils/composePath/ComposeSmartShareLedgerPath';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';
import { generatedBondRoutes } from '@/utils/routes/generatedRoutes/generatedBondRoutes';
import { generatedShareRoutes } from '@/utils/routes/generatedRoutes/generatedShareRoutes';
import BondTransactionSingleView from '@/view/Admin/TransactionSingleView/BondTransactionSingleView';
import { NftTransactionSingleView } from '@/view/Admin/TransactionSingleView/NftTransactionSingleView';
import ShareTransactionSingleView from '@/view/Admin/TransactionSingleView/ShareTransactionSingleView';
import CreateSmartBond from '@/view/Core/CreateSmartBond/CreateSmartBond';
import CreateSmartShare from '@/view/Core/CreateSmartShare/CreateSmartShare';
import CoreLanding from '@/view/Core/Landing/CoreLanding';
import Account from '@/view/Core/MyAccount/MyAccount';
import SmartBondListing from '@/view/Shared/SmartBondListing/SmartBondListing';
import SmartShareListing from '@/view/Shared/SmartShareListing/SmartShareListing';
import ViewBond from '@/view/Shared/ViewBond/ViewBond';
import ViewShare from '@/view/Shared/ViewShare/ViewShare';

const CoreDomain: FC = () => {
  return (
    <Page>
      <Routes>
        <Route path={ClientRoutes.Root} element={<CoreLanding />} />
        <Route
          path={`${ClientRoutes.Shares.root}/*`}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.CORPORATE, ROLE_TYPE.PRIVATE]}>
              <SmartShareListing />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${ClientRoutes.Bonds.root}/*`}
          element={
            <ProtectedRoute
              allowedRoles={[ROLE_TYPE.CORPORATE, ROLE_TYPE.PRIVATE]}
              allowedTenants={[TENANT.META_SWISS, TENANT.GREEN_STATE]}
            >
              <SmartBondListing />
            </ProtectedRoute>
          }
        />
        <Route
          path={generatedBondRoutes(ClientRoutes.Bonds.editSmartBond)}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.CORPORATE]} allowedTenants={[TENANT.META_SWISS]}>
              <ViewBond />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${ClientRoutes.Bonds.root}/${ClientRoutes.Bonds.viewSmartBond}/${ClientRoutes.Bonds.transaction}`}
          element={<BondTransactionSingleView />}
        />
        <Route
          path={ClientRoutes.Bonds.smartBondCreation}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.CORPORATE]} allowedTenants={[TENANT.META_SWISS]}>
              <CreateSmartBond />
            </ProtectedRoute>
          }
        />
        <Route
          path={ClientRoutes.Shares.smartShareCreation}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.CORPORATE]}>
              <CreateSmartShare />
            </ProtectedRoute>
          }
        />
        <Route
          path={generatedBondRoutes(ClientRoutes.Bonds.viewSmartBond)}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.CORPORATE, ROLE_TYPE.PRIVATE]}>
              <ViewBond />
            </ProtectedRoute>
          }
        />
        <Route
          path={generatedShareRoutes(ClientRoutes.Shares.viewSmartShare)}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.CORPORATE, ROLE_TYPE.PRIVATE]}>
              <ViewShare />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${generatedShareRoutes(ClientRoutes.Shares.viewSmartShare)}/${ClientRoutes.Shares.transaction}`}
          element={<ShareTransactionSingleView />}
        />
        <Route
          path={`${ClientRoutes.MyAccount.root}/*`}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.CORPORATE, ROLE_TYPE.PRIVATE]}>
              <Account isMyAccount />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.transactions}/${ClientRoutes.Transactions.bondSingleView}`}
          element={<BondTransactionSingleView />}
        />
        <Route
          path={`${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.transactions}/${ClientRoutes.Transactions.shareSingleView}`}
          element={<ShareTransactionSingleView />}
        />
        <Route
          path={`${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.transactions}/${ClientRoutes.Transactions.nftSingleView}`}
          element={<NftTransactionSingleView />}
        />
        <Route
          path={`${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.couponIssued}/${ClientRoutes.Transactions.singleView}`}
          element={<BondTransactionSingleView />}
        />
        <Route
          path={`${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.smartBondsPortfolio}/${ClientRoutes.Transactions.bondSingleView}`}
          element={<BondTransactionSingleView />}
        />
        <Route
          path={`${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.smartSharesPortfolio}/${ClientRoutes.Transactions.shareSingleView}`}
          element={<ShareTransactionSingleView />}
        />
        <Route
          path={composeSmartShareLedgerPath(ClientRoutes.Shares.root, ClientRoutes.Shares.viewSmartShare)}
          element={<ViewShare />}
        />
        <Route
          path={composeSmartShareLedgerPath(
            ClientRoutes.MyAccount.shareholdersLedger,
            ClientRoutes.Transactions.shareSingleView
          )}
          element={<ShareTransactionSingleView />}
        />
        <Route
          path={composeSmartShareLedgerPath(
            ClientRoutes.MyAccount.shareholdersLedger,
            ClientRoutes.Transactions.singleView
          )}
          element={<ShareTransactionSingleView />}
        />
        <Route
          path={`${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.smartBondCreated}/${ClientRoutes.Bonds.editSmartBond}`}
          element={<ViewBond />}
        />
        <Route
          path={`${ClientRoutes.Shares.root}/${ClientRoutes.Shares.viewSmartShare}/${ClientRoutes.Transactions.shareSingleView}`}
          element={<ShareTransactionSingleView />}
        />
        <Route
          path={`${ClientRoutes.Bonds.root}/${ClientRoutes.Bonds.viewSmartBond}/${ClientRoutes.Transactions.bondSingleView}`}
          element={<BondTransactionSingleView />}
        />
        <Route
          path={`${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.shareholdersLedger}/${ClientRoutes.Transactions.shareSingleView}`}
          element={<ShareTransactionSingleView />}
        />
        <Route
          path={`${ClientRoutes.MyAccount.root}/${ClientRoutes.Users.smartBondCreated}/${ClientRoutes.Bonds.editSmartBond}/${ClientRoutes.Transactions.bondSingleView}`}
          element={<BondTransactionSingleView />}
        />
        <Route path="*" element={<Navigate to={ClientRoutes.Root} />} />
      </Routes>
    </Page>
  );
};

export default CoreDomain;
