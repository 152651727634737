import { ResetPasswordWithCodeRequest } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const resetPasswordWithCodePath = 'auth/reset-password-with-code';

export const resetPasswordWithCode = (build: BuildType) => {
  return build.mutation<void, ResetPasswordWithCodeRequest>({
    query: ({ email, newPassword, passwordConfirmation, code }) => ({
      url: resetPasswordWithCodePath,
      method: 'POST',
      body: { email, newPassword, passwordConfirmation, code },
    }),
  });
};
