import { useState, useEffect } from 'react';

const getHeight = () => window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export const useScreenResize = () => {
  const [width, setWidth] = useState(getWidth);
  const [height, setHeight] = useState(getHeight);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const resizeListener = () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        setWidth(getWidth());
        setHeight(getHeight());
      }, 150);
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return {
    width,
    height,
  };
};
