import camelCase from 'lodash/camelCase';
import { TFunction } from 'react-i18next';

import { AssetResponse, PrivateUserFullResponse, UserFullResponse } from '@/shared/types/api';
import { formatDate } from '@/utils/date/FormatDate';

export const parseFormObject = (response: UserFullResponse, assets: AssetResponse[], translate: TFunction) => {
  const { name, phoneNumber, address, city, email, zipCode, country } = response;

  const assetsData = assets.reduce((prev, current) => {
    return { ...prev, [camelCase(current.type)]: { fileId: current.id, url: current.key } };
  }, {});

  return {
    name,
    phoneNumber,
    legalForm: response.endUser.corporateUser?.legalForm?.name ?? response.legalForm?.name,
    businessFocus: response.endUser.corporateUser?.businessFocus?.translationCode
      ? translate(response.endUser.corporateUser.businessFocus.translationCode.split('.')[2] as keyof TFunction)
      : translate(response.businessFocus?.translationCode?.split('.')[2] as keyof TFunction),
    address,
    city,
    email,
    owner: response.endUser.corporateUser?.owner ?? response.owner,
    zipCode,
    ceo: response.endUser.corporateUser?.ceo ?? response.ceo,
    country: country?.name,
    ...assetsData,
  };
};

export const parsePrivateUserFormObject = (response: PrivateUserFullResponse, assets: AssetResponse[]) => {
  const { address, city, email, zipCode, phoneNumber, country } = response;

  const assetsData = assets.reduce((prev, current) => {
    return { ...prev, [camelCase(current.type)]: { fileId: current.id, url: current.key } };
  }, {});

  return {
    name: `${response.privateUser.firstName} ${response.privateUser.lastName}`,
    birthDate: response.privateUser.birthDate ? formatDate(response.privateUser.birthDate) : undefined,
    phoneNumber,
    email,
    address,
    city,
    zipCode,
    citizenship: response.country?.nationality,
    country: country?.name,
    ...assetsData,
  };
};
