import { SendShareRequest } from '@/shared/types/api/models/SendShareRequest';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const assignSharePath = 'share-transaction/send-share';

export const assignShare = (build: BuildType) => {
  return build.mutation<void, SendShareRequest>({
    query: (assignShare: SendShareRequest) => ({
      url: assignSharePath,
      method: 'POST',
      body: assignShare,
    }),
  });
};
