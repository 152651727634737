import React, { FC, createContext, useContext } from 'react';

import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { PageableItems } from '@/shared/types/api';
import { useGetBondsQuery } from '@/store/service';

interface ISmartBondListingRequestsContext {
  data: (string | PageableItems)[];
  fetch: () => void;
  setFilters: (filters: { [x: string]: any } | undefined) => void;
  resetData: () => void;
}

interface SmartBondListingContextProviderProps {
  children: React.ReactNode;
}

export const SmartBondListingContext = createContext({} as ISmartBondListingRequestsContext);

export const useSmartBondListingContext = () => useContext(SmartBondListingContext);

export const SmartBondListingContextProvider: FC<SmartBondListingContextProviderProps> = ({ children }) => {
  const { fetch, resetData, data, setFilters } = useTableWithPagination({
    query: useGetBondsQuery,
    skipIfNoFilters: true,
  });

  return (
    <SmartBondListingContext.Provider
      value={{
        data,
        fetch,
        setFilters,
        resetData,
      }}
    >
      {children}
    </SmartBondListingContext.Provider>
  );
};
