import { LoginRequest, LoginResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const loginPath = 'auth/login';

export const login = (build: BuildType) => {
  return build.mutation<LoginResponse, LoginRequest>({
    query: ({ email, password }) => ({
      url: loginPath,
      method: 'POST',
      body: { email, password },
    }),
  });
};
