// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ROLE_TYPE } from '@/enums/RoleType';
import { UpdateShareRequest } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';
import { api } from '@/store/service';

const baseShareUrl = 'users';

const getUpdateUserDetailsUrl = (userId: string, userType: string) => {
  return `${baseShareUrl}/${userId}/${userType}/update`;
};

export const updateUserDetails = (build: BuildType) => {
  return build.mutation<void, UpdateShareRequest>({
    query: (userData: any) => ({
      url: getUpdateUserDetailsUrl(userData.userId, userData.userType),
      method: 'PATCH',
      body: userData.body,
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
      try {
        const { data: updatedUserData } = await queryFulfilled;
        const { files, ...restData } = updatedUserData;

        dispatch(
          api.util.updateQueryData(
            updatedUserData.role === ROLE_TYPE.PRIVATE ? 'getPrivateUser' : 'getCorporateUser',
            restData.id,
            (draft: any[]) => {
              Object.assign(draft, restData);
            }
          )
        );

        dispatch(
          api.util.updateQueryData(
            updatedUserData.role === ROLE_TYPE.PRIVATE ? 'getMyPrivateUser' : 'getMyCorporateUser',
            restData.id,
            (draft: any[]) => {
              Object.assign(draft, restData);
            }
          )
        );

        dispatch(
          api.util.updateQueryData(
            updatedUserData.role === ROLE_TYPE.PRIVATE ? 'getPrivateUserAssets' : 'getCorporateUserAssets',
            updatedUserData.id,
            (draft: any[]) => {
              draft.length = 0;
              files.map((file: any) => {
                draft.push(file);
                return;
              });
            }
          )
        );
      } catch (e) {
        dispatch(api.util.invalidateTags(['CorporateUser']));
        dispatch(api.util.invalidateTags(['MyCorporateUser']));
        dispatch(api.util.invalidateTags(['PrivateUser']));
        dispatch(api.util.invalidateTags(['MyPrivateUser']));
      }
    },
  });
};
