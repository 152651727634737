import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import ControlledInput from '@/components/Form/ControlledInput';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { TransactionDetailInfoResponse } from '@/shared/types/api';
import { getTransactionFieldValues } from '@/utils/getTransactionFieldValues/getTransactionFieldValues';
import { setFieldValues } from '@/utils/setFieldValues/setFieldValues';

interface TransactionDetailInfoFormProps {
  transaction: TransactionDetailInfoResponse;
}

const TransactionDetailInfoForm: FC<TransactionDetailInfoFormProps> = ({ transaction }) => {
  const { translate } = useTranslation();
  const { setValue } = useFormContext();

  useEffect(() => {
    if (transaction) {
      setFieldValues(getTransactionFieldValues(transaction, translate), setValue);
    }
  }, [transaction]);

  return (
    <TransactionDetailInfoFormContainer>
      <RowFirst>
        <Column>
          <ControlledInput name={'time'} placeholder={translate('transactionTime')} id={'time'} disabled={true} />
        </Column>
        <Column>
          <ControlledInput
            name={'detectedAmount'}
            placeholder={translate('detectedAmount')}
            id={'detectedAmount'}
            disabled={true}
          />
        </Column>
        <Column>
          <ControlledInput name={'hash'} placeholder={translate('hash')} id={'hash'} disabled={true} />
        </Column>
        <Column>
          <ControlledInput
            name={'issuerName'}
            placeholder={translate('issuerName')}
            id={'issuerName'}
            disabled={true}
          />
        </Column>
        <Column>
          <ControlledInput name={'buyerName'} placeholder={translate('buyerName')} id={'buyerName'} disabled={true} />
        </Column>
      </RowFirst>
      <RowFirst>
        <Column>
          <ControlledInput name={'code'} placeholder={translate('code')} id={'code'} disabled={true} />
        </Column>
        <Column>
          <ControlledInput name={'amount'} placeholder={translate('amountChf')} id={'amount'} disabled={true} />
        </Column>
        <Column>
          <ControlledInput name={'quantity'} placeholder={translate('quantity')} id={'quantity'} disabled={true} />
        </Column>
        <Column>
          <ControlledInput
            name={'paymentRefNumber'}
            placeholder={translate('referenceNumber')}
            id={'paymentRefNumber'}
            disabled={true}
          />
        </Column>
        <Column>
          <ControlledInput name={'status'} placeholder={translate('status')} id={'status'} disabled={true} />
        </Column>
      </RowFirst>
    </TransactionDetailInfoFormContainer>
  );
};

export default TransactionDetailInfoForm;

const TransactionDetailInfoFormContainer = styled.div`
  position: relative;
  padding: 2rem;
  margin-top: 2rem;
  gap: 3rem;
`;

const RowFirst = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin: 2.2rem 0;
  flex-wrap: wrap;
`;

const Column = styled.div`
  width: 15%;
`;
