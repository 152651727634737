import { TFunction } from 'i18next';
import * as yup from 'yup';

export const smartBondFeaturesSchema = (translate: TFunction) => {
  return yup.object().shape({
    value: yup.number(),
    currencies: yup.array(),
    denomination: yup.number(),
    interest: yup.number(),
    couponsPaid: yup.string(),
    offeringMonths: yup.number(),
    issuedDate: yup.date(),
    maturity: yup
      .number()
      .min(12, 'Minimum value is 12')
      .max(300, 'Maximum value is 300')
      .required(translate('required')),
    regions: yup.object(),
    listingOption: yup.boolean(),
    issuer: yup.string(),
  });
};
