export const formatAmountWithoutRate = (amount: number, currencyDecimals: number): string => {
  return Number(amount).toFixed(currencyDecimals);
};

export const formatAmount = (amount: number, currencyDecimals = 2, currencyRate = 1, currencyName = ''): string => {
  const fixedAmount: string = (amount * currencyRate).toFixed(currencyDecimals);
  const fixedAmountWithoutDecimals: string = fixedAmount.split('.')[0];
  const decimals: string = fixedAmount.split('.')[1];
  const separatedNumber: string = fixedAmountWithoutDecimals.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  return `${separatedNumber}.${decimals} ${currencyName}`;
};

export const formatChartAmount = (amount: number, currencyDecimals = 2, currencyRate = 1): number => {
  return +(amount / currencyRate / 100).toFixed(currencyDecimals);
};
