import styled from 'styled-components';

export const UserInfoContainer = styled.div`
  height: 10rem;
  width: 100%;
  background: linear-gradient(
    183.73deg,
    hsla(0, 0%, 100%, 0.2) 1.73%,
    hsla(0, 0%, 91.4%, 0.375) 99.66%,
    hsla(0, 0%, 91.4%, 0.5) 0
  );
  border-radius: 1.5rem;
  padding: 1.6rem;
  display: flex;
`;

export const AvatarWrraper = styled.div`
  margin-left: auto;
`;

export const UseInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;
