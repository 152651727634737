import { CountryResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const countriesPath = 'countries';

export const getCountries = (build: BuildType) => {
  return build.query<CountryResponse[], void>({
    query: () => countriesPath,
  });
};
