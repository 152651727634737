import { Button, Text } from 'issuix-ui-kit';
import { FC, useState } from 'react';

import ControlledForm from '@/components/Form/ControlledForm';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { AssetResponse, ShareResponse } from '@/shared/types/api';
import SmartShareFeaturesForm from '@/view/Shared/ViewShare/SmartShareFeatures/SmartShareFeaturesForm/SmartShareFeaturesForm';
import { smartShareFeaturesSchema } from '@/view/Shared/ViewShare/SmartShareFeatures/SmartShareFeaturesForm/SmartShareFeaturesFormSchema';
import { CardHeader, EditButtonContainer, SmartShareContainer } from '@/view/Shared/ViewShare/ViewShare.styles';

interface SmartShareFeaturesProps {
  share: ShareResponse;
  assets: AssetResponse[];
  hasEdit: boolean;
}

const SmartShareFeatures: FC<SmartShareFeaturesProps> = ({ share, assets, hasEdit = true }) => {
  const { translate } = useTranslation();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  return (
    <SmartShareContainer>
      <CardHeader>
        <Text content={translate('smartShareFeatures')} size={'large'} />
      </CardHeader>
      <EditButtonContainer>
        {!isEditMode && hasEdit && <Button onClick={() => setIsEditMode(!isEditMode)} text={translate('edit')} />}
      </EditButtonContainer>
      <ControlledForm schema={smartShareFeaturesSchema(translate, share)}>
        <SmartShareFeaturesForm share={share} isEditMode={isEditMode} setIsEditMode={setIsEditMode} assets={assets} />
      </ControlledForm>
    </SmartShareContainer>
  );
};

export default SmartShareFeatures;
