import { useEffect, useState } from 'react';

import { LANGUAGES, TRANSLATION_NAME } from '@/enums/Language';

export const useBrowserLanguage = () => {
  const [language, setLanguage] = useState(TRANSLATION_NAME.ENGLISH);

  useEffect(() => {
    if (navigator.language.split('-')[0] === LANGUAGES.GERMAN) {
      setLanguage(TRANSLATION_NAME.GERMAN);
    }
  }, [language]);

  return language;
};
