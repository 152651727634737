import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import ControlledMultipleUpload from '@/components/Form/ControlledMultipleUpload';
import { useMultipleUpload } from '@/hooks/useMultipleUpload/useMultipleUpload';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useCreateNftCollectionContext } from '@/view/Core/CreateNftCollection/CreateNftCollectionContext';

export const CreateNftForm = () => {
  const {
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { nftCollectionId } = useCreateNftCollectionContext();
  const { onUpload, onRetry, onSubmit } = useMultipleUpload(setValue, 'files');
  const { translate } = useTranslation();

  return (
    <CreateNftFormContainer>
      <ControlledMultipleUpload
        name={'files'}
        placeholder={translate('uploadNft')}
        incorrectFileFormatLabel={translate('incorrectFileFormat')}
        dragDropFilesHereLabel={translate('dropFilesOr')}
        onSubmit={async () => {
          setIsLoading(true);
          await onSubmit(nftCollectionId!);
          setIsLoading(false);
        }}
        onUpload={async (uploadedFiles) => {
          setIsLoading(true);
          await onUpload(uploadedFiles, getValues('files')?.values, getValues('files')?.progress);
          setIsLoading(false);
        }}
        onRetry={async (event) => await onRetry(event)}
        errors={errors}
        submitLabel={translate('submit')}
        browseFilesLabel={translate('browseFiles')}
        isDeleteAvailable={false}
        disabled={!nftCollectionId}
        disabledLabel={translate('uploadDisabled')}
        isLoading={isLoading}
      />
    </CreateNftFormContainer>
  );
};

const CreateNftFormContainer = styled.div`
  gap: 3rem;
`;
