import { useCallback } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

export const useNav = () => {
  const routerNavigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const navigate = useCallback((route: string) => routerNavigate(route), []);

  const query = useCallback(
    (key: string) => {
      return searchParams.get(key);
    },
    [searchParams]
  );

  return {
    navigate,
    params,
    location,
    query,
  };
};
