import { TitleBar } from 'issuix-ui-kit';
import { FC } from 'react';
import styled from 'styled-components';

import { FeatureFlag } from '@/components/FeatureFlag/FeatureFlag';
import { FEATURE_FLAGS } from '@/components/FeatureFlag/featureFlags';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';

const Advisors: FC = () => {
  const { translate } = useTranslation();

  return (
    <AdvisorsContainer>
      <FeatureFlag name={FEATURE_FLAGS.CHAT}>
        <StyledTitleBar text={translate('advisors')} fontSize={'medium'} />
      </FeatureFlag>
    </AdvisorsContainer>
  );
};

export default Advisors;

const AdvisorsContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 3rem;
  padding: 3rem;
  background: linear-gradient(
    183.73deg,
    hsla(0, 0%, 100%, 0.2) 1.73%,
    hsla(0, 0%, 91.4%, 0.375) 99.66%,
    hsla(0, 0%, 91.4%, 0.5) 0
  );
`;

const StyledTitleBar = styled(TitleBar)`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
`;
