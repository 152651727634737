import { Button, IColumn, Modal, Pill, PILL_SELECTED, PILL_SIZE, Table, Text } from 'issuix-ui-kit';
import { useMemo, useState } from 'react';
import { TFunction } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FeatureFlag } from '@/components/FeatureFlag/FeatureFlag';
import { FEATURE_FLAGS } from '@/components/FeatureFlag/featureFlags';
import ControlledForm from '@/components/Form/ControlledForm';
import { SendNotificationModal } from '@/components/SendNotificationModal/SendNotificationModal';
import { APPS } from '@/enums/App';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { renderColumn } from '@/shared/commonUtils/renderColumn/renderColumn';
import { USER_STATUS_TYPE_ENUM } from '@/shared/types/UserType';
import { AdminUserResponse } from '@/shared/types/api';
import { useApproveUserMutation, useRejectUserMutation } from '@/store/service';
import { composeAccountChildPath } from '@/utils/composePath/ComposeAccountChildPath';
import { composePath } from '@/utils/composePath/ComposePath';
import { parseBoolean } from '@/utils/parseBoolean/parseBoolean';
import { isMetaSwissTenant } from '@/utils/tenant/Tenant';
import UsersFilterForm from '@/view/Admin/Users/FilterForm/UsersFilterForm';
import { UsersFilterSchema } from '@/view/Admin/Users/FilterForm/UsersFilterSchema';
import {
  ButtonBox,
  CardHeader,
  FiltersContainer,
  TableContainer,
  UsersCard,
  UsersContainer,
} from '@/view/Admin/Users/Users.styles';
import { UsersContextProvider, useUsersContext } from '@/view/Admin/Users/UsersContext';

const Users = () => {
  return (
    <UsersContextProvider>
      <UsersView />
    </UsersContextProvider>
  );
};

const UsersView = () => {
  const navigate = useNavigate();
  const { data, fetch } = useUsersContext();
  const { translate } = useTranslation();
  const [approveUser] = useApproveUserMutation();
  const [rejectUser] = useRejectUserMutation();

  const isSalesApplication = useMemo(() => {
    const hostName = window.location.hostname;
    return hostName.split('.')[0] === APPS.SALES;
  }, [window.location.hostname]);

  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('');

  const onViewClick = (id: string) => {
    navigate(composePath(composeAccountChildPath({}), { id }));
  };

  const onSendPushNotificationToAllClick = () => {
    setIsNotificationModalOpen(true);
  };

  const onSendPushNotificationClick = (userId: string) => {
    setUserId(userId);
    setIsNotificationModalOpen(true);
  };

  const onSendNotificationModalClose = () => {
    setIsNotificationModalOpen(false);
    setUserId('');
  };

  const onApprovePillClick = (id: string) => {
    approveUser({ id });
  };

  const onRejectPillClick = (id: string) => {
    rejectUser({ id });
  };

  const tableDef: IColumn[] = [
    {
      name: 'endUserRole',
      width: 1,
      header: {
        label: translate('role').toUpperCase(),
      },
      column: {
        render: (item: AdminUserResponse) => {
          return <p>{translate(item.endUserRole as keyof TFunction)}</p>;
        },
      },
    },
    {
      name: 'name',
      width: 2,
      header: {
        label: translate('name').toUpperCase(),
      },
    },
    {
      name: 'email',
      width: 3,
      header: {
        label: translate('email').toUpperCase(),
      },
    },
    isMetaSwissTenant() ? renderColumn('tenant', 2, translate('tenant')) : renderColumn('', 0),
    {
      name: 'isFullyRegistered',
      width: 2,
      header: {
        label: translate('fullRegister').toUpperCase(),
      },
      column: {
        render: (item: AdminUserResponse) => {
          return <p>{parseBoolean(item.isFullyRegistered, translate)}</p>;
        },
      },
    },
    {
      name: 'status',
      width: 1,
      header: {
        label: translate('status').toUpperCase(),
      },
      column: {
        render: (item: AdminUserResponse) => (
          <Pill
            disabled={!item.isFullyRegistered}
            leftLabel={translate('approved')}
            rightLabel={translate('suspended')}
            onLeftClick={() => onApprovePillClick(item.id)}
            onRightClick={() => onRejectPillClick(item.id)}
            selected={item.status === USER_STATUS_TYPE_ENUM.APPROVED ? PILL_SELECTED.LEFT : PILL_SELECTED.RIGHT}
            size={PILL_SIZE.SMALL}
          />
        ),
      },
    },
    {
      name: 'actions',
      width: 4,
      header: {
        label: translate('actions').toUpperCase(),
      },
      column: {
        render: (item: AdminUserResponse) => (
          <ButtonBox>
            <Button fullyRounded onClick={() => onViewClick(item.id)} text={translate('view')} size={'medium'} />
            <FeatureFlag name={FEATURE_FLAGS.PUSH_NOTIFICATIONS}>
              <Button
                fullyRounded
                onClick={() => onSendPushNotificationClick(item.id)}
                text={translate('sendPushNotification')}
                size={'medium'}
              />
            </FeatureFlag>
          </ButtonBox>
        ),
      },
    },
  ];

  return (
    <UsersContainer>
      <UsersCard>
        <CardHeader>
          <Text content={translate('users')} size={'large'} />
          <FeatureFlag name={FEATURE_FLAGS.PUSH_NOTIFICATIONS} disabled={isSalesApplication}>
            <Button
              onClick={() => onSendPushNotificationToAllClick()}
              text={translate('sendPushNotificationToAllUsers')}
              disabled={data.length === 0}
            />
          </FeatureFlag>
        </CardHeader>
        <TableContainer>
          <Table data={data} fetchTrigger={() => fetch()} tableDef={tableDef}></Table>
        </TableContainer>
      </UsersCard>
      <FiltersContainer>
        <Text content={translate('filters')} size={'large'} />
        <ControlledForm schema={UsersFilterSchema()}>
          <UsersFilterForm />
        </ControlledForm>
      </FiltersContainer>
      <Modal isOpen={isNotificationModalOpen} close={() => onSendNotificationModalClose()}>
        <SendNotificationModal onClose={() => onSendNotificationModalClose()} userId={userId} />
      </Modal>
    </UsersContainer>
  );
};

export default Users;
