import { CurrencyResponse, BondResponse } from '@/shared/types/api';

const DEFAULT_CURRENCY = 'CHF';

export const getBondWithDenomination = (bond: BondResponse, currency: CurrencyResponse) => {
  const { value, quantity } = bond;

  return {
    ...bond,
    denomination: getDenomination(value, quantity, currency),
  };
};

export const getDenomination = (value: number, quantity: number, currency: CurrencyResponse) => {
  const denomination = value / quantity;

  if (currency.currencyCode === DEFAULT_CURRENCY) {
    return denomination;
  }

  return denomination / currency.rate;
};

export const getPricePerShare = (value: number, currency: CurrencyResponse) => {
  const denomination = value;

  if (currency.currencyCode === DEFAULT_CURRENCY) {
    return denomination;
  }

  return denomination / currency.rate;
};
