import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const AssignToShareModalFormSchema = (translate: TFunction, quantity: string) => {
  return Yup.object().shape({
    quantity: Yup.number()
      .max(Number(quantity), translate('maxQuantity', { maxAmount: quantity }))
      .required(translate('required'))
      .typeError(translate('addQuantity')),
    date: Yup.string().required(translate('errors.required')),
  });
};
