import { Button, ColoredStatus, IColumn, Table, Text } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CasesCard, CardHeader, ActionContainer } from '@/components/Cases/Cases.style';
import { TableContainer } from '@/components/SmartBondCreated/SmartBondCreated';
import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { CaseResponse } from '@/shared/types/api';
import { useGetCasesForRecipientQuery } from '@/store/service';
import { composeAccountChildPath } from '@/utils/composePath/ComposeAccountChildPath';
import { composePath } from '@/utils/composePath/ComposePath';
import { formatDate } from '@/utils/date/FormatDate';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';
import { translateStatus } from '@/utils/translateStatus/translateStatus';

const Cases: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const { fetch, data, setFilters } = useTableWithPagination({ query: useGetCasesForRecipientQuery });

  const onViewClick = (caseId: string) => {
    const path = composeAccountChildPath({ path: ClientRoutes.Users.case });
    navigate(composePath(path, { id: params.id, caseId }));
  };

  useEffect(() => {
    setFilters({ recipientId: params.id });
  }, []);

  const tableDef: IColumn[] = [
    {
      name: 'name',
      width: 1,
      header: {
        label: translate('title').toUpperCase(),
      },
    },
    {
      name: 'createdAt',
      width: 1.5,
      header: {
        label: translate('createdAt').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          return <p>{formatDate(item.createdAt)}</p>;
        },
      },
    },
    {
      name: 'updatedAt',
      width: 1.5,
      header: {
        label: translate('updatedAt').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          return <p>{formatDate(item.updatedAt)}</p>;
        },
      },
    },
    {
      name: 'status',
      width: 1.5,
      header: {
        label: translate('status').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          return <ColoredStatus value={item.status} translatedValue={translateStatus(item.status, translate)} />;
        },
      },
    },
    {
      name: 'view',
      width: 3.5,
      header: {
        label: translate('actions').toUpperCase(),
      },
      column: {
        render: (item: CaseResponse) => (
          <ActionContainer>
            <Button fullyRounded onClick={() => onViewClick(item.id)} text={translate('view')} size={'medium'} />
          </ActionContainer>
        ),
      },
    },
  ];

  return (
    <CasesCard>
      <CardHeader>
        <Text content={translate('cases')} size={'large'} />
      </CardHeader>
      <TableContainer>
        <Table data={data} fetchTrigger={() => fetch()} tableDef={tableDef} />
      </TableContainer>
    </CasesCard>
  );
};

export default Cases;
