import { useFormContext } from 'react-hook-form';

export const useEditEntity = () => {
  const {
    getValues,
    formState: { dirtyFields },
    reset,
  } = useFormContext();

  const dataForUpdate = () => {
    let updateData = {};
    Object.keys(dirtyFields).map((item: string) => {
      updateData = { ...updateData, [item]: getValues(item) };
    });

    return updateData;
  };

  const updateShareBond = async (entityId: { [key: string]: string }, updateRecord: any, user?: any) => {
    const data = dataForUpdate();
    await updateRecord({
      body: { ...data, doneBy: user },
      ...entityId,
    });
  };

  const updateUser = async (userId: string, userType: string, updateRecordFunc: any) => {
    const data = dataForUpdate();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const returnedData = updateRecordFunc({ body: { ...data, ...checkIfExist(data) }, userId, userType });
    reset(data);
    return returnedData;
  };

  const cancelHandler = (data: any) => {
    reset(data);
  };

  const checkIfExist = (data: any, array = ['businessFocus', 'legalForm', 'country']) => {
    return array.reduce((prev: any, item: any) => {
      if (data[item]) {
        return { ...prev, [`${item}Id`]: data[item] };
      }
      return prev;
    }, {});
  };

  return {
    updateShareBond,
    cancelHandler,
    updateUser,
  };
};
