import { PasswordInput } from 'issuix-ui-kit';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface ControlledPasswordProps {
  name: string;
  placeholder: string;
  id?: string;
}

const ControlledPassword: FC<ControlledPasswordProps> = ({ placeholder, name = '', id }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field }) => (
        <PasswordInput
          id={id}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          placeholder={placeholder}
          name={name}
          errors={errors}
        />
      )}
    />
  );
};

export default ControlledPassword;
