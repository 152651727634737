import { pagingLimit } from '@/shared/constants/Paging';
import { PageableItems } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const getItemTransactionsPath = 'transactions/item';

const getItemTransactionsUrl = (offset: number, query: { id: string; status: string }) => {
  return `${getItemTransactionsPath}/${query.id}?offset=${offset}&limit=${pagingLimit}&status=${query.status}`;
};

export const getItemTransactions = (build: BuildType) => {
  return build.query<PageableItems, any>({
    providesTags: ['GetItemTransactions'],
    query: ({ offset, query }) => {
      return {
        url: getItemTransactionsUrl(offset, query),
        method: 'GET',
      };
    },
  });
};
