import { TFunction } from 'i18next';
import * as yup from 'yup';

export const quickRegisterPrivateSchema = (translate: TFunction) =>
  yup.object().shape({
    firstName: yup.string().required(translate('required')),
    lastName: yup.string().required(translate('required')),
    email: yup
      .string()
      .email()
      .required(translate('emailRequired'))
      .min(5, translate('emailMinChar'))
      .max(255, translate('emailMaxChar')),
    password: yup
      .string()
      .required(translate('required'))
      .min(5, translate('minChar', { value: 5 }))
      .max(255, translate('maxChar', { value: 255 })),
    passwordConfirmation: yup
      .string()
      .required(translate('required'))
      .oneOf([yup.ref('password'), null], translate('passwordMatch')),
    termsAndConditions: yup.boolean().required(translate('required')),
  });

export const quickRegisterCorporateSchema = (translate: TFunction) =>
  yup.object().shape({
    name: yup.string().required(translate('required')),
    email: yup
      .string()
      .email()
      .required(translate('emailRequired'))
      .min(5, translate('emailMinChar'))
      .max(255, translate('emailMaxChar')),
    password: yup
      .string()
      .required(translate('required'))
      .min(5, translate('minChar', { value: 5 }))
      .max(255, translate('maxChar', { value: 255 })),
    passwordConfirmation: yup
      .string()
      .required(translate('required'))
      .oneOf([yup.ref('password'), null], translate('passwordMatch')),
    termsAndConditions: yup.boolean().required(translate('required')),
  });
