import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const nftCollectionDetailsFormSchema = (translate: TFunction) => {
  return Yup.object().shape({
    collectionName: Yup.string().required(translate('required')),
    pricePerNft: Yup.number()
      .required(translate('mustBeDefined'))
      .typeError(translate('mustBeANumber'))
      .min(0, translate('mustBeAPositiveNumber')),
    product: Yup.string().required(translate('required')),
    tenant: Yup.string().required(translate('required')),
    bankName: Yup.string().required(translate('required')),
    bankAddress: Yup.string().required(translate('required')),
    iban: Yup.string().required(translate('required')),
    swift: Yup.string().required(translate('required')),
    regions: Yup.array().of(Yup.string()).nullable().min(1).required(translate('required')),
    currencies: Yup.array().of(Yup.string()).nullable().min(1).required(translate('required')),
    productDocument: Yup.object().required(translate('required')),
    logo: Yup.object().required(translate('required')),
    banner: Yup.object().required(translate('required')),
  });
};
