import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {},
});

export default currencySlice.reducer;
