import { ModalDialog, Text } from 'issuix-ui-kit';
import { FC } from 'react';

import AdminChangePasswordModalForm from '@/components/AdminChangePasswordModal/AdminChangePasswordModalForm/AdminChangePasswordModalForm';
import { AdminChangePasswordModalFormSchema } from '@/components/AdminChangePasswordModal/AdminChangePasswordModalForm/AdminChangePasswordModalFormSchema';
import ControlledForm from '@/components/Form/ControlledForm';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';

interface AdminChangePasswordModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const AdminChangePasswordModal: FC<AdminChangePasswordModalProps> = ({ isOpen, setIsOpen }) => {
  const { translate } = useTranslation();
  return (
    <ModalDialog isOpen={isOpen} close={() => setIsOpen(false)}>
      <Text content={translate('changePassword')} size={'large'} />
      <ControlledForm schema={AdminChangePasswordModalFormSchema(translate)}>
        <AdminChangePasswordModalForm setIsOpen={setIsOpen} />
      </ControlledForm>
    </ModalDialog>
  );
};

export default AdminChangePasswordModal;
