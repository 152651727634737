import { Button } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';
import { TFunction } from 'react-i18next';
import styled from 'styled-components';

import Advisors from '@/components/Advisors/Advisors';
import UserInfo from '@/components/UserInfo/UserInfo';
import { ASSET_TYPE_ENUM } from '@/enums/AssetTypeEnum';
import { useAuth } from '@/hooks/useAuth/useAuth';
import { useLogout } from '@/hooks/useLogout/useLogout';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useAssignedAssetUrlMutation, useGetPrivateUserAssetsQuery } from '@/store/service';
import { findAssetByType } from '@/utils/assets/findAssetByType';

const RightMenu: FC = () => {
  const { translate } = useTranslation();
  const { user } = useAuth();
  const { logout } = useLogout();
  const [getImageUrl, { data: imageUrl }] = useAssignedAssetUrlMutation();

  const { data: privateUserAssets } = useGetPrivateUserAssetsQuery(user!.id, { skip: !user || !user.id });

  useEffect(() => {
    const picture = findAssetByType(privateUserAssets, ASSET_TYPE_ENUM.PICTURE);
    if (privateUserAssets && privateUserAssets.length && picture) {
      getImageUrl({ assetId: picture.id });
    }
  }, [privateUserAssets]);

  return (
    <>
      <UserInfo name={user!.displayName} role={translate(user!.role as keyof TFunction)} avatarImg={imageUrl} />
      <StyledButton text={translate('logout')} size={'large'} onClick={logout} />
      <Advisors />
    </>
  );
};

export default RightMenu;

const StyledButton = styled(Button)`
  width: 100%;
  margin-block: 3rem;
`;
