import * as yup from 'yup';

export const buyerInfoFormSchema = () => {
  yup.object().shape({
    name: yup.string(),
    phoneNumber: yup.string(),
    address: yup.string(),
    city: yup.string(),
    email: yup.string(),
    zipCode: yup.string(),
    country: yup.string(),
    birthDate: yup.date(),
    citizenship: yup.string(),
  });
};
