import { useWizardContext } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { useRegistrationContext } from '@/components/AuthModal/RegistrationContext';
import ControlledSelect from '@/components/Form/ControlledSelect';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useUserType } from '@/hooks/useUserType/useUserTypeHook';
import { RegistrationFirstStepState } from '@/shared/types/AuthTypes';
import { ROLES } from '@/shared/types/UserType';
import { setFieldValues } from '@/utils/setFieldValues/setFieldValues';

const FirstStepForm: FC = () => {
  const { translate } = useTranslation();
  const { userTypeOptions } = useUserType(ROLES);

  const {
    getValues,
    formState: { isValid },
    setValue,
  } = useFormContext();

  const {
    ejected: { goToNextPage, goToPreviousPage, setOnNextClickHandler, setOnBackClickHandler },
    modifyNextButtonProps,
  } = useWizardContext();

  const { submitFirstStepData, firstStepData } = useRegistrationContext();

  useEffect(() => {
    if (firstStepData) {
      setFieldValues<RegistrationFirstStepState>(firstStepData, setValue);
    }
  }, []);

  useEffect(() => {
    modifyNextButtonProps({ disabled: !isValid, label: translate('next') });
  }, [isValid]);

  const fetchData = () => {
    submitFirstStepData(getValues());
    goToNextPage();
  };

  const goBack = () => {
    goToPreviousPage();
  };

  useEffect(() => {
    setOnNextClickHandler(() => {
      fetchData();
    });
    setOnBackClickHandler(() => {
      goBack();
    });
  }, []);

  return (
    <SelectContainer>
      <ControlledSelect name={'accountType'} items={userTypeOptions} placeholder={translate('accountType')} />
    </SelectContainer>
  );
};

export default FirstStepForm;

const SelectContainer = styled.div`
  width: 38%;
  text-align: left;
`;
