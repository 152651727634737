import { Button } from 'issuix-ui-kit';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import ControlledPassword from '@/components/Form/ControlledPassword';
import { Row } from '@/components/Layout/Row/Row.styled';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useAdminChangePasswordMutation } from '@/store/service';

interface AdminChangePasswordModalFormProps {
  setIsOpen: (isOpen: boolean) => void;
}

const AdminChangePasswordModalForm: FC<AdminChangePasswordModalFormProps> = ({ setIsOpen }) => {
  const { translate } = useTranslation();
  const params = useParams();
  const {
    getValues,
    formState: { isValid },
  } = useFormContext();
  const [adminChangePassword, { isLoading }] = useAdminChangePasswordMutation();

  const onUpdate = async () => {
    const data = { ...getValues(), id: params.id };
    await adminChangePassword(data);
    setIsOpen(false);
  };

  return (
    <AdminChangePasswordModalContainer>
      <FullWidthRow>
        <ControlledPassword name={'newPassword'} placeholder={translate('newPassword')} id={'name'} />
      </FullWidthRow>
      <FullWidthRow>
        <ControlledPassword name={'passwordConfirmation'} placeholder={translate('passwordConfirmation')} id={'name'} />
      </FullWidthRow>
      <Row>
        <ActionContainer>
          <Button text={translate('cancel')} onClick={() => setIsOpen(false)} buttonType={'secondary'} />
          <Button
            text={translate('update')}
            onClick={() => onUpdate()}
            disabled={!isValid}
            buttonType={'primary'}
            isLoading={isLoading}
          />
        </ActionContainer>
      </Row>
    </AdminChangePasswordModalContainer>
  );
};

export default AdminChangePasswordModalForm;

const AdminChangePasswordModalContainer = styled.div`
  width: 100%;
  padding-inline: 8rem;
  padding-bottom: 6rem;
  gap: 3rem;
  position: relative;
`;

const ActionContainer = styled.div`
  display: flex;
  gap: 2rem;
  height: fit-content;
  align-self: flex-end;
  position: absolute;
  right: 0;
  bottom: 0;
`;

const FullWidthRow = styled(Row)`
  & > div {
    width: 100%;
  }
`;
