import { AssetUploadUnassignedResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const unassignedAssetPath = 'assets/unassigned';

export const unassignedAsset = (build: BuildType) => {
  return build.mutation<AssetUploadUnassignedResponse, FormData>({
    query: (formData) => ({
      url: unassignedAssetPath,
      method: 'POST',
      body: formData,
    }),
  });
};
