export enum APPS {
  CORE = 'core',
  VERIFY = 'verify',
  ADMIN = 'admin',
  SALES = 'sales',
}

export enum TENANT {
  META_SWISS = 'metaswiss',
  GREEN_STATE = 'greenstate',
}
