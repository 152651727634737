import { Button } from 'issuix-ui-kit';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import ControlledTextarea from '@/components/Form/ControlledTextarea';
import { Row } from '@/components/Layout/Row/Row.styled';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useRejectTransactionMutation } from '@/store/service';

interface RejectTransactionModalFormProps {
  setIsOpen: (isOpen: boolean) => void;
}

const RejectTransactionModalForm: FC<RejectTransactionModalFormProps> = ({ setIsOpen }) => {
  const { translate } = useTranslation();
  const params = useParams();
  const {
    getValues,
    formState: { isValid },
  } = useFormContext();

  const [rejectTransaction, { isLoading }] = useRejectTransactionMutation();

  const onReject = async () => {
    const data = { ...getValues(), id: params.id };
    await rejectTransaction(data);
    setIsOpen(false);
  };

  return (
    <RejectTransactionModalContainer>
      <FullWidthRow>
        <ControlledTextarea name={'note'} label={translate('note')} errors={'Error'} />
      </FullWidthRow>
      <Row>
        <ActionContainer>
          <Button text={translate('cancel')} onClick={() => setIsOpen(false)} buttonType={'secondary'} />
          <Button
            text={translate('reject')}
            onClick={() => onReject()}
            disabled={!isValid}
            buttonType={'danger'}
            isLoading={isLoading}
          />
        </ActionContainer>
      </Row>
    </RejectTransactionModalContainer>
  );
};

export default RejectTransactionModalForm;

const RejectTransactionModalContainer = styled.div`
  width: 100%;
  padding-inline: 8rem;
  padding-bottom: 6rem;
  gap: 3rem;
  position: relative;
`;

const ActionContainer = styled.div`
  display: flex;
  gap: 2rem;
  height: fit-content;
  align-self: flex-end;
  position: absolute;
  right: 0;
  bottom: 0;
`;

const FullWidthRow = styled(Row)`
  & > div {
    width: 100%;
  }
`;
