import { TFunction } from 'react-i18next';

import { BondTransactionResponse, ShareTransactionResponse, TransactionDetailInfoResponse } from '@/shared/types/api';
import { translateStatus } from '@/utils/translateStatus/translateStatus';

export const getTransactionFieldValues = (transaction: TransactionDetailInfoResponse, translate: TFunction) => {
  return {
    ...transaction,
    status: translateStatus(transaction.status, translate),
  };
};

export const getTransactionInfoFieldValues = (
  transaction: (ShareTransactionResponse | BondTransactionResponse) & { deliveryAddress?: string },
  translate: TFunction
) => {
  return {
    ...transaction,
    status: translateStatus(transaction.status, translate),
  };
};
