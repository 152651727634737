import { ImageType } from '@/shared/types/ImageType';

export type RegistrationFirstStepState = {
  accountType?: string;
};

export const InitialRegistrationFirstStepState: RegistrationFirstStepState = {
  accountType: '',
};

export type RegistrationSecondStepState = {
  email?: string;
  password?: string;
  passwordConfirmation?: string;
  address?: string;
  zipCode?: string;
  ceo?: string;
  city?: string;
  owner?: string;
  phoneNumber?: string;
  name?: string;
  legalFormId?: string;
  countryId?: string;
  firstName?: string;
  lastName?: string;
  birthDate?: string;
  citizenship?: string;
};

export const InitialRegistrationSecondStepState: RegistrationSecondStepState = {
  email: '',
  password: '',
  passwordConfirmation: '',
  address: '',
  zipCode: '',
  ceo: '',
  city: '',
  owner: '',
  phoneNumber: '',
  name: '',
  legalFormId: '',
  countryId: '',
  firstName: '',
  lastName: '',
  birthDate: '',
  citizenship: '',
};

export type RegistrationThirdStepState = {
  ethWallet?: string;
  btcWallet?: string;
  businessFocusId?: string;
  termsAndConditions?: boolean;
  corporateMarketExperience?: boolean;
  richNetWorth?: boolean;
  proofOfDomicile?: string;
  commercialRegister?: string;
  signature?: string;
  image?: string | ImageType;
  phoneNumberPrefix?: string;
  preferredLanguage?: string;
  firstTimeLogin?: boolean;
};

export const InitialRegistrationThirdStepState: RegistrationThirdStepState = {
  ethWallet: '',
  btcWallet: '',
  businessFocusId: '',
  termsAndConditions: false,
  corporateMarketExperience: false,
  richNetWorth: false,
  proofOfDomicile: '',
  commercialRegister: '',
  signature: '',
  image: '',
  phoneNumberPrefix: '',
  preferredLanguage: '',
  firstTimeLogin: false,
};

export type InitialRegistrationThirdStepState = RegistrationFirstStepState &
  RegistrationSecondStepState &
  RegistrationThirdStepState;
