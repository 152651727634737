import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const corporateUserDetailsFormSchema = (translate: TFunction) => {
  return Yup.object().shape({
    name: Yup.string().required(translate('required')).nullable(),
    address: Yup.string().required(translate('required')).nullable(),
    businessFocusId: Yup.string().required(translate('required')).nullable(),
    ethWallet: Yup.string().required(translate('required')).nullable(),
    legalFormId: Yup.string().required(translate('required')).nullable(),
    ceo: Yup.string().required(translate('required')).nullable(),
    btcWallet: Yup.string().required(translate('required')).nullable(),
    countryId: Yup.string().required(translate('required')).nullable(),
    owner: Yup.string().required(translate('required')).nullable(),
    city: Yup.string()
      .required(translate('required'))
      .matches(/^[A-Za-z]+$/, translate('alphabet'))
      .nullable(),
    phoneNumber: Yup.string()
      .required(translate('required'))
      .matches(/^[0-9]+$/, translate('digits'))
      .nullable(),
    zipCode: Yup.string()
      .required(translate('required'))
      .matches(/^[0-9]+$/, translate('digits'))
      .nullable(),
  });
};
