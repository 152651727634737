import { useMemo } from 'react';
import { TFunction } from 'react-i18next';

import { LegalFormResponse } from '@/shared/types/api';
import { useGetLegalFormsQuery } from '@/store/service';

export const useLegalForms = (translate: TFunction) => {
  const { data: legalForms } = useGetLegalFormsQuery();

  const legalFormsAsDropdown = useMemo(
    () =>
      legalForms?.map((legalForm: LegalFormResponse) => {
        return {
          value: legalForm.id,
          label: translate(legalForm?.translationCode?.split('.')[2] as keyof TFunction),
        };
      }),
    [legalForms, translate]
  );

  return {
    legalForms: legalForms || [],
    legalFormsAsDropdown: legalFormsAsDropdown || [],
  };
};
