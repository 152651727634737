import { Text, useWizardContext } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import ControlledForm from '@/components/Form/ControlledForm';
import { FirstStepSetQuantityContainer } from '@/components/Payment/PaymentModal/Steps/FirstStepSetQuantity/FirstStepSetQuantity.styles';
import {
  firstStepSetQuantitySchema,
  QuantityForm,
  QUANTITY_FORM_FIELDS,
} from '@/components/Payment/PaymentModal/Steps/FirstStepSetQuantity/FirstStepSetQuantitySchema';
import { usePaymentContext } from '@/components/Payment/PaymentModal/context/PaymentContext';
import CurrencyBlock from '@/components/Payment/PaymentModal/shared/CurrencyBlock/CurrencyBlock';
import PurchaseInfo from '@/components/Payment/PaymentModal/shared/PurchaseInfo/PurchaseInfo';
import { PurchaseInfoContainer } from '@/components/Payment/PaymentModal/shared/PurchaseInfo/PurchaseInfo.styles';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { BondResponse, ShareResponse } from '@/shared/types/api';

const FirstStepSetQuantityForm: FC = () => {
  const {
    watch,
    setValue,
    formState: { isValid },
  } = useFormContext<QuantityForm>();
  const {
    modifyNextButtonProps,
    modifyBackButtonProps,
    ejected: { setOnNextClickHandler, goToNextPage },
  } = useWizardContext();
  const { setQuantity, quantity } = usePaymentContext();

  const quantityWatch = watch(QUANTITY_FORM_FIELDS.QUANTITY);

  useEffect(() => {
    modifyBackButtonProps({ show: false });
  }, []);

  useEffect(() => {
    if (!quantity) {
      return;
    }

    setValue(QUANTITY_FORM_FIELDS.QUANTITY, quantity, { shouldDirty: true });
  }, [quantity]);

  useEffect(() => {
    setOnNextClickHandler(() => {
      setQuantity(quantityWatch);
      goToNextPage();
    });
  }, [quantityWatch]);

  useEffect(() => {
    modifyNextButtonProps({ disabled: !isValid });
  }, [isValid]);

  return (
    <PurchaseInfoContainer>
      <PurchaseInfo quantity={quantityWatch} />
    </PurchaseInfoContainer>
  );
};

const FirstStepSetQuantity: FC = () => {
  const { resource, resourceType, currency } = usePaymentContext();
  const { translate } = useTranslation();

  if (!resource || !currency) {
    return <></>;
  }

  const quantity = (resource as BondResponse).remainingBonds || (resource as ShareResponse).availableForSale;

  return (
    <FirstStepSetQuantityContainer>
      <CurrencyBlock>
        {/* Fix the type error like this temporarily */}
        <Text content={translate('availableQuantity', { count: quantity } as any)} />
      </CurrencyBlock>
      <ControlledForm
        schema={firstStepSetQuantitySchema(
          quantity.toString(),
          resourceType === 'ACTIONS'
            ? Math.round((resource as ShareResponse).minimalInvestment / (resource as ShareResponse).price)
            : undefined,
          translate
        )}
      >
        <FirstStepSetQuantityForm />
      </ControlledForm>
    </FirstStepSetQuantityContainer>
  );
};

export default FirstStepSetQuantity;
