import { BuildType } from '@/shared/types/redux/ReduxAction';

const deleteCaseAssetsPath = (caseId: string) => `/case?caseId=${caseId}`;

export const deleteCaseAssets = (build: BuildType) => {
  return build.mutation<void, any>({
    invalidatesTags: ['GetCaseDetails'],
    query: ({ data, caseId }) => ({
      url: deleteCaseAssetsPath(caseId),
      method: 'DELETE',
      body: data,
    }),
  });
};
