import { Text } from 'issuix-ui-kit';
import { FC } from 'react';

import SmartShareLedgerCalculation from '@/components/SmartShareLedgerCalculation/SmartShareLedgerCalculation';
import SmartShareLedgerTable from '@/components/SmartShareLegderTable/SmartShareLedgerTable';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { SmartShareLedgerContainer } from '@/view/Admin/SmartShareLegder/SmartShareLedger.styles';

interface SmartShareLedgerProps {
  userId: string;
  isDashboard: boolean;
}

const SmartShareLedger: FC<SmartShareLedgerProps> = ({ userId, isDashboard }) => {
  const { translate } = useTranslation();

  return (
    <SmartShareLedgerContainer>
      <Text content={translate('smartShareLedger')} size={'large'} />
      <div>
        <SmartShareLedgerTable userId={userId} isDashboard={isDashboard} />
        <SmartShareLedgerCalculation userId={userId} />
      </div>
    </SmartShareLedgerContainer>
  );
};

export default SmartShareLedger;
