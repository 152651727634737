import { CreateNftCollectionRequest } from '@/shared/types/api';

export const createNftCollectionMapper = (nftCollection: any): CreateNftCollectionRequest => {
  return {
    price: nftCollection.price,
    name: nftCollection.name,
    productTitle: nftCollection.productTitle,
    description: nftCollection.description,
    productDescription: {
      content: {
        content1: nftCollection.content1,
        content2: nftCollection.content2,
        content3: nftCollection.content3,
        content4: nftCollection.content4,
        content5: nftCollection.content5,
      },
    },
    logo: nftCollection.logo.fileId,
    banner: nftCollection.banner.fileId,
    productDocument: nftCollection.productDocument.fileId,
    bankName: nftCollection.bankName,
    bankAddress: nftCollection.bankAddress,
    iban: nftCollection.iban,
    swift: nftCollection.swift,
    regionIds: nftCollection.regions,
    currencyIds: nftCollection.currencyIds,
  };
};
