import styled from 'styled-components';

import { PaymentStepLayout } from '@/components/Payment/PaymentModal/PaymentModal.styles';

export const FirstStepSetQuantityContainer = styled(PaymentStepLayout)`
  display: grid;
  grid-template-rows: 5rem 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  height: max-content;
`;
