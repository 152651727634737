import { Text } from 'issuix-ui-kit';
import { FC, useEffect, useState } from 'react';

import { CalculationsBox, SmartBondPortfolioCard, TableRowContainer } from './SmartBondPortfolio.styles';

import AccountCalculationBox from '@/components/AccountCalculationBox/AccountCalculationBox';
import { PRODUCT_TYPE_ENUM } from '@/enums/ProductType';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { getSelectedCurrency } from '@/store/global/selectors';
import { useGetBondPortfolioCalculationsQuery, useGetPortfolioChartQuery } from '@/store/service';
import {
  baseBondPortfolioDefaultCalculation,
  BondPortfolioCalculation,
  formatCalculation,
} from '@/utils/bondCalculations/bondPortfolioCalculation';
import SmartBondPortfolioChart from '@/view/Core/SmartBondPortfolio/SmartBondPortfolioChart/SmartBondPortfolioChart';
import SmartBondPortfolioTable from '@/view/Core/SmartBondPortfolio/SmartBondPortfolioTable/SmartBondPortfolioTable';

interface SmartBondPortfolioProps {
  userId: string;
}

const SmartBondPortfolio: FC<SmartBondPortfolioProps> = ({ userId }) => {
  const { translate } = useTranslation();
  const { data: chart } = useGetPortfolioChartQuery({
    query: { productType: PRODUCT_TYPE_ENUM.BOND_PAPERS, userId: userId },
  });
  const selector = useAppSelector((state: any) => getSelectedCurrency(state));

  const [calculation, setCalculation] = useState<BondPortfolioCalculation>(baseBondPortfolioDefaultCalculation);

  const { data: bondCalculations } = useGetBondPortfolioCalculationsQuery({ query: { id: userId } });

  useEffect(() => {
    if (bondCalculations) {
      setCalculation(formatCalculation(bondCalculations, selector?.decimals, selector?.rate, selector?.currencyCode));
    }
  }, [bondCalculations, selector]);

  return (
    <SmartBondPortfolioCard>
      <Text content={translate('smartBondsPortfolio')} size={'large'} />
      <TableRowContainer>
        <SmartBondPortfolioTable userId={userId} />
        <SmartBondPortfolioChart chart={chart} />
        <CalculationsBox>
          <AccountCalculationBox
            label={translate('totalRepay')}
            value={calculation.totalReturnAmount}
            iconUrl={selector?.iconUrl}
          />
          <AccountCalculationBox
            label={translate('annualCoupons')}
            value={calculation.annualCoupons}
            iconUrl={selector?.iconUrl}
          />
          <AccountCalculationBox
            label={translate('totalInvested')}
            value={calculation.totalInvestedAmount}
            iconUrl={selector?.iconUrl}
          />
        </CalculationsBox>
      </TableRowContainer>
    </SmartBondPortfolioCard>
  );
};
export default SmartBondPortfolio;
