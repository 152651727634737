import { TabsNavigationButton } from 'issuix-ui-kit';
import { FC, memo, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { composeNftCollectionsChildPath } from '@/utils/composePath/ComposeNftCollectionsChildPath';
import { composePath } from '@/utils/composePath/ComposePath';

type Props = {
  label: string;
  path: string;
  collectionId?: string;
  addNew?: boolean;
};

const NftHeaderNavigationItem: FC<Props> = ({ label, path, collectionId, addNew }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setIsActive(
      location.pathname ===
        (addNew
          ? composeNftCollectionsChildPath(path)
          : `${composePath(composeNftCollectionsChildPath(path), { id: collectionId })}`)
    );
  }, [location, collectionId]);

  return (
    <TabsNavigationButtonWrapper>
      <TabsNavigationButton
        isActive={isActive}
        onClick={() =>
          navigate(
            addNew
              ? composeNftCollectionsChildPath(path)
              : composePath(composeNftCollectionsChildPath(path), { id: collectionId })
          )
        }
        label={label.toUpperCase()}
      />
    </TabsNavigationButtonWrapper>
  );
};

export default memo(NftHeaderNavigationItem);

const TabsNavigationButtonWrapper = styled.div`
  display: flex;
  margin-block: 1rem;
`;
