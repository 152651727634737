import { useMemo } from 'react';

import { useGetTenantUsersQuery } from '@/store/service';

export const useTenantUsers = () => {
  const { data: tenantUsers } = useGetTenantUsersQuery();

  const tenantUsersAsDropdown = useMemo(
    () =>
      tenantUsers?.map((tenantUser: any) => {
        return {
          value: tenantUser.id,
          label: tenantUser.endUser.tenantUser.name,
        };
      }),
    [tenantUsers]
  );

  return {
    tenantUsers: tenantUsers || [],
    tenantUsersAsDropdown: tenantUsersAsDropdown || [],
  };
};
