import { QuickRegistrationCorporateUserRequest, RegisterResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const registrationPath = 'auth/corporate/quick-signup';
const checkEmailPath = 'users/check-email';
const checkCorporateNamePath = 'users/check-name';

export const quickRegistrationCorporateUser = (build: BuildType) => {
  return build.mutation<RegisterResponse, QuickRegistrationCorporateUserRequest>({
    query: (data: QuickRegistrationCorporateUserRequest) => ({
      url: registrationPath,
      method: 'POST',
      body: data,
    }),
  });
};

export const checkEmailExists = (build: BuildType) => {
  return build.mutation<boolean, any>({
    query: (email) => ({
      url: `${checkEmailPath}/${email}`,
      method: 'POST',
    }),
  });
};

export const checkCorporateNameExists = (build: BuildType) => {
  return build.mutation<boolean, any>({
    query: (name) => ({
      url: `${checkCorporateNamePath}/${name}`,
      method: 'POST',
    }),
  });
};
