import { Button, IColumn, Table, Text } from 'issuix-ui-kit';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Download from '@/assets/core/icons/download.svg';
import DownloadDocumentsModal from '@/components/DocumentsModal/DownloadDocumentsModal';
import { DownloadButton } from '@/components/UserTransactions/UserTransactions.styles';
import { PRODUCT_TYPE_ENUM } from '@/enums/ProductType';
import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { BondPortfolioResponse } from '@/shared/types/BondPortfolioResponse';
import { useGetUserPortfolioQuery } from '@/store/service';
import { composePath } from '@/utils/composePath/ComposePath';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';
import { SplitTableBlock } from '@/view/Core/SmartBondPortfolio/SmartBondPortfolio.styles';
import { TableContainer } from '@/view/Core/SmartBondPortfolio/SmartBondPortfolioTable/SmartBondPortfolioTable.styles';

interface SmartBondPortfolioTableProps {
  userId: string;
}

const SmartBondPortfolioTable: FC<SmartBondPortfolioTableProps> = ({ userId }) => {
  const { translate } = useTranslation();
  const { fetch, data } = useTableWithPagination({
    query: useGetUserPortfolioQuery,
    id: PRODUCT_TYPE_ENUM.BOND_PAPERS,
    additionalId: userId,
  });
  const navigate = useNavigate();
  const [fileItem, setFileItem] = useState<BondPortfolioResponse | undefined>();

  const onAction = (id: string) => {
    navigate(composePath(`${ClientRoutes.Transactions.bondSingleView}`, { id }));
  };

  const onDownloadClickHandler = (item: BondPortfolioResponse) => {
    if (fileItem?.id) {
      return;
    }
    setFileItem(item);
  };

  const onModalClose = () => {
    setFileItem(undefined);
  };

  const tableDef: IColumn[] = [
    {
      name: 'code',
      width: 1.5,
      header: {
        label: translate('smartBondCode').toUpperCase(),
      },
    },
    {
      name: 'company',
      width: 1.5,
      header: {
        label: translate('company').toUpperCase(),
      },
    },
    {
      name: 'quantity',
      width: 1,
      header: {
        label: translate('amount').toUpperCase(),
      },
    },
    {
      name: 'amount',
      width: 1,
      header: {
        label: translate('price').toUpperCase(),
      },
    },
    {
      name: 'actions',
      width: 2,
      header: {
        label: translate('actions').toUpperCase(),
      },
      column: {
        render: (item: BondPortfolioResponse) => (
          <Button onClick={() => onAction(item.id)} text={translate('view')} fullyRounded size={'medium'} />
        ),
      },
    },
    {
      name: 'download',
      width: 1,
      header: {
        label: translate('documents').toUpperCase(),
      },
      column: {
        render: (item: BondPortfolioResponse) => {
          if (!item.assets) {
            return <Text content={translate('noDocuments')} />;
          }
          return <DownloadButton src={Download} alt={'download'} onClick={() => onDownloadClickHandler(item)} />;
        },
      },
    },
  ];

  return (
    <SplitTableBlock>
      {fileItem?.id && (
        <DownloadDocumentsModal isModalOpen={!!fileItem?.id} onModalClose={onModalClose} transaction={fileItem} />
      )}
      <TableContainer>
        <Table data={data} fetchTrigger={() => fetch()} tableDef={tableDef} />
      </TableContainer>
    </SplitTableBlock>
  );
};

export default SmartBondPortfolioTable;
