export const InitialShare = {
  id: undefined,
  price: undefined,
  totalAmount: undefined,
  availableForSale: undefined,
  sellInCurrencyIds: undefined,
  isin: undefined,
  shareCapital: undefined,
  issuedDate: undefined,
  type: undefined,
  round: undefined,
  roundDuration: undefined,
  minimalInvestment: undefined,
  regionIds: undefined,
  bankName: undefined,
  bankAddress: undefined,
  iban: undefined,
  swift: undefined,
  businessPlan: undefined,
  presentation: undefined,
  secondPartyOpinion: undefined,
  evaluation: undefined,
  onePager: undefined,
  whitePaper: undefined,
  secondPartyOpinionOption: false,
  brokerageOption: false,
  advisoryOption: false,
  storageOption: false,
  underwritingOption: false,
};
