import { FC, useEffect, useState } from 'react';

import { Row, SmartSharesLedgerCalculationContainer } from './SmartShareLedgerCalculation.styles';

import AccountCalculationBox from '@/components/AccountCalculationBox/AccountCalculationBox';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useGetSmartSharesLedgerCalculationQuery } from '@/store/service';
import {
  baseSmartShareLedgerDefaultCalculation,
  formatCalculation,
  SmartSharesLedgerCalculation,
} from '@/utils/smartSharesLedgerCalculation/smartSharesLedgerCalculation';

interface SmartShareLedgerCalculationProps {
  userId: string;
}

const SmartShareLedgerCalculation: FC<SmartShareLedgerCalculationProps> = ({ userId }) => {
  const { translate } = useTranslation();
  const currency = useAppSelector((state) => state.global.currency);

  const [calculation, setCalculation] = useState<SmartSharesLedgerCalculation>(baseSmartShareLedgerDefaultCalculation);

  const { data } = useGetSmartSharesLedgerCalculationQuery({ query: { userId: userId } }, { skip: !userId });

  useEffect(() => {
    if (data && currency) {
      setCalculation(formatCalculation(data, currency.decimals, currency.rate, currency.currencyCode ?? undefined));
    }
  }, [data, currency]);

  return (
    <SmartSharesLedgerCalculationContainer>
      <Row>
        <AccountCalculationBox label={translate('totalAmount')} value={calculation.totalAmountOfSmartShares} />
        <AccountCalculationBox
          label={translate('smartSharesOffered')}
          value={calculation.smartSharesOfferedOnPlatform}
        />
        <AccountCalculationBox label={translate('smartSharesSold')} value={calculation.smartSharesSoldOnPlatform} />
      </Row>
      <Row>
        <AccountCalculationBox label={translate('smartSharesOutstanding')} value={calculation.smartSharesOutstanding} />
        <AccountCalculationBox
          label={translate('totalCapitalGained')}
          value={calculation.totalCapitalGained}
          iconUrl={currency?.iconUrl}
        />
        <AccountCalculationBox label={translate('totalShareholders')} value={calculation.totalShareholders} />
      </Row>
      <Row>
        <AccountCalculationBox label={translate('numberOfRounds')} value={calculation.numberOfRounds} />
      </Row>
    </SmartSharesLedgerCalculationContainer>
  );
};

export default SmartShareLedgerCalculation;
