export type Bond = {
  id?: string;
  value?: string;
  sellInCurrencyIds?: string[];
  denomination?: string;
  interest?: string;
  isin?: string;
  couponsPaid?: string;
  offeringMonths?: string;
  issuedDate?: string;
  maturity?: string;
  regionIds?: string[];
  listingOption?: string;
  bankName?: string;
  bankAddress?: string;
  iban?: string;
  swift?: string;
  businessPlan?: string;
  presentation?: string;
  secondPartyOpinion?: string;
  evaluation?: string;
  onePager?: string;
  whitePaper?: string;
  insuranceOption?: boolean;
  secondPartyOpinionOption?: boolean;
  brokerageOption?: boolean;
  advisoryOption?: boolean;
  storageOption?: boolean;
  underwritingOption?: boolean;
};

export const InitialBond: Bond = {
  id: undefined,
  value: undefined,
  sellInCurrencyIds: undefined,
  denomination: undefined,
  interest: undefined,
  isin: undefined,
  couponsPaid: undefined,
  offeringMonths: undefined,
  issuedDate: undefined,
  maturity: undefined,
  regionIds: undefined,
  listingOption: undefined,
  bankName: undefined,
  bankAddress: undefined,
  iban: undefined,
  swift: undefined,
  businessPlan: undefined,
  presentation: undefined,
  secondPartyOpinion: undefined,
  evaluation: undefined,
  onePager: undefined,
  whitePaper: undefined,
  insuranceOption: undefined,
  secondPartyOpinionOption: undefined,
  brokerageOption: undefined,
  advisoryOption: undefined,
  storageOption: undefined,
  underwritingOption: undefined,
};
