import { BuildType } from '@/shared/types/redux/ReduxAction';

const logoutPath = 'auth/logout';

export const logout = (build: BuildType) => {
  return build.mutation<null, void>({
    query: () => ({
      url: logoutPath,
      method: 'POST',
    }),
  });
};
