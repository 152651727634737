import styled from 'styled-components';

export const CardHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;

    > div,
    button {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
`;
