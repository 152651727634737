import { useMemo } from 'react';

import { RoundOptionResponse } from '@/shared/types/api';
import { useGetRoundOptionsQuery } from '@/store/service';
import { composeDropdownOptions } from '@/utils/composeDropdownOptions/ComposeDropdownOptions';

export const useRoundOptions = () => {
  const { data: roundOptions } = useGetRoundOptionsQuery();

  const roundOptionsAsDropdown = useMemo(() => {
    return composeDropdownOptions<RoundOptionResponse>(roundOptions || [], 'value', 'label');
  }, [roundOptions]);

  return {
    roundOptions: roundOptions || [],
    roundOptionsAsDropdown: roundOptionsAsDropdown || [],
  };
};
