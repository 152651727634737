import { TransactionResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseRejectTransactionUrl = 'transactions';

const rejectTransactionUrl = (id: string) => {
  return `${baseRejectTransactionUrl}/${id}/reject`;
};

export const rejectTransaction = (build: BuildType) => {
  return build.mutation<TransactionResponse, any>({
    invalidatesTags: ['GetTransactionDetails', 'GetNftCollectionStatistics'],
    query: (rejectTransactionData: any) => ({
      url: rejectTransactionUrl(rejectTransactionData.id),
      method: 'PATCH',
      body: { note: rejectTransactionData.note },
    }),
  });
};
