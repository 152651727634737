import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAuth } from '../useAuth/useAuth';

import { AUTH_MODAL_TYPE } from '@/enums/AuthModalType';

export const useOnAuthorizeClick = () => {
  const { isAuthenticated } = useAuth();

  const [searchParams] = useSearchParams();

  const handleClick = useCallback(() => {
    if (!isAuthenticated) {
      searchParams.set('authModal', AUTH_MODAL_TYPE.SIGN_IN);
      return;
    }
  }, [isAuthenticated]);

  return {
    authProtectedClick: handleClick,
  };
};
