import { Select } from 'issuix-ui-kit';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface SelectProps {
  isOpenUp?: boolean;
  disabled?: boolean;
  items: Item[];
  placeholder: string;
  name: string;
  className?: string;
}

export interface Item {
  label: string;
  value: string;
}

const ControlledSelect: FC<SelectProps> = ({ isOpenUp, items, placeholder, disabled, name, className }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field: { onChange, value } }) => (
        <Select
          isOpenUp={isOpenUp}
          items={items}
          onSelect={onChange}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          name={name}
          className={className}
        />
      )}
    />
  );
};

export default ControlledSelect;
