import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const bondSlice = createSlice({
  name: 'bond',
  initialState,
  reducers: {},
});

export default bondSlice.reducer;
