import { Text } from 'issuix-ui-kit';
import { FC } from 'react';

import ControlledForm from '@/components/Form/ControlledForm';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { AssetResponse, BondTransactionResponse, ShareTransactionResponse } from '@/shared/types/api';
import BuyerInfoForm from '@/view/Admin/TransactionSingleView/BuyerInfo/BuyerInfoForm/BuyerInfoForm';
import { buyerInfoFormSchema } from '@/view/Admin/TransactionSingleView/BuyerInfo/BuyerInfoForm/BuyerInfoFormSchema';
import {
  CardHeader,
  TransactionSingleViewContainer,
} from '@/view/Admin/TransactionSingleView/TransactionSingleView.styles';

interface BuyerInfoProps {
  transaction: BondTransactionResponse | ShareTransactionResponse;
  assets: AssetResponse[];
}

const BuyerInfo: FC<BuyerInfoProps> = ({ transaction, assets }) => {
  const { translate } = useTranslation();

  return (
    <TransactionSingleViewContainer>
      <CardHeader>
        <Text content={translate('buyer')} size={'large'} />
      </CardHeader>
      <ControlledForm schema={buyerInfoFormSchema}>
        <BuyerInfoForm transaction={transaction} assets={assets} />
      </ControlledForm>
    </TransactionSingleViewContainer>
  );
};

export default BuyerInfo;
