import { BuildType } from '@/shared/types/redux/ReduxAction';

const rejectUserUrl = (id: string) => {
  return `/admin/users/${id}/reject`;
};

export const rejectUser = (build: BuildType) => {
  return build.mutation<void, any>({
    invalidatesTags: ['GetUserRequest', 'GetUserRequests', 'GetUsers'],
    query: ({ id, reason }) => ({
      url: rejectUserUrl(id),
      method: 'PATCH',
      body: {
        reason,
      },
    }),
  });
};
