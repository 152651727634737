import camelCase from 'lodash/camelCase';
import { TFunction } from 'react-i18next';

import { AssetResponse, CorporateUserFullResponse } from '@/shared/types/api';

export const parseFormObjectCorporateUser = (
  response: CorporateUserFullResponse,
  assets: AssetResponse[],
  translate: TFunction
) => {
  const { corporateUser, phoneNumber, address, city, email, zipCode, country, displayName, ethWallet, btcWallet } =
    response;

  const assetsData = assets.reduce((prev, current) => {
    return { ...prev, [camelCase(current.type)]: { fileId: current.id, url: current.key } };
  }, {});

  return {
    name: displayName,
    phoneNumber: phoneNumber || '',
    legalForm: corporateUser.legalForm?.name || '',
    businessFocus: translate(corporateUser.businessFocus?.translationCode?.split('.')[2] as keyof TFunction) || '',
    address: address || '',
    city: city || '',
    email,
    owner: corporateUser.owner || '',
    zipCode: zipCode || '',
    ceo: corporateUser.ceo || '',
    country: country ? country.name : undefined,
    ethWallet: ethWallet || '',
    btcWallet: btcWallet || '',
    ...assetsData,
  };
};
