import * as yup from 'yup';

export const smartShareFeaturesSchema = () => {
  yup.object().shape({
    price: yup.number(),
    currencies: yup.array(),
    issuedDate: yup.date(),
    regions: yup.object(),
  });
};
