import { Button, IColumn, Table, Text } from 'issuix-ui-kit';
import { useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SmartBondListingFilterForm from './FilterForm/SmartBondListingFilterForm';
import { SmartBondListingFilterSchema } from './FilterForm/SmartBondListingSchema';
import {
  ActionWrapper,
  CardHeader,
  FiltersContainer,
  SmartBondListingCard,
  SmartBondListingContainer,
  TableContainer,
} from './SmartBondListing.styles';
import { SmartBondListingContextProvider, useSmartBondListingContext } from './SmartBondListingContext';

import ControlledForm from '@/components/Form/ControlledForm';
import PaymentModal from '@/components/Payment/PaymentModal/PaymentModal';
import RoleProtected from '@/components/RoleProtected/RoleProtected';
import { PRODUCT_TYPE_ENUM } from '@/enums/ProductType';
import { ROLE_TYPE } from '@/enums/RoleType';
import { STATUS_ENUM } from '@/enums/Status';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useAuth } from '@/hooks/useAuth/useAuth';
import { useRatingOptions } from '@/hooks/useRatingOptions/useRatingOptions';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { USER_STATUS_TYPE_ENUM } from '@/shared/types/UserType';
import { BondResponse, RatingOptionResponse } from '@/shared/types/api';
import { getSelectedCurrency } from '@/store/global/selectors';
import { composePath } from '@/utils/composePath/ComposePath';
import { formatAmount } from '@/utils/formatAmount/FormatAmount';
import { parseBoolean } from '@/utils/parseBoolean/parseBoolean';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';

const SmartBondsListing = () => {
  return (
    <SmartBondListingContextProvider>
      <SmartBondListingView />
    </SmartBondListingContextProvider>
  );
};

const SmartBondListingView = () => {
  const { data, fetch, setFilters } = useSmartBondListingContext();
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const { ratingOptions } = useRatingOptions(translate);
  const [buyBondId, setBuyBondId] = useState<string>('');
  const currency = useAppSelector((state) => getSelectedCurrency(state));
  const { user } = useAuth();

  useEffect(() => {
    if (!currency) {
      return;
    }

    setFilters({ currencyId: currency.id, status: STATUS_ENUM.APPROVED });
  }, [currency]);

  if (!currency) {
    return <></>;
  }

  const onViewClick = (id: string) => {
    navigate(composePath(ClientRoutes.Bonds.viewSmartBond, { id }));
  };

  const onBuyClick = (id: string) => {
    setBuyBondId(id);
  };

  const bondPriceCalculation = (item: BondResponse) => {
    return formatAmount(item.value, currency.decimals, currency.rate, currency.currencyCode);
  };

  const getRating = (item: string): RatingOptionResponse => {
    return ratingOptions.find((rating: RatingOptionResponse) => rating.value === item);
  };

  const tableDef: IColumn[] = [
    {
      name: 'code',
      width: 1,
      header: {
        label: translate('code').toUpperCase(),
      },
    },
    {
      name: 'userName',
      width: 1.5,
      header: {
        label: translate('companyName').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => <>{item.user.name}</>,
      },
    },
    {
      name: 'country',
      width: 1,
      header: {
        label: translate('country').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => <>{item.user.country?.name}</>,
      },
    },
    {
      name: 'rating',
      width: 1,
      header: {
        label: translate('rating').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => (
          <>{getRating(item.rating)?.label === 'None' ? translate('none') : getRating(item.rating).label}</>
        ),
      },
    },
    {
      name: 'price',
      width: 2,
      header: {
        label: translate('smartBondPrice').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => <>{bondPriceCalculation(item)}</>,
      },
    },
    {
      name: 'maturity',
      width: 1,
      header: {
        label: translate('maturity').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => <>{`${item.maturity} ${translate('months')}`}</>,
      },
    },
    {
      name: 'interest',
      width: 1,
      header: {
        label: translate('interest').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => <>{`${item.interest} %`}</>,
      },
    },
    {
      name: 'insurance',
      width: 1,
      header: {
        label: translate('insurance').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => <>{parseBoolean(item.insuranceOption, translate)}</>,
      },
    },
    {
      name: 'industry',
      width: 2,
      header: {
        label: translate('industry').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => {
          return (
            <>
              {item.user.endUser?.corporateUser?.businessFocus?.translationCode
                ? translate(
                    item.user.endUser?.corporateUser?.businessFocus?.translationCode?.split('.')[2] as keyof TFunction
                  )
                : translate(
                    item.user.endUser?.tenantUser?.businessFocus?.translationCode?.split('.')[2] as keyof TFunction
                  )}
            </>
          );
        },
      },
    },
    {
      name: 'actions',
      width: 2,
      header: {
        label: translate('actions').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => (
          <ActionWrapper>
            <Button fullyRounded onClick={() => onViewClick(item.id)} text={translate('view')} size={'medium'} />
            <RoleProtected allowedRoles={[ROLE_TYPE.PRIVATE, ROLE_TYPE.CORPORATE]}>
              {user!.isFullyRegistered && user!.status === USER_STATUS_TYPE_ENUM.APPROVED && (
                <Button fullyRounded onClick={() => onBuyClick(item.id)} text={translate('buy')} size={'medium'} />
              )}
            </RoleProtected>
          </ActionWrapper>
        ),
      },
    },
  ];

  return (
    <>
      <PaymentModal
        isOpen={!!buyBondId}
        id={buyBondId}
        type={PRODUCT_TYPE_ENUM.BOND_PAPERS}
        onClose={() => setBuyBondId('')}
      />
      <SmartBondListingContainer>
        <SmartBondListingCard>
          <CardHeader>
            <Text content={translate('smartBondsListing')} size={'large'} />
          </CardHeader>
          <TableContainer>
            <Table data={data} fetchTrigger={() => fetch()} tableDef={tableDef}></Table>
          </TableContainer>
        </SmartBondListingCard>
        <FiltersContainer>
          <Text content={translate('filters')} size={'large'} />
          <ControlledForm schema={SmartBondListingFilterSchema()}>
            <SmartBondListingFilterForm />
          </ControlledForm>
        </FiltersContainer>
      </SmartBondListingContainer>
    </>
  );
};

export default SmartBondsListing;
