import { ShareResponse } from '../../../../shared/types/api';

import { BuildType } from '@/shared/types/redux/ReduxAction';

const getTenantSharePath = 'shares/tenant';

export const getTenantShare = (build: BuildType) => {
  return build.query<ShareResponse, void>({
    query: () => ({
      url: getTenantSharePath,
      method: 'GET',
    }),
  });
};
