import { nftPagingLimit } from '@/shared/constants/Paging';
import { PageableItems } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const getNftsByUserIdUrl = (userId: string, offset: number) =>
  `/admin/nft/user/${userId}?offset=${offset}&limit=${nftPagingLimit}`;

export const getNftsByUserId = (build: BuildType) => {
  return build.query<PageableItems, any>({
    query: ({ offset, query }) => ({
      url: getNftsByUserIdUrl(query.id, offset),
      method: 'GET',
    }),
  });
};
