import React, { createContext, FC, useContext, useState } from 'react';

interface ICreateNftCollectionContext {
  nftCollectionId: string | undefined;
  setNftCollectionId: (nftCollectionId: string) => void;
  nftProductId: string | undefined;
  setNftProductId: (nftProductId: string) => void;
}

interface CreateNftCollectionContextProviderProps {
  children: React.ReactNode;
}

export const CreateNftCollectionContext = createContext({} as ICreateNftCollectionContext);

export const useCreateNftCollectionContext = () => useContext(CreateNftCollectionContext);

export const CreateNftCollectionContextProvider: FC<CreateNftCollectionContextProviderProps> = ({ children }) => {
  const [nftCollectionId, setNftCollectionId] = useState<string | undefined>(undefined);
  const [nftProductId, setNftProductId] = useState<string | undefined>(undefined);

  return (
    <CreateNftCollectionContext.Provider
      value={{
        nftCollectionId,
        setNftCollectionId,
        nftProductId,
        setNftProductId,
      }}
    >
      {children}
    </CreateNftCollectionContext.Provider>
  );
};
