import { GreenStateTheme, MetaSwissTheme } from 'issuix-ui-kit';
import { FC } from 'react';
import ReactCodeInput from 'react-code-input';
import { Controller, useFormContext } from 'react-hook-form';

import { TENANT } from '@/enums/App';
import { getTenant } from '@/utils/getTenant/getTenant';

export interface ControlledCodeProps {
  name: string;
  placeholder: string;
}

const ControlledCode: FC<ControlledCodeProps> = ({ name, placeholder }) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field: { value, onChange } }) => (
        <ReactCodeInput
          type={'number'}
          fields={6}
          name={name}
          inputMode={'numeric'}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          {...InputStyleProps}
        />
      )}
    />
  );
};

const InputStyleProps = {
  inputStyle: {
    color:
      getTenant() === TENANT.GREEN_STATE ? GreenStateTheme.colors.white.primary : MetaSwissTheme.colors.white.primary,
    margin: '2.5px',
    width: '20px',
    fontSize: '14px',
    height: '26px',
    border: `1px solid ${
      getTenant() === TENANT.GREEN_STATE ? GreenStateTheme.colors.green.primary : MetaSwissTheme.colors.green.primary
    }`,
    backgroundColor:
      getTenant() === TENANT.GREEN_STATE ? GreenStateTheme.colors.blue.primary : MetaSwissTheme.colors.blue.primary,
    borderRadius: '3px',
    paddingLeft: '5px',
  },
};

export default ControlledCode;
