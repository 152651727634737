import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import ControlledInput from '@/components/Form/ControlledInput';
import { ASSET_TYPE_ENUM } from '@/enums/AssetTypeEnum';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import {
  AssetResponse,
  BondTransactionResponse,
  CorporateUserFullResponse,
  PrivateUserFullResponse,
  ShareTransactionResponse,
} from '@/shared/types/api';
import { useAssignedAssetUrlMutation } from '@/store/service';
import { findAssetByType } from '@/utils/assets/findAssetByType';
import { parseFormObjectCorporateUser } from '@/utils/getCorporateUserFieldValues/getCorporateUserFieldValues';
import { parsePrivateUserFormObject } from '@/utils/getUserFieldValues/getUserFieldValues';
import { setFieldValues } from '@/utils/setFieldValues/setFieldValues';
import {
  BuyerInfoContainer,
  BuyerInfoFormContainer,
  BuyerInfoWrapper,
  BuyerLogo,
  BuyerLogoWrapper,
  Column,
  RowFirst,
} from '@/view/Admin/TransactionSingleView/BuyerInfo/BuyerInfoForm/BuyerInfoForm.styles';

interface BuyerInfoProps {
  transaction: BondTransactionResponse | ShareTransactionResponse;
  assets: AssetResponse[];
}

const isPrivateUser = (
  buyer: PrivateUserFullResponse | CorporateUserFullResponse
): buyer is PrivateUserFullResponse => {
  return buyer.role === 'private';
};

const BuyerInfoForm: FC<BuyerInfoProps> = ({ transaction, assets }) => {
  const { translate } = useTranslation();
  const { setValue } = useFormContext();

  const [getImageUrl, { data: imageUrl }] = useAssignedAssetUrlMutation();

  useEffect(() => {
    if (transaction && assets) {
      isPrivateUser(transaction.buyer)
        ? setFieldValues(parsePrivateUserFormObject(transaction.buyer, assets), setValue)
        : setFieldValues(parseFormObjectCorporateUser(transaction.buyer, assets, translate), setValue);
    }
  }, [transaction]);

  useEffect(() => {
    const picture = findAssetByType(assets, ASSET_TYPE_ENUM.PICTURE);
    if (assets && assets.length && picture) {
      getImageUrl({ assetId: picture.id });
    }
  }, [assets]);

  return (
    <BuyerInfoFormContainer>
      <BuyerInfoWrapper>
        <BuyerLogoWrapper>{imageUrl && <BuyerLogo src={imageUrl} />}</BuyerLogoWrapper>
        <BuyerInfoContainer>
          <RowFirst>
            <Column>
              <ControlledInput placeholder={translate('name')} id={'name'} name={'name'} disabled={true} />
            </Column>
            <Column>
              <ControlledInput
                placeholder={translate('birthDate')}
                id={'birthDate'}
                name={'birthDate'}
                disabled={true}
              />
            </Column>
            <Column>
              <ControlledInput
                placeholder={translate('phoneNumber')}
                id={'phoneNumber'}
                name={'phoneNumber'}
                disabled={true}
              />
            </Column>
          </RowFirst>
          <RowFirst>
            <Column>
              <ControlledInput placeholder={translate('email')} id={'email'} name={'email'} disabled={true} />
            </Column>
            <Column>
              <ControlledInput placeholder={translate('country')} id={'country'} name={'country'} disabled={true} />
            </Column>
            <Column>
              <ControlledInput placeholder={translate('zipCode')} id={'zipCode'} name={'zipCode'} disabled={true} />
            </Column>
          </RowFirst>
          <RowFirst>
            <Column>
              <ControlledInput
                placeholder={translate('citizenship')}
                id={'citizenship'}
                name={'citizenship'}
                disabled={true}
              />
            </Column>
            <Column>
              <ControlledInput placeholder={translate('address')} id={'address'} name={'address'} disabled={true} />
            </Column>
            <Column>
              <ControlledInput placeholder={translate('city')} id={'city'} name={'city'} disabled={true} />
            </Column>
          </RowFirst>
        </BuyerInfoContainer>
      </BuyerInfoWrapper>
    </BuyerInfoFormContainer>
  );
};

export default BuyerInfoForm;
