import { GreenStateTheme, MetaSwissTheme, useWizardContext } from 'issuix-ui-kit';
import moment from 'moment';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { FormCol, FormRow } from '../../ThreeStep.styles';

import { useRegistrationContext } from '@/components/AuthModal/RegistrationContext';
import ControlledDateInput from '@/components/Form/ControlledDateInput';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledPassword from '@/components/Form/ControlledPassword';
import ControlledSelect from '@/components/Form/ControlledSelect';
import { TENANT } from '@/enums/App';
import { useCheckEmailOnBlur } from '@/hooks/useCheckEmailOnBlur/useCheckEmailOnBlur';
import { useCountries } from '@/hooks/useCountries/UseCountries';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { RegistrationSecondStepState } from '@/shared/types/AuthTypes';
import { getTenant } from '@/utils/getTenant/getTenant';
import { setFieldValues } from '@/utils/setFieldValues/setFieldValues';

const SecondStepPrivateForm: FC = () => {
  const { translate } = useTranslation();
  const {
    ejected: { goToNextPage, setOnNextClickHandler, goToPreviousPage, setOnBackClickHandler },
    modifyNextButtonProps,
    modifyBackButtonProps,
  } = useWizardContext();

  const { submitSecondStepData, secondStepData } = useRegistrationContext();

  const { countriesAsDropdown } = useCountries();

  const { checkEmailOnBlur, isEmailExist } = useCheckEmailOnBlur();

  const {
    getValues,
    formState: { isValid },
    setValue,
  } = useFormContext();

  const fetchData = () => {
    submitSecondStepData(getValues());
    goToNextPage();
  };

  const goBack = () => {
    submitSecondStepData(getValues());
    goToPreviousPage();
  };

  useEffect(() => {
    setOnNextClickHandler(() => {
      fetchData();
    });
    setOnBackClickHandler(() => {
      goBack();
    });
  }, []);

  useEffect(() => {
    if (secondStepData) {
      setFieldValues<RegistrationSecondStepState>(secondStepData, setValue);
    }
  }, []);

  useEffect(() => {
    modifyNextButtonProps({ disabled: !isValid || isEmailExist, label: translate('next') });
  }, [isValid, isEmailExist]);

  useEffect(() => {
    modifyBackButtonProps({ label: translate('back') });
  }, []);

  return (
    <>
      <FormRow>
        <FormCol>
          <ControlledInput placeholder={translate('firstName')} id="firstName" name="firstName" />
          <ControlledPassword placeholder={translate('password')} id="password" name="password" />
          <ControlledDateInput
            placeholder={translate('birthDate')}
            id="birthDate"
            name="birthDate"
            maxDate={moment(new Date(), 'dd.mm.yyyy').toDate()}
          />
          <ControlledInput
            placeholder={translate('emailAddress')}
            id="email"
            name="email"
            onBlur={() => checkEmailOnBlur(getValues().email)}
          />
          <ControlledSelect name="countryId" items={countriesAsDropdown} placeholder={translate('country')} />
          <ControlledInput placeholder={translate('citizenship')} id="citizenship" name="citizenship" />
        </FormCol>
        <FormCol>
          <ControlledInput placeholder={translate('lastName')} id="lastName" name="lastName" />
          <ControlledPassword
            placeholder={translate('confirmPassword')}
            id="passwordConfirmation"
            name="passwordConfirmation"
          />
          <ControlledInput placeholder={translate('phoneNumber')} id="phoneNumber" name="phoneNumber" />
          <ControlledInput placeholder={translate('city')} id="city" name="city" />
          <ControlledInput placeholder={translate('zipCode')} id="zipCode" name="zipCode" />
          <ControlledInput placeholder={translate('address')} id="address" name="address" />
        </FormCol>
      </FormRow>
      {isEmailExist && <ErrorMessage>{translate('emailExist')}</ErrorMessage>}
    </>
  );
};

export default SecondStepPrivateForm;

const ErrorMessage = styled.p`
  color: ${getTenant() === TENANT.GREEN_STATE ? GreenStateTheme.colors.red.error : MetaSwissTheme.colors.red.error};
  font-size: 1.2rem;
  position: relative;
`;
