import { TitleBar } from 'issuix-ui-kit';
import { FC } from 'react';

import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { smartSharesListingData } from '@/view/Core/Landing/LandingMockData';
import ListData from '@/view/Core/Landing/components/ListData';

const SmartSharesLanding: FC = () => {
  const { translate } = useTranslation();
  return (
    <>
      <TitleBar text={translate('smartSharesListing')} fontSize={'large'} />
      <ListData data={smartSharesListingData} />
    </>
  );
};

export default SmartSharesLanding;
