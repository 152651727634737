import { colors, GlobalSpinner, Text } from 'issuix-ui-kit';
import { FC, useEffect, useState } from 'react';

import { CalculationsBox, SmartSharePortfolioCard, TableRowContainer } from './SmartSharePortfolio.styles';
import SmartSharePortfolioChart from './SmartSharePortfolioChart/SmartSharePortfolioChart';

import AccountCalculationBox from '@/components/AccountCalculationBox/AccountCalculationBox';
import { PRODUCT_TYPE_ENUM } from '@/enums/ProductType';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { getSelectedCurrency } from '@/store/global/selectors';
import { useGetInvestedAmountQuery, useGetPortfolioChartQuery } from '@/store/service';
import { formatAmount } from '@/utils/formatAmount/FormatAmount';
import SmartSharePortfolioTable from '@/view/Core/SmartSharePortfolio/SmartSharePortfolioTable/SmartSharePortfolioTable';

interface SmartSharePortfolioProps {
  userId?: string;
}

const SmartSharePortfolio: FC<SmartSharePortfolioProps> = ({ userId }) => {
  const { translate } = useTranslation();
  const { data: chart, isLoading } = useGetPortfolioChartQuery(
    {
      query: { productType: PRODUCT_TYPE_ENUM.ACTIONS, userId: userId },
    },
    { skip: !userId }
  );
  const selector = useAppSelector((state) => getSelectedCurrency(state));
  const [calculation, setCalculation] = useState<string>('');

  const { data: invested } = useGetInvestedAmountQuery({ query: { id: userId } }, { skip: !userId });

  useEffect(() => {
    setCalculation(formatAmount(invested, selector?.decimals, selector?.rate, selector?.currencyCode));
  }, [invested, selector]);

  return (
    <GlobalSpinner isActive={!chart && !userId && isLoading} background={colors.blue.primary}>
      <SmartSharePortfolioCard>
        <Text content={translate('smartSharePortfolio')} size={'large'} />
        <TableRowContainer>
          <SmartSharePortfolioTable userId={userId} />
          <SmartSharePortfolioChart chart={chart} />
          <CalculationsBox>
            <AccountCalculationBox label={translate('invested')} value={calculation} iconUrl={selector?.iconUrl} />
          </CalculationsBox>
        </TableRowContainer>
      </SmartSharePortfolioCard>
    </GlobalSpinner>
  );
};
export default SmartSharePortfolio;
