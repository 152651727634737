import { Step, StepState, Text, Wizard } from 'issuix-ui-kit';

import RegisterFinalModal from '../RegisterModal/RegisterFinalModal/RegisterFinalModal';

import SecondStep from './steps/SecondStep/SecondStep';
import ThirdStep from './steps/ThirdStep/ThirdStep';

import { LogoContainer, RegisterContainer } from '@/components/AuthModal/RegisterModal/RegisterModal.styles';
import { useRegistrationContext } from '@/components/AuthModal/RegistrationContext';
import FirstStep from '@/components/AuthModal/ThreeStepRegisterModal/steps/FirstStep/FirstStep';
import Logo from '@/components/Logo/Logo';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { getTenant } from '@/utils/getTenant/getTenant';

const ThreeStepRegisterModal = () => {
  const { isSuccessfulRegistration } = useRegistrationContext();
  const { translate } = useTranslation();
  const tenant = getTenant();

  return (
    <RegisterContainer>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      {!isSuccessfulRegistration && (
        <Text
          content={translate('welcomeTo', { tenant: tenant.charAt(0).toUpperCase() + tenant.slice(1) })}
          size="large"
        />
      )}
      {isSuccessfulRegistration ? <RegisterFinalModal /> : <Wizard steps={args.steps} />}
    </RegisterContainer>
  );
};

export default ThreeStepRegisterModal;

const args: { steps: Step[] } = {
  steps: [
    { position: 1, component: <FirstStep />, state: 'current' as StepState },
    { position: 2, component: <SecondStep />, state: 'next' as StepState },
    { position: 3, component: <ThirdStep />, state: 'next' as StepState },
  ],
};
