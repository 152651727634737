import { BuildType } from '@/shared/types/redux/ReduxAction';

const checkIfIsinExistSharesPath = '/shares/check-isin';

export const checkIfIsinExistShares = (build: BuildType) => {
  return build.mutation<boolean, string>({
    query: (isin: string) => ({
      url: checkIfIsinExistSharesPath,
      method: 'POST',
      body: { isin },
    }),
  });
};
