import styled from 'styled-components';

export const LogoContainer = styled.div`
  margin: 4rem 4rem 2rem 4rem;
  width: 80%;
`;

export const FormContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
`;
