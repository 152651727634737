import { useMemo } from 'react';
import { TFunction } from 'react-i18next';

import { EndUserRoleOptionResponse } from '@/shared/types/api';
import { useGetEndUserRoleOptionsQuery } from '@/store/service';

export const useEndUserRoleOptions = (translate: TFunction) => {
  const { data: endUserRoleOptions } = useGetEndUserRoleOptionsQuery();

  const filteredEndUserRoleOptions = endUserRoleOptions?.filter(
    (endUserRole: EndUserRoleOptionResponse) => endUserRole.value !== 'tenant'
  );

  const endUserRoleOptionsAsDropdown = useMemo(
    () =>
      endUserRoleOptions?.map((endUserRole: EndUserRoleOptionResponse) => {
        return {
          value: endUserRole.id,
          label: translate(endUserRole.value as keyof TFunction),
        };
      }),
    [endUserRoleOptions, translate]
  );

  const filteredEndUserRoleOptionsAsDropdown = useMemo(
    () =>
      filteredEndUserRoleOptions?.map((endUserRole: EndUserRoleOptionResponse) => {
        return {
          value: endUserRole.id,
          label: translate(endUserRole.value as keyof TFunction),
        };
      }),
    [endUserRoleOptions, translate]
  );

  return {
    endUserRoleOptions: endUserRoleOptions || [],
    endUserRoleOptionsAsDropdown: endUserRoleOptionsAsDropdown || [],
    filteredEndUserRoleOptionsAsDropdown: filteredEndUserRoleOptionsAsDropdown || [],
  };
};
