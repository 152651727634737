import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const nftSlice = createSlice({
  name: 'nft',
  initialState,
  reducers: {},
});

export default nftSlice.reducer;
