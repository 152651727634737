import { Button, ModalDialog } from 'issuix-ui-kit';
import { FC } from 'react';

import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { ButtonsContainer, StyledText } from '@/view/Admin/Requests/Requests.styles';

type CouponPaymentModalType = {
  selectedCoupon: string | undefined;
  setSelectedCoupon: (selectedCoupon: string | undefined) => void;
  onClose: () => void;
  onPillClick: (selectedCoupon: string) => void;
};

export const CouponPaymentModal: FC<CouponPaymentModalType> = ({
  selectedCoupon,
  onClose,
  setSelectedCoupon,
  onPillClick,
}) => {
  const { translate } = useTranslation();

  return (
    <ModalDialog isOpen={!!selectedCoupon} close={onClose}>
      <StyledText content={translate('approve')} size={'extraLarge'} />
      <StyledText content={translate('areYouSureCouponPay')} size={'medium'} />
      <ButtonsContainer>
        <Button text={translate('cancel')} onClick={onClose} buttonType={'secondary'} />
        <Button
          text={translate('approve')}
          onClick={() => {
            selectedCoupon ? onPillClick(selectedCoupon) : setSelectedCoupon(undefined);
            setSelectedCoupon(undefined);
          }}
          buttonType={'primary'}
        />
      </ButtonsContainer>
    </ModalDialog>
  );
};
