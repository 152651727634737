import { CurrencyResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const currenciesPath = 'currencies';

export const getCurrencies = (build: BuildType) => {
  return build.query<CurrencyResponse[], void>({
    query: () => currenciesPath,
  });
};
