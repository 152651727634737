import { Text } from 'issuix-ui-kit';
import styled from 'styled-components';

export const AccountCalculationBoxStyled = styled.div`
  min-width: 22rem;
  width: 30rem;
  min-height: 14rem;
  height: fit-content;
  border: solid 0.2rem rgba(255, 255, 255, 0.5);
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  border-radius: 1.5rem;
  gap: 1rem;
`;

export const IconStyled = styled.img`
  height: 2.4rem;
  width: 2.4rem;
  cursor: pointer;
`;

export const StyledText = styled(Text)`
  padding-inline: 1rem;
  text-align: center;
`;
