import { Text } from 'issuix-ui-kit';
import styled from 'styled-components';

interface Props {
  message: string;
  color: string;
}

const ErrorTextContainer = styled.div`
  margin-top: 4rem;
  grid-column: 1 / 4;
`;

export const PaymentError = ({ message, color }: Props) => (
  <ErrorTextContainer>
    <Text content={message} color={color} />
  </ErrorTextContainer>
);
