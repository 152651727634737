import { Modal } from 'issuix-ui-kit';
import { FC } from 'react';

import ForgotPasswordModal from './ForgotPasswordModal/ForgotPasswordModal';
import RegisterModal from './RegisterModal/RegisterModal';
import { RegistrationContextProvider, useRegistrationContext } from './RegistrationContext';
import ThreeStepRegisterModal from './ThreeStepRegisterModal/ThreeStepRegisterModal';

import { useAuthContext } from '@/components/Auth/AuthContext';
import ResetPasswordWithCodeModal from '@/components/AuthModal/ResetPasswordWithCodeModal/ResetPasswordWithCodeModal';
import SignInModal from '@/components/AuthModal/SignInModal/SignInModal';
import { AUTH_MODAL_TYPE } from '@/enums/AuthModalType';

export const AuthModal: FC = () => {
  return (
    <RegistrationContextProvider>
      <AuthModalView />
    </RegistrationContextProvider>
  );
};

const AuthModalView = () => {
  const { modalType, closeModal } = useAuthContext();

  const { submitFirstStepData, submitSecondStepData, submitThirdStepData, submitForgotPasswordData } =
    useRegistrationContext();

  const onClose = () => {
    closeModal();
    submitFirstStepData({});
    submitSecondStepData({});
    submitThirdStepData({}, false);
    submitForgotPasswordData('');
  };

  return (
    <Modal close={onClose} isOpen={!!modalType}>
      {modalType === AUTH_MODAL_TYPE.REGISTER && <RegisterModal />}
      {modalType === AUTH_MODAL_TYPE.THREE_STEP_REGISTER && <ThreeStepRegisterModal />}
      {modalType === AUTH_MODAL_TYPE.SIGN_IN && <SignInModal />}
      {modalType === AUTH_MODAL_TYPE.FORGOT_PASSWORD && <ForgotPasswordModal />}
      {modalType === AUTH_MODAL_TYPE.RESET_PASSWORD_WITH_CODE && <ResetPasswordWithCodeModal />}
    </Modal>
  );
};
