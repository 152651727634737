import { FC, PropsWithChildren } from 'react';

import { ROLE_TYPE } from '@/enums/RoleType';
import { useAuth } from '@/hooks/useAuth/useAuth';

type Props = {
  allowedRoles: Array<ROLE_TYPE>;
  customCondition?: boolean;
};

const RoleProtected: FC<PropsWithChildren<Props>> = ({ children, allowedRoles = [], customCondition = true }) => {
  const { user } = useAuth();

  if (!customCondition) {
    return <></>;
  }

  if (!allowedRoles.some((allowedRole) => allowedRole === user?.role)) {
    return <></>;
  }

  return <>{children}</>;
};

export default RoleProtected;
