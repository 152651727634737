import { Dropdown } from 'issuix-ui-kit';
import { FC, PropsWithChildren, useMemo } from 'react';

import { usePaymentContext } from '@/components/Payment/PaymentModal/context/PaymentContext';
import {
  SetCurrencyContainer,
  TitleContainer,
} from '@/components/Payment/PaymentModal/shared/CurrencyBlock/CurrencyBlock.styled';
import { CurrencyResponse } from '@/shared/types/api';
import { composeCurrencyDropdownOptions } from '@/utils/composeCurrencyDropdownOptions/composeCurrencyDropdownOptions';

type Props = {
  disabled?: boolean;
};

const CurrencyBlock: FC<PropsWithChildren<Props>> = ({ disabled = false, children }) => {
  const { resource, currency, setCurrency } = usePaymentContext();

  const currenciesAsDropdown = useMemo(() => {
    return composeCurrencyDropdownOptions<CurrencyResponse>(
      resource?.currencies || [],
      'id',
      'currencyCode',
      'iconUrl',
      'decimals',
      'rate'
    );
  }, [resource?.currencies]);

  if (!currency) {
    return <></>;
  }

  return (
    <>
      <TitleContainer>{children}</TitleContainer>
      <SetCurrencyContainer>
        <Dropdown
          disabled={disabled}
          items={currenciesAsDropdown}
          onSelect={(val) => setCurrency(resource?.currencies.find((currency) => currency.id === val) || undefined)}
          value={currency.id}
        />
      </SetCurrencyContainer>
    </>
  );
};

export default CurrencyBlock;
