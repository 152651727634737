import { CreateCaseRequest } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const createCasePath = '/case';

export const createCase = (build: BuildType) => {
  return build.mutation<void, CreateCaseRequest>({
    invalidatesTags: ['GetCasesForRecipient'],
    query: (data: CreateCaseRequest) => ({
      url: createCasePath,
      method: 'POST',
      body: data,
    }),
  });
};
