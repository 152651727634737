import * as yup from 'yup';

export const companyInfoSchema = () =>
  yup.object().shape({
    name: yup.string(),
    phoneNumber: yup.string(),
    legalForm: yup.string(),
    businessFocus: yup.string(),
    address: yup.string(),
    city: yup.string(),
    email: yup.string(),
    owner: yup.string(),
    zipCode: yup.string(),
    ceo: yup.string(),
    country: yup.string(),
  });
