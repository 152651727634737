import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseAdminUsersUrl = 'admin/users';
const baseUsersUrl = 'users';

export const getUserById = (build: BuildType) => {
  return build.query({
    query: (userId: string) => ({
      url: `${baseAdminUsersUrl}/${userId}`,
      method: 'GET',
    }),
  });
};

export const getCorporateUser = (build: BuildType) => {
  return build.query({
    query: (userId: string) => ({
      url: `${baseAdminUsersUrl}/${userId}/corporate`,
      method: 'GET',
    }),
    providesTags: ['CorporateUser'],
  });
};

export const getPrivateUser = (build: BuildType) => {
  return build.query({
    query: (userId: string) => ({
      url: `${baseAdminUsersUrl}/${userId}/private`,
      method: 'GET',
    }),
    providesTags: ['PrivateUser'],
  });
};

export const getUserRole = (build: BuildType) => {
  return build.query({
    query: (userId: string) => ({
      url: `${baseUsersUrl}/${userId}/role`,
      method: 'GET',
    }),
  });
};

export const getMyCorporateUser = (build: BuildType) => {
  return build.query({
    query: (userId: string) => ({
      url: `${baseUsersUrl}/${userId}/corporate`,
      method: 'GET',
    }),
    providesTags: ['MyCorporateUser'],
  });
};

export const getMyPrivateUser = (build: BuildType) => {
  return build.query({
    query: (userId: string) => ({
      url: `${baseUsersUrl}/${userId}/private`,
      method: 'GET',
    }),
    providesTags: ['MyPrivateUser'],
  });
};
