import { TFunction } from 'i18next';

import { NFT_STATUS_ENUM } from '@/enums/nft/NftStatusEnum';

export const mapNftStatusToTranslatedLabel = (status: NFT_STATUS_ENUM, translate: TFunction) => {
  switch (status) {
    case NFT_STATUS_ENUM.AVAILABLE:
      return translate('availableNft');
    case NFT_STATUS_ENUM.RESERVED:
      return translate('reservedNft');
    case NFT_STATUS_ENUM.SOLD:
      return translate('soldNft');
    case NFT_STATUS_ENUM.OWNED:
      return translate('viewNft');
  }
};
