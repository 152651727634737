import { FC } from 'react';

import { ROLE_TYPE } from '@/enums/RoleType';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useNav } from '@/hooks/useNav/UseNav';
import { getUserSelector } from '@/store/global/selectors';
import { useGetCorporateUserAssetsQuery, useGetShareAssetsQuery, useGetShareByIdQuery } from '@/store/service';
import CompanyInfo from '@/view/Shared/ViewShare/CompanyInfo/CompanyInfo';
import SmartShareFeatures from '@/view/Shared/ViewShare/SmartShareFeatures/SmartShareFeatures';
import Transactions from '@/view/Shared/ViewShare/Transactions/Transactions';
import { ViewShareWrapper } from '@/view/Shared/ViewShare/ViewShare.styles';

const ViewShare = () => {
  return <ViewShareContent />;
};

const ViewShareContent: FC = () => {
  const user = useAppSelector((state) => getUserSelector(state));
  const { params } = useNav();
  const { data: share } = useGetShareByIdQuery({ query: { id: params.id && params.id } }, { skip: !params });
  const { data: assets } = useGetShareAssetsQuery({ query: { id: params.id && params.id } }, { skip: !params });
  const { data: userAssets } = useGetCorporateUserAssetsQuery(share?.user?.id, { skip: !share?.user?.id });

  return (
    <ViewShareWrapper>
      <SmartShareFeatures
        share={share}
        assets={assets}
        hasEdit={user?.id === share?.user.id || user?.role === ROLE_TYPE.ADMIN}
      />
      <CompanyInfo user={share?.user} assets={userAssets} />
      <Transactions
        itemId={share?.id}
        canViewTransaction={user?.id === share?.user.id || user?.role === ROLE_TYPE.ADMIN}
      />
    </ViewShareWrapper>
  );
};

export default ViewShare;
