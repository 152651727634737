import { SmartShareLedgerCalculationResponse } from '@/shared/types/api/models/SmartShareLedgerCalculationResponse';
import { formatAmount } from '@/utils/formatAmount/FormatAmount';

export const baseSmartShareLedgerDefaultCalculation = {
  totalAmountOfSmartShares: '0',
  smartSharesOfferedOnPlatform: '0',
  smartSharesSoldOnPlatform: '0',
  smartSharesOutstanding: '0',
  totalCapitalGained: '0',
  totalShareholders: '0',
  numberOfRounds: '0',
};

export type SmartSharesLedgerCalculation = {
  totalAmountOfSmartShares: string;
  smartSharesOfferedOnPlatform: string;
  smartSharesSoldOnPlatform: string;
  smartSharesOutstanding: string;
  totalCapitalGained: string;
  totalShareholders: string;
  numberOfRounds: string;
};

export const formatCalculation = (
  smartShareLedgerCalculation: SmartShareLedgerCalculationResponse,
  currencyDecimals = 2,
  currencyRate = 1,
  currencyName = ''
): SmartSharesLedgerCalculation => {
  return {
    totalAmountOfSmartShares: smartShareLedgerCalculation.totalAmountOfSmartShares.toString(),
    smartSharesOfferedOnPlatform: smartShareLedgerCalculation.smartSharesOfferedOnPlatform.toString(),
    smartSharesSoldOnPlatform: smartShareLedgerCalculation.smartSharesSoldOnPlatform.toString(),
    smartSharesOutstanding: smartShareLedgerCalculation.smartSharesOutstanding.toString(),
    totalCapitalGained: formatAmount(
      smartShareLedgerCalculation.totalCapitalGained,
      currencyDecimals,
      currencyRate,
      currencyName
    ),
    totalShareholders: smartShareLedgerCalculation.totalShareholders.toString(),
    numberOfRounds: smartShareLedgerCalculation.numberOfRounds,
  };
};
