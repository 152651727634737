import SecondStepCorporateForm from './SecondStepCorporateForm';
import SecondStepPrivateForm from './SecondStepPrivateForm';
import { secondStepCorporateSchema, secondStepPrivateSchema } from './SecondStepSchema';

import { useRegistrationContext } from '@/components/AuthModal/RegistrationContext';
import ControlledForm from '@/components/Form/ControlledForm';
import { ROLE_TYPE } from '@/enums/RoleType';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';

const SecondStep = () => {
  const { translate } = useTranslation();
  const { firstStepData } = useRegistrationContext();

  return (
    <>
      {firstStepData.accountType === ROLE_TYPE.PRIVATE ? (
        <ControlledForm schema={secondStepPrivateSchema(translate)}>
          <SecondStepPrivateForm />
        </ControlledForm>
      ) : (
        <ControlledForm schema={secondStepCorporateSchema(translate)}>
          <SecondStepCorporateForm />
        </ControlledForm>
      )}
    </>
  );
};

export default SecondStep;
