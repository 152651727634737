import { Text } from 'issuix-ui-kit';
import { FC, PropsWithChildren } from 'react';

import {
  DetailsBlockLayout,
  DetailsBlockLayoutTitle,
  DetailsBlockLayoutContent,
} from '@/components/Payment/PaymentModal/shared/DetailBlock/DetailBlock.style';

type Props = {
  title: string;
  className?: string;
};

const DetailBlock: FC<PropsWithChildren<Props>> = ({ title, children, className }) => {
  return (
    <DetailsBlockLayout className={className}>
      <DetailsBlockLayoutTitle>
        <Text content={title} size={'large'} />
      </DetailsBlockLayoutTitle>
      <DetailsBlockLayoutContent>{children}</DetailsBlockLayoutContent>
    </DetailsBlockLayout>
  );
};

export default DetailBlock;
