import { FC } from 'react';
import { FieldErrors, FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import styled from 'styled-components';

import ControlledUpload from '@/components/Form/ControlledUpload';
import { Row } from '@/components/Layout/Row/Row.styled';
import { DOCUMENT_UPLOAD_STATUS } from '@/enums/DocumentStatus';
import { ASSET_NFT_ENUM } from '@/enums/nft/AssetNftEnum';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useUploadFile } from '@/hooks/useUploadFile/useUploadFile';
import { AssetResponse } from '@/shared/types/api';
import {
  getDragAndDropDocumentationLabel,
  getViewDocumentationLabel,
} from '@/utils/getDocumentationLabel/getDocumentationLabel';

type DocumentationProps = {
  collectionAssets: AssetResponse[] | [];
  productAsset: AssetResponse;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  errors?: FieldErrors;
  isEditMode: boolean;
};

export const Documentation: FC<DocumentationProps> = ({
  collectionAssets,
  productAsset,
  setValue,
  getValues,
  errors,
  isEditMode,
}) => {
  const { translate } = useTranslation();

  const { uploadUnassignedAsset, viewFile } = useUploadFile();

  return (
    <DocumentationBox>
      <RowWrap>
        <ControlledUpload
          name={'productDocument'}
          placeholder={translate('product')}
          incorrectFileFormatLabel={translate('incorrectFileFormat')}
          dragDropFilesHereLabel={getDragAndDropDocumentationLabel(
            translate,
            getValues('productDocument')?.fileId ? DOCUMENT_UPLOAD_STATUS.UPLOADED : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
          )}
          clickToViewDocumentLabel={getViewDocumentationLabel(
            translate,
            getValues('productDocument')?.fileId ? DOCUMENT_UPLOAD_STATUS.UPLOADED : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
          )}
          onViewDocument={(fileId: string) =>
            viewFile(fileId, 'productDocument', getValues, productAsset ? [productAsset] : [])
          }
          onUploadFile={async (file) => await uploadUnassignedAsset(file, 'productDocument', setValue)}
          onDelete={(name: string) =>
            setValue(
              name,
              { fileId: undefined, url: undefined, type: name },
              { shouldDirty: true, shouldValidate: true }
            )
          }
          errors={errors?.productDocument}
          isEditMode={isEditMode}
        />
        <ControlledUpload
          placeholder={translate('logo')}
          name={ASSET_NFT_ENUM.LOGO}
          incorrectFileFormatLabel={translate('incorrectFileFormat')}
          dragDropFilesHereLabel={getDragAndDropDocumentationLabel(
            translate,
            getValues(ASSET_NFT_ENUM.LOGO)?.fileId
              ? DOCUMENT_UPLOAD_STATUS.UPLOADED
              : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
          )}
          clickToViewDocumentLabel={getViewDocumentationLabel(
            translate,
            getValues(ASSET_NFT_ENUM.LOGO)?.fileId
              ? DOCUMENT_UPLOAD_STATUS.UPLOADED
              : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
          )}
          onViewDocument={(fileId: string) => viewFile(fileId, ASSET_NFT_ENUM.LOGO, getValues, collectionAssets)}
          onUploadFile={async (file) => await uploadUnassignedAsset(file, ASSET_NFT_ENUM.LOGO, setValue)}
          onDelete={(name: string) =>
            setValue(
              name,
              { fileId: undefined, url: undefined, type: name },
              { shouldDirty: true, shouldValidate: true }
            )
          }
          errors={errors?.logo}
          isEditMode={isEditMode}
        />
        <ControlledUpload
          placeholder={translate('banner')}
          name={ASSET_NFT_ENUM.BANNER}
          onViewDocument={(fileId: string) => viewFile(fileId, ASSET_NFT_ENUM.BANNER, getValues, collectionAssets)}
          incorrectFileFormatLabel={translate('incorrectFileFormat')}
          dragDropFilesHereLabel={getDragAndDropDocumentationLabel(
            translate,
            getValues(ASSET_NFT_ENUM.BANNER)?.fileId
              ? DOCUMENT_UPLOAD_STATUS.UPLOADED
              : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
          )}
          clickToViewDocumentLabel={getViewDocumentationLabel(
            translate,
            getValues(ASSET_NFT_ENUM.BANNER)?.fileId
              ? DOCUMENT_UPLOAD_STATUS.UPLOADED
              : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
          )}
          onUploadFile={async (file) => await uploadUnassignedAsset(file, ASSET_NFT_ENUM.BANNER, setValue)}
          onDelete={(name: string) =>
            setValue(
              name,
              { fileId: undefined, url: undefined, type: name },
              { shouldDirty: true, shouldValidate: true }
            )
          }
          errors={errors?.banner}
          isEditMode={isEditMode}
        />
      </RowWrap>
    </DocumentationBox>
  );
};

const DocumentationBox = styled.div`
  width: 100%;
`;

const RowWrap = styled(Row)`
  flex-wrap: wrap;
`;
