import * as yup from 'yup';

export const transactionDetailInfoSchema = () => {
  return yup.object().shape({
    time: yup.date(),
    detectedAmount: yup.number(),
    hash: yup.string(),
    issuerName: yup.string(),
    buyerName: yup.string(),
    code: yup.string(),
    amount: yup.number(),
    quantity: yup.number(),
    paymentRefNumber: yup.string(),
    status: yup.string(),
  });
};
