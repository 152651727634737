import { AssetResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const getNftCollectionAssetsPath = 'nft-collection';

const getNftCollectionAssetsUrl = (query: { id: string }): string => `${getNftCollectionAssetsPath}/${query.id}/assets`;

export const getNftCollectionAssets = (build: BuildType) => {
  return build.query<AssetResponse[], any>({
    query: ({ query }) => ({
      url: getNftCollectionAssetsUrl(query),
      method: 'GET',
    }),
  });
};
