import { Button, Text } from 'issuix-ui-kit';
import { FC, useState } from 'react';
import styled from 'styled-components';

import ControlledForm from '@/components/Form/ControlledForm';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { AssetResponse, BondResponse } from '@/shared/types/api';
import SmartBondFeaturesForm from '@/view/Shared/ViewBond/SmartBondFeatures/SmartBondFeaturesForm/SmartBondFeaturesForm';
import { smartBondFeaturesSchema } from '@/view/Shared/ViewBond/SmartBondFeatures/SmartBondFeaturesForm/SmartBondFeaturesSchema';
import { SmartBondContainer, CardHeader } from '@/view/Shared/ViewBond/ViewBond.styles';

interface SmartBondFeaturesProps {
  bond: BondResponse;
  assets: AssetResponse[];
  hasEdit: boolean;
}

const SmartBondFeatures: FC<SmartBondFeaturesProps> = ({ bond, assets, hasEdit = true }) => {
  const { translate } = useTranslation();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  return (
    <SmartBondContainer>
      <CardHeader>
        <Text content={translate('smartBondFeatures')} size={'large'} />
      </CardHeader>
      <EditButtonContainer>
        {!isEditMode && hasEdit && <Button onClick={() => setIsEditMode(!isEditMode)} text={translate('edit')} />}
      </EditButtonContainer>
      <ControlledForm schema={smartBondFeaturesSchema(translate)}>
        <SmartBondFeaturesForm bond={bond} isEditMode={isEditMode} setIsEditMode={setIsEditMode} assets={assets} />
      </ControlledForm>
    </SmartBondContainer>
  );
};

export default SmartBondFeatures;

export const EditButtonContainer = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
`;
