import { LeftMenuItem } from 'issuix-ui-kit';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { FeatureFlag } from '@/components/FeatureFlag/FeatureFlag';
import { FEATURE_FLAGS } from '@/components/FeatureFlag/featureFlags';
import RoleProtected from '@/components/RoleProtected/RoleProtected';
import { PAGES } from '@/enums/Pages';
import { ROLE_TYPE } from '@/enums/RoleType';
import { useAuth } from '@/hooks/useAuth/useAuth';
import { useGetCurrentPage } from '@/hooks/useGetCurrentPage/useGetCurrentPage';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { USER_STATUS_TYPE_ENUM } from '@/shared/types/UserType';
import { featureDashboard } from '@/utils/featuresFlags/featureFlags';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';

const LeftMenu: FC = () => {
  const { user } = useAuth();
  const { translate } = useTranslation();
  const { currentPage } = useGetCurrentPage();
  const navigate = useNavigate();

  return (
    <LeftMenuContainer>
      <FeatureFlag name={FEATURE_FLAGS.DASHBOARD}>
        <RoleProtected allowedRoles={[ROLE_TYPE.ADMIN]}>
          <LeftMenuItem
            onClick={() => navigate(ClientRoutes.Root)}
            label={translate('shareDashboard')}
            isSelected={currentPage === PAGES.ROOT}
            marginTop
          />
        </RoleProtected>
      </FeatureFlag>

      <FeatureFlag name={FEATURE_FLAGS.DASHBOARD}>
        <RoleProtected allowedRoles={[ROLE_TYPE.ADMIN]}>
          <LeftMenuItem
            onClick={() => navigate(ClientRoutes.Dashboard.bond)}
            label={translate('bondDashboard')}
            isSelected={currentPage === PAGES.BOND_DASHBOARD}
          />
        </RoleProtected>
      </FeatureFlag>

      <FeatureFlag name={FEATURE_FLAGS.DASHBOARD}>
        <RoleProtected allowedRoles={[ROLE_TYPE.ADMIN]}>
          <LeftMenuItem
            onClick={() => navigate(ClientRoutes.Dashboard.nft)}
            label={translate('nftDashboard')}
            isSelected={currentPage === PAGES.NFT_DASHBOARD}
          />
        </RoleProtected>
      </FeatureFlag>

      {!featureDashboard && (
        <RoleProtected allowedRoles={[ROLE_TYPE.CORPORATE, ROLE_TYPE.PRIVATE, ROLE_TYPE.ADMIN]}>
          <LeftMenuItem
            label={translate('mainPage')}
            onClick={() => navigate(ClientRoutes.Root)}
            disabled={user?.role === ROLE_TYPE.ADMIN}
            isSelected={currentPage === PAGES.ROOT}
          />
        </RoleProtected>
      )}
      <RoleProtected allowedRoles={[ROLE_TYPE.CORPORATE, ROLE_TYPE.PRIVATE]}>
        <LeftMenuItem
          onClick={() => navigate(ClientRoutes.Shares.root)}
          label={translate('sharesListing')}
          isSelected={currentPage === PAGES.SMART_SHARES_LISTING}
          marginTop
        />
      </RoleProtected>
      <RoleProtected allowedRoles={[ROLE_TYPE.CORPORATE, ROLE_TYPE.PRIVATE]}>
        <LeftMenuItem
          onClick={() => navigate(ClientRoutes.Bonds.root)}
          label={translate('bondsListing')}
          isSelected={currentPage === PAGES.SMART_BONDS_LISTING}
        />
      </RoleProtected>
      <FeatureFlag name={FEATURE_FLAGS.BONDS}>
        <RoleProtected allowedRoles={[ROLE_TYPE.ADMIN]}>
          <LeftMenuItem
            onClick={() => navigate(ClientRoutes.Bonds.smartBondPending)}
            label={translate('bondsPending')}
            isSelected={currentPage === PAGES.SMART_BONDS_PENDING}
            marginTop
          />
        </RoleProtected>
      </FeatureFlag>
      <FeatureFlag name={FEATURE_FLAGS.BONDS}>
        <RoleProtected allowedRoles={[ROLE_TYPE.ADMIN]}>
          <LeftMenuItem
            label={translate('bondsListing')}
            onClick={() => navigate(ClientRoutes.Bonds.root)}
            isSelected={currentPage === PAGES.SMART_BONDS_LISTING}
          />
        </RoleProtected>
      </FeatureFlag>
      {user!.isFullyRegistered && (
        <RoleProtected
          allowedRoles={[ROLE_TYPE.CORPORATE]}
          customCondition={user?.status === USER_STATUS_TYPE_ENUM.APPROVED}
        >
          <LeftMenuItem
            label={translate('smartShareCreation')}
            onClick={() => navigate(ClientRoutes.Shares.smartShareCreation)}
            isSelected={currentPage === PAGES.SMART_SHARE_CREATION}
            marginTop
          />
        </RoleProtected>
      )}
      {user!.isFullyRegistered && (
        <RoleProtected
          allowedRoles={[ROLE_TYPE.CORPORATE]}
          customCondition={user?.status === USER_STATUS_TYPE_ENUM.APPROVED}
        >
          <LeftMenuItem
            label={translate('smartBondCreation')}
            onClick={() => navigate(ClientRoutes.Bonds.smartBondCreation)}
            isSelected={currentPage === PAGES.SMART_BOND_CREATION}
          />
        </RoleProtected>
      )}
      <FeatureFlag name={FEATURE_FLAGS.SHARES}>
        <RoleProtected allowedRoles={[ROLE_TYPE.ADMIN]}>
          <LeftMenuItem
            label={translate('sharesPending')}
            onClick={() => navigate(ClientRoutes.Shares.smartSharePending)}
            isSelected={currentPage === PAGES.SMART_SHARES_PENDING}
            marginTop
          />
        </RoleProtected>
      </FeatureFlag>
      <FeatureFlag name={FEATURE_FLAGS.SHARES}>
        <RoleProtected allowedRoles={[ROLE_TYPE.ADMIN]}>
          <LeftMenuItem
            label={translate('sharesListing')}
            onClick={() => navigate(ClientRoutes.Shares.root)}
            isSelected={currentPage === PAGES.SMART_SHARES_LISTING}
          />
        </RoleProtected>
      </FeatureFlag>
      <RoleProtected allowedRoles={[ROLE_TYPE.PRIVATE, ROLE_TYPE.CORPORATE]}>
        <LeftMenuItem
          label={translate('account')}
          onClick={() => navigate(ClientRoutes.MyAccount.root)}
          isSelected={currentPage === PAGES.ACCOUNT}
          marginTop
        />
      </RoleProtected>
      <RoleProtected allowedRoles={[ROLE_TYPE.ADMIN]}>
        <LeftMenuItem
          label={translate('users')}
          onClick={() => navigate(ClientRoutes.Users.root)}
          isSelected={currentPage === PAGES.USERS}
          marginTop
        />
      </RoleProtected>
      <RoleProtected allowedRoles={[ROLE_TYPE.ADMIN]}>
        <LeftMenuItem
          label={translate('requests')}
          onClick={() => navigate(ClientRoutes.Requests.root)}
          isSelected={currentPage === PAGES.REQUESTS}
        />
      </RoleProtected>
      <RoleProtected allowedRoles={[ROLE_TYPE.ADMIN]}>
        <FeatureFlag name={FEATURE_FLAGS.TRANSACTIONS_TO_FROM}>
          <LeftMenuItem
            label={translate('transactionsToBuyers')}
            onClick={() => navigate(ClientRoutes.Transactions.toBuyers)}
            isSelected={currentPage === PAGES.TRANSACTIONS_TO_BUYERS}
            marginTop
          />
          <LeftMenuItem
            label={translate('transactionsFromBuyers')}
            onClick={() => navigate(ClientRoutes.Transactions.fromBuyers)}
            isSelected={currentPage === PAGES.TRANSACTIONS_FROM_BUYERS}
          />
        </FeatureFlag>
      </RoleProtected>
    </LeftMenuContainer>
  );
};

export default LeftMenu;

const LeftMenuContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0 3rem 3rem 0;
  background: linear-gradient(188.49deg, #fff, hsla(0, 0%, 100%, 0.25) 0.01%, hsla(0, 0%, 91.4%, 0.25));
  padding: 3rem 0 3rem 3rem;
  display: flex;
  flex-direction: column;
`;
