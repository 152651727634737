import { BuildType } from '@/shared/types/redux/ReduxAction';

const getRequestChangesUrl = (caseId: string) => {
  return `case/${caseId}/request-changes`;
};

export const requestChanges = (build: BuildType) => {
  return build.mutation<void, any>({
    invalidatesTags: ['GetCasesForRecipient', 'GetCaseDetails'],
    query: ({ caseId, message }) => ({
      url: getRequestChangesUrl(caseId),
      method: 'POST',
      body: { message },
    }),
  });
};
