import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const auditSlice = createSlice({
  name: 'audit',
  initialState,
  reducers: {},
});

export default auditSlice.reducer;
