import { ForgotPasswordRequest } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const forgotPasswordPath = 'auth/forgot-password';

export const forgotPassword = (build: BuildType) => {
  return build.mutation<void, ForgotPasswordRequest>({
    query: ({ email }) => ({
      url: forgotPasswordPath,
      method: 'POST',
      body: { email },
    }),
  });
};
