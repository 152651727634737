import { Modal } from 'issuix-ui-kit';
import { FC } from 'react';
import styled from 'styled-components';

import SignInModal from '@/components/AuthModal/SignInModal/SignInModal';

const Login: FC = () => {
  return (
    <LandingWrapper>
      <Modal isOpen={true}>
        <SignInModal hideFullRegistration />
      </Modal>
    </LandingWrapper>
  );
};

const LandingWrapper = styled.div`
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export default Login;
