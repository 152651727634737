import { CreateShareRequest } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const createSharePath = 'shares';

export const createShare = (build: BuildType) => {
  return build.mutation<void, CreateShareRequest>({
    query: (share: CreateShareRequest) => ({
      url: createSharePath,
      method: 'POST',
      body: share,
    }),
  });
};
