import camelCase from 'lodash/camelCase';

import { formatAmount } from '../formatAmount/FormatAmount';

import { getConcatValues } from '@/shared/commonUtils/getConcatValues/getConcatValues';
import { AssetResponse, ShareResponse, CurrencyResponse } from '@/shared/types/api';
import { formatDateWithTime } from '@/utils/date/FormatDate';

export const parseFormObject = (response: ShareResponse, assets?: AssetResponse[], currency?: CurrencyResponse) => {
  const assetsData = assets?.reduce((prev, current) => {
    return { ...prev, [camelCase(current.type)]: { fileId: current.id, url: current.key } };
  }, {});

  const {
    price,
    totalAmount,
    availableForSale,
    currencies,
    isin,
    shareCapital,
    issuedDate,
    type,
    round,
    roundDuration,
    minimalInvestment,
    regions,
  } = response;

  return {
    price,
    totalAmount,
    availableForSale,
    currencies: getConcatValues(currencies),
    isin,
    shareCapital,
    nominalValue: formatAmount(response.shareCapital / response.totalAmount, currency?.decimals, currency?.rate),
    issuedDate: issuedDate ? formatDateWithTime(issuedDate) : undefined,
    type,
    round,
    roundDuration: roundDuration.toString(),
    minimalInvestment,
    regions: getConcatValues(regions),
    ...assetsData,
  };
};
