import { Button } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { ButtonContainer, FilterContainer, InputContainer, LastInputContainer } from '../SmartSharesPending.styles';

import ControlledInput from '@/components/Form/ControlledInput';
import ControlledSelect from '@/components/Form/ControlledSelect';
import { STATUS_ENUM } from '@/enums/Status';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useCountries } from '@/hooks/useCountries/UseCountries';
import { useRoundOptions } from '@/hooks/useRoundOptions/useRoundOptions';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useSmartSharesPendingContext } from '@/view/Admin/SmartSharesPending/SmartSharesPendingContext';

const SmartSharesPendingFilterForm: FC = () => {
  const { translate } = useTranslation();
  const { countriesAsDropdown } = useCountries();
  const { roundOptionsAsDropdown } = useRoundOptions();
  const currency = useAppSelector((state) => state.global.currency);

  const {
    reset,
    watch,
    formState: { isDirty },
  } = useFormContext();

  const { setFilters, resetData } = useSmartSharesPendingContext();

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    watch((value) => {
      resetData();
      setFilters({ currencyId: currency?.id, status: STATUS_ENUM.APPROVED, ...value });
    });
  }, [watch]);

  return (
    <div>
      <FilterContainer>
        <InputContainer>
          <ControlledInput name={'code'} placeholder={translate('code')} />
        </InputContainer>
        <InputContainer>
          <ControlledSelect items={countriesAsDropdown} name={'country'} placeholder={translate('country')} />
        </InputContainer>
        <InputContainer>
          <ControlledInput name={'minInvestment'} placeholder={translate('minInvestment')} />
        </InputContainer>
        <InputContainer>
          <ControlledInput name={'maxInvestment'} placeholder={translate('maxInvestment')} />
        </InputContainer>
        <InputContainer>
          <ControlledSelect items={roundOptionsAsDropdown} placeholder={translate('round')} name={'round'} />
        </InputContainer>
      </FilterContainer>
      <FilterContainer>
        <LastInputContainer>
          <ControlledInput name={'companyName'} placeholder={translate('companyName')} />
        </LastInputContainer>
        <ButtonContainer>
          <Button
            onClick={() => {
              isDirty && reset();
            }}
            text={translate('reset')}
          />
        </ButtonContainer>
      </FilterContainer>
    </div>
  );
};

export default SmartSharesPendingFilterForm;
