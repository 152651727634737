import { Card, Text } from 'issuix-ui-kit';
import styled from 'styled-components';

export const CaseCard = styled(Card)`
  padding: 3rem;
  position: relative;
`;

export const DocumentsTitle = styled(Text)`
  padding-top: 3rem;
  color: ${(props: any) => props.theme.colors.green.primary} !important;
  font-size: ${(props: any) => props.theme.typography.extraSmall.fontSize} !important;
`;

export const CaseViewContainer = styled.div`
  margin-top: 4rem;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const DocumentsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;
`;

export const RequestChangesContainer = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
  min-height: 30rem;
  max-height: 30rem;
  padding: 0 0 0 2.5rem;
  border-radius: 1.5rem;
  overflow-y: auto;
  background: linear-gradient(
    186.83deg,
    rgba(255, 255, 255, 0.2) 1.91%,
    rgba(233, 233, 233, 0.5) 100%,
    rgba(233, 233, 233, 0.5) 100%
  );
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25));
`;

export const ActionsContainer = styled.div`
  position: absolute;
  right: 4rem;
  bottom: 4rem;
  display: flex;
  gap: 2rem;
`;

export const RequestChangeItem = styled.li`
  margin-block: 1rem;

  &::marker {
    color: ${(props) => props.theme.colors.green.primary};
  }
`;
