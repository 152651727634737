import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const getChangePasswordModalFormSchema = (translate: TFunction) => {
  return Yup.object().shape({
    currentPassword: Yup.string().required(translate('required')),
    newPassword: Yup.string()
      .required(translate('required'))
      .min(5, translate('minChar', { value: 5 }))
      .max(255, translate('maxChar', { value: 255 })),
    passwordConfirmation: Yup.string()
      .required(translate('required'))
      .oneOf([Yup.ref('newPassword'), null], translate('passwordMatch')),
  });
};
