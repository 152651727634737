import { SmartShareLedgerCalculationResponse } from '@/shared/types/api/models/SmartShareLedgerCalculationResponse';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const smartSharesLedgerCalculation = 'calculations';

const getSmartSharesLedgerCalculationUrl = (query: { userId: string }) =>
  `${smartSharesLedgerCalculation}/${query.userId}`;

export const getSmartSharesLedgerCalculation = (build: BuildType) => {
  return build.query<SmartShareLedgerCalculationResponse, any>({
    query: ({ query }) => ({
      url: getSmartSharesLedgerCalculationUrl(query),
      method: 'GET',
    }),
  });
};
