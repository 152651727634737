export const ClientRoutes = {
  Root: '/',
  Dashboard: {
    share: 'share-dashboard',
    bond: 'bond-dashboard',
    transaction: ':bondId/transaction/:id',
    nft: 'nft-dashboard',
    nftTransaction: ':nftId/nft/:id',
    nftCollection: ':id',
    addNftCollection: 'add-new-nft-collection',
  },
  Bonds: {
    root: 'smart-bonds',
    smartBondCreation: 'smart-bond-creation',
    smartBondPending: 'smart-bonds-pending',
    viewSmartBond: ':id',
    transactions: ':bondId/transactions',
    transaction: 'transactions/:id',
    smartBondPendingView: 'smart-bonds-pending/:id',
    editSmartBond: ':id/edit',
  },
  Shares: {
    root: 'smart-shares',
    smartShareCreation: 'smart-share-creation',
    smartSharePending: 'smart-shares-pending',
    viewSmartShare: ':id',
    transactions: ':shareId/transactions',
    transaction: 'transactions/:id',
    smartSharePendingView: 'smart-share-pending/:id',
  },
  Users: {
    root: 'users',
    account: ':id',
    smartBondsPortfolio: 'smart-bonds-portfolio',
    couponsIssued: 'coupons-issued',
    smartSharesPortfolio: 'smart-shares-portfolio',
    nftPortfolio: 'nft-portfolio',
    smartShareLedger: 'smart-share-ledger',
    couponIssued: 'coupon-issued',
    transactions: 'transactions',
    smartBondCreated: 'smart-bond-created',
    cases: 'cases',
    case: 'cases/:caseId',
  },
  MyAccount: {
    root: 'my-account',
    account: ':id',
    smartBondsPortfolio: 'smart-bonds-portfolio',
    couponsIssued: 'coupons-issued',
    smartSharesPortfolio: 'smart-shares-portfolio',
    smartShareLedger: 'smart-share-ledger',
    couponIssued: 'coupon-issued',
    transactions: 'transactions',
    smartBondCreated: 'smart-bond-created',
    shareholdersLedger: 'share-holders-ledger',
  },
  Transactions: {
    toBuyers: 'transactions-to-buyers',
    fromBuyers: 'transactions-from-buyers',
    fromBuyersId: ':id',
    singleView: ':id',
    shareSingleView: 'share/:id',
    bondSingleView: 'bond/:id',
    nftSingleView: 'nft/:id',
    detailInfo: ':id',
    share: 'share',
    bond: 'bond',
  },
  Requests: {
    root: 'requests',
    viewRequest: ':id',
  },
};
