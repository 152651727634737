import { Button, IColumn, Pill, PILL_SELECTED, PILL_SIZE, Table, Text } from 'issuix-ui-kit';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CardHeader, CouponIssuedCard, TableContainer } from './CouponIssued.style';

import { CouponPaymentModal } from '@/components/CouponPaymentModal/CouponPaymentModal';
import { COUPON_STATUS_ENUM } from '@/enums/CouponStatus';
import { ROLE_TYPE } from '@/enums/RoleType';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { isCouponPillPaidStatus } from '@/shared/commonUtils/is-coupon-pill-paid-status/is-coupon-pill-paid-status';
import { CouponTable } from '@/shared/types/CouponTable';
import { getUserSelector } from '@/store/global/selectors';
import { useChangeCouponStatusMutation, useGetIssuedCouponsByUserIdQuery } from '@/store/service';
import { composePath } from '@/utils/composePath/ComposePath';
import { formatDate } from '@/utils/date/FormatDate';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';

interface CouponsIssuedProps {
  userId: string;
}

const CouponsIssued: FC<CouponsIssuedProps> = ({ userId }) => {
  const { translate } = useTranslation();
  const { data, fetch } = useTableWithPagination({ query: useGetIssuedCouponsByUserIdQuery, id: userId });
  const navigate = useNavigate();
  const user = useAppSelector((state) => getUserSelector(state));
  const [changeCouponStatus] = useChangeCouponStatusMutation();
  const [selectedCoupon, setSelectedCoupon] = useState<string | undefined>(undefined);

  const onActionClick = (item: CouponTable) => {
    navigate(composePath(ClientRoutes.Transactions.singleView, { id: item.transactionId }));
  };

  const onPillClick = (id: string) => {
    changeCouponStatus({ query: { id } });
  };

  const onCouponPayModalClose = () => {
    setSelectedCoupon(undefined);
  };

  const tableDef: IColumn[] = [
    {
      name: 'smartBondCode',
      width: 2,
      header: {
        label: translate('smartBondCode').toUpperCase(),
      },
    },
    {
      name: 'issuerName',
      width: 2,
      header: {
        label: translate('issuerName').toUpperCase(),
      },
    },
    {
      name: 'buyerName',
      width: 2,
      header: {
        label: translate('buyerName').toUpperCase(),
      },
    },
    {
      name: 'expiryDate',
      width: 2,
      header: {
        label: translate('paymentExpected').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          return <p>{formatDate(item.expiryDate)}</p>;
        },
      },
    },
    {
      name: 'paymentDate',
      width: 1,
      header: {
        label: translate('datePaid').toUpperCase(),
      },
      column: {
        render: (item: CouponTable) => {
          return <p>{item.paymentDate ?? '/'}</p>;
        },
      },
    },
    {
      name: 'price',
      width: 2,
      header: {
        label: translate('price').toUpperCase(),
      },
      column: {
        render: (item: CouponTable) => {
          return <p>{Number(item.price).toFixed(2)}</p>;
        },
      },
    },
    {
      name: 'currency',
      width: 1,
      header: {
        label: translate('currency').toUpperCase(),
      },
    },
    {
      name: 'billingSequenceNumber',
      width: 2,
      header: {
        label: translate('billingSequenceNumber').toUpperCase(),
      },
    },
    {
      name: 'status',
      width: 2,
      header: {
        label: translate('paid').toUpperCase(),
      },
      column: {
        render: (item: CouponTable) => {
          return (
            <Pill
              selected={item.status === COUPON_STATUS_ENUM.PAID ? PILL_SELECTED.LEFT : PILL_SELECTED.RIGHT}
              rightLabel={translate('notPaid')}
              leftLabel={translate('paid')}
              size={PILL_SIZE.SMALL}
              onRightClick={() => console.log('Disabled')}
              onLeftClick={() => setSelectedCoupon(item.id)}
              disabled={isCouponPillPaidStatus(item.status) || user?.role !== ROLE_TYPE.ADMIN}
            />
          );
        },
      },
    },
    {
      name: 'actions',
      width: 2,
      header: {
        label: translate('actions').toUpperCase(),
      },
      column: {
        render: (item: CouponTable) => (
          <Button fullyRounded onClick={() => onActionClick(item)} text={translate('view')} size={'medium'} />
        ),
      },
    },
  ];
  return (
    <CouponIssuedCard>
      <CouponPaymentModal
        selectedCoupon={selectedCoupon}
        setSelectedCoupon={setSelectedCoupon}
        onClose={onCouponPayModalClose}
        onPillClick={onPillClick}
      />
      <CardHeader>
        <Text content={translate('couponsIssued')} size="large" />
      </CardHeader>
      <TableContainer>
        <Table data={data} fetchTrigger={() => fetch()} tableDef={tableDef}></Table>
      </TableContainer>
    </CouponIssuedCard>
  );
};

export default CouponsIssued;
