import { Button, IColumn, Table, Text } from 'issuix-ui-kit';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SplitTableBlock } from '../SmartSharePortfolio.styles';

import { TableContainer } from './SmartSharePortfoliotTable.styles';

import Download from '@/assets/core/icons/download.svg';
import DownloadDocumentsModal from '@/components/DocumentsModal/DownloadDocumentsModal';
import { DownloadButton } from '@/components/UserTransactions/UserTransactions.styles';
import { PRODUCT_TYPE_ENUM } from '@/enums/ProductType';
import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { SharePortfolioResponse } from '@/shared/types/SharePortfolioResponse';
import { useGetUserPortfolioQuery } from '@/store/service';
import { composePath } from '@/utils/composePath/ComposePath';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';

interface SmartSharePortfolioTableProps {
  userId?: string;
}

const SmartSharePortfolioTable: FC<SmartSharePortfolioTableProps> = ({ userId }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const { fetch, data } = useTableWithPagination({
    query: useGetUserPortfolioQuery,
    id: PRODUCT_TYPE_ENUM.ACTIONS,
    additionalId: userId,
  });
  const [fileItem, setFileItem] = useState<SharePortfolioResponse | undefined>();

  const onAction = (id: string) => {
    navigate(composePath(`${ClientRoutes.Transactions.shareSingleView}`, { id }));
  };

  const onDownloadClickHandler = (item: any) => {
    if (fileItem?.id) {
      return;
    }
    setFileItem(item);
  };

  const onModalClose = () => {
    setFileItem(undefined);
  };

  const tableDef: IColumn[] = [
    {
      name: 'company',
      width: 1,
      header: {
        label: translate('company').toUpperCase(),
      },
    },
    {
      name: 'totalSharesBought',
      width: 1.5,
      header: {
        label: translate('totalShares').toUpperCase(),
      },
    },
    {
      name: 'pricePerShare',
      width: 1.5,
      header: {
        label: translate('pricePerShare').toUpperCase(),
      },
    },
    {
      name: 'round',
      width: 1,
      header: {
        label: translate('round').toUpperCase(),
      },
    },
    {
      name: 'investedAmount',
      width: 1.5,
      header: {
        label: translate('invested').toUpperCase(),
      },
    },
    {
      name: 'actions',
      width: 2,
      header: {
        label: translate('actions').toUpperCase(),
      },
      column: {
        render: (item: SharePortfolioResponse) => (
          <Button onClick={() => onAction(item.id)} text={translate('view')} fullyRounded />
        ),
      },
    },
    {
      name: 'download',
      width: 1,
      header: {
        label: translate('documents').toUpperCase(),
      },
      column: {
        render: (item: SharePortfolioResponse) => {
          if (!item.assets) {
            return <Text content={translate('noDocuments')} />;
          }
          return <DownloadButton src={Download} alt={'download'} onClick={() => onDownloadClickHandler(item)} />;
        },
      },
    },
  ];

  return (
    <SplitTableBlock>
      {fileItem?.id && (
        <DownloadDocumentsModal isModalOpen={!!fileItem?.id} onModalClose={onModalClose} transaction={fileItem} />
      )}
      <TableContainer>
        <Table data={data} fetchTrigger={() => fetch()} tableDef={tableDef} />
      </TableContainer>
    </SplitTableBlock>
  );
};

export default SmartSharePortfolioTable;
