import { BondTransactionResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseBondTransactionUrl = 'bond-transactions';

const getBondTransactionByIdUrl = (query: { id: string }): string => `${baseBondTransactionUrl}/${query.id}`;

export const getBondTransactionById = (build: BuildType) => {
  return build.query<BondTransactionResponse, any>({
    query: ({ query }) => ({
      url: getBondTransactionByIdUrl(query),
      method: 'GET',
    }),
  });
};
