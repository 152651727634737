import { stringify } from 'qs';

import { pagingLimit } from '@/shared/constants/Paging';
import { PageableItems } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseUsersUrl = 'admin/users';

const usersUrl = 'users';

const getUserAdminUrl = (offset: number, query: { role: string; search: string } | undefined): string =>
  `${baseUsersUrl}?status=APPROVED,SUSPENDED&offset=${offset}&limit=${pagingLimit}&${stringify(query)}`;

const getUsersUrl = () => `${usersUrl}`;

export const getUsers = (build: BuildType) => {
  return build.query<PageableItems, any>({
    providesTags: ['GetUsers'],
    query: ({ offset, query }) => ({
      url: getUserAdminUrl(offset, query),
      method: 'GET',
    }),
  });
};

export const getAllUsers = (build: BuildType) => {
  return build.query({
    query: () => ({
      url: getUsersUrl(),
      method: 'GET',
    }),
  });
};
