import { ChartResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const getPortfolioChartPath = '/portfolios/chart';

const getPortfolioChartUrl = (query: { productType: string; userId: string }) =>
  `${getPortfolioChartPath}?productType=${query.productType}&userId=${query.userId}`;

export const getPortfolioChart = (build: BuildType) => {
  return build.query<ChartResponse[], any>({
    query: ({ query }) => ({
      url: getPortfolioChartUrl(query),
      method: 'GET',
    }),
  });
};
