import { UploadFile } from 'issuix-ui-kit';
import { FC } from 'react';
import { Controller, useFormContext, Control } from 'react-hook-form';

export interface ControlledInputProps {
  name: string;
  placeholder: string;
  disabled?: boolean;
  autoFocus?: boolean;
  id?: string;
  incorrectFileFormatLabel: string;
  dragDropFilesHereLabel: string;
  clickToViewDocumentLabel: string;
  isEditMode?: boolean;
  onViewDocument: (fileId: string) => void;
  onUploadFile: (file: File) => void;
  onDelete: (name: string) => void;
  errors: any;
  parentControl?: Control;
}

const ControlledUpload: FC<ControlledInputProps> = ({
  placeholder,
  name = '',
  disabled = false,
  incorrectFileFormatLabel,
  dragDropFilesHereLabel,
  clickToViewDocumentLabel,
  isEditMode = false,
  onViewDocument,
  onUploadFile,
  onDelete,
  errors,
  parentControl,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={parentControl || control}
      defaultValue=""
      name={name}
      render={({ field }) => {
        return (
          <UploadFile
            label={placeholder}
            name={name}
            value={field.value ?? undefined}
            onViewDocument={() => onViewDocument(field.value.fileId)}
            IncorrectFileFormatLabel={incorrectFileFormatLabel}
            DragDropFilesHereLabel={dragDropFilesHereLabel}
            ClickToViewDocumentLabel={clickToViewDocumentLabel}
            edit={isEditMode}
            uploadFile={onUploadFile}
            disabled={disabled}
            onDelete={onDelete}
            errors={errors}
          />
        );
      }}
    />
  );
};

export default ControlledUpload;
