import { Card } from 'issuix-ui-kit';
import styled from 'styled-components';

export const SmartShareChartContainer = styled(Card).attrs(() => ({
  notFullHeight: true,
}))`
  padding: 4rem 2rem;
  position: relative;
`;

export const ChartContainer = styled.div`
  height: 200px;
`;
