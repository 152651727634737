import { Button, IColumn, Table, Text } from 'issuix-ui-kit';
import { useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SmartBondsPendingFilterForm from './FilterForm/SmartBondsPendingFilterForm';
import { SmartBondPendingFilterSchema } from './FilterForm/SmartBondsPendingSchema';
import {
  SmartBondListingContainer,
  TableContainer,
  FiltersContainer,
  SmartBondListingCard,
  CardHeader,
  ActionsContainer,
} from './SmartBondsPending.styles';

import { AcceptModal } from '@/components/AcceptModal/AcceptModal';
import ControlledForm from '@/components/Form/ControlledForm';
import { RejectModal } from '@/components/RejectModal/RejectModal';
import { STATUS_ENUM } from '@/enums/Status';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useRatingOptions } from '@/hooks/useRatingOptions/useRatingOptions';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { BondResponse, RatingOptionResponse } from '@/shared/types/api';
import { useApproveBondMutation, useRejectBondMutation } from '@/store/service';
import { composePath } from '@/utils/composePath/ComposePath';
import { formatAmount } from '@/utils/formatAmount/FormatAmount';
import { parseBoolean } from '@/utils/parseBoolean/parseBoolean';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';
import {
  SmartBondPendingContextProvider,
  useSmartBondPendingContext,
} from '@/view/Admin/SmartBondsPending/SmartBondsPendingContext';

const SmartBondsPending = () => {
  return (
    <SmartBondPendingContextProvider>
      <SmartBondPendingView />
    </SmartBondPendingContextProvider>
  );
};

const SmartBondPendingView = () => {
  const { data, fetch, setFilters } = useSmartBondPendingContext();
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const { ratingOptions } = useRatingOptions(translate);
  const currency = useAppSelector((state) => state.global.currency);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState<boolean>(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState<boolean>(false);
  const [bondId, setBondId] = useState<string>('');
  const [reason, setReason] = useState<string>('');

  const [rejectBond] = useRejectBondMutation();
  const [approveBond] = useApproveBondMutation();

  const onActionClick = (id: string) => {
    navigate(composePath(`/${ClientRoutes.Bonds.smartBondPendingView}`, { id }));
  };

  const bondPriceCalculation = (item: BondResponse) => {
    return formatAmount(item.value, currency!.decimals, currency!.rate, currency!.currencyCode);
  };

  const getRating = (item: string): RatingOptionResponse => {
    return ratingOptions.find((rating: RatingOptionResponse) => rating.value === item);
  };

  useEffect(() => {
    if (!currency) {
      return;
    }

    setFilters({ currencyId: currency?.id, status: STATUS_ENUM.PENDING });
  }, [currency]);

  const onAccept = () => {
    approveBond({ id: bondId });
    setIsAcceptModalOpen(false);
  };

  const onReject = () => {
    rejectBond({ reason, id: bondId });
    setIsRejectModalOpen(false);
  };

  const onAcceptModalOpen = (id: string) => {
    setIsAcceptModalOpen(true);
    setBondId(id);
  };

  const onRejectModalOpen = (id: string) => {
    setIsRejectModalOpen(true);
    setBondId(id);
  };

  const onRejectModalClose = () => {
    setIsRejectModalOpen(false);
  };

  const onAcceptModalClose = () => {
    setIsAcceptModalOpen(false);
  };

  const tableDef: IColumn[] = [
    {
      name: 'code',
      width: 1,
      header: {
        label: translate('code').toUpperCase(),
      },
    },
    {
      name: 'userName',
      width: 1.5,
      header: {
        label: translate('companyName').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => <>{item.user.name}</>,
      },
    },
    {
      name: 'country',
      width: 1,
      header: {
        label: translate('country').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => <>{item.user.country?.name}</>,
      },
    },
    {
      name: 'rating',
      width: 1,
      header: {
        label: translate('rating').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => (
          <>{getRating(item.rating)?.label === 'None' ? translate('none') : getRating(item.rating).label}</>
        ),
      },
    },
    {
      name: 'price',
      width: 2,
      header: {
        label: translate('smartBondPrice').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => <>{bondPriceCalculation(item)}</>,
      },
    },
    {
      name: 'maturity',
      width: 1,
      header: {
        label: translate('maturity').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => <>{`${item.maturity} ${translate('months')}`}</>,
      },
    },
    {
      name: 'interest',
      width: 1,
      header: {
        label: translate('interest').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => <>{`${item.interest} %`}</>,
      },
    },
    {
      name: 'insurance',
      width: 1,
      header: {
        label: translate('insurance').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => <>{parseBoolean(item.insuranceOption, translate)}</>,
      },
    },
    {
      name: 'industry',
      width: 2,
      header: {
        label: translate('industry').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => {
          return (
            <>
              {item.user.endUser?.corporateUser?.businessFocus?.translationCode
                ? translate(
                    item.user.endUser?.corporateUser?.businessFocus?.translationCode?.split('.')[2] as keyof TFunction
                  )
                : translate(
                    item.user.endUser?.tenantUser?.businessFocus?.translationCode?.split('.')[2] as keyof TFunction
                  )}
            </>
          );
        },
      },
    },
    {
      name: 'actions',
      width: 3.5,
      header: {
        label: translate('actions').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => (
          <ActionsContainer>
            <Button fullyRounded onClick={() => onActionClick(item.id)} text={translate('view')} size={'medium'} />
            <Button
              onClick={() => onAcceptModalOpen(item.id)}
              text={translate('accept')}
              fullyRounded
              size={'medium'}
            />
            <Button
              onClick={() => onRejectModalOpen(item.id)}
              text={translate('reject')}
              buttonType={'danger'}
              fullyRounded
              size={'medium'}
            />
          </ActionsContainer>
        ),
      },
    },
  ];

  return (
    <SmartBondListingContainer>
      <SmartBondListingCard>
        <CardHeader>
          <Text content={translate('bondsPending')} size={'large'} />
        </CardHeader>
        <TableContainer>
          <Table data={data} fetchTrigger={() => fetch()} tableDef={tableDef}></Table>
        </TableContainer>
      </SmartBondListingCard>
      <FiltersContainer>
        <Text content={translate('filters')} size={'large'} />
        <ControlledForm schema={SmartBondPendingFilterSchema()}>
          <SmartBondsPendingFilterForm />
        </ControlledForm>
      </FiltersContainer>
      <AcceptModal
        isAcceptModalOpen={isAcceptModalOpen}
        onAcceptModalClose={onAcceptModalClose}
        onAccept={onAccept}
        title={translate('accept')}
        subtitle={translate('areYouSureAccept')}
      />
      <RejectModal
        isRejectModalOpen={isRejectModalOpen}
        onRejectModalClose={onRejectModalClose}
        onReject={onReject}
        title={translate('reject')}
        subtitle={translate('areYouSureReject')}
        reason={reason}
        setReason={setReason}
      />
    </SmartBondListingContainer>
  );
};

export default SmartBondsPending;
