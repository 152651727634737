import { Button, Input, Text } from 'issuix-ui-kit';
import isEmpty from 'lodash/isEmpty';
import { FC, useEffect } from 'react';

import {
  CardWrapper,
  CompanyLogo,
  CompanyLogoWrapper,
  DocumentWrapper,
  SectionTitle,
  RequestContainer,
  RequestDataWrapper,
  RequestInfo,
  RequestInfoContainer,
  UploadFileComponent,
  DocumentContainer,
  UploadSection,
  CardContainer,
  RequestDataContainer,
  RejectButton,
  ActionWrapper,
} from './Request.styled';

import { AcceptModal } from '@/components/AcceptModal/AcceptModal';
import { RejectModal } from '@/components/RejectModal/RejectModal';
import { ASSET_TYPE_ENUM } from '@/enums/AssetTypeEnum';
import { DOCUMENT_UPLOAD_STATUS } from '@/enums/DocumentStatus';
import { STATUS_ENUM } from '@/enums/Status';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { AssetResponse } from '@/shared/types/api';
import { PrivateUserFullResponse } from '@/shared/types/api/models/PrivateUserFullResponse';
import { useAssignedAssetUrlMutation, useGetPrivateUserAssetsQuery } from '@/store/service';
import { findAssetByType } from '@/utils/assets/findAssetByType';
import { formatDate } from '@/utils/date/FormatDate';
import {
  getDragAndDropDocumentationLabel,
  getViewDocumentationLabel,
} from '@/utils/getDocumentationLabel/getDocumentationLabel';

interface Props {
  user: PrivateUserFullResponse;
  viewDocument: (assets: AssetResponse[], assetType: string) => void;
  getAssetByType: (
    assets: AssetResponse[],
    assetType: string
  ) => AssetResponse | { id: string; fileName: string } | undefined;
  openReject: () => void;
  openAccept: () => void;
  isRejectModalOpen: boolean;
  isAcceptModalOpen: boolean;
  onAcceptModalClose: () => void;
  onAccept: () => void;
  onRejectModalClose: () => void;
  setReason: (reason: string) => void;
  reason: string;
  onReject: () => void;
}

export const PrivateRequestView: FC<Props> = ({
  user,
  viewDocument,
  getAssetByType,
  openReject,
  openAccept,
  isAcceptModalOpen,
  isRejectModalOpen,
  onAcceptModalClose,
  onAccept,
  onRejectModalClose,
  setReason,
  reason,
  onReject,
}) => {
  const { translate } = useTranslation();

  const { data: assets } = useGetPrivateUserAssetsQuery(user.id, { skip: !user });

  const [getImageUrl, { data: imageUrl }] = useAssignedAssetUrlMutation();

  useEffect(() => {
    const picture = findAssetByType(assets, ASSET_TYPE_ENUM.PICTURE);
    if (assets && assets.length && picture) {
      getImageUrl({ assetId: picture.id });
    }
  }, [assets]);

  if (isEmpty(user)) return null;

  return (
    <RequestContainer>
      <CardContainer>
        <CardWrapper>
          <RequestInfoContainer>
            <SectionTitle>
              <Text size={'extraLarge'} content={translate('request')} />
            </SectionTitle>
            <RequestDataContainer>
              <CompanyLogoWrapper>
                <CompanyLogo src={imageUrl?.url} alt="logo" />
              </CompanyLogoWrapper>
              <RequestDataWrapper>
                <RequestInfo>
                  <Input name={'name'} placeholder={translate('name')} value={user.privateUser.firstName} disabled />
                  <Input
                    name={'phoneNumber'}
                    placeholder={translate('phoneNumber')}
                    value={user.phoneNumber}
                    disabled
                  />
                  <Input name={'city'} placeholder={translate('city')} value={user.city} disabled />
                  <Input name={'zipCode'} placeholder={translate('zipCode')} value={user.zipCode} disabled />
                </RequestInfo>
                <RequestInfo>
                  <Input
                    name={'lastName'}
                    placeholder={translate('lastName')}
                    value={user.privateUser.lastName}
                    disabled
                  />
                  <Input name={'Address'} placeholder={translate('address')} value={user.address} disabled />
                  <Input
                    name={'birthDate'}
                    placeholder={translate('birthDate')}
                    value={user.privateUser.birthDate ? formatDate(user.privateUser.birthDate) : undefined}
                    disabled
                  />
                </RequestInfo>
                <RequestInfo>
                  <Input name={'email'} placeholder={translate('emailAddress')} value={user.email} disabled />
                  <Input name={'country'} placeholder={translate('country')} value={user.country?.name} disabled />
                  <Input
                    name={'citizenship'}
                    placeholder={translate('citizenship')}
                    value={user.privateUser.citizenship}
                    disabled
                  />
                </RequestInfo>
              </RequestDataWrapper>
            </RequestDataContainer>
          </RequestInfoContainer>
          <DocumentContainer>
            <SectionTitle>
              <Text size={'extraLarge'} content={translate('documentation')} />
            </SectionTitle>
            <UploadSection>
              {assets && assets.length ? (
                <DocumentWrapper>
                  <UploadFileComponent
                    label={translate('passport')}
                    name="passport"
                    IncorrectFileFormatLabel={translate('incorrectFileFormat')}
                    DragDropFilesHereLabel={getDragAndDropDocumentationLabel(
                      translate,
                      getAssetByType(assets, ASSET_TYPE_ENUM.PASSPORT)?.id
                        ? DOCUMENT_UPLOAD_STATUS.UPLOADED
                        : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
                    )}
                    ClickToViewDocumentLabel={getViewDocumentationLabel(
                      translate,
                      getAssetByType(assets, ASSET_TYPE_ENUM.PASSPORT)?.id
                        ? DOCUMENT_UPLOAD_STATUS.UPLOADED
                        : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
                    )}
                    value={{
                      fileId: getAssetByType(assets, ASSET_TYPE_ENUM.PASSPORT)?.id || '',
                      url: getAssetByType(assets, ASSET_TYPE_ENUM.PASSPORT)?.fileName || '',
                    }}
                    onViewDocument={() => viewDocument(assets, ASSET_TYPE_ENUM.PASSPORT)}
                    edit={false}
                  />
                  <UploadFileComponent
                    label={translate('proofOfDomicile')}
                    name="proofOfDomicile"
                    IncorrectFileFormatLabel={translate('incorrectFileFormat')}
                    DragDropFilesHereLabel={getDragAndDropDocumentationLabel(
                      translate,
                      getAssetByType(assets, ASSET_TYPE_ENUM.PROOF_OF_DOMICILE)?.id
                        ? DOCUMENT_UPLOAD_STATUS.UPLOADED
                        : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
                    )}
                    ClickToViewDocumentLabel={getViewDocumentationLabel(
                      translate,
                      getAssetByType(assets, ASSET_TYPE_ENUM.PROOF_OF_DOMICILE)?.id
                        ? DOCUMENT_UPLOAD_STATUS.UPLOADED
                        : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
                    )}
                    value={{
                      fileId: getAssetByType(assets, ASSET_TYPE_ENUM.PROOF_OF_DOMICILE)?.id || '',
                      url: getAssetByType(assets, ASSET_TYPE_ENUM.PROOF_OF_DOMICILE)?.fileName || '',
                    }}
                    onViewDocument={() => viewDocument(assets, ASSET_TYPE_ENUM.PROOF_OF_DOMICILE)}
                    edit={false}
                  />
                </DocumentWrapper>
              ) : (
                <div>{translate('noDocuments')}</div>
              )}
              <ActionWrapper>
                <Button
                  text={translate('accept')}
                  onClick={openAccept}
                  disabled={!user.isFullyRegistered || user.status !== STATUS_ENUM.PENDING}
                />
                <RejectButton
                  onClick={openReject}
                  text={translate('reject')}
                  buttonType="danger"
                  disabled={!user.isFullyRegistered || user.status !== STATUS_ENUM.PENDING}
                />
              </ActionWrapper>
            </UploadSection>
          </DocumentContainer>
        </CardWrapper>
      </CardContainer>
      <AcceptModal
        isAcceptModalOpen={isAcceptModalOpen}
        onAcceptModalClose={onAcceptModalClose}
        onAccept={onAccept}
        title={translate('approve')}
        subtitle={`${translate('areYouSureUserApprove')} ${user.displayName}?`}
      />
      <RejectModal
        isRejectModalOpen={isRejectModalOpen}
        onRejectModalClose={onRejectModalClose}
        onReject={onReject}
        title={translate('reject')}
        subtitle={`${translate('areYouSureUserReject')} ${user.displayName}?`}
        reason={reason}
        setReason={setReason}
      />
    </RequestContainer>
  );
};
