import { Button, IColumn, Pill, PILL_SELECTED, PILL_SIZE, Table, Text } from 'issuix-ui-kit';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Transactions from './Transactions/Transactions';

import AccountCalculationBox from '@/components/AccountCalculationBox/AccountCalculationBox';
import { CouponPaymentModal } from '@/components/CouponPaymentModal/CouponPaymentModal';
import { CalculationsBox } from '@/components/SmartBondCreated/SmartBondCreated';
import { TENANT } from '@/enums/App';
import { COUPON_STATUS_ENUM } from '@/enums/CouponStatus';
import { ROLE_TYPE } from '@/enums/RoleType';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { isCouponPillPaidStatus } from '@/shared/commonUtils/is-coupon-pill-paid-status/is-coupon-pill-paid-status';
import { getSelectedCurrency, getUserSelector } from '@/store/global/selectors';
import {
  useChangeCouponStatusMutation,
  useGetBondAssetsQuery,
  useGetBondCalculationsQuery,
  useGetTenantBondsQuery,
} from '@/store/service';
import {
  baseBondDefaultCalculation,
  BondCalculation,
  formatCalculation,
} from '@/utils/bondCalculations/bondCalculation';
import { formatDate } from '@/utils/date/FormatDate';
import { formatAmount } from '@/utils/formatAmount/FormatAmount';
import { getTenant } from '@/utils/getTenant/getTenant';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';
import {
  CardHeader,
  TableContainer,
  TransactionsToBuyersCard,
} from '@/view/Admin/TransactionsToBuyers/TransactionsToBuyers.styles';
import {
  TransactionsToBuyersContextProvider,
  useTransactionsToBuyersContext,
} from '@/view/Admin/TransactionsToBuyers/TransactionsToBuyersContext';
import SmartBondFeatures from '@/view/Shared/ViewBond/SmartBondFeatures/SmartBondFeatures';

const BondDashboard = () => {
  return (
    <TransactionsToBuyersContextProvider>
      <BondDashboardView />
    </TransactionsToBuyersContextProvider>
  );
};

const BondDashboardView: FC = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const user = useAppSelector((state) => getUserSelector(state));

  const { data: bonds } = useGetTenantBondsQuery();
  const { data: assets } = useGetBondAssetsQuery({ query: { id: bonds && bonds[0]?.id } }, { skip: !bonds });
  const currency = useAppSelector((state) => getSelectedCurrency(state));
  const [selectedCoupon, setSelectedCoupon] = useState<string | undefined>(undefined);
  const [changeCouponStatus] = useChangeCouponStatusMutation();
  const { data, fetch, setFilters } = useTransactionsToBuyersContext();
  const [calculation, setCalculation] = useState<BondCalculation>(baseBondDefaultCalculation);
  const tenant = getTenant();

  const onBondCreationClick = () => {
    navigate(ClientRoutes.Bonds.smartBondCreation);
  };

  const bond = bonds ? bonds[0] : undefined;

  const id = tenant === TENANT.GREEN_STATE ? bond?.user?.id : user?.id;

  useEffect(() => {
    setFilters({ smartBondCode: bond?.code });
  }, [bond]);

  const { data: bondCalculations } = useGetBondCalculationsQuery({ query: { id: id } }, { skip: !id });

  useEffect(() => {
    if (bondCalculations) {
      setCalculation(
        formatCalculation(bondCalculations, currency?.decimals, currency?.rate, currency?.currencyCode ?? undefined)
      );
    }
  }, [bondCalculations, currency]);

  const onCouponPayModalClose = () => {
    setSelectedCoupon(undefined);
  };

  const onPillClick = (id: string) => {
    changeCouponStatus({ query: { id } });
  };

  if (!bonds || bonds.length === 0) {
    return user?.email === 'office@nimbus-tech.io' ? (
      <>
        <Button text={translate('smartBondCreation')} onClick={onBondCreationClick} />
      </>
    ) : (
      <></>
    );
  }

  const tableDef: IColumn[] = [
    {
      name: 'smartBondCode',
      width: 2,
      header: {
        label: translate('smartBondCode').toUpperCase(),
      },
    },
    {
      name: 'issuerName',
      width: 2,
      header: {
        label: translate('issuerName').toUpperCase(),
      },
    },
    {
      name: 'buyerName',
      width: 2,
      header: {
        label: translate('buyerName').toUpperCase(),
      },
    },
    {
      name: 'expiryDate',
      width: 2,
      header: {
        label: translate('paymentExpected').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          return <p>{formatDate(item.expiryDate)}</p>;
        },
      },
    },
    {
      name: 'paymentDate',
      width: 1,
      header: {
        label: translate('datePaid').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          return <p>{item.paymentDate ? formatDate(item.paymentDate) : '/'}</p>;
        },
      },
    },
    {
      name: 'amount',
      width: 2,
      header: {
        label: translate('amount').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          return <p>{formatAmount(item.amount, currency?.decimals, currency?.rate, currency?.currencyCode)}</p>;
        },
      },
    },
    {
      name: 'billingSequenceNumber',
      width: 2,
      header: {
        label: translate('billingSequenceNumber').toUpperCase(),
      },
    },
    {
      name: 'status',
      width: 2,
      header: {
        label: translate('paid').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          return (
            <Pill
              selected={item.status === COUPON_STATUS_ENUM.PAID ? PILL_SELECTED.LEFT : PILL_SELECTED.RIGHT}
              rightLabel={translate('notPaid')}
              leftLabel={translate('paid')}
              size={PILL_SIZE.SMALL}
              onRightClick={() => console.log('Disabled')}
              onLeftClick={() => setSelectedCoupon(item.id)}
              disabled={isCouponPillPaidStatus(item.status)}
            />
          );
        },
      },
    },
  ];

  if (!currency) {
    return <></>;
  }

  return (
    <LandingWrapper>
      <CouponPaymentModal
        selectedCoupon={selectedCoupon}
        setSelectedCoupon={setSelectedCoupon}
        onClose={onCouponPayModalClose}
        onPillClick={onPillClick}
      />
      <SmartBondFeatures bond={bond} assets={assets} hasEdit={false} />
      <TransactionsToBuyersCard>
        <CardHeader>
          <Text content={translate('coupons')} size="large" />
        </CardHeader>
        <TableContainer>
          <Table data={data} fetchTrigger={() => fetch()} tableDef={tableDef}></Table>
        </TableContainer>
      </TransactionsToBuyersCard>
      <CalculationsBox>
        <AccountCalculationBox
          label={translate('totalRepay')}
          value={calculation.totalRepayAmount}
          iconUrl={currency.iconUrl}
        />
        <AccountCalculationBox
          label={translate('annualCoupons')}
          value={calculation.annualCouponsToPay}
          iconUrl={currency.iconUrl}
        />
        <AccountCalculationBox
          label={translate('totalIssuance')}
          value={calculation.totalSmartBondIssuance}
          iconUrl={currency.iconUrl}
        />
      </CalculationsBox>
      <Transactions
        itemId={bond.id ?? ''}
        canViewTransaction={user?.id === bond?.user.id || user?.role === ROLE_TYPE.ADMIN}
      />
    </LandingWrapper>
  );
};

const LandingWrapper = styled.div`
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: auto;
`;

export default BondDashboard;
