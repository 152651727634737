import { Button } from 'issuix-ui-kit';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { useAuthContext } from '@/components/Auth/AuthContext';
import { FormContainer } from '@/components/AuthModal/SignInModal/SignInModal.styles';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledPassword from '@/components/Form/ControlledPassword';
import { AUTH_MODAL_TYPE } from '@/enums/AuthModalType';
import { useIfExist } from '@/hooks/useIfExist/useIfExist';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { LoginRequest } from '@/shared/types/api';
import { useLoginMutation } from '@/store/service';

const SignInForm = () => {
  const { translate } = useTranslation();
  const {
    handleSubmit,
    formState: { isValid },
  } = useFormContext();
  const [login, { isLoading, isSuccess, isError }] = useLoginMutation();
  const { closeModal, setModalType } = useAuthContext();

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.code === 'Enter') {
        submitHandler();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [isValid]);

  useIfExist([isLoading, isSuccess], isSuccess, () => closeModal());

  const submitHandler = () => {
    return handleSubmit((data) => login(data as LoginRequest))();
  };
  return (
    <FormContainer>
      <ControlledInput name={'email'} placeholder={translate('emailAddress')} id={'email'} />
      <ControlledPassword name={'password'} placeholder={translate('password')} id={'password'} />
      <ForgotPasswordContainer>
        <ForgotPassword onClick={() => setModalType(AUTH_MODAL_TYPE.FORGOT_PASSWORD)}>
          {translate('forgotPassword')}
        </ForgotPassword>
      </ForgotPasswordContainer>
      {isError && <ErrorMessage>{translate('badCredentials')}</ErrorMessage>}
      <Button onClick={submitHandler} text={translate('logIn')} isLoading={isLoading} disabled={!isValid} />
    </FormContainer>
  );
};

export default SignInForm;

const ForgotPasswordContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
`;

const ForgotPassword = styled.a`
  margin-top: -1rem;
  margin-bottom: 2rem;
  font-size: ${(props) => props.theme.typography.small.fontSize};
`;

const ErrorMessage = styled.p`
  color: ${(props) => props.theme.colors.red.error};
  font-size: 1.2rem;
  position: relative;
`;
