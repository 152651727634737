import { Button, Text } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import ControlledInput from '../Form/ControlledInput';

import ControlledForm from '@/components/Form/ControlledForm';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import {
  ActionContainer,
  InputsContainer,
  SendNotificationModalWrapper,
} from '@/components/SendNotificationModal/SendNotificationModal.style';
import { sendNotificationModalSchema } from '@/components/SendNotificationModal/SendNotificationModalSchema';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useSendNotificationMutation, useSendNotificationToAllMutation } from '@/store/service';

type Props = {
  onClose: () => void;
  userId: string;
};

const SendDirectEmailForm: FC<Props> = ({ onClose, userId }) => {
  const { translate } = useTranslation();
  const {
    getValues,
    formState: { errors, isValid },
  } = useFormContext();

  const [sendNotification, { isLoading: isSendNotificationLoading, isSuccess: isSendNotificationSuccess }] =
    useSendNotificationMutation();
  const [
    sendNotificationToAll,
    { isLoading: isSendNotificationToAllLoading, isSuccess: isSendNotificationToAllSuccess },
  ] = useSendNotificationToAllMutation();

  const onSend = () => {
    const formValues = getValues();

    const content = {
      en: { title: formValues.titleEn, content: formValues.contentEn },
      de: { title: formValues.titleDe, content: formValues.contentDe },
    };

    if (userId) {
      sendNotification({
        userId,
        content,
      });
      return;
    }

    sendNotificationToAll({
      content,
    });
  };

  useEffect(() => {
    if (isSendNotificationToAllSuccess || isSendNotificationSuccess) {
      onCancel();
    }
  }, [isSendNotificationSuccess, isSendNotificationToAllSuccess]);

  const onCancel = () => {
    onClose();
  };

  return (
    <>
      <InputsContainer>
        <ControlledInput type="text" name="titleEn" id="titleEn" placeholder={translate('titleEn')} />
        <ControlledInput type="text" name="titleDe" id="titleDe" placeholder={translate('titleDe')} />
        <ControlledTextarea name="contentEn" label={translate('contentEn')} errors={errors} />
        <ControlledTextarea name="contentDe" label={translate('contentDe')} errors={errors} />
      </InputsContainer>
      <ActionContainer>
        <Button text={translate('cancel')} onClick={onCancel} buttonType={'secondary'} />
        <Button
          text={translate('send')}
          onClick={onSend}
          buttonType={'primary'}
          isLoading={isSendNotificationLoading || isSendNotificationToAllLoading}
          disabled={!isValid}
        />
      </ActionContainer>
    </>
  );
};

export const SendNotificationModal: FC<Props> = ({ onClose, userId }) => {
  const { translate } = useTranslation();
  return (
    <SendNotificationModalWrapper>
      <Text content={translate('sendNotification')} size={'large'} />
      <ControlledForm schema={sendNotificationModalSchema(translate)}>
        <SendDirectEmailForm onClose={onClose} userId={userId} />
      </ControlledForm>
    </SendNotificationModalWrapper>
  );
};
