import { BuildType } from '@/shared/types/redux/ReduxAction';

const approveShareUrl = (id: string) => {
  return `/shares/${id}/accept`;
};

export const approveShare = (build: BuildType) => {
  return build.mutation<void, any>({
    invalidatesTags: ['GetShares'],
    query: ({ id }) => ({
      url: approveShareUrl(id),
      method: 'PATCH',
    }),
  });
};
