import { NftHolder, NftCard } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';

import { NftsContainer } from '@/components/Nfts/Nfts.style';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { getSelectedCurrency } from '@/store/global/selectors';
import { useGetNftCollectionStatisticsQuery, useGetNftsByCollectionIdQuery } from '@/store/service';
import { formatAmount } from '@/utils/formatAmount/FormatAmount';
import { mapNftSerialNumber } from '@/utils/mapNftSerialNumber/mapNftSerialNumber';
import { mapNftStatusToTranslatedLabel } from '@/utils/mapNftStatusToTranslatedLabel/mapNftStatusToTranslatedLabel';

interface NftsParams {
  id: string;
}

const Nfts: FC<NftsParams> = ({ id }) => {
  const { translate } = useTranslation();
  const currency = useAppSelector((state) => getSelectedCurrency(state));

  const { data: statistics } = useGetNftCollectionStatisticsQuery({ id });
  const { data, fetch } = useTableWithPagination({ query: useGetNftsByCollectionIdQuery, id: id });

  useEffect(() => {
    if (!currency) {
      return;
    }
  }, [currency]);

  if (!currency || !data) {
    return <></>;
  }

  return (
    <NftsContainer>
      <NftCard
        heading={translate('nfts')}
        totalNft={statistics?.total.toString()}
        totalNftText={translate('total')}
        soldNft={statistics?.sold.toString()}
        soldNftText={translate('soldNft')}
        pendingNft={statistics?.reserved.toString()}
        pendingNftText={translate('reservedNft')}
        remainingNft={statistics?.available.toString()}
        remainingNftText={translate('availableNft')}
        fetchTrigger={fetch}
      >
        {data.map((nft: any) => (
          <NftHolder
            key={nft.serialNumber}
            text={mapNftStatusToTranslatedLabel(nft.status, translate)}
            status={nft.status}
            image={nft.imageUrl}
            price={formatAmount(nft.price, currency.decimals, currency.rate, currency.currencyCode)}
            serialNumber={mapNftSerialNumber(nft.serialNumber)}
          />
        ))}
      </NftCard>
    </NftsContainer>
  );
};

export default Nfts;
