import { Text, useWizardContext } from 'issuix-ui-kit';
import { FC, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import FullDisabledField from '@/components/FullDisabledField/FullDisabledField';
import { Container } from '@/components/Payment/PaymentModal/Steps/ThirdStepPaymentMethod/Crypto/CyptoPaymentOption/CryptoPaymentOption.styles';
import { PaymentError } from '@/components/Payment/PaymentModal/Steps/ThirdStepPaymentMethod/PaymentError';
import { usePaymentContext } from '@/components/Payment/PaymentModal/context/PaymentContext';
import CurrencyBlock from '@/components/Payment/PaymentModal/shared/CurrencyBlock/CurrencyBlock';
import { CURRENCIES } from '@/enums/Currencies';
import { PRODUCT_TYPE_ENUM } from '@/enums/ProductType';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { usePayViaCryptoMutation } from '@/store/service';

const StyledFullDisabledField = styled(FullDisabledField)`
  margin-top: 4rem;
`;

const CryptoPaymentDetails: FC = () => {
  const { translate } = useTranslation();
  const {
    ejected: { setOnNextClickHandler, goToNextPage },
    modifyNextButtonProps,
  } = useWizardContext();
  const [payViaCrypto] = usePayViaCryptoMutation();
  const { currency, resource, quantity, resourceType } = usePaymentContext();
  const [errorMessage, setErrorMessage] = useState('');
  const [hashValue, setHashValue] = useState('');
  const { colors } = useTheme();

  useEffect(() => {
    if (!currency) {
      return;
    }

    const walletHashValue =
      currency.currencyCode === CURRENCIES.BTC ? process.env.REACT_APP_BTC_WALLET : process.env.REACT_APP_ETH_WALLET;

    if (!walletHashValue) {
      return;
    }

    setHashValue(walletHashValue);
  }, [currency]);

  useEffect(() => {
    setOnNextClickHandler(() => {
      if (!currency || !resource || !resourceType) {
        setErrorMessage(translate('paymentFormError'));
        return;
      }

      modifyNextButtonProps({ loading: true });
      setErrorMessage('');

      payViaCrypto({
        currencyId: currency.id,
        itemId: resource.id,
        paymentMethod: 'CRYPTO',
        productType: resourceType === PRODUCT_TYPE_ENUM.BOND_PAPERS ? 'BOND_PAPERS' : 'ACTIONS',
        quantity: quantity,
      })
        .then((result: any) => {
          modifyNextButtonProps({ loading: false });

          if (result?.error?.status >= 400) {
            setErrorMessage(translate('genericPaymentError'));
            return;
          }

          goToNextPage();
        })
        .catch(() => {
          modifyNextButtonProps({ loading: false });
          setErrorMessage(translate('genericPaymentError'));
        });
    });
  }, [currency, resource, quantity, resourceType]);

  return (
    <Container>
      <CurrencyBlock disabled={true}>
        <Text content={translate('pleaseConfirmForm')} />
      </CurrencyBlock>
      <StyledFullDisabledField value={hashValue} label={translate('hashValue')} />
      {errorMessage ? <PaymentError message={errorMessage} color={colors.red.error} /> : null}
    </Container>
  );
};

export default CryptoPaymentDetails;
