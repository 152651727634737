import { Text } from 'issuix-ui-kit';
import styled from 'styled-components';

import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';

const TermsAndConditionsQuickRegistration = () => {
  const { translate } = useTranslation();

  return (
    <Container>
      <TextLine>
        <Text content={translate('iAgree')} size={'medium'} /> <a>{translate('termsAndConditionsPart3')}</a>{' '}
      </TextLine>
      <TextLine>
        <Text content={translate('and')} size={'medium'} /> <a>{translate('privacyPolicy')}</a>{' '}
      </TextLine>
    </Container>
  );
};

export default TermsAndConditionsQuickRegistration;

export const Container = styled.div`
  font-size: 1.8rem;
`;

const TextLine = styled.p`
  margin: 0;
`;
