import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useCheckIfIsinExistMutation, useCheckIfIsinExistSharesMutation } from '@/store/service';

export const useCheckIfIsinExistOnBlur = () => {
  const [checkIfIsinExist, { data }] = useCheckIfIsinExistMutation();

  const isinData = useMemo(() => {
    return data;
  }, [data]);

  const {
    formState: { errors },
  } = useFormContext();

  const checkIfIsinExistOnBlur = (isin: string) => {
    if (!errors.isin) {
      checkIfIsinExist(isin);
    }
  };
  return { checkIfIsinExistOnBlur, isinData };
};

export const useCheckIfIsinExistSharesOnBlur = () => {
  const [checkIfIsinExistShares, { data }] = useCheckIfIsinExistSharesMutation();

  const {
    formState: { errors },
  } = useFormContext();

  const checkIfIsinExistOnBlur = (isin: string) => {
    if (!errors.isin) {
      checkIfIsinExistShares(isin);
    }
  };
  return { checkIfIsinExistOnBlur, data };
};
