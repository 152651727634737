import { formatAmount } from '@/utils/formatAmount/FormatAmount';

export const baseBondPortfolioDefaultCalculation = {
  totalInvestedAmount: '0',
  annualCoupons: '0',
  totalReturnAmount: '0',
};

export type BondPortfolioCalculation = {
  totalInvestedAmount: string;
  annualCoupons: string;
  totalReturnAmount: string;
};

export const formatCalculation = (
  bondPortfolioCalculation: any,
  currencyDecimals = 2,
  currencyRate = 1,
  currencyName = ''
): BondPortfolioCalculation => {
  return {
    totalInvestedAmount: formatAmount(
      bondPortfolioCalculation.totalInvested,
      currencyDecimals,
      currencyRate,
      currencyName
    ),
    annualCoupons: formatAmount(bondPortfolioCalculation.annualCoupons, currencyDecimals, currencyRate, currencyName),
    totalReturnAmount: formatAmount(bondPortfolioCalculation.totalReturn, currencyDecimals, currencyRate, currencyName),
  };
};
