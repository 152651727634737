import { colors, GlobalSpinner, ProtectedComponent } from 'issuix-ui-kit';
import { FC } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { AuthModal } from '@/components/AuthModal/AuthModal';
import CopyRightsBox from '@/components/CopyRightBox/CopyRightBox';
import LeftMenu from '@/components/LeftMenu/LeftMenu';
import RightMenu from '@/components/RightMenu/RightMenu';
import { TENANT } from '@/enums/App';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useAuth } from '@/hooks/useAuth/useAuth';
import { isLoadingSelector } from '@/store/global/selectors';
import CoreDomain from '@/view/Core/CoreDomain';

const CoreLayout: FC<{ tenant: TENANT }> = ({ tenant }) => {
  const { isAuthenticated } = useAuth();
  const isLoading = useAppSelector((state) => isLoadingSelector(state));

  return (
    <Screen tenant={tenant}>
      <GlobalStyle />
      <GlobalSpinner isActive={isLoading} background={colors.blue.primary}>
        <ContentContainer>
          <AuthModal />
          <SideMenuBox>
            <ProtectedComponent condition={isAuthenticated}>
              <LeftMenu />
            </ProtectedComponent>
            <ProtectedComponent condition={!isAuthenticated}>
              <CopyRightsBox />
            </ProtectedComponent>
          </SideMenuBox>
          <CoreDomain />
          <SideMenuBox>
            <ProtectedComponent condition={isAuthenticated}>
              <RightMenu />
            </ProtectedComponent>
          </SideMenuBox>
        </ContentContainer>
      </GlobalSpinner>
    </Screen>
  );
};

export default CoreLayout;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 30px;
  padding: 30px 30px 30px 0;
`;

const Screen = styled.div<{ tenant: string }>`
  height: 100vh;
  width: 100vw;
  background: url(${require(`../../assets/core/images/background.jpg`)}) no-repeat center;
  background-size: cover;
`;

const SideMenuBox = styled.div`
  align-items: flex-end;
  width: 14vw;
  flex-direction: column;
  height: 100%;
  display: flex;
`;

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${(props: any) => props.theme.typography.fontFamily.default};
  }
`;
