import { BuildType } from '@/shared/types/redux/ReduxAction';

const smartSharesOfferedCalculation = 'calculations/shares-offered';

const getSmartSharesOfferedCalculationUrl = (query: { userId: string }) =>
  `${smartSharesOfferedCalculation}?userId=${query.userId}`;

export const getSmartSharesOffered = (build: BuildType) => {
  return build.query<number, any>({
    query: ({ query }) => ({
      url: getSmartSharesOfferedCalculationUrl(query),
      method: 'GET',
    }),
  });
};
