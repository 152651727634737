import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const secondStepCorporateSchema = (translate: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .email(translate('validEmail'))
      .required(translate('required'))
      .min(5, translate('minChar', { value: 5 }))
      .max(255, translate('maxChar', { value: 255 })),
    password: Yup.string()
      .required(translate('required'))
      .min(5, translate('minChar', { value: 5 }))
      .max(255, translate('maxChar', { value: 255 })),
    passwordConfirmation: Yup.string()
      .required(translate('required'))
      .oneOf([Yup.ref('password'), null], translate('passwordMatch')),
    address: Yup.string().required(translate('required')),
    zipCode: Yup.string()
      .required(translate('required'))
      .matches(/^[0-9]+$/, translate('digits')),
    ceo: Yup.string().required(translate('required')),
    city: Yup.string().required(translate('required')),
    owner: Yup.string().required(translate('required')),
    phoneNumber: Yup.string()
      .required(translate('required'))
      .matches(/^[0-9]+$/, translate('digits')),
    name: Yup.string().required(translate('required')),
    legalFormId: Yup.string().required(translate('required')),
    countryId: Yup.string().required(translate('required')),
  });

export const secondStepPrivateSchema = (translate: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .email(translate('validEmail'))
      .required(translate('required'))
      .min(5, translate('minChar', { value: 5 }))
      .max(255, translate('maxChar', { value: 255 })),
    password: Yup.string()
      .required(translate('required'))
      .min(5, translate('minChar', { value: 5 }))
      .max(255, translate('maxChar', { value: 255 })),
    passwordConfirmation: Yup.string()
      .required(translate('required'))
      .oneOf([Yup.ref('password'), null], translate('passwordMatch')),
    address: Yup.string().required(translate('required')),
    zipCode: Yup.string()
      .required(translate('required'))
      .matches(/^[0-9]+$/, translate('digits')),
    firstName: Yup.string().required(translate('required')),
    city: Yup.string().required(translate('required')),
    lastName: Yup.string().required(translate('required')),
    phoneNumber: Yup.string()
      .required(translate('required'))
      .matches(/^[0-9]+$/, translate('digits')),
    citizenship: Yup.string().required(translate('required')),
    birthDate: Yup.string().required(translate('required')),
    countryId: Yup.string().required(translate('required')),
  });
