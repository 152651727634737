import { pagingLimit } from '@/shared/constants/Paging';
import { PageableItems } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseIssuedCouponsByTransactionIdUrl = 'coupons/transaction';

const getIssuedCouponsByTransactionIdUrl = (offset: number, query: { id: string }) => {
  return `${baseIssuedCouponsByTransactionIdUrl}/${query.id}?offset=${offset}&limit=${pagingLimit}`;
};

export const getIssuedCouponsByTransactionId = (build: BuildType) => {
  return build.query<PageableItems, any>({
    providesTags: ['GetCoupons'],
    query: ({ offset, query }) => ({
      url: getIssuedCouponsByTransactionIdUrl(offset, query),
      method: 'GET',
    }),
  });
};
