import styled from 'styled-components';

export const PaymentOptionCardContainer = styled.div<{ isSelected: boolean }>`
  background-color: ${(props) => (props.isSelected ? '#9BFDCA' : 'rgba(233, 233, 233, 0.5)')};
  height: 12.5rem;
  width: 22.8rem;
  margin-right: 1rem;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  display: flex;
  padding: 1.5rem 0 1.5rem 1.5rem;
  height: 100%;
`;
