import { Button, Text } from 'issuix-ui-kit';
import { FC, useState } from 'react';

import AssignToShareModal from '../AssignToShareModal/AssignToShareModal';

import { ButtonsContainer, UserDetailCard } from './UserDetailsView.style';

import AdminChangePasswordModal from '@/components/AdminChangePasswordModal/AdminChangePasswordModal';
import CreateCaseModal from '@/components/CaseModal/CreateCaseModal';
import { createCaseSchema } from '@/components/CaseModal/CreateCaseSchema';
import ChangePasswordModal from '@/components/ChangePasswordModal/ChangePasswordModal';
import ControlledForm from '@/components/Form/ControlledForm';
import CorporateUserDetailsForm from '@/components/UserDetailsView/components/CorporateUserDetailsForm/CorporateUserDetailsForm';
import { corporateUserDetailsFormSchema } from '@/components/UserDetailsView/components/CorporateUserDetailsForm/CorporateUserDetailsSchema';
import PrivateUserDetailsForm from '@/components/UserDetailsView/components/PrivateUserDetailsForm/PrivateUserDetailsForm';
import { privateUserDetailsFormSchema } from '@/components/UserDetailsView/components/PrivateUserDetailsForm/PrivateUserDetailsSchema';
import { ROLE_TYPE } from '@/enums/RoleType';
import { SHARE_STATUS_ENUM } from '@/enums/share/ShareStatusEnum';
import { useAuth } from '@/hooks/useAuth/useAuth';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { AssetResponse, CorporateUserFullResponse, PrivateUserFullResponse } from '@/shared/types/api';
import { useGetShareStatusQuery, useGetSmartSharesOfferedQuery } from '@/store/service';

interface UserDetailsProps {
  privateUser?: PrivateUserFullResponse;
  corporateUser?: CorporateUserFullResponse;
  assets: AssetResponse[];
  isMyAccount?: boolean;
}

const mapStatusToButtonState = (status: SHARE_STATUS_ENUM) => status !== SHARE_STATUS_ENUM.APPROVED;

const UserDetailsView: FC<UserDetailsProps> = ({ privateUser, corporateUser, assets, isMyAccount = false }) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isAdminChangePasswordModalOpen, setIsAdminChangePasswordModalOpen] = useState<boolean>(false);
  const [isCreateCaseModalOpen, setIsCreateCaseModalOpen] = useState<boolean>(false);
  const { translate } = useTranslation();
  const [isAssignShareModalOpen, setIsAssignShareModalOpen] = useState<boolean>(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState<boolean>(false);
  const { user } = useAuth();

  const { data } = useGetShareStatusQuery();
  const { data: quantity } = useGetSmartSharesOfferedQuery(
    { query: { userId: user?.id && user?.id } },
    { skip: !user?.id }
  );

  return (
    <UserDetailCard>
      <AdminChangePasswordModal isOpen={isAdminChangePasswordModalOpen} setIsOpen={setIsAdminChangePasswordModalOpen} />
      <ChangePasswordModal isOpen={isChangePasswordModalOpen} setIsOpen={setIsChangePasswordModalOpen} />
      <AssignToShareModal isOpen={isAssignShareModalOpen} setIsOpen={setIsAssignShareModalOpen} quantity={quantity} />
      <ControlledForm schema={createCaseSchema(translate)}>
        <CreateCaseModal isOpen={isCreateCaseModalOpen} setIsOpen={setIsCreateCaseModalOpen} />
      </ControlledForm>
      <ButtonsContainer>
        {!isMyAccount && (
          <>
            <>
              {user?.role === ROLE_TYPE.ADMIN && (
                <Button onClick={() => setIsCreateCaseModalOpen(true)} text={translate('createCase')} />
              )}
            </>
            <Button onClick={() => setIsAdminChangePasswordModalOpen(true)} text={translate('changePassword')} />
          </>
        )}
        {isMyAccount && (
          <>
            <Button onClick={() => setIsChangePasswordModalOpen(true)} text={translate('changePassword')} />
            {data?.status && (
              <Button
                onClick={() => setIsAssignShareModalOpen(true)}
                text={translate('assignToShare')}
                disabled={mapStatusToButtonState(data.status)}
              />
            )}
          </>
        )}
        <Button onClick={() => setIsEditMode(!isEditMode)} text={translate('edit')} />
      </ButtonsContainer>
      <Text content={privateUser ? translate('userDetails') : translate('companyDetails')} size={'large'} />
      {corporateUser && (
        <ControlledForm schema={corporateUserDetailsFormSchema(translate)}>
          <CorporateUserDetailsForm
            user={corporateUser}
            isEditMode={isEditMode}
            assets={assets}
            setIsEditMode={setIsEditMode}
          />
        </ControlledForm>
      )}
      {privateUser && (
        <ControlledForm schema={privateUserDetailsFormSchema(translate)}>
          <PrivateUserDetailsForm
            user={privateUser}
            isEditMode={isEditMode}
            assets={assets}
            setIsEditMode={setIsEditMode}
          />
        </ControlledForm>
      )}
    </UserDetailCard>
  );
};

export default UserDetailsView;
