import { Button, IColumn, Table, Text } from 'issuix-ui-kit';
import { useNavigate } from 'react-router-dom';

import {
  CardHeader,
  NftAcceptedTransactionsContainer,
  TableContainer,
} from '@/components/NftAcceptedTransactions/NftAcceptedTransactions.style';
import { useNav } from '@/hooks/useNav/UseNav';
import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useGetNftCollectionAcceptedTransactionsQuery } from '@/store/service';
import { composePath } from '@/utils/composePath/ComposePath';
import { formatDate } from '@/utils/date/FormatDate';
import { mapNftSerialNumber } from '@/utils/mapNftSerialNumber/mapNftSerialNumber';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';

const NftAcceptedTransactions = () => {
  const { translate } = useTranslation();
  const { params } = useNav();
  const navigate = useNavigate();

  const { data, fetch } = useTableWithPagination({
    query: useGetNftCollectionAcceptedTransactionsQuery,
    id: params.id,
  });

  const onActionClick = (id: string) => {
    navigate(composePath(ClientRoutes.Transactions.nftSingleView, { id: id }));
  };

  const tableDef: IColumn[] = [
    {
      name: 'createdAt',
      width: 2,
      header: {
        label: translate('boughtDate').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          return <>{formatDate(item.createdAt)}</>;
        },
      },
    },
    {
      name: 'serialNumber',
      width: 2,
      header: {
        label: translate('serialNumber').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          return <>{mapNftSerialNumber(item.serialNumber)}</>;
        },
      },
    },
    {
      name: 'amount',
      width: 2,
      header: {
        label: translate('amount').toUpperCase(),
      },
    },
    {
      name: 'buyerName',
      width: 2,
      header: {
        label: translate('buyerName').toUpperCase(),
      },
    },
    {
      name: 'action',
      width: 2,
      header: {
        label: translate('actions').toUpperCase(),
      },
      column: {
        render: (item: any) => (
          <Button fullyRounded size="medium" onClick={() => onActionClick(item.id)} text={translate('view')} />
        ),
      },
    },
  ];

  return (
    <NftAcceptedTransactionsContainer>
      <CardHeader>
        <Text content={translate('acceptedTransactions')} size={'large'} />
      </CardHeader>
      <TableContainer>
        <Table fetchTrigger={() => fetch()} tableDef={tableDef} data={data} />
      </TableContainer>
    </NftAcceptedTransactionsContainer>
  );
};

export default NftAcceptedTransactions;
