import { Card } from 'issuix-ui-kit';
import styled from 'styled-components';

export const CasesCard = styled(Card)`
  height: 100%;
  width: 100%;
  padding: 3rem;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const ActionContainer = styled.div`
  display: flex;
  gap: 2rem;
`;
