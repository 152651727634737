import { Button, IColumn, ModalDialog, Table, Text, Textarea } from 'issuix-ui-kit';
import React, { useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ControlledForm from '@/components/Form/ControlledForm';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { renderColumn } from '@/shared/commonUtils/renderColumn/renderColumn';
import { USER_STATUS_TYPE_ENUM } from '@/shared/types/UserType';
import { AdminUserResponse, PageableItems } from '@/shared/types/api';
import { useApproveUserMutation, useRejectUserMutation } from '@/store/service';
import { composePath } from '@/utils/composePath/ComposePath';
import { composeRequestChildPath } from '@/utils/composePath/ComposeRequestChildPath';
import { formatDate } from '@/utils/date/FormatDate';
import { parseBoolean } from '@/utils/parseBoolean/parseBoolean';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';
import { isMetaSwissTenant } from '@/utils/tenant/Tenant';
import RequestsFilterForm from '@/view/Admin/Requests/FilterForm/RequestsFilterForm';
import { RequestsFilterSchema } from '@/view/Admin/Requests/FilterForm/RequestsFilterSchema';
import {
  ActionsContainer,
  ButtonsContainer,
  CardHeader,
  FiltersContainer,
  RequestCard,
  StyledText,
  TableContainer,
  UsersContainer,
} from '@/view/Admin/Requests/Requests.styles';
import { RequestsContextProvider, useRequestsContext } from '@/view/Admin/Requests/RequestsContext';

const Requests = () => {
  return (
    <RequestsContextProvider>
      <RequestsView />
    </RequestsContextProvider>
  );
};

const RequestsView = () => {
  const navigate = useNavigate();
  const { data, fetch } = useRequestsContext();
  const { translate } = useTranslation();

  const [isAcceptModalDialogOpen, setIsAcceptModalDialogOpen] = useState<boolean>(false);
  const [isRejectModalDialogOpen, setIsRejectModalDialogOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [id, setId] = useState<string>('');
  const [reason, setReason] = useState<string>('');
  const [rejectUser, { isLoading: isRejectLoading, isSuccess: isRejectSuccess }] = useRejectUserMutation();
  const [approveUser, { isLoading: isApproveLoading, isSuccess: isApproveSuccess }] = useApproveUserMutation();

  const onActionClick = (id: string, user: any) => {
    navigate(
      composePath(composeRequestChildPath(ClientRoutes.Requests.viewRequest, `role=${user?.endUserRole}`), { id })
    );
  };

  const onAcceptModalDialogClose = () => {
    setIsAcceptModalDialogOpen(false);
    setName('');
  };

  const onRejectModalDialogClose = () => {
    setIsRejectModalDialogOpen(false);
    setName('');
  };

  const openAccept = (name: string, id: string) => {
    setIsAcceptModalDialogOpen(true);
    setName(name);
    setId(id);
  };

  const openReject = (name: string, id: string) => {
    setIsRejectModalDialogOpen(true);
    setName(name);
    setId(id);
  };

  // TODO - NIS/203 - fix updating data
  const onAccept = () => {
    approveUser({ id });
    let position = 0;
    const updatedItem: PageableItems = data.find((item: any, index) => {
      position = index;
      return item.id === id;
    }) as PageableItems;
    data[position] = { ...updatedItem, status: USER_STATUS_TYPE_ENUM.APPROVED } as PageableItems;
  };

  // TODO - NIS/203 - fix updating data
  const onReject = () => {
    rejectUser({ reason, id });
    let position = 0;
    const updatedItem: PageableItems = data.find((item: any, index) => {
      position = index;
      return item.id === id;
    }) as PageableItems;

    data[position] = { ...updatedItem, status: USER_STATUS_TYPE_ENUM.SUSPENDED } as PageableItems;
  };

  useEffect(() => {
    if (isRejectSuccess) {
      setIsRejectModalDialogOpen(false);
      setReason('');
    }
  }, [isRejectSuccess]);

  useEffect(() => {
    if (isApproveSuccess) {
      setIsAcceptModalDialogOpen(false);
      setName('');
    }
  }, [isApproveSuccess]);

  const tableDef: IColumn[] = [
    {
      name: 'endUserRole',
      width: 1,
      header: {
        label: translate('role').toUpperCase(),
      },
      column: {
        render: (item: AdminUserResponse) => {
          return <p>{translate(item.endUserRole as keyof TFunction)}</p>;
        },
      },
    },
    {
      name: 'name',
      width: 2,
      header: {
        label: translate('name').toUpperCase(),
      },
    },
    {
      name: 'email',
      width: 3,
      header: {
        label: translate('email').toUpperCase(),
      },
    },
    {
      name: 'createdAt',
      width: 2,
      header: {
        label: translate('registrationDate').toUpperCase(),
      },
      column: {
        render: (item: AdminUserResponse) => {
          return <p>{formatDate(item.createdAt)}</p>;
        },
      },
    },
    {
      name: 'isFullyRegistered',
      width: 2,
      header: {
        label: translate('fullRegister').toUpperCase(),
      },
      column: {
        render: (item: AdminUserResponse) => {
          return <p>{parseBoolean(item.isFullyRegistered, translate)}</p>;
        },
      },
    },
    isMetaSwissTenant() ? renderColumn('tenant', 2, translate('tenant')) : renderColumn('', 0),
    {
      name: 'actions',
      width: 3,
      header: {
        label: translate('actions').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          return (
            <ActionsContainer>
              <Button
                onClick={() => onActionClick(item.id, item)}
                text={translate('view')}
                fullyRounded
                size={'medium'}
              />
              <Button
                onClick={() => openAccept(item.name, item.id)}
                text={translate('accept')}
                disabled={!item.isFullyRegistered || item.status !== USER_STATUS_TYPE_ENUM.PENDING}
                fullyRounded
                size={'medium'}
              />
              <Button
                onClick={() => openReject(item.name, item.id)}
                text={translate('reject')}
                buttonType={'danger'}
                disabled={!item.isFullyRegistered || item.status !== USER_STATUS_TYPE_ENUM.PENDING}
                fullyRounded
                size={'medium'}
              />
            </ActionsContainer>
          );
        },
      },
    },
  ];

  return (
    <UsersContainer>
      <RequestCard>
        <CardHeader>
          <Text content={translate('requests')} size="large" />
        </CardHeader>
        <TableContainer>
          <Table data={data} fetchTrigger={() => fetch()} tableDef={tableDef}></Table>
        </TableContainer>
      </RequestCard>
      <FiltersContainer>
        <Text content={translate('filters')} size={'large'} />
        <ControlledForm schema={RequestsFilterSchema()}>
          <RequestsFilterForm />
        </ControlledForm>
      </FiltersContainer>
      <ModalDialog isOpen={isAcceptModalDialogOpen} close={onAcceptModalDialogClose}>
        <StyledText content={translate('approve')} size={'extraLarge'} />
        <StyledText content={`${translate('areYouSureUserApprove')} ${name}?`} size={'medium'} />
        <ButtonsContainer>
          <Button
            text={translate('cancel')}
            onClick={onAcceptModalDialogClose}
            buttonType={'secondary'}
            disabled={isApproveLoading}
          />
          <Button text={translate('approve')} onClick={onAccept} buttonType={'primary'} isLoading={isApproveLoading} />
        </ButtonsContainer>
      </ModalDialog>
      <ModalDialog isOpen={isRejectModalDialogOpen} close={onRejectModalDialogClose}>
        <StyledText content={translate('reject')} size={'large'} />
        <StyledText content={`${translate('areYouSureUserReject')} ${name}?`} size={'medium'} />
        <Textarea
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setReason(e.target.value)}
          value={reason}
          name={'reason'}
        />
        <ButtonsContainer>
          <Button
            text={translate('cancel')}
            onClick={onRejectModalDialogClose}
            buttonType={'secondary'}
            disabled={isRejectLoading}
          />
          <Button text={translate('reject')} onClick={onReject} buttonType={'danger'} isLoading={isRejectLoading} />
        </ButtonsContainer>
      </ModalDialog>
    </UsersContainer>
  );
};

export default Requests;
