import { FC } from 'react';

import { useNav } from '@/hooks/useNav/UseNav';
import { useGetTransactionDetailsQuery } from '@/store/service';
import TransactionDetailInfo from '@/view/Admin/TransactionDetailView/TransactionDetailInfo/TransactionDetailInfo';
import { TransactionDetailViewWrapper } from '@/view/Admin/TransactionDetailView/TransactionDetailView.styles';

const TransactionDetailView = () => {
  return <TransactionDetailViewContent />;
};

const TransactionDetailViewContent: FC = () => {
  const { params } = useNav();
  const { data } = useGetTransactionDetailsQuery(
    { query: { id: params.id && params.id } },
    { refetchOnMountOrArgChange: true },
    { skip: !params.id }
  );

  return (
    <TransactionDetailViewWrapper>
      <TransactionDetailInfo transaction={data} />
    </TransactionDetailViewWrapper>
  );
};

export default TransactionDetailView;
