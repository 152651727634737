import styled from 'styled-components';

export const SetCurrencyContainer = styled.div`
  grid-row: 1 / 2;
  grid-column: 3 / 4;
  display: grid;
  > * {
    justify-self: self-end;
  }
`;

export const TitleContainer = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / 3;
  justify-self: self-start;
`;
