import { useMemo } from 'react';
import { TFunction } from 'react-i18next';

import { AvailabilityOptionResponse } from '@/shared/types/api';
import { useGetAvailabilityOptionsQuery } from '@/store/service';

export const useAvailabilityOptions = (translate: TFunction) => {
  const { data: availabilityOptions } = useGetAvailabilityOptionsQuery();

  const availabilityOptionsAsDropdown = useMemo(
    () =>
      availabilityOptions?.map((availabilityOption: AvailabilityOptionResponse) => {
        return {
          value: availabilityOption.id,
          label: translate(availabilityOption.value.split('_').join('').toLowerCase() as keyof TFunction),
        };
      }),
    [availabilityOptions, translate]
  );

  return {
    availabilityOptions: availabilityOptions || [],
    availabilityOptionsAsDropdown: availabilityOptionsAsDropdown || [],
  };
};
