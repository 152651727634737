import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const thirdStepPrivateSchema = (translate: TFunction) =>
  Yup.object().shape({
    ethWallet: Yup.string(),
    btcWallet: Yup.string(),
    image: Yup.object().required(translate('required')),
    proofOfDomicile: Yup.object().required(translate('required')),
    passport: Yup.object().required(translate('required')),
    termsAndConditions: Yup.boolean().required().oneOf([true], 'Check'),
  });

export const thirdStepCorporateSchema = (translate: TFunction) =>
  Yup.object().shape({
    ethWallet: Yup.string(),
    btcWallet: Yup.string(),
    businessFocusId: Yup.string().required(translate('required')),
    image: Yup.object().required(translate('required')),
    commercialRegister: Yup.object().required(translate('required')),
    proofOfDomicile: Yup.object().required(translate('required')),
    signature: Yup.object().required(translate('required')),
  });
