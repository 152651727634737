import { ModalDialog, Text } from 'issuix-ui-kit';
import { FC } from 'react';

import AssignToShareModalForm from './AssignToShareModalForm/AssignToShareModalForm';
import { AssignToShareModalFormSchema } from './AssignToShareModalForm/AssignToShareModalFormSchema';

import ControlledForm from '@/components/Form/ControlledForm';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';

interface AssignToShareModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  quantity: number;
}

const AssignToShareModal: FC<AssignToShareModalProps> = ({ isOpen, setIsOpen, quantity }) => {
  const { translate } = useTranslation();

  return (
    <ModalDialog isOpen={isOpen} close={() => setIsOpen(false)}>
      <Text content={translate('assignToShare')} size={'large'} />
      <ControlledForm schema={AssignToShareModalFormSchema(translate, quantity?.toString())}>
        <AssignToShareModalForm setIsOpen={setIsOpen} />
      </ControlledForm>
    </ModalDialog>
  );
};

export default AssignToShareModal;
