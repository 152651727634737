import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { TENANT } from '@/enums/App';
import { ROLE_TYPE } from '@/enums/RoleType';
import { useAuth } from '@/hooks/useAuth/useAuth';
import { getTenant } from '@/utils/getTenant/getTenant';

type Props = {
  fallback?: string;
  allowedRoles?: ROLE_TYPE[];
  allowedTenants?: TENANT[];
  customCondition?: boolean;
  children: React.ReactNode;
};

const ProtectedRoute: FC<Props> = ({
  children,
  allowedRoles = [],
  fallback,
  customCondition = true,
  allowedTenants,
}) => {
  const { isAuthenticated, user } = useAuth();

  const tenant = getTenant();

  if (
    !isAuthenticated ||
    (user!.role && allowedRoles?.length && !allowedRoles.some((allowedRole) => allowedRole === user!.role)) ||
    (tenant && allowedTenants?.length && !allowedTenants.some((allowedTenant) => allowedTenant === tenant)) ||
    !customCondition
  ) {
    return <Navigate to={fallback || '/'} />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
