import { TENANT } from '@/enums/App';
import { GreenStateLogo, MetaSwissLogo } from '@/shared/icons';
import { getTenant } from '@/utils/getTenant/getTenant';

const Logo = () => {
  const tenant = getTenant();

  return tenant === TENANT.GREEN_STATE ? <GreenStateLogo /> : <MetaSwissLogo />;
};

export default Logo;
