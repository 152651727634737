import { IColumn, Pill, PILL_SELECTED, PILL_SIZE, Table, Text } from 'issuix-ui-kit';
import { FC, useState } from 'react';

import { CouponPaymentModal } from '@/components/CouponPaymentModal/CouponPaymentModal';
import { COUPON_STATUS_ENUM } from '@/enums/CouponStatus';
import { ROLE_TYPE } from '@/enums/RoleType';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { isCouponPillPaidStatus } from '@/shared/commonUtils/is-coupon-pill-paid-status/is-coupon-pill-paid-status';
import { getUserSelector } from '@/store/global/selectors';
import { useChangeCouponStatusMutation, useGetIssuedCouponsByTransactionIdQuery } from '@/store/service';
import { formatDate } from '@/utils/date/FormatDate';
import {
  CardHeader,
  TransactionSingleViewContainer,
} from '@/view/Admin/TransactionSingleView/TransactionSingleView.styles';
import { TableContainer } from '@/view/Shared/ViewBond/Transactions/Transaction.styles';

interface CouponsProps {
  transactionId: string | undefined;
}

const Coupons: FC<CouponsProps> = ({ transactionId }) => {
  const { translate } = useTranslation();
  const { fetch, data } = useTableWithPagination({ query: useGetIssuedCouponsByTransactionIdQuery, id: transactionId });
  const [changeCouponStatus] = useChangeCouponStatusMutation();
  const user = useAppSelector((state) => getUserSelector(state));
  const [selectedCoupon, setSelectedCoupon] = useState<string | undefined>(undefined);

  const onPillClick = (id: string) => {
    changeCouponStatus({ query: { id } });
  };

  const onCouponPayModalClose = () => {
    setSelectedCoupon(undefined);
  };

  const tableDef: IColumn[] = [
    {
      name: 'expiryDate',
      width: 2,
      header: {
        label: translate('expiryDate').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          return <p>{formatDate(item.expiryDate)}</p>;
        },
      },
    },
    {
      name: 'paymentDate',
      width: 2,
      header: {
        label: translate('paymentDate').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          return <p>{item.paymentDate ? formatDate(item.paymentDate) : '/'}</p>;
        },
      },
    },
    {
      name: 'billingSequenceNumber',
      width: 2,
      header: {
        label: translate('billingSequenceNumber').toUpperCase(),
      },
    },
    {
      name: 'amount',
      width: 1,
      header: {
        label: translate('amount').toUpperCase(),
      },
    },
    {
      name: 'status',
      width: 2,
      header: {
        label: translate('status').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          return (
            <Pill
              selected={item.status === COUPON_STATUS_ENUM.PAID ? PILL_SELECTED.LEFT : PILL_SELECTED.RIGHT}
              rightLabel={translate('notPaid')}
              leftLabel={translate('paid')}
              size={PILL_SIZE.SMALL}
              onRightClick={() => console.log('Disabled')}
              onLeftClick={() => {
                setSelectedCoupon(item.id);
              }}
              disabled={isCouponPillPaidStatus(item.status) || user?.role !== ROLE_TYPE.ADMIN}
            />
          );
        },
      },
    },
  ];

  return (
    <TransactionSingleViewContainer>
      <CouponPaymentModal
        selectedCoupon={selectedCoupon}
        setSelectedCoupon={setSelectedCoupon}
        onClose={onCouponPayModalClose}
        onPillClick={onPillClick}
      />
      <CardHeader>
        <Text content={translate('coupons')} size={'large'} />
      </CardHeader>
      <TableContainer>
        <Table data={data} fetchTrigger={() => fetch()} tableDef={tableDef} />
      </TableContainer>
    </TransactionSingleViewContainer>
  );
};

export default Coupons;
