import { BuildType } from '@/shared/types/redux/ReduxAction';

const currenciesPath = 'notifications';

export const sendNotification = (build: BuildType) => {
  return build.mutation<void, any>({
    query: (notificationData: any) => ({
      url: `${currenciesPath}/send-notification`,
      method: 'POST',
      body: notificationData,
    }),
  });
};
