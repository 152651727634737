import { Button, IColumn, Table, Text } from 'issuix-ui-kit';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AcceptedTransactionsContainer,
  TableContainer,
} from '@/components/AcceptedTransactions/AcceptedTransactions.style';
import { PRODUCT_TYPE_ENUM } from '@/enums/ProductType';
import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { AcceptedTransaction } from '@/shared/types/AcceptedTransaction';
import { useGetAcceptedTransactionsQuery } from '@/store/service';
import { composePath } from '@/utils/composePath/ComposePath';
import { formatDate } from '@/utils/date/FormatDate';
import { featureHashColumn } from '@/utils/featuresFlags/featureFlags';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';

const AcceptedTransactions: FC = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const { fetch, data } = useTableWithPagination({ query: useGetAcceptedTransactionsQuery });

  const onActionClick = (item: AcceptedTransaction) => {
    switch (item.productType) {
      case PRODUCT_TYPE_ENUM.ACTIONS:
        return navigate(composePath(ClientRoutes.Transactions.shareSingleView, { id: item.id }));
      case PRODUCT_TYPE_ENUM.BOND_PAPERS:
        return navigate(composePath(ClientRoutes.Transactions.bondSingleView, { id: item.id }));
      case PRODUCT_TYPE_ENUM.NFT:
        return navigate(composePath(ClientRoutes.Transactions.nftSingleView, { id: item.id }));
    }
  };

  const tableDef: IColumn[] = [
    {
      name: 'createdAt',
      width: 1,
      header: {
        label: translate('date').toUpperCase(),
      },
      column: {
        render: (item: AcceptedTransaction) => {
          return <p>{item.createdAt ? formatDate(item.createdAt) : undefined}</p>;
        },
      },
    },
    {
      name: 'buyerName',
      width: 1,
      header: {
        label: translate('buyerName').toUpperCase(),
      },
    },
    {
      name: 'detectedAmount',
      width: 2,
      header: {
        label: translate('detectedAmount').toUpperCase(),
      },
    },
    {
      name: 'hash',
      width: 1,
      header: {
        label: translate('hash').toUpperCase(),
      },
      column: {
        render: (item: AcceptedTransaction) => {
          return <p>{item.hash}</p>;
        },
      },
    },
    {
      name: 'details',
      width: 2,
      header: {
        label: translate('actions').toUpperCase(),
      },
      column: {
        render: (item: AcceptedTransaction) => (
          <Button fullyRounded onClick={() => onActionClick(item)} text={translate('view')} size={'medium'} />
        ),
      },
    },
  ];

  const tableDefWithoutHash: IColumn[] = [
    {
      name: 'createdAt',
      width: 1,
      header: {
        label: translate('date').toUpperCase(),
      },
      column: {
        render: (item: AcceptedTransaction) => {
          return <p>{item.createdAt ? formatDate(item.createdAt) : undefined}</p>;
        },
      },
    },
    {
      name: 'buyerName',
      width: 1,
      header: {
        label: translate('buyerName').toUpperCase(),
      },
    },
    {
      name: 'detectedAmount',
      width: 2,
      header: {
        label: translate('detectedAmount').toUpperCase(),
      },
    },
    {
      name: 'details',
      width: 2,
      header: {
        label: translate('actions').toUpperCase(),
      },
      column: {
        render: (item: AcceptedTransaction) => (
          <Button fullyRounded size="medium" onClick={() => onActionClick(item)} text={translate('view')} />
        ),
      },
    },
  ];

  return (
    <AcceptedTransactionsContainer>
      <Text content={translate('acceptedTransaction')} size={'large'} />
      <TableContainer>
        <Table
          data={data}
          fetchTrigger={() => fetch()}
          tableDef={featureHashColumn ? tableDef : tableDefWithoutHash}
        ></Table>
      </TableContainer>
    </AcceptedTransactionsContainer>
  );
};

export default AcceptedTransactions;
