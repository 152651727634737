import { PageableItems } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const getNftCollectionsUrl = `/nft-collection`;

export const getNftCollections = (build: BuildType) => {
  return build.query<PageableItems, any>({
    providesTags: ['GetNftCollections'],
    query: () => ({
      url: getNftCollectionsUrl,
      method: 'GET',
    }),
  });
};
