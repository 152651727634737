import { Card } from 'issuix-ui-kit';
import styled from 'styled-components';

export const NftsContainer = styled(Card).attrs(() => ({
  notFullHeight: true,
}))`
  width: 100%;
  padding: 4rem 2rem;
  position: relative;
`;
