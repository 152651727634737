import { Card } from 'issuix-ui-kit';
import styled from 'styled-components';

export const SmartSharePortfolioCard = styled(Card)`
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  padding: 3rem;
`;

export const SplitTableBlock = styled.div`
  width: 100%;
`;

export const SplitChartBlock = styled.div`
  width: 100%;
`;

export const TableRowContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CalculationsBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
