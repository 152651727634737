import { Button, Dropdown, ProtectedComponent } from 'issuix-ui-kit';
import { FC, useEffect, useState } from 'react';

import { CardHeaderContainer } from './CardHeader.style';

import { useAuthContext } from '@/components/Auth/AuthContext';
import { BreadCrumbs } from '@/components/BreadCrumbs/BreadCrumbs';
import { FeatureFlag } from '@/components/FeatureFlag/FeatureFlag';
import { FEATURE_FLAGS } from '@/components/FeatureFlag/featureFlags';
import { AUTH_MODAL_TYPE } from '@/enums/AuthModalType';
import { useAuth } from '@/hooks/useAuth/useAuth';
import { useBrowserLanguage } from '@/hooks/useBrowserLanguage/useBrowserLanguage';
import { useCurrencies } from '@/hooks/useCurrencies/UseCurrencies';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { availableLanguages } from '@/shared/constants/DropdownLanguage';

const CardHeader: FC = () => {
  const { translate } = useTranslation();
  const { setTranslationLanguage } = useTranslation();
  const { isAuthenticated } = useAuth();
  const language = useBrowserLanguage();
  const [languageDropdownValue, setLanguageDropdownValue] = useState<string>(language);

  const { currenciesAsDropdown, setSelectedCurrency, selectedCurrency } = useCurrencies();
  const { setModalType } = useAuthContext();

  useEffect(() => {
    setLanguageDropdownValue(language);
  }, [language]);

  useEffect(() => {
    setTranslationLanguage(languageDropdownValue);
  }, [languageDropdownValue]);

  return (
    <CardHeaderContainer>
      <div>
        <ProtectedComponent condition={!isAuthenticated}>
          <FeatureFlag name={FEATURE_FLAGS.LOGIN}>
            <Button text={translate('login')} onClick={() => setModalType(AUTH_MODAL_TYPE.SIGN_IN)} size="small" />
          </FeatureFlag>
          <FeatureFlag name={FEATURE_FLAGS.REGISTRATION}>
            <Button
              text={translate('register')}
              buttonType="secondary"
              onClick={() => setModalType(AUTH_MODAL_TYPE.REGISTER)}
              size="small"
            />
          </FeatureFlag>
        </ProtectedComponent>
        <ProtectedComponent condition={isAuthenticated}>
          <BreadCrumbs />
        </ProtectedComponent>
      </div>
      <div>
        <Dropdown items={currenciesAsDropdown} onSelect={(val) => setSelectedCurrency(val)} value={selectedCurrency} />
        <Dropdown
          items={availableLanguages}
          onSelect={(val) => {
            setLanguageDropdownValue(val);
          }}
          value={languageDropdownValue}
        />
      </div>
    </CardHeaderContainer>
  );
};

export default CardHeader;
