import { Card, UploadFile, Button } from 'issuix-ui-kit';
import styled from 'styled-components';

export const RequestContainer = styled.div`
  width: 100%;
  margin: auto;
  height: fit-content;
  padding: 2.5rem;
`;

export const SectionTitle = styled.div`
  margin-bottom: 3rem;
`;

export const CardContainer = styled(Card)`
  padding: 4rem;
  height: 100%;
  position: relative;
`;

export const CardWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const RequestInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const RequestInfo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
`;

export const RequestDataWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;

  & > :last-child {
    align-self: flex-start;
  }
`;

export const CompanyLogoWrapper = styled.div`
  display: flex;
  height: 20rem;
  width: 25rem;
  border-radius: 50%;
  background-color: white;
  margin-right: 2rem;
`;

export const CompanyLogo = styled.img`
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;

export const DocumentWrapper = styled.div`
  display: flex;
`;

export const DocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RequestDataContainer = styled.div`
  display: flex;
`;

export const ActionWrapper = styled.div`
  align-self: flex-end;
`;

export const UploadSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const UploadFileComponent = styled(UploadFile)`
  margin-bottom: 0 !important;
  margin-right: 2rem;
`;

export const RejectButton = styled(Button)`
  margin-left: 2rem;
`;
