import { FC } from 'react';

import CreateSmartShareForm from './CreateSmartShareForm/CreateSmartShareForm';
import { createSmartShareSchema } from './CreateSmartShareSchema';

import ControlledForm from '@/components/Form/ControlledForm';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';

const CreateSmartShare: FC = () => {
  const { translate } = useTranslation();
  return (
    <ControlledForm schema={createSmartShareSchema(translate)}>
      <CreateSmartShareForm />
    </ControlledForm>
  );
};
export default CreateSmartShare;
