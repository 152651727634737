import * as yup from 'yup';

export const smartShareListingFilterSchema = () =>
  yup.object().shape({
    code: yup.string(),
    companyName: yup.string(),
    country: yup.string(),
    maxInvestment: yup.string(),
    minInvestment: yup.string(),
    round: yup.string(),
  });
