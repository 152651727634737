import axios from 'axios';
import { useEffect, useState } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';

import { browserService } from '@/service/browser/BrowserService';
import { BROWSER_SERVICE_KEYS } from '@/service/browser/BrowserServiceKeys';
import { AssetUnassignedNftResponse } from '@/shared/types/api';
import { useCreateNftMutation, useUnassignedAssetUrlMutation } from '@/store/service';
import { getTenant } from '@/utils/getTenant/getTenant';

export const useMultipleUpload = (setValue?: UseFormSetValue<FieldValues>, type?: string) => {
  const [progressInfo, setProgressInfo] = useState<number[]>([]);
  const [addedImages, setAddedImages] = useState<AssetUnassignedNftResponse[]>([]);
  const [addedThumbnails, setAddedThumbnails] = useState<AssetUnassignedNftResponse[]>([]);
  const [getSignedUrl] = useUnassignedAssetUrlMutation();
  const [createNft] = useCreateNftMutation();

  useEffect(() => {
    if (setValue && type) {
      setValue(type, { progress: progressInfo, values: addedThumbnails });
    }
  }, [progressInfo, addedThumbnails]);

  const onUpload = async (files: File[], uploadedFiles: AssetUnassignedNftResponse[], progress: number[]) => {
    let startIndex = 0;
    if (progress?.length > 0) {
      startIndex = progress.length;
      setAddedImages(uploadedFiles);
      setProgressInfo(progress);
    }
    for (let i = 0; i < files.length; i++) {
      await onUploadHandler(files[i], startIndex);
      startIndex++;
    }
  };

  const onUploadHandler = async (file: File, index: number) => {
    const token = browserService.get<string>(BROWSER_SERVICE_KEYS.ACCESS_TOKEN);
    const tenant = getTenant();

    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('fileType', file.type);

    return axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/assets/unassigned-nft`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Tenant: tenant,
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setProgressInfo((previousProgress) => {
            const updatedProgress = [...previousProgress];
            updatedProgress[index] = progress;
            return updatedProgress;
          });
        },
      })
      .then(async (response) => {
        const createdImage: AssetUnassignedNftResponse = response.data.image;
        const createdThumbnail: AssetUnassignedNftResponse = response.data.thumbnail;
        if (createdImage.fileId && createdThumbnail.fileId) {
          const image = await getSignedUrl({ assetId: createdImage.fileId }).unwrap();
          createdImage.url = image.url;
          const thumbnail = await getSignedUrl({ assetId: createdThumbnail.fileId }).unwrap();
          createdThumbnail.url = thumbnail.url;
        }
        const progress = !createdImage?.fileId || !createdThumbnail?.fileId ? -1 : 100;
        setProgressInfo((previousProgress) => {
          const updatedProgress = [...previousProgress];
          updatedProgress[index] = progress;
          return updatedProgress;
        });
        setAddedImages((previousFiles) => {
          const updatedFiles = [...previousFiles];
          updatedFiles[index] = createdImage;
          return updatedFiles;
        });
        setAddedThumbnails((previousFiles) => {
          const updatedFiles = [...previousFiles];
          updatedFiles[index] = createdThumbnail;
          return updatedFiles;
        });
      });
  };

  const onRetry = async (event: any) => {
    const file = addedImages.find((addedFile) => addedFile.serialNumber === event.currentTarget.parentNode.id);
    if (!file) {
      return;
    }

    const clientFile = new File([new Blob()], file.file.originalname, {
      type: file.file.mimetype,
      lastModified: Date.now(),
    });

    const index = addedImages.findIndex((addedFile) => addedFile.serialNumber === event.currentTarget.parentNode.id);

    const token = browserService.get<string>(BROWSER_SERVICE_KEYS.ACCESS_TOKEN);
    const tenant = getTenant();

    const formData: FormData = new FormData();
    formData.append('file', clientFile);
    formData.append('fileType', clientFile.type);

    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/assets/unassigned-nft`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Tenant: tenant,
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setProgressInfo((previousProgress) => {
            const updatedProgress = [...previousProgress];
            updatedProgress[index] = progress;
            return updatedProgress;
          });
        },
      })
      .then(async (response) => {
        const createdImage: AssetUnassignedNftResponse = response.data.image;
        const createdThumbnail: AssetUnassignedNftResponse = response.data.thumbnail;
        if (createdImage.fileId && createdThumbnail.fileId) {
          const image = await getSignedUrl({ assetId: createdImage.fileId }).unwrap();
          createdImage.url = image.url;
          const thumbnail = await getSignedUrl({ assetId: createdThumbnail.fileId }).unwrap();
          createdThumbnail.url = thumbnail.url;
        }
        const progress = !createdImage?.fileId ? -1 : 100;
        setProgressInfo((previousProgress) => {
          const updatedProgress = [...previousProgress];
          updatedProgress[index] = progress;
          return updatedProgress;
        });
        setAddedImages((previousFiles) => {
          const updatedFiles = [...previousFiles];
          updatedFiles[index] = createdImage;
          return updatedFiles;
        });
        setAddedThumbnails((previousFiles) => {
          const updatedFiles = [...previousFiles];
          updatedFiles[index] = createdThumbnail;
          return updatedFiles;
        });
      });
  };

  const onSubmit = async (nftCollectionId: string) => {
    const submittedFiles: AssetUnassignedNftResponse[] = [];

    for (let i = 0; i < addedImages.length; i++) {
      const response = await createNft({
        image: addedImages[i].fileId,
        thumbnail: addedThumbnails[i].fileId,
        nftCollectionId,
        serialNumber: addedImages[i].serialNumber,
      });

      if (response?.error) {
        console.error(response.error.data.payload.message);
      } else {
        submittedFiles.push(addedThumbnails[i]);
      }
    }

    removeSubmittedFiles(submittedFiles);
  };

  const removeSubmittedFiles = (submittedFiles: AssetUnassignedNftResponse[]) => {
    const indexArray: number[] = [];
    for (let i = 0; i < addedImages.length; i++) {
      const foundIndex = submittedFiles.findIndex(
        (submittedFile) => submittedFile.serialNumber === addedThumbnails[i].serialNumber
      );
      if (foundIndex !== -1) {
        indexArray.push(i);
      }
    }
    const updatedProgress = [...progressInfo];
    const filteredArray = updatedProgress.filter((_, index) => !indexArray.includes(index));
    setProgressInfo(filteredArray);
    setAddedImages((previousFiles) => {
      const updatedFiles = [...previousFiles];
      const files = updatedFiles.filter(
        (file) => !submittedFiles.find((submittedFile) => submittedFile.serialNumber === file.serialNumber)
      );
      return files;
    });
    setAddedThumbnails((previousFiles) => {
      const updatedFiles = [...previousFiles];
      const files = updatedFiles.filter(
        (file) => !submittedFiles.find((submittedFile) => submittedFile.serialNumber === file.serialNumber)
      );
      return files;
    });
  };

  return {
    onUpload,
    onRetry,
    onSubmit,
    progress: progressInfo,
    files: addedThumbnails,
  };
};
