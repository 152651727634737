import { Button, Text } from 'issuix-ui-kit';
import { FC, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import ReactQuill from 'react-quill';
import './quill.snow.css';

import ControlledInput from '../Form/ControlledInput';

import { AddAttachments } from '@/assets/admin/greenstate/icons';
import ControlledForm from '@/components/Form/ControlledForm';
import ControlledUpload from '@/components/Form/ControlledUpload';
import { directEmailSchema } from '@/components/SendDirectEmail/DirectMailSchema';
import {
  ActionContainer,
  AddAttachmentButton,
  AttachmentsContent,
  AttachmentsWrapper,
  ContentWrapper,
  EditorWrapper,
  Footer,
  SendDirectEmailWrapper,
  SubjectWrapper,
} from '@/components/SendDirectEmail/SendDirectEmail.style';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useUploadFile } from '@/hooks/useUploadFile/useUploadFile';
import { useDirectEmailMutation } from '@/store/service';

type Props = {
  onClose: () => void;
};

const SendDirectEmailForm: FC<Props> = ({ onClose }) => {
  const { translate } = useTranslation();
  const [editorState, setEditorState] = useState<any>();
  const [isEmptyText, setIsEmptyText] = useState<string>();
  const { uploadUnassignedAsset, viewFile } = useUploadFile();
  const {
    setValue,
    getValues,
    control,
    reset,
    formState: { errors, isValid },
  } = useFormContext();

  const [directEmail, { isSuccess }] = useDirectEmailMutation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attachments',
  });

  useEffect(() => {
    const regex = /(<([^>]+)>)/gi;
    const newString = editorState?.replace(regex, '');
    setIsEmptyText(newString);
  }, [editorState]);

  const onSend = () => {
    directEmail({
      ...getValues(),
      attachments: getValues().attachments.map((attachment: { fileId: string }) => attachment.fileId),
      body: editorState,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      onClose();
    }
  }, [isSuccess]);

  const onCancel = () => {
    onClose();
  };

  const modules = {
    toolbar: [['bold', 'italic', 'underline'], [{ header: [1, 2, false] }]],
  };

  const formats = useMemo(() => ['header', 'bold', 'italic', 'underline'], []);

  return (
    <>
      <SubjectWrapper>
        <ControlledInput type="text" name="subject" id="subject" placeholder={translate('subject')} />
      </SubjectWrapper>
      <EditorWrapper>
        <ContentWrapper>
          <ReactQuill theme="snow" value={editorState} onChange={setEditorState} modules={modules} formats={formats} />
        </ContentWrapper>
        <Footer />
      </EditorWrapper>
      <AttachmentsWrapper>
        <Text content={translate('attachments')} size={'medium'} />
        <Text content={translate('max3')} size={'small'} />
        <AttachmentsContent>
          {fields.map((field, index) => {
            return (
              <ControlledUpload
                parentControl={control}
                key={`attachments.${index}`}
                placeholder={`${translate('attachment')} ${index + 1}`}
                incorrectFileFormatLabel={translate('incorrectFileFormat')}
                dragDropFilesHereLabel={translate('dropFilesHere')}
                clickToViewDocumentLabel={translate('clickToViewDocumentLabel')}
                name={`attachments.${index}`}
                onViewDocument={(fileId: string) => viewFile(fileId, `attachments.${index}`, getValues)}
                onUploadFile={(file) => uploadUnassignedAsset(file, `attachments.${index}`, setValue)}
                errors={errors?.[`attachments.${index}`]}
                onDelete={() => remove(index)}
                isEditMode
              />
            );
          })}
          {fields.length < 3 && (
            <AddAttachmentButton onClick={() => append({ fileId: null, url: null })}>
              <AddAttachments />
            </AddAttachmentButton>
          )}
        </AttachmentsContent>
      </AttachmentsWrapper>
      <ActionContainer>
        <Button text={translate('cancel')} onClick={onCancel} buttonType={'secondary'} />
        <Button
          text={translate('send')}
          onClick={onSend}
          buttonType={'primary'}
          isLoading={false}
          disabled={!isValid || !isEmptyText}
        />
      </ActionContainer>
    </>
  );
};

export const SendDirectEmail: FC<Props> = ({ onClose }) => {
  const { translate } = useTranslation();
  return (
    <SendDirectEmailWrapper>
      <Text content={translate('sendDirectEmail')} size={'large'} />
      <ControlledForm schema={directEmailSchema(translate)}>
        <SendDirectEmailForm onClose={onClose} />
      </ControlledForm>
    </SendDirectEmailWrapper>
  );
};
