import { BuildType } from '@/shared/types/redux/ReduxAction';

export const assetAssignedUrl = (build: BuildType) => {
  return build.mutation({
    query: ({ assetId }) => ({
      url: 'assets/assigned-url',
      method: 'POST',
      body: { assetId },
    }),
  });
};
