import { DateInput } from 'issuix-ui-kit';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface ControllerDateInputProps {
  name: string;
  disabled?: boolean;
  placeholder: string;
  id?: string;
  maxDate?: Date;
  minDate?: Date;
}

const ControlledDateInput: FC<ControllerDateInputProps> = ({
  name = '',
  disabled = false,
  placeholder,
  maxDate,
  minDate,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <DateInput
          name={name}
          errors={errors}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          maxDate={maxDate}
          minDate={minDate}
        />
      )}
    />
  );
};

export default ControlledDateInput;
