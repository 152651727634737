import { useWizardContext } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';
import styled from 'styled-components';

import { PaymentStepLayout } from '@/components/Payment/PaymentModal/PaymentModal.styles';
import CryptoPaymentDetails from '@/components/Payment/PaymentModal/Steps/ThirdStepPaymentMethod/Crypto/CyptoPaymentOption/CryptoPaymentOption';
import ThirdStepPaymentMethodBank from '@/components/Payment/PaymentModal/Steps/ThirdStepPaymentMethod/NonCrypto/ThirdStepPaymentMethodBank';
import ThirdStepPaymentMethodNonCrypto from '@/components/Payment/PaymentModal/Steps/ThirdStepPaymentMethod/NonCrypto/ThirdStepPaymentMethodNonCrypto';
import { usePaymentContext } from '@/components/Payment/PaymentModal/context/PaymentContext';
import { TENANT } from '@/enums/App';
import { getTenant } from '@/utils/getTenant/getTenant';

const ThirdStepPaymentContainer = styled(PaymentStepLayout)`
  display: grid;
  grid-template-rows: 5rem repeat(2, minmax(6rem, max-content));
  grid-template-columns: 1fr 1fr 1fr;
`;

const ThirdStepPaymentMethod: FC = () => {
  const { isCrypto } = usePaymentContext();
  const tenant = getTenant();
  const {
    ejected: { goToPreviousPage, setOnBackClickHandler },
  } = useWizardContext();

  useEffect(() => {
    setOnBackClickHandler(() => {
      goToPreviousPage();
    });
  }, []);

  const renderPaymentMethods = () => {
    if (tenant === TENANT.GREEN_STATE) {
      return <ThirdStepPaymentMethodBank />;
    } else {
      return isCrypto ? <CryptoPaymentDetails /> : <ThirdStepPaymentMethodNonCrypto />;
    }
  };

  return <ThirdStepPaymentContainer>{renderPaymentMethods()}</ThirdStepPaymentContainer>;
};

export default ThirdStepPaymentMethod;
