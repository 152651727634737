import { Text } from 'issuix-ui-kit';
import { FC } from 'react';
import styled from 'styled-components';

import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { getTenant } from '@/utils/getTenant/getTenant';

const CopyRightsBox: FC = () => {
  const { translate } = useTranslation();
  const tenant = getTenant();

  return (
    <CopyRightsTextBox>
      <Text size="extraSmall" content={translate('copyRights', { tenant: tenant.toUpperCase() })} />
    </CopyRightsTextBox>
  );
};

export default CopyRightsBox;

const CopyRightsTextBox = styled.div`
  width: 20rem;
  text-align: center;
  right: 100%;
  margin-top: auto;
`;
