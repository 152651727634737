import { Button, Text } from 'issuix-ui-kit';
import { FC, useState } from 'react';

import ControlledForm from '@/components/Form/ControlledForm';
import {
  EditButtonContainer,
  SmartShareFeaturesContainer,
} from '@/components/SmartShareFeatures/SmartShareFeatures.style';
import { smartShareFormSchema } from '@/components/SmartShareFeatures/UpdateSmartShareSchema';
import SmartShareFeaturesForm from '@/components/SmartShareFeatures/components/SmartShareFeaturesForm';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { ShareResponse, AssetResponse } from '@/shared/types/api';

interface SmartShareFeaturesProps {
  share: ShareResponse;
  assets: AssetResponse[];
}

const SmartShareFeatures: FC<SmartShareFeaturesProps> = ({ share, assets }) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { translate } = useTranslation();

  return (
    <SmartShareFeaturesContainer>
      <Text content={translate('smartShareFeatures')} size={'large'} />
      <EditButtonContainer>
        {!isEditMode && <Button onClick={() => setIsEditMode(!isEditMode)} text={translate('edit')} />}
      </EditButtonContainer>
      <ControlledForm schema={smartShareFormSchema(translate, share)}>
        <SmartShareFeaturesForm share={share} isEditMode={isEditMode} assets={assets} setIsEditMode={setIsEditMode} />
      </ControlledForm>
    </SmartShareFeaturesContainer>
  );
};

export default SmartShareFeatures;
