import { useWizardContext } from 'issuix-ui-kit';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { FormCol, UploadImageWrapper, UploadZone, Wallets } from '../../ThreeStep.styles';

import { useRegistrationContext } from '@/components/AuthModal/RegistrationContext';
import ControlledImageUpload from '@/components/Form/ControlledImageUpload';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledUpload from '@/components/Form/ControlledUpload';
import { ASSET_CAMEL_CASE_TYPE_ENUM } from '@/enums/AssetTypeEnum';
import { useBusinessFocus } from '@/hooks/useBusinessFocus/useBusinessFocus';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useUploadFile } from '@/hooks/useUploadFile/useUploadFile';
import { RegistrationThirdStepState } from '@/shared/types/AuthTypes';
import { useUnassignedAssetUrlMutation } from '@/store/service';
import { mapFieldValuesToThirdStepRegisterData } from '@/utils/register/mapFieldValuesToThirdStepRegisterData';
import { setFieldValues } from '@/utils/setFieldValues/setFieldValues';

const ThirdStepCorporateForm = () => {
  const { translate } = useTranslation();
  const {
    ejected: { goToNextPage, setOnNextClickHandler, goToPreviousPage, setOnBackClickHandler },
    modifyNextButtonProps,
    modifyBackButtonProps,
  } = useWizardContext();
  const { submitThirdStepData, thirdStepData } = useRegistrationContext();
  const {
    getValues,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useFormContext();
  const { uploadUnassignedAsset, viewFile } = useUploadFile();
  const [getSignedUrl, { data: imageUrl }] = useUnassignedAssetUrlMutation();

  const { businessFocusAsDropdown } = useBusinessFocus(translate);

  useEffect(() => {
    if (thirdStepData) {
      setFieldValues<RegistrationThirdStepState>(thirdStepData, setValue);
      if (thirdStepData.image && typeof thirdStepData.image !== 'string') {
        getSignedUrl({ assetId: thirdStepData.image?.fileId });
      }
    }
  }, []);

  const watchFields = (fieldNames: string[]) => {
    return watch(fieldNames);
  };

  const valueWatcher = watchFields(['commercialRegister', 'proofOfDomicile', 'signature']);

  const fetchData = () => {
    submitThirdStepData(
      mapFieldValuesToThirdStepRegisterData(getValues(), [
        ASSET_CAMEL_CASE_TYPE_ENUM.PROOF_OF_DOMICILE,
        ASSET_CAMEL_CASE_TYPE_ENUM.SIGNATURE,
        ASSET_CAMEL_CASE_TYPE_ENUM.IMAGE,
        ASSET_CAMEL_CASE_TYPE_ENUM.COMMERCIAL_REGISTER,
      ]),
      true
    );
    goToNextPage();
  };

  const goBack = () => {
    submitThirdStepData(getValues(), false);
    goToPreviousPage();
  };

  useEffect(() => {
    setOnNextClickHandler(() => {
      fetchData();
    });
    setOnBackClickHandler(() => {
      goBack();
    });
  }, []);

  const uploadImageHandler = async (image: any) => {
    await uploadUnassignedAsset(image, 'image', setValue);
    getSignedUrl({ assetId: getValues('image').fileId });
  };

  useEffect(() => {
    modifyNextButtonProps({
      disabled: !isValid || valueWatcher[0] === '' || valueWatcher[1] === '' || valueWatcher[2] === '',
      label: translate('next'),
    });
  }, [isValid]);

  useEffect(() => {
    modifyBackButtonProps({ label: translate('back') });
  }, []);

  return (
    <ThirdStepContainer>
      <Wallets>
        <ControlledInput placeholder={translate('ethWallet')} id="ethWallet" name="ethWallet" />
        <ControlledInput placeholder={translate('btcWallet')} id="btcWallet" name="btcWallet" />
        <StyledControlledSelect
          name="businessFocusId"
          items={businessFocusAsDropdown}
          placeholder={translate('businessFocus')}
        />
      </Wallets>
      <UploadZone>
        <FormCol>
          <UploadImageWrapper>
            <ControlledImageUpload
              name={ASSET_CAMEL_CASE_TYPE_ENUM.IMAGE}
              onUploadFile={(image) => uploadImageHandler(image)}
              edit
              errors={errors}
              uploadImageText={imageUrl?.url ? '' : translate('addYourImage')}
              maxImageSizeText={imageUrl?.url ? '' : translate('maxSizeIs2')}
              imageSrc={imageUrl?.url}
            />
          </UploadImageWrapper>
          <ControlledUpload
            placeholder={translate('signature')}
            incorrectFileFormatLabel={translate('incorrectFileFormat')}
            dragDropFilesHereLabel={translate('dropFilesHere')}
            clickToViewDocumentLabel={translate('clickToViewDocumentLabel')}
            name={ASSET_CAMEL_CASE_TYPE_ENUM.SIGNATURE}
            onViewDocument={(file) => viewFile(file, ASSET_CAMEL_CASE_TYPE_ENUM.SIGNATURE, getValues)}
            onUploadFile={(file) => uploadUnassignedAsset(file, ASSET_CAMEL_CASE_TYPE_ENUM.SIGNATURE, setValue)}
            errors={errors?.signature}
            onDelete={(name: string) => setValue(name, '', { shouldDirty: true, shouldValidate: true })}
            isEditMode
          />
        </FormCol>
        <FormCol>
          <ControlledUpload
            placeholder={translate('commercialRegister')}
            incorrectFileFormatLabel={translate('incorrectFileFormat')}
            dragDropFilesHereLabel={translate('dropFilesHere')}
            clickToViewDocumentLabel={translate('clickToViewDocumentLabel')}
            name={ASSET_CAMEL_CASE_TYPE_ENUM.COMMERCIAL_REGISTER}
            onViewDocument={(fileId: string) =>
              viewFile(fileId, ASSET_CAMEL_CASE_TYPE_ENUM.COMMERCIAL_REGISTER, getValues)
            }
            onUploadFile={(file) =>
              uploadUnassignedAsset(file, ASSET_CAMEL_CASE_TYPE_ENUM.COMMERCIAL_REGISTER, setValue)
            }
            errors={errors?.commercialRegister}
            onDelete={(name: string) => setValue(name, '', { shouldDirty: true, shouldValidate: true })}
            isEditMode
          />
          <ControlledUpload
            placeholder={translate('proofOfDomicile')}
            incorrectFileFormatLabel={translate('incorrectFileFormat')}
            dragDropFilesHereLabel={translate('dropFilesHere')}
            clickToViewDocumentLabel={translate('clickToViewDocumentLabel')}
            name={ASSET_CAMEL_CASE_TYPE_ENUM.PROOF_OF_DOMICILE}
            onViewDocument={(fileId: string) =>
              viewFile(fileId, ASSET_CAMEL_CASE_TYPE_ENUM.PROOF_OF_DOMICILE, getValues)
            }
            onUploadFile={(file) => uploadUnassignedAsset(file, ASSET_CAMEL_CASE_TYPE_ENUM.PROOF_OF_DOMICILE, setValue)}
            errors={errors?.proofOfDomicile}
            onDelete={(name: string) => setValue(name, '', { shouldDirty: true, shouldValidate: true })}
            isEditMode
          />
        </FormCol>
      </UploadZone>
    </ThirdStepContainer>
  );
};

export default ThirdStepCorporateForm;

const ThirdStepContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledControlledSelect = styled(ControlledSelect)`
  z-index: 1000;
`;
