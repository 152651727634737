import { FullRegistrationCorporateUserRequest } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const registrationPath = 'auth/corporate/full-signup';

export const fullRegistrationCorporateUser = (build: BuildType) => {
  return build.mutation<void, FullRegistrationCorporateUserRequest>({
    query: (data: FullRegistrationCorporateUserRequest) => ({
      url: registrationPath,
      method: 'POST',
      body: data,
    }),
  });
};
