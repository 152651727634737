import { useAppSelector } from '../useAppSelector/UseAppSelector';

import { getUserSelector, isAuthenticatedSelector } from '@/store/global/selectors';

export const useAuth = () => {
  const user = useAppSelector((state) => getUserSelector(state));
  const isAuthenticated = useAppSelector((state) => isAuthenticatedSelector(state));

  return {
    user,
    isAuthenticated,
  };
};
