import { TFunction } from 'i18next';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Column } from '../../TransactionSingleView.styles';

import ControlledInput from '@/components/Form/ControlledInput';
import { Row } from '@/components/Layout/Row/Row.styled';
import { PRODUCT_TYPE_ENUM } from '@/enums/ProductType';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { BondTransactionResponse, OrderResponse, ShareTransactionResponse } from '@/shared/types/api';
import { getTransactionInfoFieldValues } from '@/utils/getTransactionFieldValues/getTransactionFieldValues';
import { setFieldValues } from '@/utils/setFieldValues/setFieldValues';

interface TransactionInfoFormProps {
  transaction: BondTransactionResponse | ShareTransactionResponse;
  order?: OrderResponse;
  type: string;
}

const getPlaceholderValue = (type: string, translate: TFunction): string => {
  switch (type) {
    case PRODUCT_TYPE_ENUM.BOND_PAPERS:
      return translate('smartBondCode');
    case PRODUCT_TYPE_ENUM.ACTIONS:
      return translate('smartShareCode');
    case PRODUCT_TYPE_ENUM.NFT:
      return translate('serialNumber');
    default:
      return '';
  }
};

const TransactionInfoForm: FC<TransactionInfoFormProps> = ({ transaction, type, order }) => {
  const { translate } = useTranslation();

  const { setValue } = useFormContext();

  useEffect(() => {
    if (transaction) {
      setFieldValues(
        getTransactionInfoFieldValues({ ...transaction, deliveryAddress: order?.deliveryAddress || '' }, translate),
        setValue
      );
    }
  }, [transaction, order]);

  return (
    <TransactionInfoFormContainer>
      <Row>
        <ControlledInput name={'amount'} placeholder={translate('amountChf')} id={'amount'} disabled={true} />
        <ControlledInput
          name={'detectedAmount'}
          placeholder={translate('detectedAmount')}
          id={'detectedAmount'}
          disabled={true}
        />
        <ControlledInput name={'quantity'} placeholder={translate('quantity')} id={'quantity'} disabled={true} />
      </Row>
      <Row>
        <Column>
          <ControlledInput
            name={'smartCode'}
            placeholder={getPlaceholderValue(type, translate)}
            id={'smartCode'}
            disabled={true}
          />
        </Column>
        <Column>
          <ControlledInput name={'status'} placeholder={translate('status')} id={'status'} disabled={true} />
        </Column>
        {type === PRODUCT_TYPE_ENUM.ACTIONS && (
          <Column>
            <ControlledInput name={'round'} placeholder={translate('round')} id={'round'} disabled={true} />
          </Column>
        )}
        {type === PRODUCT_TYPE_ENUM.NFT && (
          <Column>
            <ControlledInput
              name={'deliveryAddress'}
              placeholder={translate('delivery')}
              id={'deliveryAddress'}
              disabled={true}
            />
          </Column>
        )}
      </Row>
    </TransactionInfoFormContainer>
  );
};

export default TransactionInfoForm;

const TransactionInfoFormContainer = styled.div`
  position: relative;
  padding: 2rem;
  margin-top: 2rem;
  gap: 3rem;
`;
