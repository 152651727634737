import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const createSmartShareSchema = (translate: TFunction) =>
  Yup.object().shape({
    price: Yup.string()
      .matches(/^\d+(\.\d{1,2})?$/, translate('digits'))
      .required(translate('required')),
    totalAmount: Yup.string()
      .matches(/^[0-9]+$/, translate('digits'))
      .required(translate('required')),
    availableForSale: Yup.string()
      .matches(/^[0-9]+$/, translate('digits'))
      .required(translate('required')),
    shareCapital: Yup.string()
      .matches(/^\d+(\.\d{1,2})?$/, translate('digits'))
      .required(translate('required')),
    type: Yup.string().required(translate('required')),
    round: Yup.string().required(translate('required')),
    roundDuration: Yup.string().required(translate('required')),
    issuedDate: Yup.string().required(translate('required')),
    minimalInvestment: Yup.string()
      .matches(/^[0-9]+$/, translate('digits'))
      .required(translate('required')),
    bankName: Yup.string().required(translate('required')),
    bankAddress: Yup.string().required(translate('required')),
    iban: Yup.string().required(translate('required')),
    swift: Yup.string().required(translate('required')),
    sellInCurrencyIds: Yup.array().of(Yup.string()).nullable().min(1).required(translate('required')),
    isin: Yup.string(),
    regionIds: Yup.array().of(Yup.string()).nullable().min(1).required(translate('required')),
  });
