import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { PAGES } from '@/enums/Pages';

export const useGetCurrentPage = () => {
  const [currentPage, setCurrentPage] = useState<string>();
  const location = useLocation();

  useEffect(() => {
    location.pathname === PAGES.ROOT && setCurrentPage(PAGES.ROOT);
    location.pathname.split('/')[1] === PAGES.BOND_DASHBOARD && setCurrentPage(PAGES.BOND_DASHBOARD);
    location.pathname.split('/')[1] === PAGES.SMART_SHARES_LISTING && setCurrentPage(PAGES.SMART_SHARES_LISTING);
    location.pathname.split('/')[1] === PAGES.SMART_BONDS_LISTING && setCurrentPage(PAGES.SMART_BONDS_LISTING);
    location.pathname.split('/')[1] === PAGES.SMART_SHARE_CREATION && setCurrentPage(PAGES.SMART_SHARE_CREATION);
    location.pathname.split('/')[1] === PAGES.SMART_BOND_CREATION && setCurrentPage(PAGES.SMART_BOND_CREATION);
    location.pathname.split('/')[1] === PAGES.SMART_BONDS_PENDING && setCurrentPage(PAGES.SMART_BONDS_PENDING);
    location.pathname.split('/')[1] === PAGES.SMART_SHARES_PENDING && setCurrentPage(PAGES.SMART_SHARES_PENDING);
    location.pathname.split('/')[1] === PAGES.USERS && setCurrentPage(PAGES.USERS);
    location.pathname.split('/')[1] === PAGES.REQUESTS && setCurrentPage(PAGES.REQUESTS);
    location.pathname.split('/')[1] === PAGES.TRANSACTIONS_TO_BUYERS && setCurrentPage(PAGES.TRANSACTIONS_TO_BUYERS);
    location.pathname.split('/')[1] === PAGES.TRANSACTIONS_FROM_BUYERS &&
      setCurrentPage(PAGES.TRANSACTIONS_FROM_BUYERS);
    location.pathname.split('/')[1] === PAGES.ACCOUNT && setCurrentPage(PAGES.ACCOUNT);
    location.pathname.split('/')[1] === PAGES.NFT_DASHBOARD && setCurrentPage(PAGES.NFT_DASHBOARD);
  }, [location]);

  return {
    currentPage,
  };
};
