import { Card, colors, GlobalSpinner } from 'issuix-ui-kit';
import { FC, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';

import CardHeader from '@/components/CardHeader/CardHeader';

interface PageProps {
  children: ReactElement;
}

const Page: FC<PageProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  return (
    <Content>
      <GlobalSpinner isActive={isLoading} background={colors.blue.primary}>
        <Card header={<CardHeader />}>{children}</Card>
      </GlobalSpinner>
    </Content>
  );
};

export default Page;

const Content = styled.div`
  min-width: calc(72vw - 60px);
  max-width: calc(72vw - 60px);
  border-radius: 3rem;
`;
