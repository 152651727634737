import { BondResponse } from '@/shared/types/api';

export const parseFormObjectAdditionalService = (response: BondResponse) => {
  const {
    insuranceOption,
    secondPartyOpinionOption,
    brokerageOption,
    advisoryOption,
    storageOption,
    underwritingOption,
  } = response;

  return {
    insuranceOption,
    secondPartyOpinionOption,
    brokerageOption,
    advisoryOption,
    storageOption,
    underwritingOption,
  };
};
