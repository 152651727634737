import { stringify } from 'qs';

import { pagingLimit } from '@/shared/constants/Paging';
import { PageableItems } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const getAllIssuedCouponsPath = '/coupons';

const getAllIssuedCouponsUrl = (
  offset: number,
  query: { smartBondCode: string; priceMinimum: number; priceMaximum: number; currency: string }
) => `${getAllIssuedCouponsPath}?offset=${offset}&limit=${pagingLimit}&${stringify(query)}`;

export const getAllIssuedCoupons = (build: BuildType) => {
  return build.query<PageableItems, any>({
    providesTags: ['GetCoupons', 'GetAllIssuedCoupons'],
    query: ({ offset, query }) => ({
      url: getAllIssuedCouponsUrl(offset, query),
      method: 'GET',
    }),
  });
};
