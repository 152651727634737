import { stringify } from 'qs';

import { pagingLimit } from '@/shared/constants/Paging';
import { PageableItems } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const getAllTransactionsPath = '/transactions';

const getAllTransactionsUrl = (offset: number, query: { transactionsForReview: string; referenceNumber: string }) =>
  `${getAllTransactionsPath}?offset=${offset}&limit=${pagingLimit}&${stringify(query)}`;

export const getAllTransactions = (build: BuildType) => {
  return build.query<PageableItems, any>({
    query: ({ offset, query }) => ({
      url: getAllTransactionsUrl(offset, query),
      method: 'GET',
    }),
  });
};
