import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const sendNotificationModalSchema = (translate: TFunction) =>
  Yup.object().shape({
    titleEn: Yup.string().required(translate('required')),
    titleDe: Yup.string().required(translate('required')),
    contentEn: Yup.string().required(translate('required')),
    contentDe: Yup.string().required(translate('required')),
  });
