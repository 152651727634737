import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Text, useWizardContext } from 'issuix-ui-kit';
import { FC, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { Cards } from '@/assets/core/icons';
import {
  HeaderContainer,
  CardElementsContainer,
  InputWrapper,
  TextWrapper,
  cardNumberOptions,
  cardExpiryOptions,
  cardCvcOptions,
  SupportedCardsContainer,
  Container,
} from '@/components/Payment/PaymentModal/Steps/ThirdStepPaymentMethod/NonCrypto/StripePaymentOption/StripeCardDetails.styles';
import { PaymentError } from '@/components/Payment/PaymentModal/Steps/ThirdStepPaymentMethod/PaymentError';
import { usePaymentContext } from '@/components/Payment/PaymentModal/context/PaymentContext';
import { PRODUCT_TYPE_ENUM } from '@/enums/ProductType';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { usePayViaCardMutation } from '@/store/service';

const StripeCardDetails: FC = () => {
  const {
    ejected: { setOnNextClickHandler, goToNextPage },
    modifyNextButtonProps,
  } = useWizardContext();
  const stripe = useStripe();
  const elements = useElements();
  const [payViaCard] = usePayViaCardMutation();
  const { currency, resource, quantity, resourceType } = usePaymentContext();
  const [errorMessage, setErrorMessage] = useState('');
  const { translate } = useTranslation();
  const { colors } = useTheme();

  const [isCompleteNumber, setIsCompleteNumber] = useState<boolean>(false);
  const [isCompleteExpiry, setIsCompleteExpiry] = useState<boolean>(false);
  const [isCompleteCvc, setIsCompleteCvc] = useState<boolean>(false);

  useEffect(() => {
    const isCardDataValid = isCompleteNumber && isCompleteExpiry && isCompleteCvc;

    if (isCardDataValid) {
      modifyNextButtonProps({ disabled: false });
    } else {
      modifyNextButtonProps({ disabled: true });
    }
  }, [isCompleteNumber, isCompleteCvc, isCompleteExpiry]);

  useEffect(() => {
    setOnNextClickHandler(() => {
      const cardNumberStripeElement = elements?.getElement(CardNumberElement);

      if (!cardNumberStripeElement) {
        setErrorMessage(translate('paymentStripeFormError'));
        return;
      }

      modifyNextButtonProps({ loading: true });
      setErrorMessage('');

      stripe
        ?.createPaymentMethod({
          type: 'card',
          card: cardNumberStripeElement,
        })
        .then((paymentMethodResult) => {
          if (!currency || !resource || !paymentMethodResult.paymentMethod || !resourceType) {
            setErrorMessage(translate('paymentFormError'));
            return;
          }

          return payViaCard({
            currencyId: currency.id,
            itemId: resource.id,
            paymentMethod: 'STRIPE',
            productType: resourceType === PRODUCT_TYPE_ENUM.BOND_PAPERS ? 'BOND_PAPERS' : 'ACTIONS',
            quantity: quantity,
            stripePaymentMethod: paymentMethodResult.paymentMethod.id,
          });
        })
        .then((result) => {
          modifyNextButtonProps({ loading: false });

          if (result?.error?.status >= 400) {
            setErrorMessage(translate('genericPaymentError'));
            return;
          }

          goToNextPage();
        })
        .catch(() => {
          setErrorMessage(translate('genericPaymentError'));
          modifyNextButtonProps({ loading: false });
        });
    });
  }, [elements, currency, resource, resourceType, quantity]);

  return (
    <Container>
      <HeaderContainer>
        <Text size="extraLarge" content={translate('pleaseCompleteTheForm')} />
      </HeaderContainer>
      <CardElementsContainer>
        <InputWrapper flexGrow={1}>
          <TextWrapper>
            <Text color={colors.green.primary} size="medium" content={translate('creditCardNumber')} />
          </TextWrapper>
          <CardNumberElement options={cardNumberOptions} onChange={(e) => setIsCompleteNumber(e.complete)} />
        </InputWrapper>
        <InputWrapper>
          <TextWrapper>
            <Text color={colors.green.primary} size="medium" content={translate('expirationDate')} />
          </TextWrapper>
          <CardExpiryElement options={cardExpiryOptions} onChange={(e) => setIsCompleteExpiry(e.complete)} />
        </InputWrapper>
        <InputWrapper>
          <TextWrapper>
            <Text color={colors.green.primary} size="medium" content={translate('cvv')} />
          </TextWrapper>
          <CardCvcElement options={cardCvcOptions} onChange={(e) => setIsCompleteCvc(e.complete)} />
        </InputWrapper>
      </CardElementsContainer>
      <SupportedCardsContainer>
        <Text color={colors.green.primary} size="small" content={translate('supportedCards')} />
        <Cards />
      </SupportedCardsContainer>
      {errorMessage ? <PaymentError message={errorMessage} color={colors.red.error} /> : null}
    </Container>
  );
};

export default StripeCardDetails;
