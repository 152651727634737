export enum PURCHASE_DETAILS_FORM_ENUM {
  ISSUER = 'issuer',
  CODE = 'code',
  MATURITY = 'maturity',
  INTEREST = 'interest',
  COUPONS_PAID = 'couponsPaid',
  QUANTITY = 'quantity',
  BUYER_NAME = 'name',
  BUYER_LAST_NAME = 'lastName',
  BUYER_ADDRESS = 'address',
}
