import styled from 'styled-components';

export const FormCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5rem;
  width: 70%;
`;

export const UploadZone = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5rem;
  width: 100%;
`;

export const Wallets = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 5rem;
  width: 100%;
`;

export const UploadImageWrapper = styled.div`
  align-self: flex-start;
`;

export const SelectWrapper = styled.div`
  padding-top: 2.5rem;
`;
