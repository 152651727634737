import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseUrl = 'audits';
const getChartDataUrl = (query: { auditSource: string; shareId: string; fromDate: string; toDate: string }) =>
  `${baseUrl}/chart?auditSource=${query.auditSource}&shareId=${query.shareId}&fromDate=${query.fromDate}&toDate=${query.toDate}`;

export const getChartData = (build: BuildType) => {
  return build.query({
    query: ({ query }) => ({
      url: getChartDataUrl(query),
      method: 'GET',
    }),
    providesTags: ['GetChartData'],
  });
};
