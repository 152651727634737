import { Card, Text } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import ControlledInput from '../Form/ControlledInput';
import { Row } from '../Layout/Row/Row.styled';

import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { BondResponse } from '@/shared/types/api';
import { parseFromObjectPaymentInfo } from '@/utils/getPaymentFieldValues/GetPaymentFieldValues';
import { setFieldValues } from '@/utils/setFieldValues/setFieldValues';

interface PaymentInfoProps {
  bond?: BondResponse;
  isView?: boolean;
}
const PaymentInfo: FC<PaymentInfoProps> = ({ bond, isView }) => {
  const { translate } = useTranslation();
  const { setValue } = useFormContext();

  useEffect(() => {
    if (bond) {
      setFieldValues(parseFromObjectPaymentInfo(bond), setValue);
    }
  }, [bond]);

  return (
    <PaymentInfoBox>
      <Text size={'large'} content={translate('paymentInfo')} />
      <Row>
        <ControlledInput disabled={isView} name={'bankName'} placeholder={translate('bankName')} id={'bankName'} />

        <ControlledInput
          disabled={isView}
          name={'bankAddress'}
          placeholder={translate('bankAddress')}
          id={'bankAddress'}
        />

        <ControlledInput disabled={isView} name={'iban'} placeholder={translate('iban')} id={'iban'} />

        <ControlledInput disabled={isView} name={'swift'} placeholder={translate('swift').toUpperCase()} id={'swift'} />
      </Row>
    </PaymentInfoBox>
  );
};

export default PaymentInfo;

const PaymentInfoBox = styled(Card)`
  height: max-content !important;
  width: 100%;
  padding: 1rem 2rem;
  margin-top: 3rem;
`;
