import { RootState } from '@/store';

export const isLoadingSelector = (state: RootState) => state.global.isLoading;

export const isErrorSelector = (state: RootState) => state.global.isError;

export const getUserSelector = (state: RootState) => state.global.user;

export const isAuthenticatedSelector = (state: RootState) => !!state.global.user;

export const getResendTokenSelector = (state: RootState) => state.global.resendToken;

export const isTenantErrorSelector = (state: RootState) => state.global.isTenantError;

export const getSelectedCurrency = (state: RootState) => state.global.currency;

export const getSelectedLanguage = (state: RootState) => state.global.language;

export const isServerErrorSelector = (state: RootState) => state.global.isServerError;
