import { GreenStateTheme, MetaSwissTheme, useWizardContext } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { FormCol, FormRow } from '../../ThreeStep.styles';

import { useRegistrationContext } from '@/components/AuthModal/RegistrationContext';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledPassword from '@/components/Form/ControlledPassword';
import ControlledSelect from '@/components/Form/ControlledSelect';
import { TENANT } from '@/enums/App';
import { useCheckCorporateNameOnBlur } from '@/hooks/useCheckCorporateNameOnBlur/useCheckCorporateNameOnBlur';
import { useCheckEmailOnBlur } from '@/hooks/useCheckEmailOnBlur/useCheckEmailOnBlur';
import { useCountries } from '@/hooks/useCountries/UseCountries';
import { useLegalForms } from '@/hooks/useLegalForms/useLegalForms';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { RegistrationSecondStepState } from '@/shared/types/AuthTypes';
import { getTenant } from '@/utils/getTenant/getTenant';
import { setFieldValues } from '@/utils/setFieldValues/setFieldValues';

const SecondStepCorporateForm: FC = () => {
  const { translate } = useTranslation();
  const {
    ejected: { goToNextPage, setOnNextClickHandler, goToPreviousPage, setOnBackClickHandler },
    modifyNextButtonProps,
    modifyBackButtonProps,
  } = useWizardContext();

  const { submitSecondStepData, secondStepData } = useRegistrationContext();

  const { countriesAsDropdown } = useCountries();

  const { legalFormsAsDropdown } = useLegalForms(translate);

  const { checkEmailOnBlur, isEmailExist } = useCheckEmailOnBlur();
  const { checkCorporateNameOnBlur, isCorporateNameExist } = useCheckCorporateNameOnBlur();

  const {
    getValues,
    formState: { isValid },
    setValue,
  } = useFormContext();

  useEffect(() => {
    if (secondStepData) {
      setFieldValues<RegistrationSecondStepState>(secondStepData, setValue);
    }
  }, []);

  useEffect(() => {
    modifyNextButtonProps({ disabled: !isValid || isEmailExist, label: translate('next') });
  }, [isValid, isEmailExist]);

  useEffect(() => {
    modifyBackButtonProps({ label: translate('back') });
  }, []);

  const fetchData = () => {
    submitSecondStepData(getValues());
    goToNextPage();
  };

  const goBack = () => {
    submitSecondStepData(getValues());
    goToPreviousPage();
  };

  useEffect(() => {
    setOnNextClickHandler(() => {
      fetchData();
    });
    setOnBackClickHandler(() => {
      goBack();
    });
  }, []);

  return (
    <>
      <FormRow>
        <FormCol>
          <ControlledInput
            placeholder={translate('corporateName')}
            id="name"
            name="name"
            onBlur={() => checkCorporateNameOnBlur(getValues().name)}
          />
          <ControlledPassword placeholder={translate('password')} id="password" name="password" />
          <ControlledInput placeholder={translate('address')} id="address" name="address" />
          <ControlledInput placeholder={translate('zipCode')} id="zipCode" name="zipCode" />
          <ControlledInput placeholder={translate('ceo')} id="ceo" name="ceo" />
          <ControlledInput
            placeholder={translate('emailAddress')}
            id="email"
            name="email"
            onBlur={() => checkEmailOnBlur(getValues().email)}
          />
        </FormCol>
        <FormCol>
          <StyledControlledSelect
            name="legalFormId"
            items={legalFormsAsDropdown}
            placeholder={translate('legalForm')}
          />
          <ControlledPassword
            placeholder={translate('confirmPassword')}
            id="passwordConfirmation"
            name="passwordConfirmation"
          />
          <ControlledInput placeholder={translate('city')} id="city" name="city" />
          <ControlledSelect name="countryId" items={countriesAsDropdown} placeholder={translate('country')} />
          <ControlledInput placeholder={translate('owner')} id="owner" name="owner" />
          <ControlledInput placeholder={translate('phoneNumber')} id="phoneNumber" name="phoneNumber" />
        </FormCol>
      </FormRow>
      {isEmailExist && <ErrorMessage>{translate('emailExist')}</ErrorMessage>}
      {isCorporateNameExist && <ErrorMessage>{translate('corporateNameExist')}</ErrorMessage>}
    </>
  );
};

export default SecondStepCorporateForm;

const ErrorMessage = styled.p`
  color: ${getTenant() === TENANT.GREEN_STATE ? GreenStateTheme.colors.red.error : MetaSwissTheme.colors.red.error};
  font-size: 1.2rem;
  position: relative;
`;

const StyledControlledSelect = styled(ControlledSelect)`
  z-index: 1000;
`;
