import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const shareSlice = createSlice({
  name: 'share',
  initialState,
  reducers: {},
});

export default shareSlice.reducer;
