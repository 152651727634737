import { IColumn } from 'issuix-ui-kit';
import { ReactElement } from 'react';

export const renderColumn = <T>(
  name: string,
  width: number,
  label?: string,
  renderFunction?: (item: T) => ReactElement
): IColumn => {
  const column: IColumn = {
    name: name,
    width: width,
    header: {
      label: label,
    },
    column: {
      render: renderFunction,
    },
  };
  return column;
};
