import { BondCalculationResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const bondCalculations = (query: { id: string }) => `calculations/bond/?userId=${query.id}`;

export const getBondCalculations = (build: BuildType) => {
  return build.query<BondCalculationResponse, any>({
    query: ({ query }) => ({
      url: bondCalculations(query),
      method: 'GET',
    }),
  });
};
