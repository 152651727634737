import { pagingLimit } from '@/shared/constants/Paging';
import { PageableItems } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const getUserPortfolioPath = '/portfolios';

const getUserPortfolioUrl = (offset: number, query: { id: string; additionalId: string }) =>
  `${getUserPortfolioPath}/?productType=${query.id}&userId=${query.additionalId}&offset=${offset}&limit=${pagingLimit}`;

export const getUserPortfolio = (build: BuildType) => {
  return build.query<PageableItems, any>({
    query: ({ offset, query }) => ({
      url: getUserPortfolioUrl(offset, query),
      method: 'GET',
    }),
  });
};
