import React, { FC, createContext, useContext } from 'react';

import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { PageableItems } from '@/shared/types/api';
import { useGetUsersQuery } from '@/store/service';

interface IUsersRequestsContext {
  data: (string | PageableItems)[];
  fetch: () => void;
  setFilters: (filters: { [x: string]: any } | undefined) => void;
  resetData: () => void;
}

interface UsersContextProviderProps {
  children: React.ReactNode;
}

export const UsersContext = createContext({} as IUsersRequestsContext);

export const useUsersContext = () => useContext(UsersContext);

export const UsersContextProvider: FC<UsersContextProviderProps> = ({ children }) => {
  const { fetch, resetData, data, setFilters } = useTableWithPagination({ query: useGetUsersQuery });

  return (
    <UsersContext.Provider
      value={{
        data,
        fetch,
        setFilters,
        resetData,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
