export const createShareMapper = (share: any) => {
  return {
    price: share.price.toString(),
    sellInCurrencyIds: share.sellInCurrencyIds,
    totalAmount: +share.totalAmount,
    availableForSale: share.availableForSale,
    shareCapital: share.shareCapital,
    type: share.type,
    round: share.round,
    roundDuration: +share.roundDuration,
    minimalInvestment: +share.minimalInvestment,
    isin: share.isin,
    issuedDate: new Date(share.issuedDate).toString(),
    regionIds: share.regionIds,
    bankName: share.bankName,
    bankAddress: share.bankAddress,
    iban: share.iban,
    swift: share.swift,
    businessPlan: share.businessPlan,
    presentation: share.presentation,
    secondPartyOpinion: share.secondPartyOpinion,
    evaluation: share.evaluation,
    onePager: share.onePager,
    whitePaper: share.whitePaper,
    secondPartyOpinionOption: share.secondPartyOpinionOption,
    brokerageOption: share.brokerageOption,
    advisoryOption: share.advisoryOption,
    storageOption: share.storageOption,
    underwritingOption: share.underwritingOption,
  };
};
