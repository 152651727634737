import { Card } from 'issuix-ui-kit';
import styled from 'styled-components';

export const SmartBondPortfolioCard = styled(Card)`
  height: 100%;
  width: 100%;
  padding: 3rem;
`;

export const SplitTableBlock = styled.div`
  width: 100%;
  height: 100%;
`;

export const SplitChartBlock = styled.div`
  width: 100%;
`;

export const TableRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const CalculationsBox = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 3rem;
  gap: 3rem;
  justify-content: center;
`;
