import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

interface Props {
  children: React.ReactNode;
  schema: any;
  defaultValues?: any;
}

const ControlledForm: FC<Props> = ({ children, schema, defaultValues }) => {
  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default ControlledForm;
