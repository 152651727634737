import { FC, useMemo } from 'react';

import { FEATURE_FLAGS, featuresMap } from '@/components/FeatureFlag/featureFlags';

type Props = {
  name: FEATURE_FLAGS;
  children: any;
  disabled?: boolean;
};

export const isFeatureEnabled = (name: FEATURE_FLAGS): boolean => {
  if (!featuresMap[name]) {
    return false;
  }

  return featuresMap[name];
};

export const FeatureFlag: FC<Props> = ({ name, children, disabled }) => {
  const isEnabled = useMemo(() => (name ? isFeatureEnabled(name) : false), [name]);

  if (!isEnabled || disabled) {
    return <></>;
  }

  return <>{children}</>;
};
