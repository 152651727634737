import { Text } from 'issuix-ui-kit';
import { FC } from 'react';

import ControlledForm from '@/components/Form/ControlledForm';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { AssetResponse, BondTransactionResponse, ShareTransactionResponse } from '@/shared/types/api';
import IssuerInfoForm from '@/view/Admin/TransactionSingleView/IssuerInfo/IssuerInfoForm/IssuerInfoForm';
import { issuerInfoFormSchema } from '@/view/Admin/TransactionSingleView/IssuerInfo/IssuerInfoForm/IssuerInfoFormSchema';
import {
  CardHeader,
  TransactionSingleViewContainer,
} from '@/view/Admin/TransactionSingleView/TransactionSingleView.styles';

interface IssuerInfoProps {
  transaction: BondTransactionResponse | ShareTransactionResponse;
  assets: AssetResponse[];
}

const IssuerInfo: FC<IssuerInfoProps> = ({ transaction, assets }) => {
  const { translate } = useTranslation();

  return (
    <TransactionSingleViewContainer>
      <CardHeader>
        <Text content={translate('issuer')} size={'large'} />
      </CardHeader>
      <ControlledForm schema={issuerInfoFormSchema}>
        <IssuerInfoForm transaction={transaction} assets={assets} />
      </ControlledForm>
    </TransactionSingleViewContainer>
  );
};

export default IssuerInfo;
