import * as yup from 'yup';

export const smartBondFeaturesSchema = () => {
  return yup.object().shape({
    value: yup.number(),
    currencies: yup.array(),
    denomination: yup.number(),
    interest: yup.number(),
    couponsPaid: yup.string(),
    offeringMonths: yup.number(),
    issuedDate: yup.date(),
    maturity: yup.number(),
    regions: yup.object(),
    listingOption: yup.boolean(),
  });
};
