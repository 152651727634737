import { useWizardContext } from 'issuix-ui-kit';
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { PaymentError } from '@/components/Payment/PaymentModal/Steps/ThirdStepPaymentMethod/PaymentError';
import { usePaymentContext } from '@/components/Payment/PaymentModal/context/PaymentContext';
import { PRODUCT_TYPE_ENUM } from '@/enums/ProductType';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { PaymentResponse } from '@/shared/types/api';
import { usePayViaBankTransferMutation } from '@/store/service';

interface PaymentError {
  error: {
    status: number;
  };
}

function isError(response: unknown): response is PaymentError {
  return typeof response === 'object' && response != null && 'error' in response && 'status' in (response as any).error;
}

export const BankPaymentOption = () => {
  const {
    modifyNextButtonProps,
    ejected: { setOnNextClickHandler, goToNextPage },
  } = useWizardContext();
  const [payViaBank] = usePayViaBankTransferMutation();
  const { currency, resource, quantity, resourceType, setTransactionId } = usePaymentContext();
  const [errorMessage, setErrorMessage] = useState('');
  const { translate } = useTranslation();
  const { colors } = useTheme();

  useEffect(() => {
    modifyNextButtonProps({ disabled: false });
    setOnNextClickHandler(() => {
      if (!resource || !currency || !resourceType) {
        setErrorMessage(translate('paymentFormError'));
        return;
      }

      modifyNextButtonProps({ loading: true });
      setErrorMessage('');

      payViaBank({
        currencyId: currency.id,
        itemId: resource.id,
        paymentMethod: 'BANK',
        productType: resourceType === PRODUCT_TYPE_ENUM.BOND_PAPERS ? 'BOND_PAPERS' : 'ACTIONS',
        quantity: quantity,
      })
        .then((response: { data: PaymentResponse } | PaymentError) => {
          modifyNextButtonProps({ loading: false });

          if (isError(response)) {
            setErrorMessage(translate('paymentFormError'));
            return;
          }

          setTransactionId(response.data.transactionId);

          goToNextPage();
        })
        .catch(() => {
          setErrorMessage(translate('genericPaymentError'));
          modifyNextButtonProps({ loading: false });
        });
    });
  }, [resource, currency, quantity, resourceType]);

  // Since we don't collect any info, this component has no UI (unless we have an error),
  // it instead serves as a way to handle the logic related to the Wizard
  return errorMessage ? <PaymentError message={errorMessage} color={colors.red.error} /> : null;
};
