export const composeDropdownOptions = <T>(
  items: T[],
  valueProp: keyof T,
  labelProp: keyof T,
  iconUrlProp?: keyof T
): {
  label: string;
  value: string;
  iconUrl?: string;
}[] => {
  return items.map((item) => {
    const mappedOptionItem = {
      value: item[valueProp] as unknown as string,
      label: item[labelProp] as unknown as string,
    };

    if (!iconUrlProp) {
      return mappedOptionItem;
    }

    return {
      ...mappedOptionItem,
      iconUrl: item[iconUrlProp] as unknown as string,
    };
  });
};
