import { Button, Card, Text } from 'issuix-ui-kit';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import CreateSmartBondFeatures from '../CreateSmartBondFeature';

import { Documentation } from '@/components/BondAndShare/Documentation';
import PaymentInfo from '@/components/BondAndShare/PaymentInfo';
import ControlledCheckbox from '@/components/Form/ControlledCheckbox';
import { Row } from '@/components/Layout/Row/Row.styled';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useEditEntity } from '@/hooks/useEditEntity/useEditEntity';
import { useNav } from '@/hooks/useNav/UseNav';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { InitialBond } from '@/shared/types/Bond';
import { CreateBondRequest } from '@/shared/types/api';
import { createBondMapper } from '@/store/bonds/CreateBondMapper';
import { getUserSelector } from '@/store/global/selectors';
import {
  useCreateBondMutation,
  useGetBondAssetsQuery,
  useGetBondByIdQuery,
  useUpdateBondMutation,
} from '@/store/service';
import { composeBondChildPath } from '@/utils/composePath/ComposeBondChildPath';
import { composePath } from '@/utils/composePath/ComposePath';
import { parseFormObjectAdditionalService } from '@/utils/getAdditionalServiceFieldValues/GetAdditionalServiceFieldValues';
import { parseFormAssetBondObject } from '@/utils/getBondFieldValues/GetAssetBondFieldValues';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';
import { setFieldValues } from '@/utils/setFieldValues/setFieldValues';

const CreateSmartBondForm = () => {
  const [resetForm, setResetForm] = useState<boolean>(false);
  const {
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { isValid, errors },
  } = useFormContext();
  const [updateBond] = useUpdateBondMutation();
  const { updateShareBond } = useEditEntity();
  const { translate } = useTranslation();
  const { location, params } = useNav();
  const navigate = useNavigate();
  const user = useAppSelector((state) => getUserSelector(state));

  const { data: bond } = useGetBondByIdQuery({ query: { id: params.id && params.id } }, { skip: !params.id });
  const { data: assets } = useGetBondAssetsQuery({ query: { id: params.id && params.id } }, { skip: !params?.id });

  const [createBond] = useCreateBondMutation();

  useEffect(() => {
    reset(InitialBond);
  }, [resetForm]);

  const onSubmitHandler = async () => {
    if (bond && isValid) {
      await updateShareBond({ bondId: bond.id }, updateBond);
      navigate(composePath(composeBondChildPath(ClientRoutes.Bonds.viewSmartBond), { id: bond.id }));
    }
  };

  useEffect(() => {
    if (assets) {
      setFieldValues(parseFormAssetBondObject(assets), setValue);
    }
  }, [assets]);

  useEffect(() => {
    if (bond) {
      setFieldValues(parseFormObjectAdditionalService({ ...bond }), setValue);
    }
  }, [bond]);

  const isEdit: boolean = location.pathname.includes('edit');

  const isView: boolean = location.pathname.includes('view-bond');

  const onCreateBond = () => {
    if (user?.email === 'office@nimbus-tech.io') {
      navigate(`/${ClientRoutes.Dashboard.bond}`);
    } else {
      navigate(`/${ClientRoutes.Bonds.root}`);
    }
  };

  return (
    <CreateBondFormContainer>
      <CreateSmartBondFeatures bond={bond} isView={isView} />
      <PaymentInfo bond={bond} isView={isView} />
      <Documentation assets={assets} setValue={setValue} getValues={getValues} isEditMode />
      <AdditionalServicesOfferedBox>
        <Text size={'large'} content={translate('additionalServices')} />
        <Row>
          <ControlledCheckbox label={translate('insuranceOption')} name="insuranceOption" />
          <ControlledCheckbox
            label={translate('secondPartyOpinionOption')}
            name="secondPartyOpinionOption"
            disabled={isView}
          />
          <ControlledCheckbox label={translate('brokerageOption')} name="brokerageOption" disabled={isView} />
          <ControlledCheckbox label={translate('advisoryOption')} name="advisoryOption" disabled={isView} />
          <ControlledCheckbox label={translate('storageOption')} name="storageOption" disabled={isView} />
          <ControlledCheckbox label={translate('underwritingOption')} name="underwritingOption" disabled={isView} />
          <>
            {isView ? (
              <></>
            ) : (
              <>
                {isEdit ? (
                  <ButtonBox>
                    <Button
                      buttonType={'secondary'}
                      onClick={() => setResetForm(!resetForm)}
                      text={translate('cancel')}
                      disabled={!isValid}
                    />
                    <Button onClick={onSubmitHandler} text={translate('edit')} disabled={!isValid} />
                  </ButtonBox>
                ) : (
                  <ButtonBox>
                    <Button
                      buttonType={'secondary'}
                      onClick={() => setResetForm(!resetForm)}
                      text={translate('cancel')}
                      disabled={!isValid || !!errors.isin || !!errors.denomination}
                    />
                    <Button
                      onClick={handleSubmit((data) => {
                        createBond(createBondMapper(data) as CreateBondRequest).then(onCreateBond());
                      })}
                      text={translate('create')}
                      disabled={!isValid || !!errors.isin || !!errors.denomination}
                    />
                  </ButtonBox>
                )}
              </>
            )}
          </>
        </Row>
      </AdditionalServicesOfferedBox>
    </CreateBondFormContainer>
  );
};

export default CreateSmartBondForm;

const CreateBondFormContainer = styled.div`
  gap: 3rem;
`;

const AdditionalServicesOfferedBox = styled(Card)`
  height: max-content !important;
  width: 100%;
  padding: 1rem 2rem;
  margin-top: 3rem;
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  gap: 2rem;
`;
