import { MultipleDropzone } from 'issuix-ui-kit';
import { FC } from 'react';
import { Control, Controller, useFormContext } from 'react-hook-form';

export type ControlledMultipleUploadProps = {
  name: string;
  placeholder: string;
  incorrectFileFormatLabel: string;
  dragDropFilesHereLabel: string;
  onUpload: (files: File[]) => void;
  onDelete?: (event: any) => void;
  onRetry: (event: any) => void;
  onSubmit: () => void;
  errors: any;
  parentControl?: Control;
  deleteLabel?: string;
  submitLabel: string;
  browseFilesLabel: string;
  isDeleteAvailable: boolean;
  disabled: boolean;
  disabledLabel: string;
  isLoading: boolean;
};

const ControlledMultipleUpload: FC<ControlledMultipleUploadProps> = ({
  name = '',
  incorrectFileFormatLabel,
  dragDropFilesHereLabel,
  onUpload,
  onRetry,
  onDelete,
  onSubmit,
  parentControl,
  errors,
  placeholder,
  deleteLabel,
  browseFilesLabel,
  submitLabel,
  isDeleteAvailable,
  disabled,
  isLoading,
  disabledLabel,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={parentControl || control}
      defaultValue={[]}
      name={name}
      render={({ field }) => {
        return (
          <MultipleDropzone
            heading={placeholder}
            values={field.value}
            incorrectFileFormatLabel={incorrectFileFormatLabel}
            dragDropFilesHereLabel={dragDropFilesHereLabel}
            uploadFiles={onUpload}
            onDelete={onDelete}
            errors={errors}
            onSubmit={onSubmit}
            onRetry={onRetry}
            submitLabel={submitLabel}
            deleteLabel={deleteLabel}
            isDeleteAvailable={isDeleteAvailable}
            browseFilesLabel={browseFilesLabel}
            disabled={disabled}
            disabledLabel={disabledLabel}
            isLoading={isLoading}
          />
        );
      }}
    />
  );
};

export default ControlledMultipleUpload;
