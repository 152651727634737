import { TFunction } from 'i18next';

import { DOCUMENT_LABEL_STATUS, DOCUMENT_UPLOAD_STATUS } from '@/enums/DocumentStatus';

export const viewDocumentationLabel = {
  [DOCUMENT_UPLOAD_STATUS.UPLOADED]: DOCUMENT_LABEL_STATUS.VIEW,
  [DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED]: DOCUMENT_LABEL_STATUS.NO_FILE,
};

export const getViewDocumentationLabel = (translate: TFunction, uploadStatus: DOCUMENT_UPLOAD_STATUS): string => {
  const label = viewDocumentationLabel[uploadStatus];
  switch (label) {
    case DOCUMENT_LABEL_STATUS.NO_FILE:
      return translate('noFile');
    case DOCUMENT_LABEL_STATUS.VIEW:
      return translate('view');
    default:
      return translate('noFile');
  }
};

export const dragAndDropDocumentationLabel = {
  [DOCUMENT_UPLOAD_STATUS.UPLOADED]: DOCUMENT_LABEL_STATUS.UPLOADED,
  [DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED]: DOCUMENT_LABEL_STATUS.UPLOAD,
};

export const getDragAndDropDocumentationLabel = (
  translate: TFunction,
  uploadStatus: DOCUMENT_UPLOAD_STATUS
): string => {
  const label = dragAndDropDocumentationLabel[uploadStatus];
  switch (label) {
    case DOCUMENT_LABEL_STATUS.UPLOADED:
      return translate('alreadyUploaded');
    case DOCUMENT_LABEL_STATUS.UPLOAD:
      return translate('dropFilesHere');
    default:
      return translate('dropFilesHere');
  }
};
