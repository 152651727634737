import { Dropdown, Text } from 'issuix-ui-kit';
import { useState } from 'react';

import TermsAndConditions from '../../TermsAndConditions/TermsAndConditions';
import { useRegistrationContext } from '../RegistrationContext';

import RegisterFinalModal from './RegisterFinalModal/RegisterFinalModal';

import RegisterCorporateForm from '@/components/AuthModal/RegisterModal/RegisterCorporateForm/RegisterCorporateForm';
import {
  DropdownContainer,
  FormsContainer,
  FormWrapper,
  LogoContainer,
  RegisterContainer,
  SeparateLine,
} from '@/components/AuthModal/RegisterModal/RegisterModal.styles';
import RegisterPrivateForm from '@/components/AuthModal/RegisterModal/RegisterPrivateForm/RegisterPrivateForm';
import {
  quickRegisterCorporateSchema,
  quickRegisterPrivateSchema,
} from '@/components/AuthModal/RegisterModal/RegisterSchema';
import ControlledForm from '@/components/Form/ControlledForm';
import FullRegistrationLink from '@/components/FullRegistrationLink/FullRegistrationLink';
import Logo from '@/components/Logo/Logo';
import { ROLE_TYPE } from '@/enums/RoleType';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useUserType } from '@/hooks/useUserType/useUserTypeHook';
import { ROLES, UserType } from '@/shared/types/UserType';
import { getTenant } from '@/utils/getTenant/getTenant';

const RegisterModal = () => {
  const { translate } = useTranslation();
  const [userType, setUserType] = useState<UserType>(ROLE_TYPE.PRIVATE);
  const { userTypeOptions } = useUserType(ROLES);
  const tenant = getTenant();

  const { isSuccessfulRegistration } = useRegistrationContext();

  return (
    <RegisterContainer>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      {isSuccessfulRegistration ? (
        <RegisterFinalModal />
      ) : (
        <FormsContainer>
          <Text
            content={translate('welcomeTo', { tenant: tenant.charAt(0).toUpperCase() + tenant.slice(1) })}
            size="large"
          />
          <FormWrapper>
            <DropdownContainer>
              <Dropdown items={userTypeOptions} onSelect={(value) => setUserType(value as UserType)} value={userType} />
            </DropdownContainer>

            {userType === ROLE_TYPE.PRIVATE && (
              <ControlledForm schema={quickRegisterPrivateSchema(translate)}>
                <RegisterPrivateForm />
              </ControlledForm>
            )}
            {userType === ROLE_TYPE.CORPORATE && (
              <ControlledForm schema={quickRegisterCorporateSchema(translate)}>
                <RegisterCorporateForm />
              </ControlledForm>
            )}
          </FormWrapper>
          <TermsAndConditions />
          <SeparateLine />
          <FullRegistrationLink />
        </FormsContainer>
      )}
    </RegisterContainer>
  );
};

export default RegisterModal;
