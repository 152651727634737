import { BuildType } from '@/shared/types/redux/ReduxAction';

const approveBondUrl = (id: string) => {
  return `/bonds/${id}/accept`;
};

export const approveBond = (build: BuildType) => {
  return build.mutation<void, any>({
    invalidatesTags: ['GetBonds'],
    query: ({ id }) => ({
      url: approveBondUrl(id),
      method: 'PATCH',
    }),
  });
};
