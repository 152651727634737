import { SelectMultiple } from 'issuix-ui-kit';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type Option = {
  value: string;
  label: string;
  iconUrl?: string;
};

type Props = {
  items: Option[];
  placeholder?: string;
  name: string;
  isOpenUp?: boolean;
  disabled?: boolean;
};

const ControlledMultipleSelect: FC<Props> = ({
  items,
  name,
  placeholder = 'Select',
  isOpenUp = false,
  disabled = false,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field: { value, onChange } }) => (
        <SelectMultiple
          errors={errors}
          items={items}
          value={value}
          onSelect={onChange}
          placeholder={placeholder}
          name={name}
          isOpenUp={isOpenUp}
          disabled={disabled}
        />
      )}
    />
  );
};

export default ControlledMultipleSelect;
