import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import SmartShareListing from '../Shared/SmartShareListing/SmartShareListing';

import Page from '@/components/Page/Page';
import ProtectedRoute from '@/components/ProtectedRoute/ProtectedRoute';
import { ROLE_TYPE } from '@/enums/RoleType';
import { useAuth } from '@/hooks/useAuth/useAuth';
import {
  featureBonds,
  featureDashboard,
  featureShares,
  featureTransactionsToFrom,
} from '@/utils/featuresFlags/featureFlags';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';
import { generatedBondRoutes } from '@/utils/routes/generatedRoutes/generatedBondRoutes';
import { generatedShareRoutes } from '@/utils/routes/generatedRoutes/generatedShareRoutes';
import ApproveBond from '@/view/Admin/ApproveBond/ApproveBond';
import ApproveShare from '@/view/Admin/ApproveShare/ApproveShare';
import BondDashboard from '@/view/Admin/BondDashboard/BondDashboard';
import Login from '@/view/Admin/Login/Login';
import NftDashboard from '@/view/Admin/NftDashboard/NftDashboard';
import Request from '@/view/Admin/Request/Request';
import Requests from '@/view/Admin/Requests/Requests';
import ShareDashboard from '@/view/Admin/ShareDashboard/ShareDashboard';
import SmartBondsPending from '@/view/Admin/SmartBondsPending/SmartBondsPending';
import SmartSharesPending from '@/view/Admin/SmartSharesPending/SmartSharesPending';
import TransactionDetailView from '@/view/Admin/TransactionDetailView/TransactionDetailView';
import BondTransactionSingleView from '@/view/Admin/TransactionSingleView/BondTransactionSingleView';
import { NftTransactionSingleView } from '@/view/Admin/TransactionSingleView/NftTransactionSingleView';
import ShareTransactionSingleView from '@/view/Admin/TransactionSingleView/ShareTransactionSingleView';
import TransactionsFromBuyers from '@/view/Admin/TransactionsFromBuyers/TransactionsFromBuyers';
import TransactionsToBuyers from '@/view/Admin/TransactionsToBuyers/TransactionsToBuyers';
import UserDetails from '@/view/Admin/UserDetails/UserDetails';
import Users from '@/view/Admin/Users/Users';
import CreateSmartBond from '@/view/Core/CreateSmartBond/CreateSmartBond';
import CreateSmartShare from '@/view/Core/CreateSmartShare/CreateSmartShare';
import CoreLanding from '@/view/Core/Landing/CoreLanding';
import SmartBondListing from '@/view/Shared/SmartBondListing/SmartBondListing';
import ViewBond from '@/view/Shared/ViewBond/ViewBond';
import ViewShare from '@/view/Shared/ViewShare/ViewShare';

const AdminDomain: FC = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated && featureDashboard) {
    return (
      <Routes>
        <Route path={ClientRoutes.Root} element={<Login />} />
      </Routes>
    );
  } else {
    return (
      <Page>
        <Routes>
          {!featureDashboard && !isAuthenticated && <Route path={ClientRoutes.Root} element={<CoreLanding />} />}
          {!featureDashboard && isAuthenticated && (
            <Route path={ClientRoutes.Root} element={<Navigate to={ClientRoutes.Requests.root} />} />
          )}
          {featureDashboard && <Route path={ClientRoutes.Root} element={<ShareDashboard />} />}
          {featureDashboard && <Route path={ClientRoutes.Dashboard.bond} element={<BondDashboard />} />}
          {featureDashboard && <Route path={`${ClientRoutes.Dashboard.nft}/*`} element={<NftDashboard />} />}
          {featureDashboard && <Route path={ClientRoutes.Requests.root} element={<Requests />} />}
          {featureBonds && <Route path={ClientRoutes.Bonds.smartBondPending} element={<SmartBondsPending />} />}
          {featureBonds && <Route path={ClientRoutes.Bonds.root} element={<SmartBondListing />} />}
          {featureShares && <Route path={ClientRoutes.Shares.smartSharePending} element={<SmartSharesPending />} />}
          {featureShares && <Route path={ClientRoutes.Shares.root} element={<SmartShareListing />} />}

          {featureBonds && (
            <Route
              path={generatedBondRoutes(ClientRoutes.Bonds.viewSmartBond)}
              element={
                <ProtectedRoute allowedRoles={[ROLE_TYPE.CORPORATE, ROLE_TYPE.PRIVATE, ROLE_TYPE.ADMIN]}>
                  <ViewBond />
                </ProtectedRoute>
              }
            />
          )}
          {featureShares && (
            <Route
              path={generatedShareRoutes(ClientRoutes.Shares.viewSmartShare)}
              element={
                <ProtectedRoute allowedRoles={[ROLE_TYPE.CORPORATE, ROLE_TYPE.PRIVATE, ROLE_TYPE.ADMIN]}>
                  <ViewShare />
                </ProtectedRoute>
              }
            />
          )}

          <Route path={`${ClientRoutes.Users.root}`} element={<Users />} />
          <Route path={`${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/*`} element={<UserDetails />} />
          <Route path={ClientRoutes.Requests.root} element={<Requests />} />

          <Route path={`${ClientRoutes.Requests.root}/${ClientRoutes.Requests.viewRequest}`} element={<Request />} />
          {featureTransactionsToFrom && (
            <Route path={ClientRoutes.Transactions.toBuyers} element={<TransactionsToBuyers />} />
          )}
          <Route
            path={`${ClientRoutes.Transactions.toBuyers}/${ClientRoutes.Transactions.singleView}`}
            element={<BondTransactionSingleView />}
          />
          <Route
            path={`${ClientRoutes.Transactions.fromBuyers}/${ClientRoutes.Transactions.fromBuyersId}/${ClientRoutes.Transactions.bondSingleView}`}
            element={<BondTransactionSingleView />}
          />
          <Route
            path={`${ClientRoutes.Transactions.fromBuyers}/${ClientRoutes.Transactions.fromBuyersId}/${ClientRoutes.Transactions.shareSingleView}`}
            element={<ShareTransactionSingleView />}
          />
          <Route
            path={`${ClientRoutes.Transactions.fromBuyers}/${ClientRoutes.Transactions.fromBuyersId}/${ClientRoutes.Transactions.nftSingleView}`}
            element={<NftTransactionSingleView />}
          />
          <Route
            path={`${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.Users.transactions}/${ClientRoutes.Transactions.bondSingleView}`}
            element={<BondTransactionSingleView />}
          />
          <Route
            path={`${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.Users.transactions}/${ClientRoutes.Transactions.shareSingleView}`}
            element={<ShareTransactionSingleView />}
          />
          <Route
            path={`${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.Users.transactions}/${ClientRoutes.Transactions.nftSingleView}`}
            element={<NftTransactionSingleView />}
          />
          <Route path={ClientRoutes.Transactions.bondSingleView} element={<BondTransactionSingleView />} />
          <Route path={ClientRoutes.Transactions.shareSingleView} element={<ShareTransactionSingleView />} />
          <Route path={ClientRoutes.Transactions.nftSingleView} element={<NftTransactionSingleView />} />
          <Route path={ClientRoutes.Transactions.fromBuyers} element={<TransactionsFromBuyers />} />
          <Route
            path={`${ClientRoutes.Transactions.fromBuyers}/${ClientRoutes.Transactions.detailInfo}`}
            element={<TransactionDetailView />}
          />
          {featureBonds && <Route path={ClientRoutes.Bonds.smartBondPendingView} element={<ApproveBond />} />}
          {featureShares && <Route path={ClientRoutes.Shares.smartSharePendingView} element={<ApproveShare />} />}
          <Route
            path={`${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.Users.smartSharesPortfolio}/${ClientRoutes.Transactions.shareSingleView}`}
            element={<ShareTransactionSingleView />}
          />
          <Route
            path={`${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.Users.smartBondsPortfolio}/${ClientRoutes.Transactions.bondSingleView}`}
            element={<BondTransactionSingleView />}
          />
          <Route
            path={`${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.Users.nftPortfolio}/${ClientRoutes.Transactions.nftSingleView}`}
            element={<NftTransactionSingleView />}
          />
          <Route
            path={`${ClientRoutes.Bonds.root}/${ClientRoutes.Bonds.viewSmartBond}/${ClientRoutes.Transactions.bondSingleView}`}
            element={<BondTransactionSingleView />}
          />
          <Route
            path={`${ClientRoutes.Shares.root}/${ClientRoutes.Shares.viewSmartShare}/${ClientRoutes.Transactions.shareSingleView}`}
            element={<ShareTransactionSingleView />}
          />
          <Route path={ClientRoutes.Transactions.singleView} element={<BondTransactionSingleView />} />
          <Route path={ClientRoutes.Transactions.singleView} element={<ShareTransactionSingleView />} />
          <Route path={ClientRoutes.Transactions.singleView} element={<NftTransactionSingleView />} />
          <Route path={ClientRoutes.Shares.smartShareCreation} element={<CreateSmartShare />} />
          <Route
            path={`${ClientRoutes.Dashboard.bond}/${ClientRoutes.Bonds.smartBondCreation}`}
            element={<CreateSmartBond />}
          />
          <Route
            path={`${ClientRoutes.Dashboard.bond}/${ClientRoutes.Dashboard.transaction}`}
            element={<BondTransactionSingleView />}
          />
          <Route
            path={`${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.Users.smartBondCreated}/${ClientRoutes.Bonds.editSmartBond}`}
            element={<ViewBond />}
          />
          <Route
            path={`${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.Users.smartBondCreated}/${ClientRoutes.Bonds.editSmartBond}/${ClientRoutes.Transactions.bondSingleView}`}
            element={<BondTransactionSingleView />}
          />
          <Route
            path={`${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.MyAccount.shareholdersLedger}/${ClientRoutes.Transactions.shareSingleView}`}
            element={<ShareTransactionSingleView />}
          />
          <Route
            path={`${ClientRoutes.Dashboard.nft}/${ClientRoutes.Dashboard.nftTransaction}`}
            element={<NftTransactionSingleView />}
          />
          {<Route path="*" element={<Navigate to={ClientRoutes.Root} />} />}
        </Routes>
      </Page>
    );
  }
};

export default AdminDomain;
