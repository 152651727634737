import { TFunction } from 'i18next';
import * as yup from 'yup';

export enum QUANTITY_FORM_FIELDS {
  QUANTITY = 'quantity',
}

export interface QuantityForm {
  [QUANTITY_FORM_FIELDS.QUANTITY]: number;
}

export const firstStepSetQuantitySchema = (quantity: string, minAmount: number | undefined, translate: TFunction) =>
  yup.object().shape({
    [QUANTITY_FORM_FIELDS.QUANTITY]: yup
      .number()
      .min(minAmount || 1, translate('minQuantity', { minAmount }))
      .max(Number(quantity), translate('maxQuantity', { maxAmount: quantity }))
      .required(translate('required'))
      .typeError(translate('addQuantity')),
  });
