import styled from 'styled-components';

import ControlledInput from '@/components/Form/ControlledInput';
import FullDisabledField from '@/components/FullDisabledField/FullDisabledField';

export const PurchaseInfoContainer = styled.div`
  grid-row: 2 / 3;
  grid-column: 1 / 4;
`;

export const PurchaseInfoFormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2rem 1fr;
  grid-template-rows: 5rem;
  width: 100%;
  height: 100%;
  grid-gap: 0.5rem;
  margin-top: 1rem;
`;

export const StyledControlledInput = styled(ControlledInput)`
  margin-top: 3px;
`;

export const StyledFullDisabledField = styled(FullDisabledField)`
  margin-top: -1rem;
`;

export const EqualsSymbolContainer = styled.div`
  display: grid;
`;
