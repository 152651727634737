import { useMemo } from 'react';

import { CountryResponse } from '@/shared/types/api';
import { useGetCountriesQuery } from '@/store/service';
import { composeDropdownOptions } from '@/utils/composeDropdownOptions/ComposeDropdownOptions';

export const useCountries = () => {
  const { data: countries } = useGetCountriesQuery();

  const countriesAsDropdown = useMemo(() => {
    return composeDropdownOptions<CountryResponse>(countries || [], 'id', 'name');
  }, [countries]);

  return {
    countries: countries || [],
    countriesAsDropdown: countriesAsDropdown || [],
  };
};
