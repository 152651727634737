import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const countriesSlice = createSlice({
  name: 'country',
  initialState,
  reducers: {},
});

export default countriesSlice.reducer;
