import { Modal, Step, Text, Wizard } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';
import styled from 'styled-components';

import { PaymentModalLayout } from '@/components/Payment/PaymentModal/PaymentModal.styles';
import FirstStepSetQuantity from '@/components/Payment/PaymentModal/Steps/FirstStepSetQuantity/FirstStepSetQuantity';
import FourthStepFeedback from '@/components/Payment/PaymentModal/Steps/FourthStepFeedback/FourthStepFeedback';
import SecondStepPurchaseDetails from '@/components/Payment/PaymentModal/Steps/SecondStepPurchaseDetails/SecondStepPurchaseDetails';
import ThirdStepPaymentMethod from '@/components/Payment/PaymentModal/Steps/ThirdStepPaymentMethod/ThirdStepPaymentMethod';
import { PaymentContextProvider, usePaymentContext } from '@/components/Payment/PaymentModal/context/PaymentContext';
import { PRODUCT_TYPE_ENUM } from '@/enums/ProductType';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { getSelectedCurrency } from '@/store/global/selectors';

// This component was moved here from the styles folder because it does a lot more than just styling
export const PaymentModalWizard = styled(Wizard)`
  height: calc(100% - 4.1rem) !important;
`;

type Props = {
  type: PRODUCT_TYPE_ENUM;
  id: string;
};

type ModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

const wizardSteps: Step[] = [
  {
    position: 1,
    component: <FirstStepSetQuantity />,
    state: 'current',
  },
  {
    position: 2,
    component: <SecondStepPurchaseDetails />,
    state: 'next',
  },
  {
    position: 3,
    component: <ThirdStepPaymentMethod />,
    state: 'next',
  },
  {
    position: 4,
    component: <FourthStepFeedback />,
    state: 'next',
  },
];

const PaymentView: FC<Props> = ({ type, id }) => {
  const { loadResource, setCurrency } = usePaymentContext();
  const currency = useAppSelector((state) => getSelectedCurrency(state));
  const { translate } = useTranslation();

  useEffect(() => {
    if (type && id && currency) {
      loadResource(type, id).then(() => {
        setCurrency(currency);
      });
    }
  }, [type, id, currency]);

  if (!currency || !id || !type) {
    return <></>;
  }

  return (
    <PaymentModalLayout>
      <Text
        size={'extraLarge'}
        content={type === PRODUCT_TYPE_ENUM.BOND_PAPERS ? translate('buyBonds') : translate('buyShares')}
      />
      <PaymentModalWizard steps={wizardSteps} />
    </PaymentModalLayout>
  );
};

const PaymentModal: FC<Props & ModalProps> = ({ type, id, isOpen, onClose }) => {
  return (
    <Modal close={onClose} isOpen={isOpen}>
      <PaymentContextProvider closeModal={onClose}>
        <PaymentView type={type} id={id} />
      </PaymentContextProvider>
    </Modal>
  );
};

export default PaymentModal;
