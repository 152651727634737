import { nftPagingLimit } from '@/shared/constants/Paging';
import { PageableItems } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const getNftsByCollectionIdUrl = (id: string, offset: number) =>
  `/admin/nft/${id}?offset=${offset}&limit=${nftPagingLimit}`;

export const getNftsByCollectionId = (build: BuildType) => {
  return build.query<PageableItems, any>({
    providesTags: ['NftInCollection'],
    query: ({ offset, query }) => ({
      url: getNftsByCollectionIdUrl(query.id, offset),
      method: 'GET',
    }),
  });
};
