import { GreenStateTheme, MetaSwissTheme } from 'issuix-ui-kit';
import { FC, useCallback, useEffect, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

import { TENANT } from '@/enums/App';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { chartColors } from '@/shared/types/ChartColors';
import { ChartPieResponse } from '@/shared/types/ChartPieResponse';
import { ChartResponse } from '@/shared/types/api';
import { formatAmount } from '@/utils/formatAmount/FormatAmount';
import { getTenant } from '@/utils/getTenant/getTenant';
import { renderActiveShape } from '@/utils/renderActiveShape/renderActiveShape';
import { renderEmptyShape } from '@/utils/renderEmptyShare/renderEmptyShape';
import { SplitChartBlock } from '@/view/Core/SmartSharePortfolio/SmartSharePortfolio.styles';

interface SmartSharePortfolioChartProps {
  chart: ChartResponse;
}

const SmartSharePortfolioChart: FC<SmartSharePortfolioChartProps> = ({ chart }) => {
  const emptyChart = [{ name: '', value: 1 }];
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [chartData, setChartData] = useState<ChartPieResponse[]>([]);
  const currency = useAppSelector((state) => state.global.currency);
  const tenant = getTenant();

  const onPieEnter = useCallback(
    (_: string | undefined, index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  useEffect(() => {
    if (chart && currency) {
      const formattedResponse = chart.items.map((item) => ({
        ...item,
        name: chart.name,
        percent: Number(item.percent) / 100,
        value: +formatAmount(+item.pricePerShare.split(' ')[0], currency.decimals, currency.rate),
        formatedValue: formatAmount(+item.pricePerShare.split(' ')[0], currency.decimals, currency.rate),
      }));
      setChartData(formattedResponse);
    }
  }, [chart]);

  return (
    <SplitChartBlock>
      <ResponsiveContainer height={350}>
        <PieChart>
          {!chartData.length && (
            <Pie
              activeIndex={0}
              activeShape={renderEmptyShape}
              dataKey="value"
              data={emptyChart}
              innerRadius={80}
              outerRadius={120}
            >
              <Cell
                fill={
                  tenant === TENANT.GREEN_STATE
                    ? GreenStateTheme.colors.grey.primary
                    : MetaSwissTheme.colors.grey.primary
                }
                opacity="0.5"
              />
            </Pie>
          )}
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={chartData}
            dataKey="value"
            innerRadius={80}
            outerRadius={110}
            onMouseEnter={onPieEnter}
          >
            {chartData.map((_, index: number) => (
              <Cell key={`cell-${index}`} fill={chartColors[index % chartColors.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </SplitChartBlock>
  );
};

export default SmartSharePortfolioChart;
