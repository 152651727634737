import { BuildType } from '@/shared/types/redux/ReduxAction';

const rejectShareUrl = (id: string) => {
  return `/shares/${id}/reject`;
};

export const rejectShare = (build: BuildType) => {
  return build.mutation<void, any>({
    invalidatesTags: ['GetShares'],
    query: ({ id, reason }) => ({
      url: rejectShareUrl(id),
      method: 'PATCH',
      body: {
        reason,
      },
    }),
  });
};
