import { Text } from 'issuix-ui-kit';

import { useAuthContext } from '@/components/Auth/AuthContext';
import { Container } from '@/components/FullRegistrationLink/FullRegistrationLink.styles';
import { AUTH_MODAL_TYPE } from '@/enums/AuthModalType';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';

const FullRegistrationLink = () => {
  const { translate } = useTranslation();
  const { setModalType } = useAuthContext();

  return (
    <Container>
      <Text content={translate('fullRegistration')} size={'small'} />
      <a onClick={() => setModalType(AUTH_MODAL_TYPE.THREE_STEP_REGISTER)}>{translate('getStartedHere')}</a>
    </Container>
  );
};

export default FullRegistrationLink;
