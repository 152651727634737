import { Button, ModalDialog } from 'issuix-ui-kit';
import { FC } from 'react';

import { ButtonsContainer, StyledText } from './AcceptModal.styles';

import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';

interface AcceptModalProps {
  isAcceptModalOpen: boolean;
  onAcceptModalClose: () => void;
  onAccept: () => void;
  title: string;
  subtitle: string;
  isAcceptLoading?: boolean;
}

export const AcceptModal: FC<AcceptModalProps> = ({
  isAcceptModalOpen,
  onAcceptModalClose,
  onAccept,
  title,
  subtitle,
  isAcceptLoading,
}) => {
  const { translate } = useTranslation();

  return (
    <ModalDialog isOpen={isAcceptModalOpen} close={onAcceptModalClose}>
      <StyledText content={title} size={'extraLarge'} />
      <StyledText content={subtitle} size={'medium'} />
      <ButtonsContainer>
        <Button
          text={translate('cancel')}
          onClick={onAcceptModalClose}
          buttonType={'secondary'}
          disabled={isAcceptLoading}
        />
        <Button text={translate('accept')} onClick={onAccept} buttonType={'primary'} isLoading={isAcceptLoading} />
      </ButtonsContainer>
    </ModalDialog>
  );
};
