import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const createBondFormSchema = (translate: TFunction) =>
  Yup.object().shape({
    value: Yup.string()
      .matches(/^[0-9]+$/, translate('digits'))
      .required(translate('required')),
    sellInCurrencyIds: Yup.array().of(Yup.string()).nullable().min(1).required(translate('required')),
    denomination: Yup.string(),
    interest: Yup.string()
      .matches(/^(\d)*(\.)?([0-9]{1})?$/, translate('digits'))
      .required(translate('required')),
    isin: Yup.string(),
    couponsPaid: Yup.string().required(translate('required')),
    offeringMonths: Yup.string()
      .matches(/^[0-9]+$/, translate('digits'))
      .required(translate('required')),
    issuedDate: Yup.string().required(translate('required')),
    maturity: Yup.number().min(12, 'Minimum value is 12').required(translate('required')),
    regionIds: Yup.array().of(Yup.string()).nullable().min(1).required(translate('required')),
    listingOption: Yup.string()
      .matches(/^(true|false)$/)
      .required(translate('required')),
    bankName: Yup.string().required(translate('required')),
    bankAddress: Yup.string().required(translate('required')),
    iban: Yup.string().required(translate('required')),
    swift: Yup.string().required(translate('required')),
    insuranceOption: Yup.string(),
    brokerageOption: Yup.string(),
    advisoryOption: Yup.string(),
    storageOption: Yup.string(),
    underwritingOption: Yup.string(),
    secondPartyOpinionOption: Yup.string(),
    issuer: Yup.string(),
  });
