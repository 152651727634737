import { TabsNavigation } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import NftCollection from '@/components/NftCollection/NftCollection';
import NftHeaderNavigationItem from '@/components/NftHeaderNavigationItem/NftHeaderNavigationItem';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { NftCollectionResponse } from '@/shared/types/api';
import { getUserSelector } from '@/store/global/selectors';
import { useGetNftCollectionsQuery } from '@/store/service';
import { composePath } from '@/utils/composePath/ComposePath';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';
import { CreateNftCollection } from '@/view/Core/CreateNftCollection/CreateNftCollection';

const NftDashboard: FC = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const user = useAppSelector((state) => getUserSelector(state));

  const { data } = useGetNftCollectionsQuery();

  useEffect(() => {
    if (location.pathname === `/${ClientRoutes.Dashboard.nft}` && data?.items.length > 0) {
      navigate(composePath(`${ClientRoutes.Dashboard.nftCollection}`, { id: data?.items[0].id }));
    }

    if (data?.items.length === 0) {
      navigate(`/${ClientRoutes.Dashboard.nft}/${ClientRoutes.Dashboard.addNftCollection}`);
    }
  }, [location.pathname, data]);

  if (!data) {
    return null;
  }

  return (
    <NftDashboardPage>
      <TabsNavigation left>
        <>
          {data.items.map((collection: NftCollectionResponse) => {
            return (
              <NftHeaderNavigationItem
                key={collection.id}
                label={collection.name}
                path={collection.id}
                collectionId={collection.id}
              />
            );
          })}
          {user?.email === 'office@nimbus-tech.io' && (
            <NftHeaderNavigationItem
              label={translate('addNew')}
              path={ClientRoutes.Dashboard.addNftCollection}
              addNew
            />
          )}
        </>
      </TabsNavigation>
      <Routes location={location}>
        <Route path={`${ClientRoutes.Dashboard.addNftCollection}`} element={<CreateNftCollection />} />
        <Route
          path={`${ClientRoutes.Dashboard.nftCollection}/*`}
          element={<NftCollection nftCollections={data.items} />}
        />
      </Routes>
    </NftDashboardPage>
  );
};

const NftDashboardPage = styled.div`
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: auto;
`;

export default NftDashboard;
