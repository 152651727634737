import React, { FC, createContext, useContext } from 'react';

import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { PageableItems } from '@/shared/types/api';
import { useGetBondsQuery } from '@/store/service';

interface ISmartBondPendingRequestsContext {
  data: (string | PageableItems)[];
  fetch: () => void;
  setFilters: (filters: { [x: string]: any } | undefined) => void;
  resetData: () => void;
}

interface SmartBondPendingContextProviderProps {
  children: React.ReactNode;
}

export const SmartBondsPendingContext = createContext({} as ISmartBondPendingRequestsContext);

export const useSmartBondPendingContext = () => useContext(SmartBondsPendingContext);

export const SmartBondPendingContextProvider: FC<SmartBondPendingContextProviderProps> = ({ children }) => {
  const { fetch, resetData, data, setFilters } = useTableWithPagination({
    query: useGetBondsQuery,
    skipIfNoFilters: true,
  });

  return (
    <SmartBondsPendingContext.Provider
      value={{
        data,
        fetch,
        setFilters,
        resetData,
      }}
    >
      {children}
    </SmartBondsPendingContext.Provider>
  );
};
