import { TFunction } from 'i18next';
import * as yup from 'yup';

export const resetPasswordWithCodeSchema = (translate: TFunction) =>
  yup.object().shape({
    code: yup.string().length(6).required(translate('required')),
    newPassword: yup
      .string()
      .required(translate('required'))
      .min(5, translate('minChar', { value: 5 }))
      .max(255, translate('maxChar', { value: 255 })),
    passwordConfirmation: yup
      .string()
      .required(translate('required'))
      .oneOf([yup.ref('newPassword'), null], translate('passwordMatch')),
  });
