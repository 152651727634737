import styled from 'styled-components';

import { CreateNftForm } from './CreateNftForm/CreateNftForm';

import { createNftSchema } from '@/components/CreateNft/CreateNftSchema';
import ControlledForm from '@/components/Form/ControlledForm';

export const CreateNft = () => {
  return (
    <ViewNftWrapper>
      <CreateNftView />
    </ViewNftWrapper>
  );
};

const CreateNftView = () => {
  return (
    <ControlledForm schema={createNftSchema()}>
      <CreateNftForm />
    </ControlledForm>
  );
};

export const ViewNftWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
