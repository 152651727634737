import { Text, Button } from 'issuix-ui-kit';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import ControlledForm from '@/components/Form/ControlledForm';
import AcceptBankTransactionModal from '@/components/TransactionModal/AcceptBankTransactionModal/AcceptBankTransactionModal';
import AcceptCryptoTransactionModal from '@/components/TransactionModal/AcceptCryptoTransactionModal/AcceptCryptoTransactionModal';
import RejectTransactionModal from '@/components/TransactionModal/RejectTransactionModal/RejectTransactionModal';
import { PRODUCT_TYPE_ENUM } from '@/enums/ProductType';
import { STATUS_ENUM } from '@/enums/Status';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { TransactionDetailInfoResponse } from '@/shared/types/api';
import { composePath } from '@/utils/composePath/ComposePath';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';
import TransactionDetailInfoForm from '@/view/Admin/TransactionDetailView/TransactionDetailInfo/TransactionDetailInfoForm/TransactionDetailInfoForm';
import { transactionDetailInfoSchema } from '@/view/Admin/TransactionDetailView/TransactionDetailInfo/TransactionDetailInfoForm/TransactionDetailInfoFormSchema';
import { TransactionDetailViewContainer } from '@/view/Admin/TransactionDetailView/TransactionDetailView.styles';
import { CardHeader } from '@/view/Admin/TransactionSingleView/TransactionSingleView.styles';

interface TransactionDetailInfoProps {
  transaction: TransactionDetailInfoResponse;
}

const TransactionDetailInfo: FC<TransactionDetailInfoProps> = ({ transaction }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const [isBankApproveModalOpen, setIsBankApproveModalOpen] = useState<boolean>(false);
  const [isCryptoApproveModalOpen, setIsCryptoApproveModalOpen] = useState<boolean>(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState<boolean>(false);

  const onView = (id: string) => {
    switch (transaction.productType) {
      case PRODUCT_TYPE_ENUM.BOND_PAPERS:
        return navigate(composePath(ClientRoutes.Transactions.bondSingleView, { id: id }));
      case PRODUCT_TYPE_ENUM.ACTIONS:
        return navigate(composePath(ClientRoutes.Transactions.shareSingleView, { id: id }));
      case PRODUCT_TYPE_ENUM.NFT:
        return navigate(composePath(ClientRoutes.Transactions.nftSingleView, { id: id }));
    }
  };

  // TODO - Replace these modals with accept/reject modals
  return (
    <TransactionDetailViewContainer>
      <CardHeader>
        <AcceptBankTransactionModal isOpen={isBankApproveModalOpen} setIsOpen={setIsBankApproveModalOpen} />
        <AcceptCryptoTransactionModal isOpen={isCryptoApproveModalOpen} setIsOpen={setIsCryptoApproveModalOpen} />
        <RejectTransactionModal isOpen={isRejectModalOpen} setIsOpen={setIsRejectModalOpen} />
        <Text content={translate('transactionsDetailInfo')} size={'large'} />
        {transaction && (
          <ButtonContainer>
            <ButtonStyled text={translate('view')} onClick={() => onView(transaction.id)} buttonType={'secondary'} />
            {transaction.status === STATUS_ENUM.PENDING && transaction.paymentMethod !== 'STRIPE' && (
              <TransactionManipulationButtonContainer>
                <ButtonStyled
                  text={translate('approve')}
                  onClick={() => {
                    transaction.paymentMethod === 'BANK'
                      ? setIsBankApproveModalOpen(true)
                      : setIsCryptoApproveModalOpen(true);
                  }}
                  buttonType={'primary'}
                />
                <ButtonStyled
                  text={translate('reject')}
                  onClick={() => setIsRejectModalOpen(true)}
                  buttonType={'danger'}
                />
              </TransactionManipulationButtonContainer>
            )}
          </ButtonContainer>
        )}
      </CardHeader>
      <ControlledForm schema={transactionDetailInfoSchema()}>
        <TransactionDetailInfoForm transaction={transaction} />
      </ControlledForm>
    </TransactionDetailViewContainer>
  );
};

export default TransactionDetailInfo;

const ButtonStyled = styled(Button)`
  margin-right: 1em;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const TransactionManipulationButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 2em;
`;
