import { Card } from 'issuix-ui-kit';
import styled from 'styled-components';

export const FormContainer = styled.div`
  position: relative;
  padding: 2rem;
  margin-top: 2rem;
  gap: 3rem;
`;

export const NftCollectionDetailsContainer = styled(Card).attrs(() => ({
  notFullHeight: false,
}))`
  width: 100%;
  height: fit-content;
  padding: 4rem 2rem;
  position: relative;
`;

export const ActionContainer = styled.div`
  position: absolute;
  right: 1rem;
`;

export const TextAreaWrapper = styled.div`
  width: 50%;
`;

export const ErrorMessage = styled.div`
  color: #f4778a;
  font-size: 1.2rem;
  position: absolute;
  top: 7.5rem;
`;
