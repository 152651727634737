import { BuildType } from '@/shared/types/redux/ReduxAction';

const checkIfIsinExistPath = '/bonds/check-isin';

export const checkIfIsinExist = (build: BuildType) => {
  return build.mutation<boolean, string>({
    query: (isin: string) => ({
      url: checkIfIsinExistPath,
      method: 'POST',
      body: { isin },
    }),
  });
};
