import { Text } from 'issuix-ui-kit';
import { FC } from 'react';
import { FieldErrors, FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import styled from 'styled-components';

import { Row } from '../Layout/Row/Row.styled';

import ControlledUpload from '@/components/Form/ControlledUpload';
import { DOCUMENT_UPLOAD_STATUS } from '@/enums/DocumentStatus';
import { ASSET_SHARE_CAMEL_CASE_ENUM, ASSET_SHARE_ENUM } from '@/enums/share/AssetShareEnum';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useUploadFile } from '@/hooks/useUploadFile/useUploadFile';
import { AssetResponse } from '@/shared/types/api';
import {
  getDragAndDropDocumentationLabel,
  getViewDocumentationLabel,
} from '@/utils/getDocumentationLabel/getDocumentationLabel';

type DocumentationProps = {
  assets: AssetResponse[] | [];
  isEditMode?: boolean;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  errors?: FieldErrors;
};

export const Documentation: FC<DocumentationProps> = ({ assets, isEditMode, setValue, getValues, errors }) => {
  const { translate } = useTranslation();

  const { uploadUnassignedAsset, viewFile } = useUploadFile();

  return (
    <DocumentationBox>
      <Text size={'large'} content={translate('documentation')} />
      <RowWrap>
        <ControlledUpload
          placeholder={translate('businessPlan')}
          name={ASSET_SHARE_CAMEL_CASE_ENUM.BUSINESS_PLAN}
          incorrectFileFormatLabel={translate('incorrectFileFormat')}
          dragDropFilesHereLabel={getDragAndDropDocumentationLabel(
            translate,
            getValues(ASSET_SHARE_CAMEL_CASE_ENUM.BUSINESS_PLAN)?.fileId
              ? DOCUMENT_UPLOAD_STATUS.UPLOADED
              : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
          )}
          clickToViewDocumentLabel={getViewDocumentationLabel(
            translate,
            getValues(ASSET_SHARE_CAMEL_CASE_ENUM.BUSINESS_PLAN)?.fileId
              ? DOCUMENT_UPLOAD_STATUS.UPLOADED
              : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
          )}
          isEditMode={isEditMode}
          onViewDocument={(fileId: string) =>
            viewFile(fileId, ASSET_SHARE_ENUM.BUSINESS_PLAN, getValues, assets, isEditMode)
          }
          onUploadFile={async (file) =>
            await uploadUnassignedAsset(file, ASSET_SHARE_CAMEL_CASE_ENUM.BUSINESS_PLAN, setValue)
          }
          disabled={!getValues(ASSET_SHARE_CAMEL_CASE_ENUM.BUSINESS_PLAN)?.fileId && !isEditMode}
          onDelete={(name: string) =>
            setValue(
              name,
              { fileId: undefined, url: undefined, type: name },
              { shouldDirty: true, shouldValidate: true }
            )
          }
          errors={errors?.businessPlan}
        />
        <ControlledUpload
          placeholder={translate('presentation')}
          name={ASSET_SHARE_CAMEL_CASE_ENUM.PRESENTATION}
          onViewDocument={(fileId: string) =>
            viewFile(fileId, ASSET_SHARE_ENUM.PRESENTATION, getValues, assets, isEditMode)
          }
          incorrectFileFormatLabel={translate('incorrectFileFormat')}
          dragDropFilesHereLabel={getDragAndDropDocumentationLabel(
            translate,
            getValues(ASSET_SHARE_CAMEL_CASE_ENUM.PRESENTATION)?.fileId
              ? DOCUMENT_UPLOAD_STATUS.UPLOADED
              : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
          )}
          clickToViewDocumentLabel={getViewDocumentationLabel(
            translate,
            getValues(ASSET_SHARE_CAMEL_CASE_ENUM.PRESENTATION)?.fileId
              ? DOCUMENT_UPLOAD_STATUS.UPLOADED
              : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
          )}
          isEditMode={isEditMode}
          onUploadFile={async (file) =>
            await uploadUnassignedAsset(file, ASSET_SHARE_CAMEL_CASE_ENUM.PRESENTATION, setValue)
          }
          disabled={!getValues(ASSET_SHARE_CAMEL_CASE_ENUM.PRESENTATION)?.fileId && !isEditMode}
          onDelete={(name: string) =>
            setValue(
              name,
              { fileId: undefined, url: undefined, type: name },
              { shouldDirty: true, shouldValidate: true }
            )
          }
          errors={errors?.presentation}
        />
        <ControlledUpload
          placeholder={translate('secondPartyOpinionOption')}
          name={ASSET_SHARE_CAMEL_CASE_ENUM.SECOND_PARTY_OPINION}
          onViewDocument={(fileId: string) =>
            viewFile(fileId, ASSET_SHARE_ENUM.SECOND_PARTY_OPINION, getValues, assets, isEditMode)
          }
          incorrectFileFormatLabel={translate('incorrectFileFormat')}
          dragDropFilesHereLabel={getDragAndDropDocumentationLabel(
            translate,
            getValues(ASSET_SHARE_CAMEL_CASE_ENUM.SECOND_PARTY_OPINION)?.fileId
              ? DOCUMENT_UPLOAD_STATUS.UPLOADED
              : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
          )}
          clickToViewDocumentLabel={getViewDocumentationLabel(
            translate,
            getValues(ASSET_SHARE_CAMEL_CASE_ENUM.SECOND_PARTY_OPINION)?.fileId
              ? DOCUMENT_UPLOAD_STATUS.UPLOADED
              : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
          )}
          isEditMode={isEditMode}
          onUploadFile={async (file) =>
            await uploadUnassignedAsset(file, ASSET_SHARE_CAMEL_CASE_ENUM.SECOND_PARTY_OPINION, setValue)
          }
          disabled={!getValues(ASSET_SHARE_CAMEL_CASE_ENUM.SECOND_PARTY_OPINION)?.fileId && !isEditMode}
          onDelete={(name: string) =>
            setValue(
              name,
              { fileId: undefined, url: undefined, type: name },
              { shouldDirty: true, shouldValidate: true }
            )
          }
          errors={errors?.secondPartyOpinion}
        />
        <ControlledUpload
          placeholder={translate('evaluation')}
          name={ASSET_SHARE_CAMEL_CASE_ENUM.EVALUATION}
          onViewDocument={(fileId: string) =>
            viewFile(fileId, ASSET_SHARE_ENUM.EVALUATION, getValues, assets, isEditMode)
          }
          incorrectFileFormatLabel={translate('incorrectFileFormat')}
          dragDropFilesHereLabel={getDragAndDropDocumentationLabel(
            translate,
            getValues(ASSET_SHARE_CAMEL_CASE_ENUM.EVALUATION)?.fileId
              ? DOCUMENT_UPLOAD_STATUS.UPLOADED
              : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
          )}
          clickToViewDocumentLabel={getViewDocumentationLabel(
            translate,
            getValues(ASSET_SHARE_CAMEL_CASE_ENUM.EVALUATION)?.fileId
              ? DOCUMENT_UPLOAD_STATUS.UPLOADED
              : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
          )}
          isEditMode={isEditMode}
          onUploadFile={async (file) =>
            await uploadUnassignedAsset(file, ASSET_SHARE_CAMEL_CASE_ENUM.EVALUATION, setValue)
          }
          disabled={!getValues(ASSET_SHARE_CAMEL_CASE_ENUM.EVALUATION)?.fileId && !isEditMode}
          onDelete={(name: string) =>
            setValue(
              name,
              { fileId: undefined, url: undefined, type: name },
              { shouldDirty: true, shouldValidate: true }
            )
          }
          errors={errors?.evaluation}
        />
        <ControlledUpload
          placeholder={translate('onePager')}
          name={ASSET_SHARE_CAMEL_CASE_ENUM.ONE_PAGER}
          onViewDocument={(fileId: string) =>
            viewFile(fileId, ASSET_SHARE_ENUM.ONE_PAGER, getValues, assets, isEditMode)
          }
          incorrectFileFormatLabel={translate('incorrectFileFormat')}
          dragDropFilesHereLabel={getDragAndDropDocumentationLabel(
            translate,
            getValues(ASSET_SHARE_CAMEL_CASE_ENUM.ONE_PAGER)?.fileId
              ? DOCUMENT_UPLOAD_STATUS.UPLOADED
              : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
          )}
          clickToViewDocumentLabel={getViewDocumentationLabel(
            translate,
            getValues(ASSET_SHARE_CAMEL_CASE_ENUM.ONE_PAGER)?.fileId
              ? DOCUMENT_UPLOAD_STATUS.UPLOADED
              : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
          )}
          isEditMode={isEditMode}
          onUploadFile={async (file) =>
            await uploadUnassignedAsset(file, ASSET_SHARE_CAMEL_CASE_ENUM.ONE_PAGER, setValue)
          }
          disabled={!getValues(ASSET_SHARE_CAMEL_CASE_ENUM.ONE_PAGER)?.fileId && !isEditMode}
          onDelete={(name: string) =>
            setValue(
              name,
              { fileId: undefined, url: undefined, type: name },
              { shouldDirty: true, shouldValidate: true }
            )
          }
          errors={errors?.onePager}
        />
        <ControlledUpload
          placeholder={translate('whitePaper')}
          name={ASSET_SHARE_CAMEL_CASE_ENUM.WHITE_PAPER}
          onViewDocument={(fileId: string) =>
            viewFile(fileId, ASSET_SHARE_ENUM.WHITE_PAPER, getValues, assets, isEditMode)
          }
          incorrectFileFormatLabel={translate('incorrectFileFormat')}
          dragDropFilesHereLabel={getDragAndDropDocumentationLabel(
            translate,
            getValues(ASSET_SHARE_CAMEL_CASE_ENUM.WHITE_PAPER)?.fileId
              ? DOCUMENT_UPLOAD_STATUS.UPLOADED
              : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
          )}
          clickToViewDocumentLabel={getViewDocumentationLabel(
            translate,
            getValues(ASSET_SHARE_CAMEL_CASE_ENUM.WHITE_PAPER)?.fileId
              ? DOCUMENT_UPLOAD_STATUS.UPLOADED
              : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
          )}
          isEditMode={isEditMode}
          onUploadFile={async (file) =>
            await uploadUnassignedAsset(file, ASSET_SHARE_CAMEL_CASE_ENUM.WHITE_PAPER, setValue)
          }
          disabled={!getValues(ASSET_SHARE_CAMEL_CASE_ENUM.WHITE_PAPER)?.fileId && !isEditMode}
          onDelete={(name: string) =>
            setValue(
              name,
              { fileId: undefined, url: undefined, type: name },
              { shouldDirty: true, shouldValidate: true }
            )
          }
          errors={errors?.whitePaper}
        />
      </RowWrap>
    </DocumentationBox>
  );
};

const DocumentationBox = styled.div`
  width: 100%;
  margin-top: 3rem;
`;

const RowWrap = styled(Row)`
  flex-wrap: wrap;
`;
