import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Text } from 'issuix-ui-kit';
import moment, { Moment } from 'moment';
import { FC, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

import { ChartContainer, SmartShareChartContainer } from '@/components/SmartShareChart/SmartShareChart.style';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useGetChartDataQuery } from '@/store/service';

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      border: {
        display: true,
        color: '#E5E7EB',
      },
      grid: {
        color: '#E5E7EB',
      },
      ticks: {
        color: '#E5E7EB',
      },
    },
    y: {
      border: {
        display: true,
        color: '#E5E7EB',
      },
      grid: {
        color: '#E5E7EB',
      },
      ticks: {
        color: '#E5E7EB',
      },
    },
  },
  layout: {
    height: 200,
    padding: {
      top: 20,
    },
  },
};

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface Props {
  shareId: string;
}

const SmartShareChart: FC<Props> = ({ shareId }) => {
  const [toDate, setToDate] = useState<number>();
  const [fromDate, setFromDate] = useState<number>();
  const [shouldSkipApiCall, setShouldSkipApiCall] = useState(true);

  useEffect(() => {
    setToDate(moment().valueOf());
    setFromDate(moment(moment()).subtract(3, 'months').valueOf());
    setShouldSkipApiCall(false);
  }, []);

  const { data: chartData } = useGetChartDataQuery(
    {
      query: {
        auditSource: 'share',
        shareId,
        fromDate,
        toDate,
      },
    },
    { skip: shouldSkipApiCall }
  );

  const chartDataSorted = Object.keys(chartData || {})
    .map((item) => moment(item, 'DD/MM/YYYY HH:mm').add(1, 'hour').local())
    .sort((itemOne: Moment, itemTwo: Moment) => {
      return (
        moment(itemOne, 'DD/MM/YYYY HH:mm').local(true).valueOf() -
        moment(itemTwo, 'DD/MM/YYYY HH:mm').local(true).valueOf()
      );
    })
    .reduce((obj: any, key: any) => {
      obj[moment(key).format('DD/MM/YYYY HH:mm')] =
        chartData[moment(key).subtract(1, 'hour').format('DD/MM/YYYY HH:mm')];
      return obj;
    }, {});

  const chartValues = {
    labels: Object.keys(chartDataSorted),
    datasets: [
      {
        label: 'Price',
        data: Object.values(chartDataSorted),
        borderColor: '#9BFDCA',
      },
    ],
  };

  const { translate } = useTranslation();

  return (
    <SmartShareChartContainer>
      <Text content={translate('smartShareChart')} size={'large'} />
      <ChartContainer>
        <Line options={options} data={chartValues} />
      </ChartContainer>
    </SmartShareChartContainer>
  );
};

export default SmartShareChart;
