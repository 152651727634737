import styled from 'styled-components';

export const RegistFinalModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
`;

export const TextWrraper = styled.div`
  margin: 2rem 0;
  width: 100%;
`;

export const ConfirmationEmailText = styled.a`
  font-size: ${(props) => props.theme.typography.large.fontSize};
  line-height: ${(props) => props.theme.typography.large.lineHeight};
`;
