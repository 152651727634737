/* eslint-disable @typescript-eslint/ban-ts-comment */
import { UpdateShareRequest } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';
import { api } from '@/store/service';
import { getTenant } from '@/utils/getTenant/getTenant';

const baseShareUrl = 'shares';

const getShareUrl = (shareId: string) => {
  return `${baseShareUrl}/${shareId}/update`;
};

export const updateShare = (build: BuildType) => {
  return build.mutation<void, UpdateShareRequest>({
    query: (shareData: any) => ({
      url: getShareUrl(shareData.shareId),
      method: 'PATCH',
      body: shareData.body,
    }),

    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
        const updatedPost = data as any;

        const { files, ...rest } = updatedPost;

        dispatch(
          api.util.updateQueryData('getShares', { query: { tenant: getTenant() } }, (draft: any) => {
            draft.items.pop();
            draft.items.unshift(rest);
          })
        );

        dispatch(
          api.util.updateQueryData('getShareAssets', { query: { id: updatedPost.id } }, (draft: any[]) => {
            draft.length = 0;
            files.map((file: any) => {
              draft.push(file);
              return;
            });
          })
        );
        setTimeout(() => {
          dispatch(api.util.invalidateTags(['GetChartData']));
        }, 30000);
      } catch (e) {
        // TODO: Handle
      }
    },
  });
};
