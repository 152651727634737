import { Button, IColumn, Table, Text } from 'issuix-ui-kit';
import { useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { CardHeader, SmartBondCreateCard } from './SmartBondCreated.style';

import AccountCalculationBox from '@/components/AccountCalculationBox/AccountCalculationBox';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useAuth } from '@/hooks/useAuth/useAuth';
import { useRatingOptions } from '@/hooks/useRatingOptions/useRatingOptions';
import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { BondResponse, RatingOptionResponse } from '@/shared/types/api';
import { getSelectedCurrency } from '@/store/global/selectors';
import { useGetBondCalculationsQuery, useGetBondsQuery } from '@/store/service';
import {
  baseBondDefaultCalculation,
  BondCalculation,
  formatCalculation,
} from '@/utils/bondCalculations/bondCalculation';
import { composePath } from '@/utils/composePath/ComposePath';
import { formatAmountWithoutRate } from '@/utils/formatAmount/FormatAmount';
import { getDenomination } from '@/utils/getBondWithDenomination/getBondWithDenomination';
import { parseBoolean } from '@/utils/parseBoolean/parseBoolean';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';

const SmartBondCreated = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const { ratingOptions } = useRatingOptions(translate);
  const currency = useAppSelector((state) => getSelectedCurrency(state));
  const { user } = useAuth();
  const params = useParams();

  const [calculation, setCalculation] = useState<BondCalculation>(baseBondDefaultCalculation);

  const { data: bondCalculations } = useGetBondCalculationsQuery({ query: { id: user?.id } }, { skip: !user });

  useEffect(() => {
    if (bondCalculations) {
      setCalculation(
        formatCalculation(bondCalculations, currency?.decimals, currency?.rate, currency?.currencyCode ?? undefined)
      );
    }
  }, [bondCalculations, currency]);

  const { fetch, data, setFilters } = useTableWithPagination({ query: useGetBondsQuery });

  useEffect(() => {
    if (user?.role !== 'admin') {
      setFilters({ showMine: true });
    } else {
      setFilters({ userId: params.id });
    }
  }, []);

  if (!currency) {
    return <></>;
  }

  const onActionClick = (id: string) => {
    navigate(composePath(ClientRoutes.Bonds.editSmartBond, { id }));
  };

  const bondPriceCalculation = (item: BondResponse) => {
    return formatAmountWithoutRate(getDenomination(item.value, item.quantity, currency), currency.decimals);
  };

  const getRating = (item: string): RatingOptionResponse => {
    return ratingOptions.find((rating: RatingOptionResponse) => rating.value === item);
  };

  const tableDef: IColumn[] = [
    {
      name: 'code',
      width: 1,
      header: {
        label: translate('code').toUpperCase(),
      },
    },
    {
      name: 'userName',
      width: 1.5,
      header: {
        label: translate('companyName').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => <>{item.user.name}</>,
      },
    },
    {
      name: 'country',
      width: 1,
      header: {
        label: translate('country').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => <>{item.user.country?.name}</>,
      },
    },
    {
      name: 'rating',
      width: 1,
      header: {
        label: translate('rating').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => (
          <>{getRating(item.rating)?.label === 'None' ? translate('none') : getRating(item.rating).label}</>
        ),
      },
    },
    {
      name: 'price',
      width: 2,
      header: {
        label: translate('smartBondPrice').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => <>{`${bondPriceCalculation(item)} ${currency.currencyCode}`}</>,
      },
    },
    {
      name: 'maturity',
      width: 1,
      header: {
        label: translate('maturity').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => <>{`${item.maturity} ${translate('months')}`}</>,
      },
    },
    {
      name: 'interest',
      width: 1,
      header: {
        label: translate('interest').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => <>{`${item.interest} %`}</>,
      },
    },
    {
      name: 'insurance',
      width: 1,
      header: {
        label: translate('insurance').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => <>{parseBoolean(item.insuranceOption, translate)}</>,
      },
    },
    {
      name: 'industry',
      width: 1,
      header: {
        label: translate('industry').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => {
          return (
            <>
              {translate(
                item.user?.endUser?.corporateUser?.businessFocus?.translationCode?.split('.')[2] as keyof TFunction
              )}
            </>
          );
        },
      },
    },
    {
      name: 'actions',
      width: 3.5,
      header: {
        label: translate('actions').toUpperCase(),
      },
      column: {
        render: (item: BondResponse) => (
          <Button fullyRounded onClick={() => onActionClick(item.id)} text={translate('edit')} size={'medium'} />
        ),
      },
    },
  ];

  return (
    <SmartBondCreateCard>
      <CardHeader>
        <Text content={translate('smartBondsCreated')} size={'large'} />
      </CardHeader>
      <TableContainer>
        <Table data={data} fetchTrigger={() => fetch()} tableDef={tableDef} />
      </TableContainer>
      <CalculationsBox>
        <AccountCalculationBox
          label={translate('totalRepay')}
          value={calculation.totalRepayAmount}
          iconUrl={currency.iconUrl}
        />
        <AccountCalculationBox
          label={translate('annualCoupons')}
          value={calculation.annualCouponsToPay}
          iconUrl={currency.iconUrl}
        />
        <AccountCalculationBox
          label={translate('totalIssuance')}
          value={calculation.totalSmartBondIssuance}
          iconUrl={currency.iconUrl}
        />
      </CalculationsBox>
    </SmartBondCreateCard>
  );
};

export default SmartBondCreated;

export const TableContainer = styled.div`
  height: 50%;
`;

export const CalculationsBox = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 3rem;
  gap: 3rem;
  justify-content: center;
`;
