import { TabsNavigationButton } from 'issuix-ui-kit';
import { FC, memo, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { composeAccountChildPath } from '@/utils/composePath/ComposeAccountChildPath';
import { composeMyAccountChildPath } from '@/utils/composePath/ComposeMyAccountChildPath';
import { composePath } from '@/utils/composePath/ComposePath';

type Props = {
  label: string;
  activeForPaths?: string[];
  path: string;
  isMyAccount?: boolean;
  userPathId?: string;
};

const AccountNavigationItem: FC<Props> = ({ label, path, isMyAccount, userPathId }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setIsActive(
      location.pathname ===
        `${
          isMyAccount
            ? composeMyAccountChildPath(path)
            : composePath(composeAccountChildPath({ path }), { id: userPathId })
        }`
    );
  }, [location, userPathId]);

  return (
    <TabsNavigationButton
      isActive={isActive}
      onClick={() => navigate(isMyAccount ? path : composePath(composeAccountChildPath({ path }), { id: userPathId }))}
      label={label.toUpperCase()}
    />
  );
};

export default memo(AccountNavigationItem);
