export const composeCurrencyDropdownOptions = <T>(
  items: T[],
  valueProp: keyof T,
  labelProp: keyof T,
  iconUrlProp?: keyof T,
  decimalsProp?: keyof T,
  rateProp?: keyof T
): {
  value: string;
  label: string;
  iconUrl?: string;
  decimals?: number;
  rate?: number;
}[] => {
  return items.map((item) => {
    const mappedOptionItem = {
      value: item[valueProp] as unknown as string,
      label: item[labelProp] as unknown as string,
    };

    if (!iconUrlProp) {
      return mappedOptionItem;
    }

    if (!decimalsProp) {
      return mappedOptionItem;
    }

    if (!rateProp) {
      return mappedOptionItem;
    }

    return {
      ...mappedOptionItem,
      iconUrl: item[iconUrlProp] as unknown as string,
      decimals: item[decimalsProp] as unknown as number,
      rate: item[rateProp] as unknown as number,
    };
  });
};
