import { BuildType } from '@/shared/types/redux/ReduxAction';

const rejectBondUrl = (id: string) => {
  return `/bonds/${id}/reject`;
};

export const rejectBond = (build: BuildType) => {
  return build.mutation<void, any>({
    invalidatesTags: ['GetBonds'],
    query: ({ id, reason }) => ({
      url: rejectBondUrl(id),
      method: 'PATCH',
      body: {
        reason,
      },
    }),
  });
};
