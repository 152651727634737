import { Button } from 'issuix-ui-kit';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import ControlledInput from '@/components/Form/ControlledInput';
import ControlledSelect from '@/components/Form/ControlledSelect';
import { Row } from '@/components/Layout/Row/Row.styled';
import { CorporateUserDocumentation } from '@/components/UserDetailsView/components/CorporateUserDetailsForm/CorporateUserDocumentation';
import { ASSET_TYPE_ENUM } from '@/enums/AssetTypeEnum';
import { useBusinessFocus } from '@/hooks/useBusinessFocus/useBusinessFocus';
import { useCountries } from '@/hooks/useCountries/UseCountries';
import { useEditEntity } from '@/hooks/useEditEntity/useEditEntity';
import { useLegalForms } from '@/hooks/useLegalForms/useLegalForms';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useUploadFile } from '@/hooks/useUploadFile/useUploadFile';
import { ImageType } from '@/shared/types/Asset';
import { AssetResponse, CorporateUserFullResponse } from '@/shared/types/api';
import {
  useAssignedAssetUrlMutation,
  useUnassignedAssetUrlMutation,
  useUpdateUserDetailsMutation,
} from '@/store/service';
import { findAssetByType } from '@/utils/assets/findAssetByType';
import { parseFormObjectCorporateUser } from '@/utils/getCorporateUserFieldValues/getCorporateUserFieldValues';
import { setFieldValues } from '@/utils/setFieldValues/setFieldValues';

interface CorporateUserDetailsFormProps {
  user?: CorporateUserFullResponse;
  isEditMode: boolean;
  assets: AssetResponse[];
  setIsEditMode: (value: boolean) => void;
}

const CorporateUserDetailsForm: FC<CorporateUserDetailsFormProps> = ({ user, isEditMode, assets, setIsEditMode }) => {
  const { countriesAsDropdown } = useCountries();
  const { translate } = useTranslation();
  const {
    setValue,
    getValues,
    formState: { dirtyFields, errors },
  } = useFormContext();
  const { updateUser, cancelHandler } = useEditEntity();
  const [updateUserMutation] = useUpdateUserDetailsMutation();
  const { businessFocusAsDropdown } = useBusinessFocus(translate);
  const { legalFormsAsDropdown } = useLegalForms(translate);
  const { uploadUnassignedAsset } = useUploadFile();

  const [unassignedImage, setUnassignedImage] = useState<ImageType | null>();

  const [getSignedUrl] = useUnassignedAssetUrlMutation();
  const [getImageUrl, { data: imageUrl }] = useAssignedAssetUrlMutation();

  useEffect(() => {
    if (assets && user) {
      setFieldValues(parseFormObjectCorporateUser(user, assets, translate), setValue);
    }
  }, [assets, user]);

  useEffect(() => {
    const picture = findAssetByType(assets, ASSET_TYPE_ENUM.PICTURE);
    if (assets && assets.length && picture) {
      getImageUrl({ assetId: picture.id });
    }
  }, [assets]);

  const onClickHandler = async () => {
    if (user) {
      await updateUser(user.id, user.role, updateUserMutation);
      setIsEditMode(false);
    }
  };

  const uploadImageHandler = async (image: File) => {
    await uploadUnassignedAsset(image, 'picture', setValue);
    const newImageAssignedUrl = await getSignedUrl({ assetId: getValues('picture').fileId }).unwrap();
    setUnassignedImage(newImageAssignedUrl);
  };

  const onCancelHandler = () => {
    setIsEditMode(false);
    cancelHandler(parseFormObjectCorporateUser(user!, assets, translate));
    setUnassignedImage(null);
    const picture = findAssetByType(assets, ASSET_TYPE_ENUM.PICTURE);
    if (assets && assets.length && picture) {
      getImageUrl({ assetId: picture?.id });
    }
  };

  if (!user) return null;

  return (
    <UserDetailsFormContainer>
      <Row>
        <ControlledInput name={'name'} placeholder={translate('name')} id={'name'} disabled={!isEditMode} />
        <ControlledInput name={'address'} placeholder={translate('address')} id={'address'} disabled={!isEditMode} />
        <ControlledSelect
          items={businessFocusAsDropdown}
          name="businessFocus"
          placeholder={translate('businessFocus')}
          disabled={!isEditMode}
        />
        <ControlledInput
          name={'ethWallet'}
          placeholder={translate('ethWallet')}
          id={'ethWallet'}
          disabled={!isEditMode}
        />
      </Row>
      <Row>
        <ControlledSelect
          items={legalFormsAsDropdown}
          name="legalForm"
          placeholder={translate('legalForm')}
          disabled={!isEditMode}
        />
        <ControlledInput name={'email'} placeholder={translate('emailAddress')} id={'email'} disabled={!isEditMode} />
        <ControlledInput name={'ceo'} placeholder={translate('CEO')} id={'ceo'} disabled={!isEditMode} />
        <ControlledInput
          name={'btcWallet'}
          placeholder={translate('btcWallet')}
          id={'btcWallet'}
          disabled={!isEditMode}
        />
      </Row>

      <Row>
        <Column>
          <ControlledInput name={'zipCode'} placeholder={translate('zipCode')} id={'zipCode'} disabled={!isEditMode} />
        </Column>
        <Column>
          <ControlledSelect
            name={'country'}
            items={countriesAsDropdown}
            placeholder={translate('country')}
            disabled={!isEditMode}
          />
        </Column>
        <Column>
          <ControlledInput name={'owner'} placeholder={translate('owner')} id={'owner'} disabled={!isEditMode} />
        </Column>
      </Row>
      <Row>
        <Column>
          <ControlledInput name={'city'} placeholder={translate('city')} id={'city'} disabled={!isEditMode} />
        </Column>
        <Column>
          <ControlledInput
            name={'phoneNumber'}
            placeholder={translate('phoneNumber')}
            id={'phoneNumber'}
            disabled={!isEditMode}
          />
        </Column>
      </Row>
      <Row>
        <CorporateUserDocumentation
          assets={assets}
          userId={user.id}
          isEditMode={isEditMode}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          uploadImageHandler={uploadImageHandler}
          unassignedImageUrl={unassignedImage}
          assignedImageUrl={imageUrl}
          username={user.displayName}
        />
      </Row>
      <Row>
        {isEditMode && (
          <ActionContainer>
            <Button text={translate('cancel')} onClick={onCancelHandler} buttonType={'secondary'} />
            <Button
              text={translate('save')}
              onClick={onClickHandler}
              disabled={!!Object.keys(errors).length || !Object.keys(dirtyFields).length}
              buttonType={'primary'}
              isLoading={false}
            />
          </ActionContainer>
        )}
      </Row>
    </UserDetailsFormContainer>
  );
};

export default CorporateUserDetailsForm;

const UserDetailsFormContainer = styled.div`
  position: relative;
  padding: 2rem;
  margin-top: 2rem;
  gap: 3rem;
`;

const ActionContainer = styled.div`
  margin-bottom: 2rem;
  margin-left: auto;
  display: flex;
  gap: 2rem;
  height: fit-content;
  align-self: flex-end;
`;

export const Column = styled.div`
  width: 24%;
`;
