import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin-top: 12;
  width: 100%;
  grid-column: 1 / 4;
`;
