import { Button } from 'issuix-ui-kit';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import AcceptedTransactions from '@/components/AcceptedTransactions/AcceptedTransactions';
import ShareholdersLedger from '@/components/ShareholdersLedger/ShareholdersLedger';
import SmartShareChart from '@/components/SmartShareChart/SmartShareChart';
import SmartShareFeatures from '@/components/SmartShareFeatures/SmartShareFeatures';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { getUserSelector } from '@/store/global/selectors';
import { useGetShareAssetsQuery, useGetSharesQuery } from '@/store/service';
import { getTenant } from '@/utils/getTenant/getTenant';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';
import SmartShareLedger from '@/view/Admin/SmartShareLegder/SmartShareLedger';

const ShareDashboard: FC = () => {
  const tenant = getTenant();
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const { data } = useGetSharesQuery({ query: { tenant } });
  const { data: assets } = useGetShareAssetsQuery({ query: { id: data && data.items[0]?.id } }, { skip: !data });
  const user = useAppSelector((state) => getUserSelector(state));

  const onShareCreationClick = () => {
    navigate(ClientRoutes.Shares.smartShareCreation);
  };

  if (!data || data.items.length === 0) {
    return user?.email === 'office@nimbus-tech.io' ? (
      <>
        <Button text={translate('smartShareCreation')} onClick={onShareCreationClick} />
      </>
    ) : (
      <></>
    );
  }

  const share = data.items[0];

  return (
    <LandingWrapper>
      <SmartShareFeatures share={share} assets={assets} />
      <SmartShareChart shareId={share.id} />
      <ShareholdersLedger userId={share.userId ?? share.user.id} />
      <SmartShareLedger userId={share.userId ?? share.user.id} isDashboard={true} />
      <AcceptedTransactions />
    </LandingWrapper>
  );
};

const LandingWrapper = styled.div`
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: auto;
`;

export default ShareDashboard;
