import { FC } from 'react';
import styled from 'styled-components';

import ControlledInput from '@/components/Form/ControlledInput';
import { PURCHASE_DETAILS_FORM_ENUM } from '@/components/Payment/PaymentModal/PurchaseDetailsFormFields.enum';
import DetailBlock from '@/components/Payment/PaymentModal/shared/DetailBlock/DetailBlock';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';

const SecondStepPurchaseDetailsBuyerInfo: FC = () => {
  const { translate } = useTranslation();

  return (
    <BuyerInfoLayout title={translate('buyerInfo')}>
      <ControlledInput disabled name={PURCHASE_DETAILS_FORM_ENUM.BUYER_NAME} placeholder={translate('name')} />
      <ControlledInput disabled name={PURCHASE_DETAILS_FORM_ENUM.BUYER_LAST_NAME} placeholder={translate('lastName')} />
      <ControlledInput disabled name={PURCHASE_DETAILS_FORM_ENUM.BUYER_ADDRESS} placeholder={translate('address')} />
    </BuyerInfoLayout>
  );
};

const BuyerInfoLayout = styled(DetailBlock)`
  grid-row: 4 / 5;
  grid-column: 1 / 4;
`;

export default SecondStepPurchaseDetailsBuyerInfo;
