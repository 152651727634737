import { Card } from 'issuix-ui-kit';
import styled from 'styled-components';

export const SmartBondContainer = styled(Card).attrs(() => ({
  notFullHeight: true,
}))`
  height: 100%;
  width: 100%;
  padding: 2rem;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  margin-left: 2rem;
`;

export const ViewBondWrapper = styled.div`
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: auto;
`;
