import { UserResponse } from '@/shared/types/api';

export const mapFieldValuesToUpdateUserData = (payload: UserResponse) => {
  return {
    address: payload.address,
    city: payload.city,
    displayName: payload.displayName,
    email: payload.email,
    firstTimeLogin: payload.firstTimeLogin,
    id: payload.id,
    isActive: payload.isActive,
    isFullyRegistered: payload.isFullyRegistered,
    phoneNumber: payload.phoneNumber,
    phoneNumberPrefix: payload.country?.phonePrefix,
    preferredLanguage: payload.preferredLanguage,
    role: payload.role,
    status: payload.status,
    zipCode: payload.zipCode,
    country: payload.country,
    userImage: payload.userImage,
  };
};
