import { Button, GreenStateTheme, MetaSwissTheme } from 'issuix-ui-kit';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { useRegistrationContext } from '../../RegistrationContext';
import TermsAndConditionsQuickRegistration from '../TermsAndConditions/TermsAndConditionsQuickRegistration';

import { FormContainer } from '@/components/AuthModal/RegisterModal/RegisterModal.styles';
import ControlledCheckbox from '@/components/Form/ControlledCheckbox';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledPassword from '@/components/Form/ControlledPassword';
import { TENANT } from '@/enums/App';
import { useCheckCorporateNameOnBlur } from '@/hooks/useCheckCorporateNameOnBlur/useCheckCorporateNameOnBlur';
import { useCheckEmailOnBlur } from '@/hooks/useCheckEmailOnBlur/useCheckEmailOnBlur';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { QuickRegistrationCorporateUserRequest } from '@/shared/types/api';
import { useQuickRegistrationCorporateUserMutation } from '@/store/service';
import { getTenant } from '@/utils/getTenant/getTenant';

const RegisterCorporateForm = () => {
  const { translate } = useTranslation();
  const {
    getValues,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useFormContext();

  const [
    quickRegistrationCorporateUser,
    { isSuccess: isCorporateSuccess, isLoading: isCorporateLoading, isError: isError, error: error },
  ] = useQuickRegistrationCorporateUserMutation();

  const { checkEmailOnBlur, isEmailExist } = useCheckEmailOnBlur();
  const { checkCorporateNameOnBlur, isCorporateNameExist } = useCheckCorporateNameOnBlur();
  const { setEmail, setIsSuccessfulRegistration } = useRegistrationContext();
  const { currentLanguage } = useTranslation();

  useEffect(() => {
    if (isCorporateSuccess) {
      setIsSuccessfulRegistration(true);
    }
  }, [isCorporateSuccess]);

  const watchFields = (fieldNames: string[]) => {
    return watch(fieldNames);
  };

  const valueWatcher = watchFields(['termsAndConditions']);

  return (
    <FormContainer>
      <ControlledInput
        name={'name'}
        placeholder={translate('name')}
        id={'name'}
        onBlur={() => checkCorporateNameOnBlur(getValues().name)}
      />
      <ControlledInput
        name={'email'}
        placeholder={translate('emailAddress')}
        id={'email'}
        onBlur={() => checkEmailOnBlur(getValues().email)}
      />
      <ControlledPassword name={'password'} placeholder={translate('password')} id={'password'} />
      <ControlledPassword
        name={'passwordConfirmation'}
        placeholder={translate('confirmPassword')}
        id={'passwordConfirmation'}
      />
      <CheckboxWrapper>
        <ControlledCheckbox richLabel={<TermsAndConditionsQuickRegistration />} name="termsAndConditions" />
      </CheckboxWrapper>
      {isEmailExist && <ErrorMessage>{translate('emailAlreadyExist')}</ErrorMessage>}
      {isCorporateNameExist && <ErrorMessage>{translate('corporateNameExist')}</ErrorMessage>}
      {isError && <ErrorMessage>{translate(error.data.payload.field[0].message)}</ErrorMessage>}
      <Button
        onClick={handleSubmit((data) => {
          setEmail(getValues().email);
          quickRegistrationCorporateUser({
            ...data,
            preferredLanguage: currentLanguage,
          } as QuickRegistrationCorporateUserRequest);
        })}
        text={translate('submit')}
        disabled={!isValid || isCorporateNameExist || isEmailExist || !valueWatcher[0]}
        isLoading={isCorporateLoading}
      />
    </FormContainer>
  );
};

export default RegisterCorporateForm;

const CheckboxWrapper = styled.div`
  margin-bottom: 2rem;
  display: -webkit-box;
`;

const ErrorMessage = styled.p`
  color: ${getTenant() === TENANT.GREEN_STATE ? GreenStateTheme.colors.red.error : MetaSwissTheme.colors.red.error};
  font-size: 1.2rem;
  position: relative;
  right: 0.5rem;
  top: 0.5rem;
`;
