import { FC } from 'react';
import styled from 'styled-components';

import HeadingCard from '@/components/HeadingCard/HeadingCard';
import SmartBondsLanding from '@/view/Core/Landing/components/SmartBondsLanding';
import SmartSharesLanding from '@/view/Core/Landing/components/SmartSharesLanding';

const CoreLanding: FC = () => {
  return (
    <LandingWrapper>
      <HeadingCard />
      <SmartSharesLanding />
      <SmartBondsLanding />
    </LandingWrapper>
  );
};

const LandingWrapper = styled.div`
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export default CoreLanding;
