import { FC, useEffect, useState } from 'react';
import { UseFormSetValue, UseFormGetValues, FieldValues, FieldErrors } from 'react-hook-form';
import styled from 'styled-components';

import AccountCalculationBox from '@/components/AccountCalculationBox/AccountCalculationBox';
import ControlledImageUpload from '@/components/Form/ControlledImageUpload';
import ControlledUpload from '@/components/Form/ControlledUpload';
import { ASSET_CAMEL_CASE_TYPE_ENUM, ASSET_TYPE_ENUM } from '@/enums/AssetTypeEnum';
import { DOCUMENT_UPLOAD_STATUS } from '@/enums/DocumentStatus';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useUploadFile } from '@/hooks/useUploadFile/useUploadFile';
import { ImageType } from '@/shared/types/Asset';
import { AssetResponse } from '@/shared/types/api';
import { useGetUserTotalInvestedAmountQuery } from '@/store/service';
import { formatAmount } from '@/utils/formatAmount/FormatAmount';
import {
  getDragAndDropDocumentationLabel,
  getViewDocumentationLabel,
} from '@/utils/getDocumentationLabel/getDocumentationLabel';

type DocumentationProps = {
  assets: AssetResponse[] | [];
  userId: string;
  isEditMode?: boolean;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  errors?: FieldErrors;
  uploadImageHandler: (image: any) => void;
  unassignedImageUrl?: ImageType | null;
  assignedImageUrl?: ImageType;
  username: string | undefined;
};

export const CorporateUserDocumentation: FC<DocumentationProps> = ({
  assets,
  userId,
  isEditMode,
  setValue,
  getValues,
  errors,
  uploadImageHandler,
  assignedImageUrl,
  unassignedImageUrl,
  username,
}) => {
  const { translate } = useTranslation();
  const { uploadUnassignedAsset, viewFile } = useUploadFile();
  const currency = useAppSelector((state) => state.global.currency);
  const [accountStatement, setAccountStatement] = useState<string>('');

  const { data } = useGetUserTotalInvestedAmountQuery({ query: { userId: userId } }, { skip: !userId });

  useEffect(() => {
    if (data && currency) {
      setAccountStatement(formatAmount(data.accountStatement, currency.decimals, currency.rate));
    }
  }, [data, currency]);

  return (
    <Container>
      <DocumentationBox>
        <ControlledImageUpload
          name={ASSET_CAMEL_CASE_TYPE_ENUM.PICTURE}
          onUploadFile={(image) => uploadImageHandler(image)}
          edit={isEditMode}
          errors={errors}
          uploadImageText={
            unassignedImageUrl?.url || assignedImageUrl?.url
              ? undefined
              : isEditMode
              ? translate('addYourImage')
              : undefined
          }
          maxImageSizeText={
            unassignedImageUrl?.url || assignedImageUrl?.url
              ? undefined
              : isEditMode
              ? translate('maxSizeIs2')
              : undefined
          }
          imageSrc={unassignedImageUrl?.url ? unassignedImageUrl.url : assignedImageUrl?.url}
          username={username}
        />
        <Documents>
          <ControlledUpload
            placeholder={translate('proofOfDomicile')}
            name={ASSET_CAMEL_CASE_TYPE_ENUM.PROOF_OF_DOMICILE}
            onViewDocument={(fileId: string) =>
              viewFile(fileId, ASSET_TYPE_ENUM.PROOF_OF_DOMICILE, getValues, assets, isEditMode)
            }
            incorrectFileFormatLabel={translate('incorrectFileFormat')}
            dragDropFilesHereLabel={getDragAndDropDocumentationLabel(
              translate,
              getValues(ASSET_CAMEL_CASE_TYPE_ENUM.PROOF_OF_DOMICILE)?.fileId
                ? DOCUMENT_UPLOAD_STATUS.UPLOADED
                : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
            )}
            clickToViewDocumentLabel={getViewDocumentationLabel(
              translate,
              getValues(ASSET_CAMEL_CASE_TYPE_ENUM.PROOF_OF_DOMICILE)?.fileId
                ? DOCUMENT_UPLOAD_STATUS.UPLOADED
                : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
            )}
            isEditMode={isEditMode}
            onUploadFile={async (file) =>
              await uploadUnassignedAsset(file, ASSET_CAMEL_CASE_TYPE_ENUM.PROOF_OF_DOMICILE, setValue)
            }
            disabled={!getValues(ASSET_CAMEL_CASE_TYPE_ENUM.PROOF_OF_DOMICILE)?.fileId && !isEditMode}
            onDelete={(name: string) =>
              setValue(
                name,
                { fileId: undefined, url: undefined, type: name },
                { shouldDirty: true, shouldValidate: true }
              )
            }
            errors={errors?.proofOfDomicile}
          />
          <ControlledUpload
            placeholder={translate('signature')}
            name={ASSET_CAMEL_CASE_TYPE_ENUM.SIGNATURE}
            onViewDocument={(fileId: string) =>
              viewFile(fileId, ASSET_TYPE_ENUM.SIGNATURE, getValues, assets, isEditMode)
            }
            incorrectFileFormatLabel={translate('incorrectFileFormat')}
            dragDropFilesHereLabel={getDragAndDropDocumentationLabel(
              translate,
              getValues(ASSET_CAMEL_CASE_TYPE_ENUM.SIGNATURE)?.fileId
                ? DOCUMENT_UPLOAD_STATUS.UPLOADED
                : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
            )}
            clickToViewDocumentLabel={getViewDocumentationLabel(
              translate,
              getValues(ASSET_CAMEL_CASE_TYPE_ENUM.SIGNATURE)?.fileId
                ? DOCUMENT_UPLOAD_STATUS.UPLOADED
                : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
            )}
            isEditMode={isEditMode}
            onUploadFile={async (file) =>
              await uploadUnassignedAsset(file, ASSET_CAMEL_CASE_TYPE_ENUM.SIGNATURE, setValue)
            }
            disabled={!getValues(ASSET_CAMEL_CASE_TYPE_ENUM.SIGNATURE)?.fileId && !isEditMode}
            onDelete={(name: string) =>
              setValue(
                name,
                { fileId: undefined, url: undefined, type: name },
                { shouldDirty: true, shouldValidate: true }
              )
            }
            errors={errors?.signature}
          />
          <ControlledUpload
            placeholder={translate('commercialRegister')}
            name={ASSET_CAMEL_CASE_TYPE_ENUM.COMMERCIAL_REGISTER}
            onViewDocument={(fileId: string) =>
              viewFile(fileId, ASSET_TYPE_ENUM.COMMERCIAL_REGISTER, getValues, assets, isEditMode)
            }
            incorrectFileFormatLabel={translate('incorrectFileFormat')}
            dragDropFilesHereLabel={getDragAndDropDocumentationLabel(
              translate,
              getValues(ASSET_CAMEL_CASE_TYPE_ENUM.COMMERCIAL_REGISTER)?.fileId
                ? DOCUMENT_UPLOAD_STATUS.UPLOADED
                : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
            )}
            clickToViewDocumentLabel={getViewDocumentationLabel(
              translate,
              getValues(ASSET_CAMEL_CASE_TYPE_ENUM.COMMERCIAL_REGISTER)?.fileId
                ? DOCUMENT_UPLOAD_STATUS.UPLOADED
                : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
            )}
            isEditMode={isEditMode}
            onUploadFile={async (file) =>
              await uploadUnassignedAsset(file, ASSET_CAMEL_CASE_TYPE_ENUM.COMMERCIAL_REGISTER, setValue)
            }
            disabled={!getValues(ASSET_CAMEL_CASE_TYPE_ENUM.COMMERCIAL_REGISTER)?.fileId && !isEditMode}
            onDelete={(name: string) =>
              setValue(
                name,
                { fileId: undefined, url: undefined, type: name },
                { shouldDirty: true, shouldValidate: true }
              )
            }
            errors={errors?.commercialRegister}
          />
        </Documents>
        <AccountCalculationBox
          label={translate('accountStatement')}
          value={accountStatement.toString()}
          iconUrl={currency?.iconUrl}
        />
      </DocumentationBox>
    </Container>
  );
};

const Documents = styled.div`
  display: flex;
  gap: 2rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DocumentationBox = styled.div`
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 2rem;
`;
