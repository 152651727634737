import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import ControlledInput from '@/components/Form/ControlledInput';
import { ASSET_TYPE_ENUM } from '@/enums/AssetTypeEnum';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { AssetResponse, UserFullResponse } from '@/shared/types/api';
import { useAssignedAssetUrlMutation } from '@/store/service';
import { findAssetByType } from '@/utils/assets/findAssetByType';
import { parseFormObject } from '@/utils/getUserFieldValues/getUserFieldValues';
import { setFieldValues } from '@/utils/setFieldValues/setFieldValues';
import {
  CompanyInfoFormContainer,
  CompanyLogo,
  CompanyLogoWrapper,
  CompanyInfoWrapper,
  RowFirst,
  Column,
  CompanyInfoContainer,
} from '@/view/Shared/ViewBond/CompanyInfo/CompanyInfoForm/CompanyInfoForm.styles';

interface CompanyInfoFormProps {
  user: UserFullResponse;
  assets: AssetResponse[];
  isEditMode: boolean;
}

const CompanyInfoForm: FC<CompanyInfoFormProps> = ({ user, assets, isEditMode }) => {
  const { translate } = useTranslation();
  const { setValue } = useFormContext();
  const [getImageUrl, { data: imageUrl }] = useAssignedAssetUrlMutation();

  useEffect(() => {
    if (user && assets) {
      setFieldValues(parseFormObject(user, assets, translate), setValue);
    }
  }, [user, assets]);

  useEffect(() => {
    const picture = findAssetByType(assets, ASSET_TYPE_ENUM.PICTURE);
    if (assets && assets.length && picture) {
      getImageUrl({ assetId: picture.id });
    }
  }, [assets]);

  return (
    <CompanyInfoFormContainer>
      <CompanyInfoWrapper>
        <CompanyLogoWrapper>{imageUrl && <CompanyLogo src={imageUrl} />}</CompanyLogoWrapper>
        <CompanyInfoContainer>
          <RowFirst>
            <Column>
              <ControlledInput
                placeholder={translate('corporateName')}
                id={'name'}
                name={'name'}
                disabled={!isEditMode}
              />
            </Column>
            <Column>
              <ControlledInput
                placeholder={translate('phoneNumber')}
                id={'phoneNumber'}
                name={'phoneNumber'}
                disabled={!isEditMode}
              />
            </Column>
            <Column>
              <ControlledInput
                placeholder={translate('legalForm')}
                id={'legalForm'}
                name={'legalForm'}
                disabled={!isEditMode}
              />
            </Column>
          </RowFirst>
          <RowFirst>
            <Column>
              <ControlledInput
                placeholder={translate('businessFocus')}
                id={'businessFocus'}
                name={'businessFocus'}
                disabled={!isEditMode}
              />
            </Column>
            <Column>
              <ControlledInput
                placeholder={translate('address')}
                id={'address'}
                name={'address'}
                disabled={!isEditMode}
              />
            </Column>
            <Column>
              <ControlledInput placeholder={translate('city')} id={'city'} name={'city'} disabled={!isEditMode} />
            </Column>
          </RowFirst>
          <RowFirst>
            <Column>
              <ControlledInput
                placeholder={translate('emailAddress')}
                id={'email'}
                name={'email'}
                disabled={!isEditMode}
              />
            </Column>
            <Column>
              <ControlledInput placeholder={translate('owner')} id={'owner'} name={'owner'} disabled={!isEditMode} />
            </Column>
            <Column>
              <ControlledInput
                placeholder={translate('zipCode')}
                id={'zipCode'}
                name={'zipCode'}
                disabled={!isEditMode}
              />
            </Column>
          </RowFirst>
          <RowFirst>
            <Column>
              <ControlledInput placeholder={translate('ceo')} id={'ceo'} name={'ceo'} disabled={!isEditMode} />
            </Column>
            <Column>
              <ControlledInput
                placeholder={translate('country')}
                id={'country'}
                name={'country'}
                disabled={!isEditMode}
              />
            </Column>
          </RowFirst>
        </CompanyInfoContainer>
      </CompanyInfoWrapper>
    </CompanyInfoFormContainer>
  );
};

export default CompanyInfoForm;
