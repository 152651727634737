import { APPS } from '@/enums/App';

export const getSubDomain = (): APPS => {
  const hostName = window.location.hostname;

  if (!hostName) {
    throw new Error('Invalid hostname');
  }
  return (hostName.split('.').reduce((prev: string, current: string) => {
    if (current.toLowerCase() === APPS.ADMIN) {
      prev = APPS.ADMIN;
    }

    if (current.toLowerCase() === APPS.VERIFY) {
      prev = APPS.VERIFY;
    }

    return prev;
  }, '') || APPS.CORE) as APPS;
};
