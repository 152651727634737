import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const regionsSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {},
});

export default regionsSlice.reducer;
