import { Card } from 'issuix-ui-kit';
import styled from 'styled-components';

import { SettingsIcon } from '@/assets/core/icons';

export const UsersContainer = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;
`;

export const TableContainer = styled.div`
  height: calc(100% - 5rem);
`;

export const FiltersContainer = styled.div`
  height: 30%;
  background: linear-gradient(
    183.73deg,
    rgba(255, 255, 255, 0.1) 1.73%,
    rgba(233, 233, 233, 0.19) 99.66%,
    rgba(233, 233, 233, 0.25) 99.66%
  );
  border-radius: 3rem;
  display: flex;
  padding: 3rem;
  flex-direction: column;
`;

export const StyledSettingsIcon = styled(SettingsIcon)`
  height: 2.4rem;
  width: 2.4rem;
  cursor: pointer;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const UsersCard = styled(Card).attrs(() => ({
  notFullHeight: true,
}))`
  height: 100%;
  width: 100%;
  padding: 3rem;
`;

export const ButtonBox = styled.div`
  display: flex;
  gap: 1rem;
`;
