import { VerifyAccountRequest } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const verifyAccountPath = 'auth/verify-account';

export const verifyAccount = (build: BuildType) => {
  return build.mutation<void, VerifyAccountRequest>({
    query: ({ verifyAccountToken }) => ({
      url: verifyAccountPath,
      method: 'POST',
      body: { verifyAccountToken },
    }),
  });
};
