import * as yup from 'yup';

export const transactionInfoFormSchema = () => {
  yup.object().shape({
    amount: yup.string(),
    smartCode: yup.string(),
    quantity: yup.number(),
    status: yup.string(),
  });
};
