import { Card } from 'issuix-ui-kit';
import styled from 'styled-components';

export const ShareholdersLedgerContainer = styled(Card).attrs(() => ({
  notFullHeight: false,
}))`
  width: 100%;
  padding: 3rem;
  position: relative;
`;

export const TableContainer = styled.div`
  height: 400px;
  padding-top: 3rem;
`;

export const HeaderWrapper = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr auto auto;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 3rem;
  top: 3rem;
`;
