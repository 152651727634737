import { Button, ModalDialog } from 'issuix-ui-kit';
import { FC } from 'react';

import { ButtonsContainer, StyledText } from './DeleteModal.styles';

import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';

interface DeleteModalProps {
  isDeleteModalOpen: boolean;
  onDeleteModalClose: () => void;
  onDelete: () => void;
  title: string;
  subtitle: string;
  isDeleteLoading?: boolean;
}

export const DeleteModal: FC<DeleteModalProps> = ({
  isDeleteModalOpen,
  onDeleteModalClose,
  onDelete,
  title,
  subtitle,
  isDeleteLoading,
}) => {
  const { translate } = useTranslation();

  return (
    <ModalDialog isOpen={isDeleteModalOpen} close={onDeleteModalClose}>
      <StyledText content={title} size={'extraLarge'} />
      <StyledText content={subtitle} size={'medium'} />
      <ButtonsContainer>
        <Button text={translate('delete')} onClick={onDelete} buttonType={'danger'} isLoading={isDeleteLoading} />
        <Button
          text={translate('cancel')}
          onClick={onDeleteModalClose}
          buttonType={'secondary'}
          disabled={isDeleteLoading}
        />
      </ButtonsContainer>
    </ModalDialog>
  );
};
