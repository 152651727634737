import { Button, Text, GreenStateTheme } from 'issuix-ui-kit';
import { useEffect } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { useAuthContext } from '@/components/Auth/AuthContext';
import { useRegistrationContext } from '@/components/AuthModal/RegistrationContext';
import { FormContainer } from '@/components/AuthModal/ResetPasswordWithCodeModal/ResetPasswordWithCodeModal.styles';
import ControlledCode from '@/components/Form/ControlledCode';
import ControlledPassword from '@/components/Form/ControlledPassword';
import { AUTH_MODAL_TYPE } from '@/enums/AuthModalType';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useResetPasswordWithCodeMutation } from '@/store/service';

const ResetPasswordWithCodeForm = () => {
  const { translate } = useTranslation();
  const {
    handleSubmit,
    formState: { isValid },
  } = useFormContext();
  const [resetPasswordWithCode, { isLoading }] = useResetPasswordWithCodeMutation();
  const { setModalType } = useAuthContext();
  const { forgotPasswordEmail } = useRegistrationContext();

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.code === 'Enter') {
        submitHandler();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [isValid]);

  const submitHandler = () => {
    handleSubmit((data: FieldValues) => {
      const validEmail: string = forgotPasswordEmail ? forgotPasswordEmail : '';
      data.email = validEmail;
      resetPasswordWithCode(data);
    })();
    return setModalType(AUTH_MODAL_TYPE.SIGN_IN);
  };

  return (
    <FormContainer>
      <InputContainer>
        <ControlledPassword name={'newPassword'} placeholder={translate('password')} id={'newPassword'} />
        <ControlledPassword
          name={'passwordConfirmation'}
          placeholder={translate('passwordConfirmation')}
          id={'passwordConfirmation'}
        />
        <CodeInputContainer>
          <Text content={translate('code')} color={GreenStateTheme.colors.green.primary} />
          <ControlledCode name={'code'} placeholder={translate('code')} />
        </CodeInputContainer>
      </InputContainer>
      <Button onClick={submitHandler} text={translate('submit')} isLoading={isLoading} disabled={!isValid} />
    </FormContainer>
  );
};

export default ResetPasswordWithCodeForm;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const CodeInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
