import { Text } from 'issuix-ui-kit';
import { FC } from 'react';

import ControlledForm from '@/components/Form/ControlledForm';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { BondTransactionResponse, OrderResponse, ShareTransactionResponse } from '@/shared/types/api';
import TransactionInfoForm from '@/view/Admin/TransactionSingleView/TransactionInfo/TransactionInfoForm/TransactionInfoForm';
import { transactionInfoFormSchema } from '@/view/Admin/TransactionSingleView/TransactionInfo/TransactionInfoForm/TransactionInfoFormSchema';
import {
  TransactionSingleViewContainer,
  CardHeader,
} from '@/view/Admin/TransactionSingleView/TransactionSingleView.styles';

interface TransactionInfoProps {
  transaction: BondTransactionResponse | ShareTransactionResponse;
  order?: OrderResponse;
  type: string;
}

const TransactionInfo: FC<TransactionInfoProps> = ({ transaction, type, order = undefined }) => {
  const { translate } = useTranslation();

  return (
    <TransactionSingleViewContainer>
      <CardHeader>
        <Text content={translate('transactionSingleView')} size={'large'} />
      </CardHeader>
      <ControlledForm schema={transactionInfoFormSchema}>
        <TransactionInfoForm transaction={transaction} type={type} order={order} />
      </ControlledForm>
    </TransactionSingleViewContainer>
  );
};

export default TransactionInfo;
