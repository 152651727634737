import { Button, IColumn, Table, Text } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { STATUS_ENUM } from '@/enums/Status';
import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { renderColumn } from '@/shared/commonUtils/renderColumn/renderColumn';
import { TransactionTable } from '@/shared/types/TransactionTable';
import { useGetItemTransactionsQuery } from '@/store/service';
import { composePath } from '@/utils/composePath/ComposePath';
import { formatDate } from '@/utils/date/FormatDate';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';
import { TableContainer } from '@/view/Shared/ViewBond/Transactions/Transaction.styles';
import { CardHeader, SmartBondContainer } from '@/view/Shared/ViewBond/ViewBond.styles';

interface TransactionsProps {
  itemId: string;
  canViewTransaction: boolean;
}

const Transactions: FC<TransactionsProps> = ({ itemId, canViewTransaction }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const { fetch, data, setFilters } = useTableWithPagination({
    query: useGetItemTransactionsQuery,
    id: itemId,
    additionalId: undefined,
    skipIfNoFilters: true,
  });

  useEffect(() => {
    setFilters({ status: STATUS_ENUM.ACCEPTED });
  }, []);

  const onAction = (id: string) => {
    navigate(composePath(ClientRoutes.Dashboard.transaction, { bondId: itemId, id: id }));
  };

  const renderViewTransaction = () => {
    return canViewTransaction
      ? {
          name: 'actions',
          width: 2,
          header: {
            label: translate('actions').toUpperCase(),
          },
          column: {
            render: (item: TransactionTable) => (
              <Button fullyRounded onClick={() => onAction(item.id)} text={translate('view')} size={'medium'} />
            ),
          },
        }
      : renderColumn('', 0);
  };

  const tableDef: IColumn[] = [
    {
      name: 'buyerName',
      width: 2,
      header: {
        label: translate('companyName').toUpperCase(),
      },
    },
    {
      name: 'country',
      width: 2,
      header: {
        label: translate('country').toUpperCase(),
      },
    },
    {
      name: 'email',
      width: 2,
      header: {
        label: translate('email').toUpperCase(),
      },
    },
    {
      name: 'quantity',
      width: 1,
      header: {
        label: translate('quantity').toUpperCase(),
      },
    },
    {
      name: 'createdAt',
      width: 2,
      header: {
        label: translate('boughtDate').toUpperCase(),
      },
      column: {
        render: (item: TransactionTable) => {
          return <p>{item.createdAt ? formatDate(item.createdAt) : undefined}</p>;
        },
      },
    },
    {
      name: 'detectedAmount',
      width: 2,
      header: {
        label: translate('detectedAmount').toUpperCase(),
      },
    },
    renderViewTransaction(),
  ];

  return (
    <SmartBondContainer>
      <CardHeader>
        <Text content={translate('acceptedTransactions')} size={'large'} />
      </CardHeader>
      <TableContainer>
        <Table data={data} fetchTrigger={() => fetch()} tableDef={tableDef} />
      </TableContainer>
    </SmartBondContainer>
  );
};

export default Transactions;
