export const chartColors = [
  '#01A58D',
  '#F68E51',
  '#EF3331',
  '#154576',
  '#F9E1C9',
  '#B995C5',
  '#FEE05E',
  '#BDD84D',
  '#98CCB6',
  '#7EB7D5',
];
