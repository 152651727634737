import { stringify } from 'qs';

import { pagingLimit } from '@/shared/constants/Paging';
import { PageableItems } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseSharesUrl = 'shares';

const getSharesUrl = (
  offset: number | undefined,
  query: {
    companyName: string;
    minInvestment: number;
    maxInvestment: number;
    country: string;
    round: string;
    currencyId: string;
    code: string;
    status: string;
  }
): string => {
  return `${baseSharesUrl}?offset=${offset ?? 0}&limit=${pagingLimit}&${stringify(query)}`;
};

export const getShares = (build: BuildType) => {
  return build.query<PageableItems, any>({
    providesTags: ['GetShares'],
    query: ({ offset, query }) => ({
      url: getSharesUrl(offset, query),
      method: 'GET',
    }),
  });
};
