import { Text } from 'issuix-ui-kit';
import { FC } from 'react';
import styled from 'styled-components';

import { resetPasswordWithCodeSchema } from './ResetPasswordWithCodeSchema';

import ResetPasswordWithCodeForm from '@/components/AuthModal/ResetPasswordWithCodeModal/ResetPasswordWithCodeForm/ResetPasswordWithCodeForm';
import { LogoContainer } from '@/components/AuthModal/ResetPasswordWithCodeModal/ResetPasswordWithCodeModal.styles';
import ControlledForm from '@/components/Form/ControlledForm';
import Logo from '@/components/Logo/Logo';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { getTenant } from '@/utils/getTenant/getTenant';

interface ResetPasswordWithCodeModalProps {
  email?: string;
}

const ResetPasswordWithCodeModal: FC<ResetPasswordWithCodeModalProps> = () => {
  const { translate } = useTranslation();
  const tenant = getTenant();

  return (
    <ResetPasswordContainer>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <Text content={translate('gettingBackIntoAccount', { tenant: tenant.toUpperCase() })} size={'large'} />
      <br />
      <Text content={`${translate('checkEmail')}`} size={'medium'} />
      <ControlledForm schema={resetPasswordWithCodeSchema(translate)}>
        <ResetPasswordWithCodeForm />
      </ControlledForm>
    </ResetPasswordContainer>
  );
};

export default ResetPasswordWithCodeModal;

const ResetPasswordContainer = styled.div`
  margin-top: 2rem;
`;
