import { configureStore } from '@reduxjs/toolkit';

import auditSlice from './audit/slice';
import bondReducer from './bonds/slice';
import caseReducer from './cases/slice';
import currencyReducer from './currencies/slice';
import globalReducer from './global/slice';
import nftReducer from './nft/slice';
import countryReducer from './resources/country/slice';
import legalFormReducer from './resources/legal-form/slice';
import optionsReducer from './resources/options/slice/index';
import regionReducer from './resources/region/slice';
import { api } from './service';
import shareReducer from './shares/slice';
import transactionReducer from './transactions/slice';

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    currency: currencyReducer,
    global: globalReducer,
    country: countryReducer,
    legalForm: legalFormReducer,
    region: regionReducer,
    bond: bondReducer,
    share: shareReducer,
    options: optionsReducer,
    case: caseReducer,
    transaction: transactionReducer,
    audit: auditSlice,
    nft: nftReducer,
  },
  middleware: (gDM) => gDM().concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
