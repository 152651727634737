import { BuildType } from '@/shared/types/redux/ReduxAction';

const approveCasePath = '/case/approve-case';

const getApproveCaseUrl = (caseId: string) => {
  return `${approveCasePath}/?caseId=${caseId}`;
};

export const approveCase = (build: BuildType) => {
  return build.mutation<void, any>({
    invalidatesTags: ['GetCasesForRecipient', 'GetCaseDetails'],
    query: ({ caseId }) => ({
      url: getApproveCaseUrl(caseId),
      method: 'POST',
    }),
  });
};
