import { ModalDialog, Text } from 'issuix-ui-kit';
import { FC } from 'react';

import ChangePasswordModalForm from '@/components/ChangePasswordModal/ChangePasswordModalForm/ChangePasswordModalForm';
import { getChangePasswordModalFormSchema } from '@/components/ChangePasswordModal/ChangePasswordModalForm/GetChangePasswordModalFormSchema';
import ControlledForm from '@/components/Form/ControlledForm';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';

interface ChangePasswordModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const ChangePasswordModal: FC<ChangePasswordModalProps> = ({ isOpen, setIsOpen }) => {
  const { translate } = useTranslation();

  return (
    <ModalDialog isOpen={isOpen} close={() => setIsOpen(false)}>
      <Text content={translate('changePassword')} size={'large'} />
      <ControlledForm schema={getChangePasswordModalFormSchema(translate)}>
        <ChangePasswordModalForm setIsOpen={setIsOpen} />
      </ControlledForm>
    </ModalDialog>
  );
};

export default ChangePasswordModal;
