import { Text } from 'issuix-ui-kit';
import { FC, ReactNode } from 'react';

import {
  IconWrapper,
  PaymentOptionCardContainer,
} from '@/components/Payment/PaymentModal/Steps/ThirdStepPaymentMethod/NonCrypto/PaymentOptionButton/PaymentOptionCard.style';

type Props = {
  icon: ReactNode;
  label: string;
  isSelected: boolean;
  onClick: () => void;
  className?: string;
};

const PaymentOptionCard: FC<Props> = ({ icon, label, isSelected, className, onClick }) => {
  return (
    <PaymentOptionCardContainer className={className} isSelected={isSelected} onClick={onClick}>
      <IconWrapper>{icon}</IconWrapper>
      <Text content={label} />
    </PaymentOptionCardContainer>
  );
};

export default PaymentOptionCard;
