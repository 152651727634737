import { Text } from 'issuix-ui-kit';
import { FC, useMemo, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { SmartBondFeatureFormContainer, Column, RowFirst } from './SmartBondFeaturesForm.styles';

import { Documentation } from '@/components/BondAndShare/Documentation';
import ControlledDateInput from '@/components/Form/ControlledDateInput';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledRadioButtonInput from '@/components/Form/ControlledRadioButtonInput';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledSelectMultiple from '@/components/Form/ControlledSelectMultiple';
import FullDisabledField from '@/components/FullDisabledField/FullDisabledField';
import { Row } from '@/components/Layout/Row/Row.styled';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useCouponPaidOptions } from '@/hooks/useCouponPaidOptions/useCouponPaidOptions';
import { useCurrencies } from '@/hooks/useCurrencies/UseCurrencies';
import { useRegions } from '@/hooks/useRegions/UseRegions';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { AssetResponse, BondResponse, CurrencyResponse } from '@/shared/types/api';
import { formatAmountWithoutRate } from '@/utils/formatAmount/FormatAmount';
import { parseFormObject } from '@/utils/getBondFieldValues/GetBondFieldValues';
import { getFilteredCurrencies } from '@/utils/getFilteredCurrencies/GetFilteredCurrencies';
import { setFieldValues } from '@/utils/setFieldValues/setFieldValues';

interface ViewSmartBondFeaturesProps {
  bond: BondResponse;
  assets: AssetResponse[];
}

const SmartBondFeaturesForm: FC<ViewSmartBondFeaturesProps> = ({ bond, assets }) => {
  const { translate } = useTranslation();
  const { currencies, currenciesAsDropdown } = useCurrencies();
  const { couponPaidOptionsAsDropdown } = useCouponPaidOptions(translate);
  const { regionsAsDropdown } = useRegions(translate);
  const {
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext();
  const filteredCurrencies: CurrencyResponse[] = useMemo(() => getFilteredCurrencies(currencies), [currencies]);
  const currency = useAppSelector((state) => state.global.currency);

  useEffect(() => {
    if (bond && currency) {
      setFieldValues(parseFormObject(bond, currency, translate, assets), setValue);
    }
  }, [bond]);

  const valueWatcher = watch(['value']);

  const [val, setVal] = useState<number>(0);

  useEffect(() => {
    if (valueWatcher.length && valueWatcher[0]) {
      setVal(valueWatcher[0]);
    } else {
      setVal(0);
    }
  }, [valueWatcher]);

  const calculatedValues = useMemo(() => {
    return filteredCurrencies.map((currency) => (
      <Column key={currency.currencyCode}>
        <FullDisabledField
          key={currency.currencyCode}
          value={+formatAmountWithoutRate(val / currency.rate, currency.decimals)}
          label={currency.currencyCode}
        />
      </Column>
    ));
  }, [filteredCurrencies, val]);

  const getListingOptions = () => [
    { label: translate('yes'), value: 'true' },
    { label: translate('no'), value: 'false' },
  ];

  return (
    <SmartBondFeatureFormContainer>
      <RowFirst>
        <Column>
          <ControlledInput placeholder={translate('faceValue')} id={'value'} name={'value'} disabled />
        </Column>
        <Column>
          <Text content={'='} size={'large'} />
        </Column>
        {calculatedValues}
      </RowFirst>
      <RowFirst>
        <Column>
          <ControlledSelectMultiple
            name={'sellInCurrencyIds'}
            items={currenciesAsDropdown}
            placeholder={translate('sellInCurrency')}
            disabled
          />
        </Column>
        <Column>
          <ControlledInput name={'denomination'} placeholder={translate('denomination')} id={'denomination'} disabled />
        </Column>
        <Column>
          <ControlledInput name={'interest'} placeholder={translate('interest')} id={'interest'} disabled />
        </Column>
        <Column>
          <ControlledSelect
            name={'couponsPaid'}
            placeholder={translate('couponsPaid')}
            items={couponPaidOptionsAsDropdown}
            disabled
          />
        </Column>
        <Column>
          <ControlledInput
            name={'offeringMonths'}
            placeholder={translate('offeringMonths')}
            id={'offeringMonths'}
            disabled
          />
        </Column>
        <Column>
          <ControlledDateInput name={'issuedDate'} placeholder={translate('issuedDate')} id={'issuedDate'} disabled />
        </Column>
        <Column>
          <ControlledInput name={'maturity'} placeholder={translate('maturity')} id={'maturity'} disabled />
        </Column>
        <Column>
          <ControlledSelectMultiple
            name={'regionIds'}
            placeholder={translate('availableTo')}
            items={regionsAsDropdown}
            disabled
          />
        </Column>
        <Column>
          <ControlledInput
            name={'remainingBonds'}
            placeholder={translate('available')}
            id={'remainingBonds'}
            disabled
          />
        </Column>
        <Column>
          <ControlledRadioButtonInput
            name={'listingOption'}
            options={getListingOptions()}
            defaultValue={'false'}
            description={translate('listing')}
            size={'small'}
            disabled
          />
        </Column>
      </RowFirst>
      <Row>
        <Documentation assets={assets} isEditMode={false} setValue={setValue} getValues={getValues} errors={errors} />
      </Row>
    </SmartBondFeatureFormContainer>
  );
};

export default SmartBondFeaturesForm;
