import { RoundOptionResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const roundOptionPath = 'round-option';

export const getRoundOptions = (build: BuildType) => {
  return build.query<RoundOptionResponse[], void>({
    query: () => roundOptionPath,
  });
};
