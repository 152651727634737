import { BuildType } from '@/shared/types/redux/ReduxAction';

const adminChangePasswordUrl = (id: string) => {
  return `/admin/users/${id}/update-password`;
};

export const adminChangePassword = (build: BuildType) => {
  return build.mutation<void, any>({
    query: ({ id, newPassword, passwordConfirmation }) => ({
      url: adminChangePasswordUrl(id),
      method: 'PATCH',
      body: { newPassword, passwordConfirmation },
    }),
  });
};
