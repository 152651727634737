import { CreateNftCollectionRequest, NftCollectionResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const createNftCollectionPath = 'core/nft-collection';

export const createNftCollection = (build: BuildType) => {
  return build.mutation<NftCollectionResponse, CreateNftCollectionRequest>({
    invalidatesTags: ['GetNftCollections'],
    query: (nftCollection: CreateNftCollectionRequest) => ({
      url: createNftCollectionPath,
      method: 'POST',
      body: nftCollection,
    }),
  });
};
