import { useMemo } from 'react';

import { PRODUCT_TYPE_ENUM } from '@/enums/ProductType';
import { Nullable } from '@/shared/types/Nullable';
import { BondResponse, CurrencyResponse, ShareResponse } from '@/shared/types/api';
import { getDenomination, getPricePerShare } from '@/utils/getBondWithDenomination/getBondWithDenomination';

export const useResourcePrice = ({
  currency,
  resourceType,
  resource,
}: {
  currency: Nullable<CurrencyResponse>;
  resourceType: Nullable<PRODUCT_TYPE_ENUM>;
  resource: Nullable<BondResponse | ShareResponse>;
}) => {
  const resourcePrice = useMemo<number>(() => {
    if (!currency || !resourceType || !resource) {
      return -1;
    }

    if (resourceType === PRODUCT_TYPE_ENUM.BOND_PAPERS) {
      const bondResource = resource as BondResponse;

      return getDenomination(bondResource.value, bondResource.quantity, currency);
    } else if (resourceType === PRODUCT_TYPE_ENUM.ACTIONS) {
      const shareResource = resource as ShareResponse;

      return getPricePerShare(shareResource.price, currency);
    }

    return -1;
  }, [currency, resourceType, resource]);

  return resourcePrice;
};
