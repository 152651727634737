import { Button, Text } from 'issuix-ui-kit';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DeleteModal } from '@/components/DeleteModal/DeleteModal';
import ControlledForm from '@/components/Form/ControlledForm';
import {
  EditButtonContainer,
  NftCollectionDetailsContainer,
} from '@/components/NftCollectionDetails/NftCollectionDetails.style';
import NftCollectionDetailsForm from '@/components/NftCollectionDetails/components/NftCollectionDetailsForm';
import { nftCollectionDetailsFormSchema } from '@/components/NftCollectionDetails/components/NftCollectionDetailsFormSchema';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { NftCollectionResponse } from '@/shared/types/api';
import { useDeleteNftCollectionMutation } from '@/store/service';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';

interface NftCollectionDetailsProps {
  nftCollection: NftCollectionResponse;
}

const NftCollectionDetails: FC<NftCollectionDetailsProps> = ({ nftCollection }) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const { translate } = useTranslation();
  const navigate = useNavigate();

  const [deleteCollection, { loading }] = useDeleteNftCollectionMutation();

  const onDeleteCollection = async (id: string) => {
    await deleteCollection({ collectionId: id });
    setIsDeleteModalOpen(false);
    navigate(`/${ClientRoutes.Dashboard.nft}`);
  };

  return (
    <NftCollectionDetailsContainer>
      <Text content={translate('nftCollection')} size={'large'} />
      <EditButtonContainer>
        {/*{!isEditMode && <Button onClick={() => setIsEditMode(!isEditMode)} text={translate('edit')} />}*/}
        {<Button onClick={() => setIsDeleteModalOpen(true)} text={translate('delete')} />}
      </EditButtonContainer>
      <ControlledForm schema={nftCollectionDetailsFormSchema(translate)}>
        <NftCollectionDetailsForm
          nftCollection={nftCollection}
          isEditMode={isEditMode}
          assets={nftCollection.assets}
          productAsset={nftCollection.product.image}
          setIsEditMode={setIsEditMode}
        />
      </ControlledForm>
      <DeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        onDelete={() => onDeleteCollection(nftCollection.id)}
        onDeleteModalClose={() => setIsDeleteModalOpen(false)}
        title={translate('deleteCollection')}
        subtitle={translate('areYouSureDeleteCollection', { name: nftCollection.name })}
        isDeleteLoading={loading}
      />
    </NftCollectionDetailsContainer>
  );
};

export default NftCollectionDetails;
