import styled from 'styled-components';

import Logo from '@/components/Logo/Logo';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';

const NotAvailableMobile = () => {
  const { translate } = useTranslation();

  return (
    <Container>
      <Logo />
      <InfoText>{translate('notAvailable')}</InfoText>
    </Container>
  );
};

export default NotAvailableMobile;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, rgba(27, 90, 161, 0.8) 0%, rgba(64, 153, 232, 0.8) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const InfoText = styled.p`
  color: white;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.25);
`;
