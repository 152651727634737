import { Card } from 'issuix-ui-kit';
import styled from 'styled-components';

export const SmartShareLedgerCard = styled(Card)`
  width: 100%;
  padding: 3rem;
  position: relative;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.5rem;
`;
