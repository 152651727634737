import { Avatar, Text } from 'issuix-ui-kit';
import { FC } from 'react';

import { UserInfoContainer, UseInfoBox, AvatarWrraper } from './UserInfo.style';

type Props = {
  name: string;
  role: string;
  avatarImg?: string;
  hasIsActive?: boolean;
  isActive?: boolean;
};
const UserInfo: FC<Props> = ({ avatarImg = '', name, role, hasIsActive = false, isActive = false }) => {
  return (
    <UserInfoContainer>
      <UseInfoBox>
        <Text size="large" content={name} />
        <Text size="small" content={role!.toUpperCase()} />
      </UseInfoBox>
      <AvatarWrraper>
        <Avatar
          userName={name ? name.toUpperCase() : ''}
          image={avatarImg}
          active={{ hasIsActive: hasIsActive, isActive: isActive }}
        />
      </AvatarWrraper>
    </UserInfoContainer>
  );
};

export default UserInfo;
