import { Button } from 'issuix-ui-kit';
import { useNavigate } from 'react-router-dom';

import { CreateNft } from '@/components/CreateNft/CreateNft';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { composePath } from '@/utils/composePath/ComposePath';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';
import { CreateNftCollectionWrapper, FinishCard } from '@/view/Core/CreateNftCollection/CreateNftCollection.styles';
import {
  CreateNftCollectionContextProvider,
  useCreateNftCollectionContext,
} from '@/view/Core/CreateNftCollection/CreateNftCollectionContext';
import { CreateNftCollectionForm } from '@/view/Core/CreateNftCollection/CreateNftCollectionForm/CreateNftCollectionForm';
// import { UploadedNfts } from '@/view/Core/CreateNftCollection/CreateNftCollectionForm/UploadedNfts';

export const CreateNftCollection = () => {
  return (
    <CreateNftCollectionContextProvider>
      <CreateNftCollectionView />
    </CreateNftCollectionContextProvider>
  );
};

const CreateNftCollectionView = () => {
  const { translate } = useTranslation();
  const { nftCollectionId } = useCreateNftCollectionContext();
  const navigate = useNavigate();

  const onFinishClick = () => {
    navigate(
      composePath(`/${ClientRoutes.Dashboard.nft}/${ClientRoutes.Dashboard.nftCollection}`, { id: nftCollectionId })
    );
  };

  return (
    <CreateNftCollectionWrapper>
      <CreateNftCollectionForm />
      <CreateNft />
      {/* <UploadedNfts /> */}
      <FinishCard>
        <Button text={translate('finish')} onClick={onFinishClick} disabled={!nftCollectionId} />
      </FinishCard>
    </CreateNftCollectionWrapper>
  );
};
