import { Button } from 'issuix-ui-kit';
import { FC } from 'react';

import greenStateLogo from '@/assets/core/icons/green-state-logo.svg';
import { useOnAuthorizeClick } from '@/hooks/useOnAutorizeClick/useOnAuthorizeClick';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import {
  ButtonWrapper,
  FirstButton,
  ItemValueWrapper,
  StyledCard,
  ListWrapper,
  ItemContainer,
  GreenStateIcon,
} from '@/view/Core/Landing/components/LandingComponents.style';
import TenantBox from '@/view/Core/Landing/components/TenantBox';

interface Props {
  data: Data[];
  isBondListing?: boolean;
}

interface Data {
  icon: string;
  payment: string;
  fundsRaised: string;
  pricePerShare: string;
  onChain: string;
  securityType: string;
  fundsRequested: string;
  headquarters: string;
}

const ListData: FC<Props> = ({ data, isBondListing = false }) => {
  const { translate } = useTranslation();

  const { authProtectedClick } = useOnAuthorizeClick();

  return (
    <ListWrapper isBondListing={isBondListing}>
      {data.map((item: any, index: number) => {
        return (
          <StyledCard key={index}>
            <ItemContainer>
              <ItemValueWrapper>
                <GreenStateIcon src={greenStateLogo} />
                <TenantBox label={translate('onChain')} value={item.onChain} hasRadio />
                <TenantBox label={translate('payment')} value={item.payment} />
                <TenantBox label={translate('securityType')} value={item.securityType} />
                <TenantBox label={translate('fundsRaised')} value={item.fundsRaised} />
                <TenantBox label={translate('fundsRequested')} value={item.fundsRequested} />
                <TenantBox label={translate('pricePerShare')} value={item.pricePerShare} />
                <TenantBox label={translate('headQuarters')} value={item.headquarters} />
              </ItemValueWrapper>
              <ButtonWrapper>
                <FirstButton
                  onClick={() => authProtectedClick()}
                  text={translate('view')}
                  buttonType={'secondary'}
                  size={'large'}
                />
                <Button
                  onClick={() => authProtectedClick()}
                  text={translate('buy')}
                  buttonType={'primary'}
                  size={'large'}
                />
              </ButtonWrapper>
            </ItemContainer>
          </StyledCard>
        );
      })}
    </ListWrapper>
  );
};

export default ListData;
