import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const caseSlice = createSlice({
  name: 'case',
  initialState,
  reducers: {},
});

export default caseSlice.reducer;
