import React, { createContext, FC, useContext } from 'react';

import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { PageableItems } from '@/shared/types/api';
import { useGetSharesQuery } from '@/store/service';

interface ISmartShareListingRequestsContext {
  data: (string | PageableItems)[];
  fetch: () => void;
  setFilters: (filters: { [x: string]: any } | undefined) => void;
  resetData: () => void;
}

interface SmartShareListingContextProviderProps {
  children: React.ReactNode;
}

export const SmartShareListingContext = createContext({} as ISmartShareListingRequestsContext);

export const useSmartShareListingContext = () => useContext(SmartShareListingContext);

export const SmartShareListingContextProvider: FC<SmartShareListingContextProviderProps> = ({ children }) => {
  const { fetch, resetData, data, setFilters } = useTableWithPagination({
    query: useGetSharesQuery,
    skipIfNoFilters: true,
  });

  return (
    <SmartShareListingContext.Provider
      value={{
        data,
        fetch,
        setFilters,
        resetData,
      }}
    >
      {children}
    </SmartShareListingContext.Provider>
  );
};
