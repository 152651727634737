import { Button } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import ControlledInput from '@/components/Form/ControlledInput';
import ControlledRadioButtonInput from '@/components/Form/ControlledRadioButtonInput';
import ControlledSelect from '@/components/Form/ControlledSelect';
import { useCurrencies } from '@/hooks/useCurrencies/UseCurrencies';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { PaidOptions } from '@/shared/constants/PaidOptions';
import { useTransactionsToBuyersContext } from '@/view/Admin/TransactionsToBuyers/TransactionsToBuyersContext';

const TransactionsToBuyersFilterForm: FC = () => {
  const { translate } = useTranslation();
  const { currenciesAsDropdown } = useCurrencies();

  const { setFilters, resetData } = useTransactionsToBuyersContext();

  const {
    reset,
    watch,
    formState: { isDirty },
  } = useFormContext();

  useEffect(() => {
    watch((value) => {
      resetData();
      setFilters(value);
    });
  }, [watch]);

  return (
    <FilterContainer>
      <InputContainer>
        <ControlledInput placeholder={translate('smartBondCode')} name={'smartBondCode'} />
      </InputContainer>
      <InputContainer>
        <ControlledInput name={'priceMinimum'} placeholder={translate('priceMinimum')} />
      </InputContainer>
      <InputContainer>
        <ControlledInput name={'priceMaximum'} placeholder={translate('priceMaximum')} />
      </InputContainer>
      <InputContainer>
        <ControlledSelect items={currenciesAsDropdown} placeholder={translate('currency')} name={'currency'} />
      </InputContainer>
      <InputContainer>
        <ControlledInput name={'buyer'} placeholder={translate('buyerName')} />
      </InputContainer>
      <InputContainer>
        <ControlledRadioButtonInput
          name={'paid'}
          options={PaidOptions}
          defaultValue={''}
          description={translate('paid')}
        />
      </InputContainer>
      <ButtonContainer>
        <Button
          onClick={() => {
            isDirty && reset();
          }}
          text={translate('reset')}
        />
      </ButtonContainer>
    </FilterContainer>
  );
};

export default TransactionsToBuyersFilterForm;

const FilterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 4rem;
  position: relative;
`;
const InputContainer = styled.div`
  width: 20%;
`;
const ButtonContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;
