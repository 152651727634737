import { Button, IColumn, Table, Text, Modal } from 'issuix-ui-kit';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SendDirectEmail } from '@/components/SendDirectEmail/SendDirectEmail';
import {
  HeaderWrapper,
  ShareholdersLedgerContainer,
  TableContainer,
} from '@/components/ShareholdersLedger/ShareholdersLedger.style';
import { TENANT } from '@/enums/App';
import { ROLE_TYPE } from '@/enums/RoleType';
import { useAuth } from '@/hooks/useAuth/useAuth';
import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { ShareholdersLedgerResponse } from '@/shared/types/ShareholdersLedgerResponse';
import { useGetShareholdersLedgerQuery, useLazyGetShareholdersPdfQuery } from '@/store/service';
import { composePath } from '@/utils/composePath/ComposePath';
import { formatDate } from '@/utils/date/FormatDate';
import { formatAmountWithoutRate } from '@/utils/formatAmount/FormatAmount';
import { getTenant } from '@/utils/getTenant/getTenant';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';

interface ShareholdersLedgerProps {
  userId: string;
}

const ShareholdersLedger: FC<ShareholdersLedgerProps> = ({ userId }) => {
  const { translate } = useTranslation();
  const { data, fetch } = useTableWithPagination({ query: useGetShareholdersLedgerQuery, id: userId });
  const [getShareholdersPdf, { data: pdfBuffer }] = useLazyGetShareholdersPdfQuery();
  const navigate = useNavigate();

  const tenant = getTenant();
  const { user } = useAuth();

  const [isDirectMailOpen, setIsDirectMailOpen] = useState<boolean>(false);

  const onActionClick = (id: string) => {
    navigate(composePath(ClientRoutes.Transactions.shareSingleView, { id: id }));
  };

  useEffect(() => {
    if (pdfBuffer) {
      const fileURL = URL.createObjectURL(pdfBuffer);
      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', 'shareholders.pdf');
      document.body.appendChild(link);
      link.click();
    }
  }, [pdfBuffer]);

  const tableDef: IColumn[] = [
    {
      name: 'buyerName',
      width: 2,
      header: {
        label: translate('name').toUpperCase(),
      },
    },
    {
      name: 'buyerCountry',
      width: 2,
      header: {
        label: translate('country').toUpperCase(),
      },
    },
    {
      name: 'address',
      width: 2,
      header: {
        label: translate('address').toUpperCase(),
      },
    },
    {
      name: 'phoneNumber',
      width: 2,
      header: {
        label: translate('phoneNumber').toUpperCase(),
      },
    },
    {
      name: 'email',
      width: 2,
      header: {
        label: translate('emailAddress').toUpperCase(),
      },
    },
    {
      name: 'createdAt',
      width: 2,
      header: {
        label: translate('dateBought').toUpperCase(),
      },
      column: {
        render: (item: ShareholdersLedgerResponse) => <p>{formatDate(item.createdAt).toString()}</p>,
      },
    },
    {
      name: 'quantity',
      width: 1,
      header: {
        label: translate('bought').toUpperCase(),
      },
    },
    {
      name: 'fiat',
      width: 1,
      header: {
        label: translate('price').toUpperCase(),
      },
      column: {
        render: (item: ShareholdersLedgerResponse) => <p>{formatAmountWithoutRate(+item.fiat, 2)} CHF</p>,
      },
    },
    {
      name: 'actions',
      width: 2,
      header: {
        label: translate('actions').toUpperCase(),
      },
      column: {
        render: (item: ShareholdersLedgerResponse) => (
          <Button fullyRounded onClick={() => onActionClick(item.id)} text={translate('view')} size={'medium'} />
        ),
      },
    },
  ];

  const onClickHandler = async () => {
    getShareholdersPdf({ userId });
  };

  return (
    <ShareholdersLedgerContainer>
      <HeaderWrapper>
        <Text content={translate('shareholdersLedger')} size={'large'} />
        {!(tenant === TENANT.META_SWISS && user?.role === ROLE_TYPE.ADMIN) && (
          <>
            <Button
              text={translate('downloadPdf')}
              onClick={onClickHandler}
              buttonType={'primary'}
              disabled={!data.length}
            />
            <Button onClick={() => setIsDirectMailOpen(true)} text={translate('directMail')} disabled={!data.length} />
          </>
        )}
      </HeaderWrapper>
      <TableContainer>
        <Table data={data} fetchTrigger={() => fetch} tableDef={tableDef}></Table>
      </TableContainer>
      <Modal isOpen={isDirectMailOpen} close={() => setIsDirectMailOpen(false)}>
        <SendDirectEmail onClose={() => setIsDirectMailOpen(false)} />
      </Modal>
    </ShareholdersLedgerContainer>
  );
};

export default ShareholdersLedger;
