import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Text } from 'issuix-ui-kit';
import { FC } from 'react';
import styled from 'styled-components';

import { PaymentBankIcon, PaymentCardIcon } from '@/assets/core/icons';
import { BankPaymentOption } from '@/components/Payment/PaymentModal/Steps/ThirdStepPaymentMethod/NonCrypto/BankPaymentOption/BankPaymentOption';
import PaymentOptionCard from '@/components/Payment/PaymentModal/Steps/ThirdStepPaymentMethod/NonCrypto/PaymentOptionButton/PaymentOptionCard';
import StripeCardDetails from '@/components/Payment/PaymentModal/Steps/ThirdStepPaymentMethod/NonCrypto/StripePaymentOption/StripeCardDetails';
import { usePaymentContext } from '@/components/Payment/PaymentModal/context/PaymentContext';
import CurrencyBlock from '@/components/Payment/PaymentModal/shared/CurrencyBlock/CurrencyBlock';
import { PAYMENT_TYPES } from '@/enums/PaymentTypes';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK!}`);

const ThirdStepPaymentMethodNonCrypto: FC = () => {
  const { setPaymentMethod, paymentMethod } = usePaymentContext();
  const { translate } = useTranslation();

  return (
    <Elements stripe={stripePromise}>
      <CurrencyBlock disabled={true}>
        <Text content={translate('choosePaymentMethod')} size={'large'} />
      </CurrencyBlock>
      <PaymentMethodContainer>
        <PaymentOptionCardPlace
          onClick={() => setPaymentMethod(PAYMENT_TYPES.CARD)}
          isSelected={paymentMethod === PAYMENT_TYPES.CARD}
          label={translate('creditCard')}
          icon={<PaymentCardIcon />}
        />
        <PaymentOptionCardPlace
          onClick={() => setPaymentMethod(PAYMENT_TYPES.BANK)}
          isSelected={paymentMethod === PAYMENT_TYPES.BANK}
          label={translate('bank')}
          icon={<PaymentBankIcon />}
        />
      </PaymentMethodContainer>
      {paymentMethod === PAYMENT_TYPES.CARD ? <StripeCardDetails /> : <BankPaymentOption />}
    </Elements>
  );
};

const PaymentOptionCardPlace = styled(PaymentOptionCard)`
  grid-column: auto;
  grid-row: 1;
  text-align: center;
  span {
    width: 100%;
  }
`;

const PaymentMethodContainer = styled.div`
  grid-row: 2 / 3;
  grid-column: 1 / 4;
  display: grid;
  justify-self: center;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 1.5rem;
`;

export default ThirdStepPaymentMethodNonCrypto;
