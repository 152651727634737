export enum DOCUMENT_STATUS {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

export enum DOCUMENT_UPLOAD_STATUS {
  UPLOADED = 'UPLOADED',
  NOT_UPLOADED = 'NOT_UPLOADED',
}

export enum DOCUMENT_LABEL_STATUS {
  VIEW = 'VIEW',
  NO_FILE = 'NO_FILE',
  UPLOADED = 'UPLOADED',
  UPLOAD = 'UPLOAD',
}
