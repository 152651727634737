import { Card } from 'issuix-ui-kit';
import styled from 'styled-components';

export const AcceptedTransactionsContainer = styled(Card).attrs(() => ({
  notFullHeight: true,
}))`
  width: 100%;
  padding: 4rem 2rem;
  position: relative;
`;

export const TableContainer = styled.div`
  height: 400px;
  padding-top: 3rem;
`;
