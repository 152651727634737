import { ValidateHashRequest, ValidateHashResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const validateHashUrl = 'transactions/validate-hash';

export const validateHash = (build: BuildType) => {
  return build.mutation<ValidateHashResponse, ValidateHashRequest>({
    query: (request: ValidateHashRequest) => ({
      url: validateHashUrl,
      method: 'POST',
      body: request,
    }),
  });
};
