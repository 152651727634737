import { TabsNavigation } from 'issuix-ui-kit';
import { useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import AccountNavigationItem from '@/components/AccountNavigation/AccountNavigationItem';
import AssignToShareModal from '@/components/AssignToShareModal/AssignToShareModal';
import Cases from '@/components/Cases/Cases';
import ProtectedRoute from '@/components/ProtectedRoute/ProtectedRoute';
import RoleProtected from '@/components/RoleProtected/RoleProtected';
import ShareholdersLedger from '@/components/ShareholdersLedger/ShareholdersLedger';
import UserDetailsView from '@/components/UserDetailsView/UserDetailsView';
import UserTransactions from '@/components/UserTransactions/UserTransactions';
import { TENANT } from '@/enums/App';
import { ROLE_TYPE } from '@/enums/RoleType';
import { useNav } from '@/hooks/useNav/UseNav';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import {
  useGetCorporateUserAssetsQuery,
  useGetCorporateUserQuery,
  useGetPrivateUserAssetsQuery,
  useGetPrivateUserQuery,
  useGetSmartSharesOfferedQuery,
  useGetUserRoleQuery,
} from '@/store/service';
import { getTenant } from '@/utils/getTenant/getTenant';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';
import Case from '@/view/Admin/Case/Case';
import NftPortfolio from '@/view/Admin/NftPortfolio/NftPortfolio';
import SmartBondsCreatedView from '@/view/Core/SmartBondCreatedView/SmartBondCreatedView';
import SmartBondPortfolio from '@/view/Core/SmartBondPortfolio/SmartBondPortfolio';
import SmartSharePortfolio from '@/view/Core/SmartSharePortfolio/SmartSharePortfolio';
import CouponsIssued from '@/view/Shared/CouponsIssued/CouponsIssued';

const UserDetails = () => {
  const location = useLocation();
  const { params } = useNav();
  const { translate } = useTranslation();
  const tenant = getTenant();

  const userId = useMemo(() => {
    return params.id;
  }, [params.id]);

  const { data: userRole } = useGetUserRoleQuery(userId, {
    skip: !userId,
  });

  const { data: privateUser } = useGetPrivateUserQuery(userId, {
    skip: !userId || !userRole || userRole?.role === ROLE_TYPE.CORPORATE,
  });
  const { data: corporateUser } = useGetCorporateUserQuery(userId, {
    skip: !userId || !userRole || userRole?.role === ROLE_TYPE.PRIVATE,
  });

  const { data: privateUserAssets } = useGetPrivateUserAssetsQuery(userId, { skip: !privateUser || !userId });
  const { data: corporateUserAssets } = useGetCorporateUserAssetsQuery(userId, {
    skip: !corporateUser || !userId,
  });

  const { data: quantity } = useGetSmartSharesOfferedQuery({ query: { userId: userId } }, { skip: !userId });

  const [isAssignShareModalOpen, setIsAssignShareModalOpen] = useState<boolean>(false);

  return (
    <AccountPage>
      <AssignToShareModal isOpen={isAssignShareModalOpen} setIsOpen={setIsAssignShareModalOpen} quantity={quantity} />
      <TabsNavigation>
        <>
          <RoleProtected allowedRoles={[ROLE_TYPE.ADMIN]}>
            <AccountNavigationItem
              label={privateUser ? translate('userDetails') : translate('companyDetails')}
              path={''}
              userPathId={userId}
            />
          </RoleProtected>
          <RoleProtected
            allowedRoles={[ROLE_TYPE.ADMIN]}
            customCondition={tenant !== TENANT.GREEN_STATE && !privateUser}
          >
            <AccountNavigationItem
              userPathId={userId}
              label={translate('smartBondsCreated')}
              path={ClientRoutes.MyAccount.smartBondCreated}
            />
          </RoleProtected>
          <RoleProtected allowedRoles={[ROLE_TYPE.ADMIN]}>
            <AccountNavigationItem
              userPathId={userId}
              label={translate('smartBondsPortfolio')}
              path={ClientRoutes.MyAccount.smartBondsPortfolio}
            />
          </RoleProtected>
          <RoleProtected allowedRoles={[ROLE_TYPE.ADMIN]} customCondition={!privateUser}>
            <AccountNavigationItem
              userPathId={userId}
              label={translate('couponsIssued')}
              path={ClientRoutes.MyAccount.couponIssued}
            />
          </RoleProtected>
          <RoleProtected allowedRoles={[ROLE_TYPE.ADMIN]} customCondition={!privateUser}>
            <AccountNavigationItem
              userPathId={userId}
              label={translate('shareholdersLedger')}
              path={ClientRoutes.MyAccount.shareholdersLedger}
            />
          </RoleProtected>
          <RoleProtected allowedRoles={[ROLE_TYPE.ADMIN]}>
            <AccountNavigationItem
              userPathId={userId}
              label={translate('smartSharesPortfolio')}
              path={ClientRoutes.Users.smartSharesPortfolio}
            />
          </RoleProtected>
          <RoleProtected allowedRoles={[ROLE_TYPE.ADMIN]} customCondition={tenant === TENANT.GREEN_STATE}>
            <AccountNavigationItem
              userPathId={userId}
              label={translate('smartNftPortfolio')}
              path={ClientRoutes.Users.nftPortfolio}
            />
          </RoleProtected>
          <RoleProtected allowedRoles={[ROLE_TYPE.ADMIN]}>
            <AccountNavigationItem
              userPathId={userId}
              label={translate('transactions')}
              path={ClientRoutes.Users.transactions}
            />
          </RoleProtected>
          <RoleProtected allowedRoles={[ROLE_TYPE.ADMIN]}>
            <AccountNavigationItem userPathId={userId} label={translate('cases')} path={ClientRoutes.Users.cases} />
          </RoleProtected>
        </>
      </TabsNavigation>
      <Routes location={location}>
        <Route
          path={''}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.ADMIN]}>
              <UserDetailsView
                privateUser={privateUser}
                corporateUser={corporateUser}
                assets={privateUserAssets || corporateUserAssets}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path={ClientRoutes.MyAccount.smartBondCreated}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.ADMIN]}>
              <SmartBondsCreatedView />
            </ProtectedRoute>
          }
        />
        <Route
          path={ClientRoutes.Users.smartBondsPortfolio}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.ADMIN]}>
              <SmartBondPortfolio userId={userId!} />
            </ProtectedRoute>
          }
        />
        <Route
          path={ClientRoutes.MyAccount.couponIssued}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.ADMIN]}>
              <CouponsIssued userId={userId!} />
            </ProtectedRoute>
          }
        />
        <Route
          path={ClientRoutes.MyAccount.shareholdersLedger}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.ADMIN]}>
              <ShareholdersLedger userId={userId!} />
            </ProtectedRoute>
          }
        />
        <Route
          path={ClientRoutes.Users.smartSharesPortfolio}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.ADMIN]}>
              <SmartSharePortfolio userId={userId} />
            </ProtectedRoute>
          }
        />
        {tenant === TENANT.GREEN_STATE && (
          <Route
            path={ClientRoutes.Users.nftPortfolio}
            element={
              <ProtectedRoute allowedRoles={[ROLE_TYPE.ADMIN]}>
                <NftPortfolio userId={userId} />
              </ProtectedRoute>
            }
          />
        )}
        <Route
          path={ClientRoutes.Users.transactions}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.ADMIN]}>
              <UserTransactions userId={userId} />
            </ProtectedRoute>
          }
        />
        <Route
          path={ClientRoutes.Users.case}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.ADMIN]}>
              <Case />
            </ProtectedRoute>
          }
        />
        <Route
          path={ClientRoutes.Users.cases}
          element={
            <ProtectedRoute allowedRoles={[ROLE_TYPE.ADMIN]}>
              <Cases />
            </ProtectedRoute>
          }
        />
      </Routes>
    </AccountPage>
  );
};

export default UserDetails;

const AccountPage = styled.div`
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: auto;
`;
