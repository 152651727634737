import { useCallback, useMemo } from 'react';
import { TFunction, useTranslation as useTranslationI18N } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { LANGUAGES, TRANSLATION_NAME } from '@/enums/Language';
import { changeLanguage } from '@/store/global/slice';

interface UseTranslationContext {
  setEnglish: () => void;
  setGerman: () => void;
  translate: TFunction;
  currentLanguage: LANGUAGES;
  isThisCurrentLanguage: (language: LANGUAGES) => boolean;
  setTranslationLanguage: (selectedLanguage: string) => void;
  isTranslateReady: boolean;
}

export const useTranslation = (): UseTranslationContext => {
  const { t: translate, i18n } = useTranslationI18N();
  const dispatch = useDispatch();

  const setGerman = useCallback(() => {
    i18n.changeLanguage(LANGUAGES.GERMAN);
    dispatch(changeLanguage(LANGUAGES.GERMAN));
  }, [i18n]);

  const setEnglish = useCallback(() => {
    i18n.changeLanguage(LANGUAGES.ENGLISH);
    dispatch(changeLanguage(LANGUAGES.ENGLISH));
  }, [i18n]);

  const isTranslateReady = useMemo(() => i18n.isInitialized, [i18n, i18n.isInitialized]);

  const currentLanguage = useMemo(() => i18n.language as LANGUAGES, [i18n, i18n.language]);

  const isThisCurrentLanguage = useCallback((language: string) => i18n.language === language, [i18n, i18n.language]);

  const setTranslationLanguage = (selectedLanguage: string) => {
    switch (selectedLanguage) {
      case TRANSLATION_NAME.ENGLISH:
        setEnglish();
        break;
      case TRANSLATION_NAME.GERMAN:
        setGerman();
        break;
      default:
        setEnglish();
    }
  };

  return {
    translate,
    setGerman,
    setEnglish,
    currentLanguage,
    isThisCurrentLanguage,
    setTranslationLanguage,
    isTranslateReady,
  };
};
