import { useMemo } from 'react';
import { TFunction } from 'react-i18next';

import { RegionResponse } from '@/shared/types/api';
import { useGetRegionsQuery } from '@/store/service';

export const useRegions = (translate: TFunction) => {
  const { data: regions } = useGetRegionsQuery();

  const regionsAsDropdown = useMemo(
    () =>
      regions?.map((region: RegionResponse) => {
        return {
          value: region.id,
          label: translate(region.code.toLowerCase() as keyof TFunction),
        };
      }),
    [regions, translate]
  );
  return {
    regions: regions || [],
    regionsAsDropdown: regionsAsDropdown || [],
  };
};
