import { FC } from 'react';
import styled from 'styled-components';

import ControlledInput from '@/components/Form/ControlledInput';
import { PURCHASE_DETAILS_FORM_ENUM } from '@/components/Payment/PaymentModal/PurchaseDetailsFormFields.enum';
import DetailBlock from '@/components/Payment/PaymentModal/shared/DetailBlock/DetailBlock';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';

const SecondStepPurchaseDetailsBond: FC = () => {
  const { translate } = useTranslation();

  return (
    <BuyerInfoLayout title={translate('smartBondInfo')}>
      <ControlledInput disabled name={PURCHASE_DETAILS_FORM_ENUM.ISSUER} placeholder={translate('smartBondIssuer')} />
      <ControlledInput disabled name={PURCHASE_DETAILS_FORM_ENUM.CODE} placeholder={translate('smartBondCode')} />
      <ControlledInput disabled name={PURCHASE_DETAILS_FORM_ENUM.MATURITY} placeholder={translate('maturity')} />
      <ControlledInput disabled name={PURCHASE_DETAILS_FORM_ENUM.MATURITY} placeholder={translate('interest')} />
      <ControlledInput disabled name={PURCHASE_DETAILS_FORM_ENUM.COUPONS_PAID} placeholder={translate('couponsPaid')} />
    </BuyerInfoLayout>
  );
};

const BuyerInfoLayout = styled(DetailBlock)`
  grid-row: 3 / 4;
  grid-column: 1 / 4;
`;

export default SecondStepPurchaseDetailsBond;
