import { Text } from 'issuix-ui-kit';
import { FC } from 'react';

import { TenantBoxContainer, TenantBoxValue, RadioCircle } from './LandingComponents.style';

interface Props {
  label: string;
  value: string;
  hasRadio?: boolean;
}

const TenantBox: FC<Props> = ({ label, value, hasRadio }) => {
  return (
    <TenantBoxContainer>
      <Text content={label} size={'small'} color={'#9bfdca'} />
      <TenantBoxValue>
        {hasRadio && <RadioCircle />}
        <Text content={value} />
      </TenantBoxValue>
    </TenantBoxContainer>
  );
};

export default TenantBox;
