import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseChangeCouponStatusUrl = 'coupons/status';

const changeCouponStatusUrl = (query: { id: string }) => {
  return `${baseChangeCouponStatusUrl}/${query.id}`;
};

export const changeCouponStatus = (build: BuildType) => {
  return build.mutation<any, any>({
    invalidatesTags: ['GetCoupons'],
    query: ({ query }) => ({
      url: changeCouponStatusUrl(query),
      method: 'PATCH',
    }),
  });
};
