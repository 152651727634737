export const smartSharesListingData = [
  {
    icon: 'green-state-logo.svg',
    payment: 'CHF, EUR, USD',
    pricePerShare: '2,500.00 CHF',
    fundsRaised: '1,200,000.00 CHF',
    onChain: 'Yes',
    securityType: 'Shares',
    fundsRequested: '6,250,000.00 CHF',
    headquarters: 'Switzerland',
  },
  {
    icon: 'green-state-logo.svg',
    payment: 'CHF, EUR, USD',
    fundsRaised: '1,200,000.00 CHF',
    pricePerShare: '2,500.00 CHF',
    onChain: 'Yes',
    securityType: 'Shares',
    fundsRequested: '6,250,000.00 CHF',
    headquarters: 'Switzerland',
  },
  {
    icon: 'green-state-logo.svg',
    payment: 'CHF, EUR, USD',
    fundsRaised: '1,200,000.00 CHF',
    pricePerShare: '2,500.00 CHF',
    onChain: 'Yes',
    securityType: 'Shares',
    fundsRequested: '6,250,000.00 CHF',
    headquarters: 'Switzerland',
  },
];

export const smartBondsListingData = [
  {
    icon: 'green-state-logo.svg',
    payment: 'CHF, EUR, USD',
    fundsRaised: '1,200,000.00 CHF',
    pricePerShare: '2,500.00 CHF',
    onChain: 'Yes',
    securityType: 'Shares',
    fundsRequested: '6,250,000.00 CHF',
    headquarters: 'Switzerland',
  },
  {
    icon: 'green-state-logo.svg',
    payment: 'CHF, EUR, USD',
    fundsRaised: '1,200,000.00 CHF',
    pricePerShare: '2,500.00 CHF',
    onChain: 'Yes',
    securityType: 'Shares',
    fundsRequested: '6,250,000.00 CHF',
    headquarters: 'Switzerland',
  },
  {
    icon: 'green-state-logo.svg',
    payment: 'CHF, EUR, USD',
    fundsRaised: '1,200,000.00 CHF',
    pricePerShare: '2,500.00 CHF',
    onChain: 'Yes',
    securityType: 'Shares',
    fundsRequested: '6,250,000.00 CHF',
    headquarters: 'Switzerland',
  },
];
