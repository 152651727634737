import { FC } from 'react';

import ThirdStepCorporateForm from './ThirdStepCorporateForm';
import ThirdStepPrivateForm from './ThirdStepPrivateForm';
import { thirdStepCorporateSchema, thirdStepPrivateSchema } from './ThirdStepSchema';

import { useRegistrationContext } from '@/components/AuthModal/RegistrationContext';
import ControlledForm from '@/components/Form/ControlledForm';
import { ROLE_TYPE } from '@/enums/RoleType';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';

const ThirdStep: FC = () => {
  const { translate } = useTranslation();
  const { firstStepData } = useRegistrationContext();

  return (
    <>
      {firstStepData.accountType === ROLE_TYPE.PRIVATE ? (
        <ControlledForm schema={thirdStepPrivateSchema(translate)}>
          <ThirdStepPrivateForm />
        </ControlledForm>
      ) : (
        <ControlledForm schema={thirdStepCorporateSchema(translate)}>
          <ThirdStepCorporateForm />
        </ControlledForm>
      )}
    </>
  );
};

export default ThirdStep;
