import { FC } from 'react';

import { GreenStateLogo, GreenStateVerifyIcon, GreenStateErrorIcon } from '@/assets/verify/greenstate/icons';
import { MetaSwissLogo, MetaSwissVerifyIcon, MetaSwissErrorIcon } from '@/assets/verify/metaswiss/icons';
import { TENANT } from '@/enums/App';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import Verify from '@/view/Verify/Verify/Verify';

const VerifyDomain: FC<{ tenant: TENANT }> = ({ tenant }) => {
  const { translate } = useTranslation();

  return (
    <>
      {tenant === TENANT.META_SWISS && (
        <Verify
          logo={<MetaSwissLogo />}
          icon={<MetaSwissVerifyIcon />}
          errorIcon={<MetaSwissErrorIcon />}
          titleSuccess={translate('welcomeTo', { tenant: tenant.charAt(0).toUpperCase() + tenant.slice(1) })}
          subtitleSuccess={translate('accountSuccessfullyActivated')}
          tenant={tenant}
        />
      )}
      {tenant === TENANT.GREEN_STATE && (
        <Verify
          logo={<GreenStateLogo />}
          icon={<GreenStateVerifyIcon />}
          errorIcon={<GreenStateErrorIcon />}
          titleSuccess={translate('welcomeTo', { tenant: tenant.charAt(0).toUpperCase() + tenant.slice(1) })}
          subtitleSuccess={translate('accountSuccessfullyActivated')}
          tenant={tenant}
        />
      )}
    </>
  );
};

export default VerifyDomain;
