import { UserCalculationResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const userCalculations = 'calculations/user';

const getUserCalculationsUrl = (query: { userId: string }) => `${userCalculations}?userId=${query.userId}`;

export const getUserTotalInvestedAmount = (build: BuildType) => {
  return build.query<UserCalculationResponse, any>({
    query: ({ query }) => ({
      url: getUserCalculationsUrl(query),
      method: 'GET',
    }),
  });
};
