import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const smartShareFormSchema = (translate: TFunction, share: any) => {
  return Yup.object().shape({
    price: Yup.string()
      .matches(/^\d+(\.\d{1,2})?$/, translate('digits'))
      .required(translate('required')),
    currencies: Yup.array()
      .of(Yup.string())
      .nullable()
      .min(1, 'Select at least one currency')
      .required(translate('required')),
    isin: Yup.string().required(),
    availableForSale: Yup.number()
      .required('Must be defined')
      .typeError('Must be a number')
      .min(0, 'Must be a positive number'),
    issuedDate: Yup.string().required(translate('required')),
    regions: Yup.array().of(Yup.string()).nullable().min(1).required(translate('required')),
    minimalInvestment: Yup.string().required(),
    roundDuration: Yup.string()
      .matches(/^[0-9]+$/, translate('digits'))
      .test('isBetween', 'Must be between 1 and 12', (value) => {
        return value ? +value >= 1 && +value <= 12 : false;
      })
      .required(translate('required')),
    totalAmount: Yup.string()
      .required()
      .test('isGreaterThan', `Must be greater than ${share.totalAmount}`, (value) => {
        return value ? value >= share.totalAmount : false;
      }),
    shareCapital: Yup.string().required(),
    nominalValue: Yup.string().required(),
    type: Yup.string()
      .matches(/^\d+(\.\d{1,2})?$/, translate('digits'))
      .required(translate('required')),
    round: Yup.string().required(),
  });
};
