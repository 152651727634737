import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

export const SignInContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
`;

export const LogoContainer = styled.div`
  margin: 4rem 4rem 2rem 4rem;
  width: 80%;
`;

export const SeparateLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: white;
`;
