import { Card } from 'issuix-ui-kit';
import styled from 'styled-components';

export const SmartBondListingContainer = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;
`;

export const TableContainer = styled.div`
  height: calc(100% - 5rem);
`;

export const FiltersContainer = styled.div`
  height: 30%;
  background: linear-gradient(
    183.73deg,
    rgba(255, 255, 255, 0.1) 1.73%,
    rgba(233, 233, 233, 0.19) 99.66%,
    rgba(233, 233, 233, 0.25) 99.66%
  );
  border-radius: 3rem;
  display: flex;
  padding: 3rem;
  flex-direction: column;
  position: relative;
`;

export const SmartBondListingCard = styled(Card).attrs(() => ({
  notFullHeight: true,
}))`
  height: 100%;
  width: 100%;
  padding: 3rem;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const ActionWrapper = styled.div`
  display: flex;
  gap: 2rem;
`;
