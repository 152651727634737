import { ShareTransactionResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseShareTransactionUrl = 'share-transaction';

const getShareTransactionByIdUrl = (query: { id: string }): string => `${baseShareTransactionUrl}/${query.id}`;

export const getShareTransactionById = (build: BuildType) => {
  return build.query<ShareTransactionResponse, any>({
    query: ({ query }) => ({
      url: getShareTransactionByIdUrl(query),
      method: 'GET',
    }),
  });
};
