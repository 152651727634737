import * as yup from 'yup';

export const TransactionsToBuyersFilterSchema = () =>
  yup.object().shape({
    smartBondCode: yup.string(),
    priceMinimum: yup.number(),
    priceMaximum: yup.number(),
    currency: yup.string(),
    paid: yup.boolean(),
    buyer: yup.string(),
  });
