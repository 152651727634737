import { ModalDialog, Text } from 'issuix-ui-kit';
import { FC } from 'react';

import RejectTransactionModalForm from './RejectTransactionModalForm/RejectTransactionModalForm';

import ControlledForm from '@/components/Form/ControlledForm';
import { rejectTransactionModalFormSchema } from '@/components/TransactionModal/RejectTransactionModal/RejectTransactionModalForm/RejectTransactionModalFormSchema';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';

interface RejectTransactionModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const RejectTransactionModal: FC<RejectTransactionModalProps> = ({ isOpen, setIsOpen }) => {
  const { translate } = useTranslation();

  return (
    <ModalDialog isOpen={isOpen} close={() => setIsOpen(false)}>
      <Text content={translate('reject')} size={'large'} />
      <Text content={translate('rejectTransaction')} size={'medium'} />
      <ControlledForm schema={rejectTransactionModalFormSchema()}>
        <RejectTransactionModalForm setIsOpen={setIsOpen} />
      </ControlledForm>
    </ModalDialog>
  );
};

export default RejectTransactionModal;
