import { TransactionDetailInfoResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseTransactionDetailsUrl = 'transactions/buyer';

const getTransactionDetailsUrl = (query: { id: string }): string => `${baseTransactionDetailsUrl}/${query.id}`;

export const getTransactionDetails = (build: BuildType) => {
  return build.query<TransactionDetailInfoResponse, any>({
    providesTags: ['GetTransactionDetails'],
    query: ({ query }) => ({
      url: getTransactionDetailsUrl(query),
      method: 'GET',
    }),
  });
};
