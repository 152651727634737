import { FullRegistrationPrivateUserRequest } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const registrationPath = 'auth/private/full-signup';

export const fullRegistrationPrivateUser = (build: BuildType) => {
  return build.mutation<void, FullRegistrationPrivateUserRequest>({
    query: (data: FullRegistrationPrivateUserRequest) => ({
      url: registrationPath,
      method: 'POST',
      body: data,
    }),
  });
};
