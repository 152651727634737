import { browserService } from '@/service/browser/BrowserService';
import { BROWSER_SERVICE_KEYS } from '@/service/browser/BrowserServiceKeys';
import { useLogoutMutation } from '@/store/service';

export const useLogout = () => {
  const [logoutRtk] = useLogoutMutation();

  const logout = () => {
    if (!browserService.get(BROWSER_SERVICE_KEYS.ACCESS_TOKEN)) {
      logoutRtk();
      return;
    }

    logoutRtk();
  };

  return {
    logout,
  };
};
