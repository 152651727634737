import { TransactionResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseAcceptTransactionUrl = 'transactions';

const acceptTransactionUrl = (query: { id: string }) => {
  return `${baseAcceptTransactionUrl}/${query.id}/accept`;
};

export const acceptTransaction = (build: BuildType) => {
  return build.mutation<TransactionResponse, any>({
    invalidatesTags: [
      'GetTransactionDetails',
      'GetItemTransactions',
      'GetAllIssuedCoupons',
      'GetNftCollectionStatistics',
    ],
    query: ({ query }) => ({
      url: acceptTransactionUrl(query),
      method: 'PATCH',
    }),
  });
};
