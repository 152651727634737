import { Button, Text } from 'issuix-ui-kit';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CardHeader, SmartShareContainer, ViewShareWrapper } from './ApproveShare.styles';
import SmartShareFeaturesForm from './SmartShareFeaturesForm/SmartShareFeaturesForm';
import { smartShareFeaturesSchema } from './SmartShareFeaturesForm/SmartShareFeaturesFormSchema';

import { AcceptModal } from '@/components/AcceptModal/AcceptModal';
import ControlledForm from '@/components/Form/ControlledForm';
import { RejectModal } from '@/components/RejectModal/RejectModal';
import { useNav } from '@/hooks/useNav/UseNav';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import {
  useApproveShareMutation,
  useGetShareAssetsQuery,
  useGetShareByIdQuery,
  useRejectShareMutation,
} from '@/store/service';
import { ActionWrapper } from '@/view/Admin/ApproveBond/ApproveBond.styles';

const ApproveShare: FC = () => {
  const { params } = useNav();
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState<boolean>(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState<boolean>(false);
  const [reason, setReason] = useState<string>('');

  const { data: share } = useGetShareByIdQuery({ query: { id: params.id && params.id } }, { skip: !params });
  const { data: assets } = useGetShareAssetsQuery({ query: { id: params.id && params.id } }, { skip: !params });

  const [rejectShare, { isSuccess: isSuccessRejectShare, isLoading: isLoadingRejectShare }] = useRejectShareMutation();
  const [approveShare, { isSuccess: isSuccessApproveShare, isLoading: isLoadingApproveShare }] =
    useApproveShareMutation();

  useEffect(() => {
    if (isSuccessApproveShare || isSuccessRejectShare) {
      navigate(-1);
    }
  }, [isSuccessApproveShare, isSuccessRejectShare]);

  const onAccept = () => {
    approveShare({ id: params.id });
    setIsAcceptModalOpen(false);
  };

  const onReject = () => {
    rejectShare({ reason, id: params.id });
    setIsRejectModalOpen(false);
    setReason('');
  };

  const onAcceptModalOpen = () => {
    setIsAcceptModalOpen(true);
  };

  const onRejectModalOpen = () => {
    setIsRejectModalOpen(true);
  };

  const onRejectModalClose = () => {
    setIsRejectModalOpen(false);
  };

  const onAcceptModalClose = () => {
    setIsAcceptModalOpen(false);
  };

  return (
    <ViewShareWrapper>
      <SmartShareContainer>
        <CardHeader>
          <Text content={translate('smartSharesPending')} size={'large'} />
        </CardHeader>
        <ControlledForm schema={smartShareFeaturesSchema}>
          <SmartShareFeaturesForm share={share} assets={assets} />
        </ControlledForm>
        <ActionWrapper>
          <Button text={translate('accept')} onClick={onAcceptModalOpen} />
          <Button onClick={onRejectModalOpen} text={translate('reject')} buttonType="danger" />
        </ActionWrapper>
        <AcceptModal
          isAcceptModalOpen={isAcceptModalOpen}
          onAcceptModalClose={onAcceptModalClose}
          onAccept={onAccept}
          title={translate('accept')}
          subtitle={translate('areYouSureAcceptShare')}
          isAcceptLoading={isLoadingApproveShare}
        />
        <RejectModal
          isRejectModalOpen={isRejectModalOpen}
          onRejectModalClose={onRejectModalClose}
          onReject={onReject}
          title={translate('reject')}
          subtitle={translate('areYouSureRejectShare')}
          reason={reason}
          setReason={setReason}
          isRejectLoading={isLoadingRejectShare}
        />
      </SmartShareContainer>
    </ViewShareWrapper>
  );
};

export default ApproveShare;
