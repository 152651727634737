import { OrderResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const getOrderByTransactionIdPath = (transactionId: string) => `admin/order/search?transactionId=${transactionId}`;

export const getOrderByTransactionId = (build: BuildType) => {
  return build.query<OrderResponse, any>({
    query: (transactionId: string) => ({
      url: getOrderByTransactionIdPath(transactionId),
      method: 'GET',
    }),
  });
};
