import { Text } from 'issuix-ui-kit';

import ControlledForm from '@/components/Form/ControlledForm';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { createNftCollectionSchema } from '@/view/Core/CreateNftCollection/CreateNftCollection.schema';
import { NftCollectionDetailsContainer } from '@/view/Core/CreateNftCollection/CreateNftCollectionForm/CreateNftCollectionForm.styles';
import { Form } from '@/view/Core/CreateNftCollection/CreateNftCollectionForm/Form';

export const CreateNftCollectionForm = () => {
  const { translate } = useTranslation();

  return (
    <NftCollectionDetailsContainer>
      <Text content={translate('createNftCollection')} size={'large'} />
      <ControlledForm schema={createNftCollectionSchema(translate)}>
        <Form />
      </ControlledForm>
    </NftCollectionDetailsContainer>
  );
};
