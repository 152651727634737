import { pagingLimit } from '@/shared/constants/Paging';
import { PageableItems } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const getNftCollectionAcceptedTransactionsPath = 'admin/nft-collection';

const getNftCollectionAcceptedTransactionsUrl = (id: string, offset: number) =>
  `${getNftCollectionAcceptedTransactionsPath}/${id}/transactions?offset=${offset}&limit=${pagingLimit}`;

export const getNftCollectionAcceptedTransactions = (build: BuildType) => {
  return build.query<PageableItems, any>({
    query: ({ offset, query }) => ({
      url: getNftCollectionAcceptedTransactionsUrl(query.id, offset),
      method: 'GET',
    }),
  });
};
