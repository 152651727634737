import { Text } from 'issuix-ui-kit';

import { Container } from '@/components/TermsAndConditions/TermsAndConditions.styles';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { getTenant } from '@/utils/getTenant/getTenant';

const TermsAndConditions = () => {
  const { translate } = useTranslation();
  const tenant = getTenant();

  return (
    <Container>
      <Text content={translate('termsAndConditionsPart1', { tenant: tenant.toUpperCase() })} size={'small'} />{' '}
      <a>{translate('termsAndConditions')}</a> <Text content={translate('termsAndConditionsPart2')} size={'small'} />{' '}
      <a>{translate('privacyPolicy')}</a>{' '}
    </Container>
  );
};

export default TermsAndConditions;
