import { Input } from 'issuix-ui-kit';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface ControlledInputProps {
  name: string;
  placeholder: string;
  disabled?: boolean;
  autoFocus?: boolean;
  id?: string;
  type?: 'text' | 'number';
  onBlur?: any;
  className?: string;
  step?: string;
  pattern?: string;
}

const ControlledInput: FC<ControlledInputProps> = ({
  placeholder,
  name = '',
  disabled = false,
  autoFocus,
  id,
  type = 'text',
  onBlur,
  className,
  step,
  pattern,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field }) => (
        <Input
          id={id}
          value={field.value ?? ''}
          onChange={field.onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          name={name}
          type={type}
          disabled={disabled}
          autoFocus={autoFocus}
          errors={errors}
          className={className}
          step={step}
          pattern={pattern}
        />
      )}
    />
  );
};

export default ControlledInput;
