import {
  featureBonds,
  featureChat,
  featureDashboard,
  featureLogin,
  featureRegistration,
  featureShares,
  featureTransactionsToFrom,
  featureTransactions,
  featureViewProfile,
  featurePushNotifications,
  featureHashColumn,
} from '@/utils/featuresFlags/featureFlags';

export enum FEATURE_FLAGS {
  DASHBOARD = 'dashboard',
  BONDS = 'bonds',
  SHARES = 'shares',
  TRANSACTIONS_TO_FROM = 'transactions-to-from',
  TRANSACTIONS = 'transactions',
  REGISTRATION = 'registration',
  LOGIN = 'login',
  VIEW_PROFILE = 'view-profile',
  CHAT = 'chat',
  PUSH_NOTIFICATIONS = 'push-notifications',
  HASH_COLUMN = 'hash-column',
}

export const featuresMap: Record<FEATURE_FLAGS, boolean> = {
  [FEATURE_FLAGS.DASHBOARD]: featureDashboard,
  [FEATURE_FLAGS.BONDS]: featureBonds,
  [FEATURE_FLAGS.SHARES]: featureShares,
  [FEATURE_FLAGS.TRANSACTIONS_TO_FROM]: featureTransactionsToFrom,
  [FEATURE_FLAGS.TRANSACTIONS]: featureTransactions,
  [FEATURE_FLAGS.REGISTRATION]: featureRegistration,
  [FEATURE_FLAGS.LOGIN]: featureLogin,
  [FEATURE_FLAGS.VIEW_PROFILE]: featureViewProfile,
  [FEATURE_FLAGS.CHAT]: featureChat,
  [FEATURE_FLAGS.PUSH_NOTIFICATIONS]: featurePushNotifications,
  [FEATURE_FLAGS.HASH_COLUMN]: featureHashColumn,
};
