import { colors, RadioButtonInput, Text } from 'issuix-ui-kit';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

interface Options {
  label: string;
  value: string;
}

export interface ControllerRadioButtonInputProps {
  name: string;
  className?: string;
  options: Options[];
  defaultValue: string;
  description: string;
  disabled?: boolean;
  size?: 'medium' | 'small' | 'extraSmall' | 'large' | 'extraLarge' | undefined;
}

const ControllerRadioButtonInput: FC<ControllerRadioButtonInputProps> = ({
  name = '',
  options,
  defaultValue,
  description,
  disabled,
  size = 'medium',
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { value, onChange, onBlur } }) => (
        <ControlledRadioButtonContainer size={size}>
          <Description>
            <Text color={colors.green.primary} content={description} size={size} />
          </Description>
          <RadioButtonGroup>
            {options.map((o) => (
              <RadioButtonInput
                key={o.label}
                value={o.value}
                fieldValue={value}
                label={o.label}
                onBlur={onBlur}
                onChange={onChange}
                name={name}
                disabled={disabled}
              />
            ))}
          </RadioButtonGroup>
        </ControlledRadioButtonContainer>
      )}
    />
  );
};

export default ControllerRadioButtonInput;

const RadioButtonGroup = styled.div`
  width: 100%;
  display: flex;
`;

export const Description = styled.label`
  text-align: left;
  transition: all 0.1s;
`;

export const ControlledRadioButtonContainer = styled.div<{ size: string }>`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${(props) => (props.size === 'medium' ? '0rem' : '-2rem')};
  margin-bottom: 0.5rem;
`;
