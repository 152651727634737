import { BuildType } from '@/shared/types/redux/ReduxAction';

const getTenantUsersUrl = 'admin/users/tenant';

export const getTenantUsers = (build: BuildType) => {
  return build.query({
    query: () => ({
      url: getTenantUsersUrl,
      method: 'GET',
    }),
  });
};
