import { Text, useWizardContext } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import ControlledForm from '@/components/Form/ControlledForm';
import { PURCHASE_DETAILS_FORM_ENUM } from '@/components/Payment/PaymentModal/PurchaseDetailsFormFields.enum';
import { SecondStepPurchaseDetailsContainer } from '@/components/Payment/PaymentModal/Steps/SecondStepPurchaseDetails/SecondStepPurchaseDetails.style';
import SecondStepPurchaseDetailsBond from '@/components/Payment/PaymentModal/Steps/SecondStepPurchaseDetails/SecondStepPurchaseDetailsBond/SecondStepPurchaseDetailsBond';
import SecondStepPurchaseDetailsBuyerInfo from '@/components/Payment/PaymentModal/Steps/SecondStepPurchaseDetails/SecondStepPurchaseDetailsBuyerInfo/SecondStepPurchaseDetailsBuyerInfo';
import SecondStepPurchaseDetailsShare from '@/components/Payment/PaymentModal/Steps/SecondStepPurchaseDetails/SecondStepPurchaseDetailsShare/SecondStepPurchaseDetailsShare';
import { usePaymentContext } from '@/components/Payment/PaymentModal/context/PaymentContext';
import CurrencyBlock from '@/components/Payment/PaymentModal/shared/CurrencyBlock/CurrencyBlock';
import PurchaseInfo from '@/components/Payment/PaymentModal/shared/PurchaseInfo/PurchaseInfo';
import { PurchaseInfoContainer } from '@/components/Payment/PaymentModal/shared/PurchaseInfo/PurchaseInfo.styles';
import { PRODUCT_TYPE_ENUM } from '@/enums/ProductType';
import { useAuth } from '@/hooks/useAuth/useAuth';
import { useCouponPaidOptions } from '@/hooks/useCouponPaidOptions/useCouponPaidOptions';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { BondResponse } from '@/shared/types/api';

const SecondStepPurchaseDetailsContent: FC = () => {
  const { quantity, resource, resourceType } = usePaymentContext();
  const { setValue } = useFormContext();
  const {
    ejected: { goToPreviousPage, goToNextPage, setOnBackClickHandler, setOnNextClickHandler },
  } = useWizardContext();
  const { user } = useAuth();
  const { translate } = useTranslation();
  const couponPaidOptions = useCouponPaidOptions(translate);

  useEffect(() => {
    setOnBackClickHandler(() => {
      goToPreviousPage();
    });
    setOnNextClickHandler(() => {
      goToNextPage();
    });
  }, []);

  useEffect(() => {
    if (!resource) {
      return;
    }

    quantity && setValue(PURCHASE_DETAILS_FORM_ENUM.QUANTITY, quantity);
    setValue(
      PURCHASE_DETAILS_FORM_ENUM.ISSUER,
      resource?.user?.role === 'corporate' ? resource?.user.displayName : resource?.user.name
    );
    setValue(PURCHASE_DETAILS_FORM_ENUM.CODE, resource?.code);

    const names = user?.displayName.split(' ');
    setValue(PURCHASE_DETAILS_FORM_ENUM.BUYER_NAME, names?.[0] || '/');
    setValue(PURCHASE_DETAILS_FORM_ENUM.BUYER_LAST_NAME, names?.[1] ?? '/');
    setValue(PURCHASE_DETAILS_FORM_ENUM.BUYER_ADDRESS, user?.address);

    if (resourceType === PRODUCT_TYPE_ENUM.BOND_PAPERS) {
      const bond = resource as BondResponse;
      const paidOption = couponPaidOptions.couponPaidOptions.find(
        (paidOption) => paidOption.value === bond.couponsPaid
      );

      setValue(PURCHASE_DETAILS_FORM_ENUM.INTEREST, bond.interest);
      setValue(PURCHASE_DETAILS_FORM_ENUM.MATURITY, bond.maturity);
      setValue(PURCHASE_DETAILS_FORM_ENUM.COUPONS_PAID, paidOption?.label);
    }
  }, [quantity, resource, couponPaidOptions, user]);

  return (
    <>
      <PurchaseInfoContainer>
        <PurchaseInfo disabled quantity={quantity} />
      </PurchaseInfoContainer>
      {resourceType === PRODUCT_TYPE_ENUM.BOND_PAPERS ? (
        <SecondStepPurchaseDetailsBond />
      ) : (
        <SecondStepPurchaseDetailsShare />
      )}
      <SecondStepPurchaseDetailsBuyerInfo />
    </>
  );
};

const SecondStepPurchaseDetails: FC = () => {
  const { translate } = useTranslation();

  return (
    <SecondStepPurchaseDetailsContainer>
      <CurrencyBlock disabled={true}>
        <Text content={translate('purchaseInfo')} size={'large'} />
      </CurrencyBlock>
      <ControlledForm schema={null}>
        <SecondStepPurchaseDetailsContent />
      </ControlledForm>
    </SecondStepPurchaseDetailsContainer>
  );
};

export default SecondStepPurchaseDetails;
