import { Text } from 'issuix-ui-kit';
import { FC } from 'react';

import {
  AccountCalculationBoxStyled,
  IconStyled,
  StyledText,
} from '@/components/AccountCalculationBox/AccountCalculationBox.styles';

interface AccountCalculationBoxProps {
  label: string;
  value: string;
  iconUrl?: string;
}

const AccountCalculationBox: FC<AccountCalculationBoxProps> = ({ label, value, iconUrl }) => {
  return (
    <AccountCalculationBoxStyled>
      <StyledText content={label} size={'medium'} />
      {iconUrl && <IconStyled src={iconUrl} />}
      <Text content={value} size={'medium'} />
    </AccountCalculationBoxStyled>
  );
};

export default AccountCalculationBox;
