import {
  StripeElementStyle,
  StripeCardNumberElementOptions,
  StripeCardExpiryElementOptions,
  StripeCardCvcElementOptions,
} from '@stripe/stripe-js';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / 4;
`;

export const CardElementsContainer = styled.div`
  display: flex;
`;

interface InputWrapperProps {
  flexGrow?: number;
}

export const InputWrapper = styled.div<InputWrapperProps>`
  margin-left: 1rem;
  margin-right: 1rem;
  border-bottom: 1px solid #ffffff;
  flex-grow: ${(props) => props.flexGrow};
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0.2rem;
`;

export const SupportedCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  margin-top: 6rem;
  margin-left: 1rem;
  gap: 1rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  margin-left: 1rem;
  margin-bottom: 4rem;
  margin-top: 2rem;
`;

/*** Stripe component styles ***/
const style: StripeElementStyle = {
  base: {
    color: 'white',
    '::placeholder': {
      color: '#e7e7e7b6',
    },
  },
};

export const cardNumberOptions: StripeCardNumberElementOptions = {
  style,
  placeholder: 'XXXX-XXXX-XXXX-XXXX',
};

export const cardExpiryOptions: StripeCardExpiryElementOptions = {
  style,
  placeholder: '01/16',
};

export const cardCvcOptions: StripeCardCvcElementOptions = {
  style,
  placeholder: '724',
};
