import { ChangePasswordRequest } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const changePasswordPath = 'auth/change-password';

export const changePassword = (build: BuildType) => {
  return build.mutation<void, ChangePasswordRequest>({
    query: ({ currentPassword, newPassword, passwordConfirmation }) => ({
      url: changePasswordPath,
      method: 'POST',
      body: { currentPassword, newPassword, passwordConfirmation },
    }),
  });
};
