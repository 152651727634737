import { Input, Textarea, Text } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';

import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { AssetBaseResponse, CaseDetailsResponse, RequestChangesResponse } from '@/shared/types/api';
import { useAssignedAssetUrlMutation, useDeleteCaseAssetsMutation } from '@/store/service';
import {
  CaseViewContainer,
  DocumentsContainer,
  DocumentsTitle,
  RequestChangeItem,
  RequestChangesContainer,
} from '@/view/Admin/Case/Case.styled';
import { UploadFileComponent } from '@/view/Admin/Request/Request.styled';

interface Props {
  caseDetails: CaseDetailsResponse;
  caseId: string | undefined;
}

export const CaseView: FC<Props> = ({ caseDetails, caseId }) => {
  const { translate } = useTranslation();

  const [getAssetUrl, { data: assetUrl }] = useAssignedAssetUrlMutation();
  const [deleteCaseAssets] = useDeleteCaseAssetsMutation();

  useEffect(() => {
    if (assetUrl) {
      window.open(assetUrl.url, '_blank', 'noopener,noreferrer');
    }
  }, [assetUrl]);

  return (
    <CaseViewContainer>
      <Input name={'name'} placeholder={translate('name')} value={caseDetails.name} disabled />
      <Textarea name={'description'} label={translate('description')} value={caseDetails.description} disabled />
      <DocumentsTitle content={translate('documents')} />
      <DocumentsContainer>
        {caseDetails.assets.length > 0 ? (
          caseDetails.assets.map((asset: AssetBaseResponse, index) => {
            return (
              <UploadFileComponent
                key={asset.id}
                label={[translate('caseDocument'), ++index].join(' ')}
                name={asset.fileName}
                IncorrectFileFormatLabel={translate('incorrectFileFormat')}
                DragDropFilesHereLabel={translate('dropFilesHere')}
                ClickToViewDocumentLabel={translate('clickToViewDocumentLabel')}
                value={{
                  fileId: asset.id,
                  url: asset.key,
                }}
                onViewDocument={() => getAssetUrl({ assetId: asset.id })}
                edit={true}
                onDelete={() => deleteCaseAssets({ caseId, data: { assetIds: [asset.id] } })}
              />
            );
          })
        ) : (
          <Text content={translate('noDocuments')} />
        )}
      </DocumentsContainer>
      <DocumentsTitle content={translate('requestedChanges')} />
      <RequestChangesContainer>
        {caseDetails.requestChanges.length > 0 ? (
          caseDetails.requestChanges.map((requestChange: RequestChangesResponse) => {
            return <RequestChangeItem key={requestChange.createdAt}>{requestChange.message} </RequestChangeItem>;
          })
        ) : (
          <Text content={translate('noRequestedChanges')} />
        )}
      </RequestChangesContainer>
    </CaseViewContainer>
  );
};
