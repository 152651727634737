import { Button, IColumn, Table } from 'issuix-ui-kit';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { TableContainer } from '@/components/SmartShareLegderTable/SmartShareLedgerTable.styles';
import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { SmartShareLedgerResponse } from '@/shared/types/SmartShareLedgerResponse';
import { useGetSmartShareLedgerQuery } from '@/store/service';
import { composePath } from '@/utils/composePath/ComposePath';
import { formatAmountWithoutRate } from '@/utils/formatAmount/FormatAmount';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';

interface SmartShareLedgerTableProps {
  userId: string;
  isDashboard: boolean;
}

const SmartShareLedgerTable: FC<SmartShareLedgerTableProps> = ({ userId, isDashboard }) => {
  const { translate } = useTranslation();
  const { fetch, data } = useTableWithPagination({ query: useGetSmartShareLedgerQuery, id: userId });
  const navigate = useNavigate();

  const onAction = (id: string) => {
    navigate(composePath(`${ClientRoutes.Shares.root}/${ClientRoutes.Shares.viewSmartShare}`, { id: id }));
  };

  const tableDefAccount: IColumn[] = [
    {
      name: 'round',
      width: 1,
      header: {
        label: translate('round').toUpperCase(),
      },
    },
    {
      name: 'status',
      width: 1.5,
      header: {
        label: translate('status').toUpperCase(),
      },
    },
    {
      name: 'pricePerShare',
      width: 1.5,
      header: {
        label: translate('pricePerShare').toUpperCase(),
      },
    },
    {
      name: 'totalShares',
      width: 2,
      header: {
        label: translate('totalShares').toUpperCase(),
      },
    },
    {
      name: 'subscribed',
      width: 1,
      header: {
        label: translate('subscribed').toUpperCase(),
      },
    },
    {
      name: 'capitalGained',
      width: 1.5,
      header: {
        label: translate('capitalGained').toUpperCase(),
      },
      column: {
        render: (item: SmartShareLedgerResponse) => <p>{formatAmountWithoutRate(item.capitalGained, 2)} CHF</p>,
      },
    },
    {
      name: 'actions',
      width: 2,
      header: {
        label: translate('actions').toUpperCase(),
      },
      column: {
        render: (item: SmartShareLedgerResponse) => (
          <Button onClick={() => onAction(item.id)} text={translate('view')} fullyRounded />
        ),
      },
    },
  ];

  const tableDefDashboard: IColumn[] = [
    {
      name: 'round',
      width: 1,
      header: {
        label: translate('round').toUpperCase(),
      },
    },
    {
      name: 'status',
      width: 1.5,
      header: {
        label: translate('status').toUpperCase(),
      },
    },
    {
      name: 'pricePerShare',
      width: 1.5,
      header: {
        label: translate('pricePerShare').toUpperCase(),
      },
    },
    {
      name: 'totalShares',
      width: 2,
      header: {
        label: translate('totalShares').toUpperCase(),
      },
    },
    {
      name: 'subscribed',
      width: 1,
      header: {
        label: translate('subscribed').toUpperCase(),
      },
    },
    {
      name: 'capitalGained',
      width: 1.5,
      header: {
        label: translate('capitalGained').toUpperCase(),
      },
    },
  ];

  return (
    <TableContainer>
      <Table fetchTrigger={() => fetch} tableDef={isDashboard ? tableDefDashboard : tableDefAccount} data={data} />
    </TableContainer>
  );
};

export default SmartShareLedgerTable;
