import { RegisterResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const resendEmailPath = 'auth/resend-email';

export const resendEmail = (build: BuildType) => {
  return build.mutation<RegisterResponse, any>({
    query: ({ resendToken, email }) => ({
      url: resendEmailPath,
      method: 'POST',
      params: resendToken,
      body: { email },
    }),
  });
};
