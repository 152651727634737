import { useMemo } from 'react';
import { TFunction } from 'react-i18next';

import { BusinessFocusResponse } from '@/shared/types/api';
import { useGetBusinessFocusQuery } from '@/store/service';

export const useBusinessFocus = (translate: TFunction) => {
  const { data: businessFocus } = useGetBusinessFocusQuery();

  const businessFocusAsDropdown = useMemo(
    () =>
      businessFocus?.map((businessFocus: BusinessFocusResponse) => {
        return {
          value: businessFocus.id,
          label: translate(businessFocus.translationCode.split('.')[2] as keyof TFunction),
        };
      }),
    [businessFocus, translate]
  );

  return {
    businessFocus: businessFocus || [],
    businessFocusAsDropdown: businessFocusAsDropdown || [],
  };
};
