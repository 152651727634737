import { Button } from 'issuix-ui-kit';
import { FC, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Documentation } from '@/components/BondAndShare/Documentation';
import ControlledDateInput from '@/components/Form/ControlledDateInput';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledMultipleSelect from '@/components/Form/ControlledSelectMultiple';
import FullDisabledField from '@/components/FullDisabledField/FullDisabledField';
import { Row } from '@/components/Layout/Row/Row.styled';
import { useAuth } from '@/hooks/useAuth/useAuth';
import { useCurrencies } from '@/hooks/useCurrencies/UseCurrencies';
import { useEditEntity } from '@/hooks/useEditEntity/useEditEntity';
import { useRegions } from '@/hooks/useRegions/UseRegions';
import { useRoundOptions } from '@/hooks/useRoundOptions/useRoundOptions';
import { useShareTypeOptions } from '@/hooks/useShareTypeOptions/useShareTypeOptions';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { AssetResponse, ShareResponse, CurrencyResponse } from '@/shared/types/api';
import { useUpdateShareMutation } from '@/store/service';
import { offeringMonthOptions } from '@/utils/constants/OfferingMonthOptions';
import { formatAmountWithoutRate } from '@/utils/formatAmount/FormatAmount';
import { getFilteredCurrencies } from '@/utils/getFilteredCurrencies/GetFilteredCurrencies';
import { parseFormObject } from '@/utils/getShareFieldValues/getShareFieldValues';
import { setFieldValues } from '@/utils/setFieldValues/setFieldValues';

interface SmartShareFeaturesFormProps {
  share: ShareResponse;
  isEditMode: boolean;
  assets: AssetResponse[];
  setIsEditMode: (value: boolean) => void;
}

const SmartShareFeatureForm: FC<SmartShareFeaturesFormProps> = ({ share, isEditMode, assets, setIsEditMode }) => {
  const { currencies, currenciesAsDropdown } = useCurrencies();
  const { translate } = useTranslation();
  const { regionsAsDropdown } = useRegions(translate);
  const { roundOptionsAsDropdown } = useRoundOptions();
  const { shareTypeOptionsAsDropdown } = useShareTypeOptions(translate);
  const {
    setValue,
    watch,
    getValues,
    formState: { dirtyFields, errors },
  } = useFormContext();
  const filteredCurrencies: CurrencyResponse[] = useMemo(() => getFilteredCurrencies(currencies), [currencies]);
  const [updateShare] = useUpdateShareMutation();
  const { updateShareBond, cancelHandler } = useEditEntity();
  const [val, setVal] = useState(0);

  const { user } = useAuth();

  useEffect(() => {
    if (share) {
      setFieldValues(parseFormObject(share, assets), setValue);
      setVal(share.price);
    }
  }, [assets, share]);

  const totalAmountWatcher = watch(['totalAmount']);
  const totalEquityWatcher = watch(['shareCapital']);

  useEffect(() => {
    if (totalEquityWatcher.length && totalEquityWatcher[0]) {
      setValue('nominalValue', Number(totalEquityWatcher[0] / totalAmountWatcher[0]).toFixed(2));
    }
  }, [totalAmountWatcher, totalEquityWatcher]);

  const calculatedValues = useMemo(() => {
    return filteredCurrencies.map((currency: any) => (
      <FullDisabledField
        key={currency.currencyCode}
        value={+formatAmountWithoutRate(val * currency.rate, currency.decimals)}
        label={currency.currencyCode}
      />
    ));
  }, [filteredCurrencies, val]);

  const onSubmitHandler = async () => {
    await updateShareBond({ shareId: share.id }, updateShare, user);
    setIsEditMode(false);
  };

  const onCancelHandler = () => {
    setIsEditMode(false);
    cancelHandler(parseFormObject(share, assets));
  };

  return (
    <CreateBondFormContainer>
      <Row>
        <ControlledInput
          name={'price'}
          placeholder={translate('pricePerShare')}
          id={'price'}
          disabled={!isEditMode}
          type={'number'}
          pattern={'d*(.d+)?'}
        />
        {calculatedValues}
      </Row>
      <Row>
        <ControlledInput
          name={'totalAmount'}
          placeholder={translate('totalAmount')}
          id={'totalAmount'}
          disabled={!isEditMode}
          type={'number'}
        />
        <ControlledInput
          name={'availableForSale'}
          placeholder={translate('availableForSale')}
          id={'availableForSale'}
          disabled={!isEditMode}
          type={'number'}
        />
        <ControlledMultipleSelect
          name={'currencies'}
          placeholder={translate('currencies')}
          items={currenciesAsDropdown}
          disabled={!isEditMode}
        />
        <ControlledInput name={'isin'} placeholder={translate('isin')} id={'isin'} disabled={!isEditMode} />
        <ControlledInput
          name={'shareCapital'}
          placeholder={translate('totalEquity')}
          id={'shareCapital'}
          disabled={!isEditMode}
          type={'number'}
          pattern={'d*(.d+)?'}
        />
        <ControlledInput name={'nominalValue'} placeholder={translate('nominalValue')} id={'nominalValue'} disabled />
      </Row>

      <Row>
        <ControlledDateInput
          name={'issuedDate'}
          placeholder={translate('issuedDate')}
          id={'issuedDate'}
          disabled={!isEditMode}
        />
        <ControlledSelect
          items={shareTypeOptionsAsDropdown}
          name="type"
          placeholder={translate('type')}
          disabled={!isEditMode}
        />
        <ControlledSelect
          items={roundOptionsAsDropdown}
          name="round"
          placeholder={translate('round')}
          disabled={!isEditMode}
        />
        <ControlledSelect
          name={'roundDuration'}
          placeholder={translate('roundDuration')}
          items={offeringMonthOptions}
          disabled={!isEditMode}
        />
        <ControlledInput
          name={'minimalInvestment'}
          placeholder={translate('minimalInvestment')}
          id={'minimalInvestment'}
          disabled={!isEditMode}
          type={'number'}
        />
        <ControlledMultipleSelect
          name={'regions'}
          placeholder={translate('regions')}
          items={regionsAsDropdown}
          disabled={!isEditMode}
        />
      </Row>
      <Row>
        <Documentation
          assets={assets}
          isEditMode={isEditMode}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
        />
      </Row>
      {isEditMode && (
        <ActionContainer>
          <Button text={translate('cancel')} onClick={onCancelHandler} buttonType={'secondary'} />
          <Button
            text={translate('save')}
            onClick={onSubmitHandler}
            disabled={!!Object.keys(errors).length || !Object.keys(dirtyFields).length}
            buttonType={'primary'}
            isLoading={false}
          />
        </ActionContainer>
      )}
    </CreateBondFormContainer>
  );
};

export default SmartShareFeatureForm;

const CreateBondFormContainer = styled.div`
  position: relative;
  padding: 2rem;
  margin-top: 2rem;
  gap: 3rem;
`;

const ActionContainer = styled.div`
  position: absolute;
  display: flex;
  gap: 2rem;
  right: 2rem;
  bottom: 2rem;
`;
