import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useCheckNftCollectionNameMutation } from '@/store/service';

export const useCheckNftCollectionNameOnBlur = () => {
  const [checkNftCollectionName, { data }] = useCheckNftCollectionNameMutation();

  const checkName = useMemo(() => {
    return data;
  }, [data]);

  const {
    formState: { errors },
  } = useFormContext();

  const checkIfNftCollectionNameExistsOnBlur = (name: string) => {
    if (!errors.name) {
      checkNftCollectionName({ query: { name } });
    }
  };

  return { checkIfNftCollectionNameExistsOnBlur, checkName };
};
