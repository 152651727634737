import { useState, useEffect } from 'react';

import { PageableItems } from '@/shared/types/api';

interface useTableWithPaginationProps {
  query: any;
  id?: string;
  additionalId?: string;
  skipIfNoFilters?: boolean;
}

export const useTableWithPagination = ({ query, id, additionalId, skipIfNoFilters }: useTableWithPaginationProps) => {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState<(string | PageableItems)[]>([]);
  const [filters, setFilters] = useState<{ [x: string]: any } | undefined>(undefined);

  const { data: newData } = query(
    { offset: offset, query: { ...filters, id, additionalId } },
    { refetchOnMountOrArgChange: true, skip: skipIfNoFilters && !filters }
  );

  useEffect(() => {
    if (newData) {
      if (offset === 0) {
        setData(newData.items);
      }

      if (offset !== 0) {
        setData([...data, ...newData.items]);
      }
    }
  }, [newData]);

  const fetch = () => {
    setOffset((off) => {
      if (newData?.totalPages * 20 <= off) {
        return off;
      }

      return off + 20;
    });
  };

  const resetData = () => {
    setData([]);
    setOffset(0);
  };

  return {
    fetch,
    resetData,
    data,
    setFilters,
  };
};
