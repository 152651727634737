import { CreateNftRequest } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const createNftPath = 'nft';

export const createNft = (build: BuildType) => {
  return build.mutation<void, CreateNftRequest>({
    invalidatesTags: ['NftInCollection', 'GetNftCollectionStatistics'],
    query: (nft: CreateNftRequest) => ({
      url: createNftPath,
      method: 'POST',
      body: nft,
    }),
  });
};
