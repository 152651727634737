import styled from 'styled-components';

export const BuyerInfoFormContainer = styled.div`
  position: relative;
  padding: 2rem;
  margin-top: 2rem;
  gap: 3rem;
`;

export const BuyerLogoWrapper = styled.div`
  display: flex;
  height: 20rem;
  width: 21rem;
  border-radius: 50%;
  background-color: white;
  margin-right: 5rem;
  margin-bottom: 2rem;
`;

export const BuyerLogo = styled.img`
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;

export const BuyerInfoWrapper = styled.div`
  display: flex;
`;

export const RowFirst = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
  margin: 2.2rem 0;
  flex-wrap: wrap;
`;

export const Column = styled.div`
  width: 27%;
`;

export const BuyerInfoContainer = styled.div`
  width: 76%;
`;
