import { IColumn, Table, Text } from 'issuix-ui-kit';

import { CardHeader, NftHoldersContainer, TableContainer } from '@/components/NftHolders/NftHolders.style';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useNav } from '@/hooks/useNav/UseNav';
import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { getSelectedCurrency } from '@/store/global/selectors';
import { useGetNftCollectionHoldersQuery } from '@/store/service';
import { formatAmount } from '@/utils/formatAmount/FormatAmount';

const NftHolders = () => {
  const { translate } = useTranslation();
  const { params } = useNav();
  const currency = useAppSelector((state) => getSelectedCurrency(state));

  const { data, fetch } = useTableWithPagination({ query: useGetNftCollectionHoldersQuery, id: params.id });

  const tableDef: IColumn[] = [
    {
      name: 'buyerName',
      width: 2,
      header: {
        label: translate('buyerName').toUpperCase(),
      },
    },
    {
      name: 'email',
      width: 2,
      header: {
        label: translate('email').toUpperCase(),
      },
    },
    {
      name: 'country',
      width: 1,
      header: {
        label: translate('country').toUpperCase(),
      },
    },
    {
      name: 'quantity',
      width: 1,
      header: {
        label: translate('quantity').toUpperCase(),
      },
    },
    {
      name: 'investedAmount',
      width: 2,
      header: {
        label: translate('investedAmount').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          return <>{formatAmount(item.investedAmount, currency?.decimals, currency?.rate, currency?.currencyCode)}</>;
        },
      },
    },
  ];

  return (
    <NftHoldersContainer>
      <CardHeader>
        <Text content={translate('nftHolders')} size={'large'} />
      </CardHeader>
      <TableContainer>
        <Table fetchTrigger={() => fetch()} tableDef={tableDef} data={data} />
      </TableContainer>
    </NftHoldersContainer>
  );
};

export default NftHolders;
