export enum ASSET_SHARE_ENUM {
  SECOND_PARTY_OPINION = 'second party opinion',
  WHITE_PAPER = 'white paper',
  PRESENTATION = 'presentation',
  ONE_PAGER = 'one pager',
  EVALUATION = 'evaluation',
  BUSINESS_PLAN = 'business plan',
}

export enum ASSET_SHARE_CAMEL_CASE_ENUM {
  SECOND_PARTY_OPINION = 'secondPartyOpinion',
  WHITE_PAPER = 'whitePaper',
  PRESENTATION = 'presentation',
  ONE_PAGER = 'onePager',
  EVALUATION = 'evaluation',
  BUSINESS_PLAN = 'businessPlan',
}
