import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useCheckCorporateNameExistsMutation } from '@/store/service';

export const useCheckCorporateNameOnBlur = () => {
  const [checkCorporateNameExists, { data }] = useCheckCorporateNameExistsMutation();

  const isCorporateNameExist = useMemo(() => {
    return data;
  }, [data]);

  const {
    formState: { errors },
  } = useFormContext();

  const checkCorporateNameOnBlur = (name: string) => {
    if (!errors.name) {
      checkCorporateNameExists(name);
    }
  };

  return { isCorporateNameExist, checkCorporateNameOnBlur };
};
