import { Checkbox } from 'issuix-ui-kit';
import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface ControlledCheckboxProps {
  label?: string;
  richLabel?: React.ReactNode;
  name: string;
  disabled?: boolean;
}

const ControlledCheckbox: FC<ControlledCheckboxProps> = ({ label, richLabel, name, disabled }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field: { value, onChange } }) => (
        <Checkbox label={label} richLabel={richLabel} checked={value} onChange={onChange} disabled={disabled} />
      )}
    />
  );
};

export default ControlledCheckbox;
