import { Card } from 'issuix-ui-kit';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  > div {
    max-height: 100%;
  }
`;

export const CardContainer = styled.div`
  height: 23rem;
`;
export const HeadingCardContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
`;
export const TitleAndIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 3rem;
  height: 100%;
  width: 20%;
  flex: 1;
`;

export const TitleContainer = styled.div`
  font-size: ${(props) => props.theme.typography.large.fontSize};
  color: white;
  height: 20%;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
  height: 100%;
  padding: 2rem;
  border-radius: 2rem;
  background: linear-gradient(281.98deg, rgba(11, 17, 119, 0.4) -11.71%, rgba(11, 17, 119, 0.05) 103.41%);
  box-shadow: rgb(0 0 0 / 10%) 2px 2px 5px -1px inset;
  position: relative;
`;

export const TableContainer = styled.div`
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 2rem;
  width: 100%;
`;

export const TableRowContainer = styled.div`
  display: flex;
  height: 25%;
`;

export const TableCellContainer = styled.div`
  @media (max-width: 950px) {
    font-size: 1.6rem;
  }
  @media (max-width: 850px) {
    font-size: 1.5rem;
  }
  @media (max-width: 770px) {
    font-size: 0.5rem;
  }
  width: 100%;
  font-size: 1rem;
  margin-right: 1rem;
`;

export const TitleAndTableContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: 1rem;
  position: absolute;
  right: 2rem;
  bottom: 2rem;
`;

export const ButtonOneContainer = styled.div`
  margin-right: 0.5rem;
`;
