import { Button, Text } from 'issuix-ui-kit';
import { FC, useState } from 'react';

import { ButtonContainer, Heading, SmartShareLedgerCard } from './SmartShareLedger.styles';

import AssignToShareModal from '@/components/AssignToShareModal/AssignToShareModal';
import RoleProtected from '@/components/RoleProtected/RoleProtected';
import SmartShareLedgerCalculation from '@/components/SmartShareLedgerCalculation/SmartShareLedgerCalculation';
import SmartShareLedgerTable from '@/components/SmartShareLegderTable/SmartShareLedgerTable';
import { ROLE_TYPE } from '@/enums/RoleType';
import { SHARE_STATUS_ENUM } from '@/enums/share/ShareStatusEnum';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useGetShareStatusQuery, useGetSmartSharesOfferedQuery } from '@/store/service';

interface SmartShareLedgerProps {
  userId: string;
}

const mapStatusToButtonState = (status: SHARE_STATUS_ENUM) => status !== SHARE_STATUS_ENUM.APPROVED;

const SmartShareLedger: FC<SmartShareLedgerProps> = ({ userId }) => {
  const { translate } = useTranslation();

  const [isAssignShareModalOpen, setIsAssignShareModalOpen] = useState<boolean>(false);

  const { data } = useGetShareStatusQuery();
  const { data: quantity } = useGetSmartSharesOfferedQuery({ query: { userId: userId } }, { skip: !userId });

  return (
    <SmartShareLedgerCard>
      <Heading>
        <Text content={translate('smartShareLedger')} size={'large'} />
        <AssignToShareModal isOpen={isAssignShareModalOpen} setIsOpen={setIsAssignShareModalOpen} quantity={quantity} />
        <RoleProtected allowedRoles={[ROLE_TYPE.CORPORATE]}>
          <ButtonContainer>
            {data?.status && (
              <Button
                onClick={() => setIsAssignShareModalOpen(true)}
                text={translate('assignToShare')}
                disabled={mapStatusToButtonState(data.status)}
              />
            )}
          </ButtonContainer>
        </RoleProtected>
      </Heading>
      <SmartShareLedgerTable userId={userId} isDashboard={false} />
      <SmartShareLedgerCalculation userId={userId} />
    </SmartShareLedgerCard>
  );
};

export default SmartShareLedger;
