import { FC } from 'react';

import CreateSmartBondForm from './CreateSmartBondForm/CreateSmartBondForm';
import { createBondFormSchema } from './CreateSmartBondSchema';

import ControlledForm from '@/components/Form/ControlledForm';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { ViewBondWrapper } from '@/view/Core/CreateSmartBond/CreateSmartBond.styles';

const CreateSmartBond = () => {
  return (
    <ViewBondWrapper>
      <CreateSmartBondView />
    </ViewBondWrapper>
  );
};

const CreateSmartBondView: FC = () => {
  const { translate } = useTranslation();
  return (
    <ControlledForm schema={createBondFormSchema(translate)}>
      <CreateSmartBondForm />
    </ControlledForm>
  );
};
export default CreateSmartBond;
