import camelCase from 'lodash/camelCase';

import { getConcatValues } from '@/shared/commonUtils/getConcatValues/getConcatValues';
import { AssetResponse, NftCollectionResponse } from '@/shared/types/api';

export const parseFormObject = (
  response: NftCollectionResponse,
  assets?: AssetResponse[],
  productAsset?: AssetResponse
) => {
  const assetsData = assets?.reduce((prev, current) => {
    return { ...prev, [camelCase(current.type)]: { fileId: current.id, url: current.key } };
  }, {});

  const productDocument = { fileId: productAsset?.id, url: productAsset?.key, type: 'productDocument' };

  const {
    name: collectionName,
    price: pricePerNft,
    product,
    tenant,
    description,
    regions,
    bankName,
    bankAddress,
    iban,
    swift,
    currencies,
  } = response;

  return {
    collectionName,
    pricePerNft,
    product: product.title,
    productDocument,
    tenant,
    description,
    ...assetsData,
    regions: getConcatValues(regions),
    currencies: getConcatValues(currencies),
    bankName,
    bankAddress,
    iban,
    swift,
  };
};
