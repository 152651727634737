import { Button, Card, Text } from 'issuix-ui-kit';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import CreateSmartShareFeatures from '../CreateSmartShareFeatures';
import { InitialShare } from '../InitialShare';

import { Documentation } from '@/components/BondAndShare/Documentation';
import PaymentInfo from '@/components/BondAndShare/PaymentInfo';
import ControlledCheckbox from '@/components/Form/ControlledCheckbox';
import { Row } from '@/components/Layout/Row/Row.styled';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { CreateShareRequest } from '@/shared/types/api';
import { useCreateShareMutation } from '@/store/service';
import { createShareMapper } from '@/store/shares/CreateShareMapper';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';

const CreateSmartShareForm = () => {
  const [resetForm, setResetForm] = useState<boolean>(false);
  const {
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { isValid },
  } = useFormContext();

  const navigate = useNavigate();

  const { translate } = useTranslation();

  const [createShare] = useCreateShareMutation();

  useEffect(() => {
    reset(InitialShare);
  }, [resetForm]);

  const onCreateShare = () => {
    navigate(`/${ClientRoutes.Shares.root}`);
  };

  return (
    <CreateShareFormContainer>
      <CreateSmartShareFeatures />
      <PaymentInfo />
      <Documentation assets={[]} setValue={setValue} getValues={getValues} isEditMode={true} />
      <AdditionalServicesOfferedBox>
        <Text size={'large'} content={translate('additionalServicesOffered')} />
        <Row>
          <ControlledCheckbox label={translate('secondPartyOpinionOption')} name="secondPartyOpinionOption" />
          <ControlledCheckbox label={translate('brokerageOption')} name="brokerageOption" />
          <ControlledCheckbox label={translate('advisoryOption')} name="advisoryOption" />
          <ControlledCheckbox label={translate('storageOption')} name="storageOption" />
          <ControlledCheckbox label={translate('underwritingOption')} name="underwritingOption" />
          <ButtonBox>
            <Button
              buttonType={'secondary'}
              onClick={() => setResetForm(!resetForm)}
              text={translate('cancel')}
              disabled={!isValid}
            />
            <Button
              onClick={handleSubmit((data) => {
                createShare(createShareMapper(data) as CreateShareRequest).then((res: any) =>
                  res.data ? onCreateShare() : console.error(res.error.data.payload.message)
                );
              })}
              text={translate('submit')}
              disabled={!isValid}
            />
          </ButtonBox>
        </Row>
      </AdditionalServicesOfferedBox>
    </CreateShareFormContainer>
  );
};

export default CreateSmartShareForm;

const CreateShareFormContainer = styled.div`
  padding: 2rem;
  gap: 3rem;
`;

const AdditionalServicesOfferedBox = styled(Card)`
  height: max-content;
  width: 100%;
  padding: 1rem 2rem;
  margin-top: 3rem;
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  gap: 2rem;
`;
