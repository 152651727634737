import { BondCalculationResponse } from '@/shared/types/api';
import { formatAmount } from '@/utils/formatAmount/FormatAmount';

export const baseBondDefaultCalculation = {
  totalSmartBondIssuance: '0',
  annualCouponsToPay: '0',
  totalRepayAmount: '0',
};

export type BondCalculation = {
  totalSmartBondIssuance: string;
  annualCouponsToPay: string;
  totalRepayAmount: string;
};

export const formatCalculation = (
  bondCalculation: BondCalculationResponse,
  currencyDecimals = 2,
  currencyRate = 1,
  currencyName = ''
): BondCalculation => {
  return {
    totalSmartBondIssuance: formatAmount(
      bondCalculation.totalSmartBondIssuance,
      currencyDecimals,
      currencyRate,
      currencyName
    ),
    annualCouponsToPay: formatAmount(bondCalculation.annualCouponsToPay, currencyDecimals, currencyRate, currencyName),
    totalRepayAmount: formatAmount(bondCalculation.totalRepayAmount, currencyDecimals, currencyRate, currencyName),
  };
};
