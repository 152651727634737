import camelCase from 'lodash/camelCase';

import { AssetResponse } from '@/shared/types/api';

export const parseFormAssetBondObject = (assets: AssetResponse[]) => {
  const assetsData = assets.reduce((prev, current) => {
    return { ...prev, [camelCase(current.type)]: { fileId: current.id, url: current.key } };
  }, {});

  return {
    ...assetsData,
  };
};
