import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useCheckEmailExistsMutation } from '@/store/service';

export const useCheckEmailOnBlur = () => {
  const [checkEmailExists, { data }] = useCheckEmailExistsMutation();

  const isEmailExist = useMemo(() => {
    return data;
  }, [data]);

  const {
    formState: { errors },
  } = useFormContext();

  const checkEmailOnBlur = (email: string) => {
    if (!errors.email) {
      checkEmailExists(email);
    }
  };

  return { checkEmailOnBlur, isEmailExist };
};
