import { Button, IColumn, Table, Text } from 'issuix-ui-kit';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { smartSharePendingFilterSchema } from './FilterForm/SmartSharesPendingSchema';

import { AcceptModal } from '@/components/AcceptModal/AcceptModal';
import ControlledForm from '@/components/Form/ControlledForm';
import { RejectModal } from '@/components/RejectModal/RejectModal';
import { STATUS_ENUM } from '@/enums/Status';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useRoundOptions } from '@/hooks/useRoundOptions/useRoundOptions';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { RoundOptionResponse, ShareResponse } from '@/shared/types/api';
import { useApproveShareMutation, useRejectShareMutation } from '@/store/service';
import { composePath } from '@/utils/composePath/ComposePath';
import { formatAmount } from '@/utils/formatAmount/FormatAmount';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';
import SmartSharesPendingFilterForm from '@/view/Admin/SmartSharesPending/FilterForm/SmartSharesPendingFilterForm';
import {
  CardHeader,
  SmartSharesPendingCard,
  SmartSharesPendingContainer,
  FiltersContainer,
  TableContainer,
  ActionsContainer,
} from '@/view/Admin/SmartSharesPending/SmartSharesPending.styles';
import {
  SmartSharePendingContextProvider,
  useSmartSharesPendingContext,
} from '@/view/Admin/SmartSharesPending/SmartSharesPendingContext';

const SmartSharesPending = () => {
  return (
    <SmartSharePendingContextProvider>
      <SmartSharePendingView />
    </SmartSharePendingContextProvider>
  );
};

const SmartSharePendingView: FC = () => {
  const { translate } = useTranslation();
  const { data, fetch, setFilters } = useSmartSharesPendingContext();
  const { roundOptions } = useRoundOptions();
  const currency = useAppSelector((state) => state.global.currency);
  const navigate = useNavigate();
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [shareId, setShareId] = useState<string>('');
  const [reason, setReason] = useState<string>('');

  const [rejectShare] = useRejectShareMutation();
  const [approveShare] = useApproveShareMutation();

  useEffect(() => {
    setFilters({ currencyId: currency?.id, status: STATUS_ENUM.PENDING });
  }, [currency]);

  const getRoundOption = (item: string): RoundOptionResponse => {
    return roundOptions.find((round: RoundOptionResponse) => round.value === item);
  };

  const onAction = (id: string) => {
    navigate(composePath(`/${ClientRoutes.Shares.smartSharePendingView}`, { id }));
  };

  const onAccept = () => {
    approveShare({ id: shareId });
    setIsAcceptModalOpen(false);
  };

  const onReject = () => {
    rejectShare({ reason, id: shareId });
    setIsRejectModalOpen(false);
  };

  const onAcceptModalOpen = (id: string) => {
    setIsAcceptModalOpen(true);
    setShareId(id);
  };

  const onRejectModalOpen = (id: string) => {
    setIsRejectModalOpen(true);
    setShareId(id);
  };

  const onRejectModalClose = () => {
    setIsRejectModalOpen(false);
  };

  const onAcceptModalClose = () => {
    setIsAcceptModalOpen(false);
  };

  const tableDef: IColumn[] = [
    {
      name: 'code',
      width: 1,
      header: {
        label: translate('code').toUpperCase(),
      },
    },
    {
      name: 'userName',
      width: 1.5,
      header: {
        label: translate('companyName').toUpperCase(),
      },
      column: {
        render: (item: ShareResponse) => <p>{item.user.name}</p>,
      },
    },
    {
      name: 'country',
      width: 1,
      header: {
        label: translate('country').toUpperCase(),
      },
      column: {
        render: (item: ShareResponse) => <p>{item.user.country?.name}</p>,
      },
    },
    {
      name: 'price',
      width: 1,
      header: {
        label: translate('sharePrice').toUpperCase(),
      },
    },
    {
      name: 'nominalValue',
      width: 2,
      header: {
        label: translate('nominalValue').toUpperCase(),
      },
      column: {
        render: (item: ShareResponse) => {
          const nominalValue = item.shareCapital / item.totalAmount;
          return (
            <p>
              {formatAmount(nominalValue, currency?.decimals, currency?.rate)} {currency?.currencyCode}
            </p>
          );
        },
      },
    },
    {
      name: 'totalAmount',
      width: 2,
      header: {
        label: translate('totalAmount').toUpperCase(),
      },
      column: {
        render: (item: ShareResponse) => <p>{item.totalAmount}</p>,
      },
    },
    {
      name: 'availableForSaleInRound',
      width: 2,
      header: {
        label: translate('sharesOffered').toUpperCase(),
      },
    },
    {
      name: 'round',
      width: 1,
      header: {
        label: translate('round').toUpperCase(),
      },
      column: {
        render: (item: ShareResponse) => <p>{getRoundOption(item.round)?.label}</p>,
      },
    },
    {
      name: 'availableForSale',
      width: 2,
      header: {
        label: translate('sharesOutstanding').toUpperCase(),
      },
    },
    {
      name: 'actions',
      width: 3.5,
      header: {
        label: translate('actions').toUpperCase(),
      },
      column: {
        render: (item: ShareResponse) => (
          <ActionsContainer>
            <Button fullyRounded onClick={() => onAction(item.id)} text={translate('view')} size={'medium'} />
            <Button
              onClick={() => onAcceptModalOpen(item.id)}
              text={translate('accept')}
              fullyRounded
              size={'medium'}
            />
            <Button
              onClick={() => onRejectModalOpen(item.id)}
              text={translate('reject')}
              buttonType={'danger'}
              fullyRounded
              size={'medium'}
            />
          </ActionsContainer>
        ),
      },
    },
  ];

  return (
    <SmartSharesPendingContainer>
      <SmartSharesPendingCard>
        <CardHeader>
          <Text content={translate('sharesPending')} size={'large'} />
        </CardHeader>
        <TableContainer>
          <Table data={data} fetchTrigger={() => fetch()} tableDef={tableDef} />
        </TableContainer>
      </SmartSharesPendingCard>
      <FiltersContainer>
        <Text content={translate('filter')} size={'large'} />
        <ControlledForm schema={smartSharePendingFilterSchema()}>
          <SmartSharesPendingFilterForm />
        </ControlledForm>
      </FiltersContainer>
      <AcceptModal
        isAcceptModalOpen={isAcceptModalOpen}
        onAcceptModalClose={onAcceptModalClose}
        onAccept={onAccept}
        title={translate('accept')}
        subtitle={translate('areYouSureAcceptShare')}
      />
      <RejectModal
        isRejectModalOpen={isRejectModalOpen}
        onRejectModalClose={onRejectModalClose}
        onReject={onReject}
        title={translate('reject')}
        subtitle={translate('areYouSureRejectShare')}
        reason={reason}
        setReason={setReason}
      />
    </SmartSharesPendingContainer>
  );
};
export default SmartSharesPending;
