import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ROLE_TYPE } from '@/enums/RoleType';
import { browserService } from '@/service/browser/BrowserService';
import { BROWSER_SERVICE_KEYS } from '@/service/browser/BrowserServiceKeys';
import { CurrencyResponse, RegisterResponse, UserResponse } from '@/shared/types/api';
import { api } from '@/store/service';
import { mapFieldValuesToUpdateUserData } from '@/utils/mapFieldValuesToUpdateUserData/mapFieldValuesToUpdateUserData';

export type GlobalState = {
  isLoading: boolean;
  isError: boolean;
  user: UserResponse | null;
  resendToken: RegisterResponse | null;
  isTenantError: boolean;
  currency: CurrencyResponse | undefined;
  language: 'en' | 'de' | undefined;
  isServerError: boolean;
};

const initialState: GlobalState = {
  isLoading: true,
  isError: false,
  isTenantError: false,
  user: browserService.get(BROWSER_SERVICE_KEYS.USER),
  resendToken: null,
  currency: undefined,
  language: undefined,
  isServerError: false,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setIsTenantError: (state: GlobalState, action: PayloadAction<any>) => {
      state.isTenantError = action.payload;
    },
    setUser: (state: GlobalState, action) => {
      state.user = action.payload;
    },
    changeCurrency: (state: GlobalState, action: PayloadAction<CurrencyResponse>) => {
      state.currency = action.payload;
    },
    changeLanguage: (state: GlobalState, action: PayloadAction<'en' | 'de'>) => {
      state.language = action.payload;
    },
    setIsServerError: (state: GlobalState, action: PayloadAction<any>) => {
      state.isServerError = action.payload;
    },
  },
  extraReducers: (builder) => {
    // ERROR
    builder.addMatcher(api.endpoints.getCurrencies.matchRejected, (state, payload) => {
      if (payload.payload) {
        state.isError = true;
      }
    });

    // LOADING
    builder.addMatcher(api.endpoints.getCurrencies.matchPending, (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(api.endpoints.getCurrencies.matchFulfilled, (state) => {
      state.isLoading = false;
    });

    // USER
    builder.addMatcher(api.endpoints.login.matchFulfilled, (state, payload) => {
      state.user = payload.payload.user;
      browserService.store(BROWSER_SERVICE_KEYS.ACCESS_TOKEN, payload.payload?.accessToken);
      browserService.store(BROWSER_SERVICE_KEYS.REFRESH_TOKEN, payload.payload?.refreshToken);
      browserService.store(BROWSER_SERVICE_KEYS.USER, payload.payload?.user);
    });
    builder.addMatcher(api.endpoints.logout.matchFulfilled, (state) => {
      state.user = null;
      browserService.clear(BROWSER_SERVICE_KEYS.ACCESS_TOKEN);
      browserService.clear(BROWSER_SERVICE_KEYS.USER);
      browserService.clear(BROWSER_SERVICE_KEYS.REFRESH_TOKEN);
    });

    // UPDATE_USER
    builder.addMatcher(api.endpoints.updateUserDetails.matchFulfilled, (state, payload) => {
      if (state.user && state.user.role !== ROLE_TYPE.ADMIN) {
        state.user = mapFieldValuesToUpdateUserData(payload.payload);
      }
    });

    // RESEND_TOKEN
    builder.addMatcher(api.endpoints.quickRegistrationPrivateUser.matchFulfilled, (state, payload) => {
      state.resendToken = payload.payload;
    });
    builder.addMatcher(api.endpoints.quickRegistrationCorporateUser.matchFulfilled, (state, payload) => {
      state.resendToken = payload.payload;
    });
    builder.addMatcher(api.endpoints.fullRegistrationPrivateUser.matchFulfilled, (state, payload) => {
      state.resendToken = payload.payload;
    });
    builder.addMatcher(api.endpoints.fullRegistrationCorporateUser.matchFulfilled, (state, payload) => {
      state.resendToken = payload.payload;
    });
    builder.addMatcher(api.endpoints.resendEmail.matchFulfilled, (state, payload) => {
      state.resendToken = payload.payload;
    });
  },
});

export const { setIsTenantError, setUser, changeCurrency, changeLanguage, setIsServerError } = globalSlice.actions;

export default globalSlice.reducer;
