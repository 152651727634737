import { Card } from 'issuix-ui-kit';
import styled from 'styled-components';

export const CreateNftCollectionWrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const FinishCard = styled(Card)`
  padding: 2rem;
  display: flex;
  align-items: flex-end;
`;
