import camelCase from 'lodash/camelCase';
import { FieldValues, UseFormSetValue, UseFormGetValues } from 'react-hook-form';

import { AssetResponse } from '@/shared/types/api';
import {
  useAssignedAssetUrlMutation,
  useUnassignedAssetMutation,
  useUnassignedAssetUrlMutation,
} from '@/store/service';

export const useUploadFile = () => {
  const [getAssetUnassignedFile] = useUnassignedAssetUrlMutation();
  const [getAssignedFile] = useAssignedAssetUrlMutation();
  const [uploadUnassignedAssetFunc] = useUnassignedAssetMutation();

  const viewFile = async (
    fileId: string,
    type: string,
    getValues: UseFormGetValues<FieldValues>,
    assets: AssetResponse[] = [],
    isEditMode?: boolean
  ) => {
    const assetByType = assets?.find((asset: any) => asset.type === type);
    const fetchAsset =
      !assetByType || (isEditMode && assetByType?.id != fileId) ? getAssetUnassignedFile : getAssignedFile;
    const asset = await fetchAsset({ assetId: getValues(camelCase(type)).fileId }).unwrap();
    window.open(asset.url, '_blank', 'noopener,noreferrer');
    return;
  };

  const onDeleteHandler = (setValue: UseFormSetValue<FieldValues>, name: string) => {
    setValue(name, { fileId: undefined, url: undefined, type: name }, { shouldDirty: true, shouldValidate: false });
  };

  const uploadUnassignedAsset = async (file: File, type: string, setValue: UseFormSetValue<FieldValues>) => {
    try {
      const formData: FormData = new FormData();
      formData.append('file', file);
      formData.append('fileType', file.type);
      const { data } = await uploadUnassignedAssetFunc(formData);
      setValue(type, data, { shouldDirty: true, shouldValidate: true });
    } catch (err) {
      console.error(err);
    }
  };

  return {
    uploadUnassignedAsset,
    viewFile,
    onDeleteHandler,
  };
};
