import { pagingLimit } from '@/shared/constants/Paging';
import { PageableItems } from '@/shared/types/PageableItems';
import { ShareholdersLedgerResponse } from '@/shared/types/ShareholdersLedgerResponse';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseShareholdersLedgerUrl = 'transactions/shareholders';

const getShareholdersLedgerUrl = (query: { id: string }, offset: number): string =>
  `${baseShareholdersLedgerUrl}?userId=${query.id}&offset=${offset}&limit=${pagingLimit}`;

const getShareholdersPdfUrl = (userId: string) => {
  return `${baseShareholdersLedgerUrl}/pdf?userId=${userId}`;
};

export const getShareholdersLedger = (build: BuildType) => {
  return build.query<PageableItems<ShareholdersLedgerResponse>, any>({
    query: ({ query, offset }) => ({
      url: getShareholdersLedgerUrl(query, offset),
      method: 'GET',
    }),
  });
};

export const getShareholdersPdf = (build: BuildType) => {
  return build.query<PageableItems<ShareholdersLedgerResponse>, any>({
    query: (query) => ({
      url: getShareholdersPdfUrl(query.userId),
      method: 'GET',
      responseHandler: (response) => response.blob(),
    }),
  });
};
