import { ShareTypeOptionResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const shareTypeOptionPath = 'share-type-option';

export const getShareTypeOptions = (build: BuildType) => {
  return build.query<ShareTypeOptionResponse[], void>({
    query: () => shareTypeOptionPath,
  });
};
