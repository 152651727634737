import { NftTransactionResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const getNftTransactionByIdPath = 'nft-transactions';

const getNftTransactionByIdUrl = (query: { id: string }): string => `${getNftTransactionByIdPath}/${query.id}`;

export const getNftTransactionById = (build: BuildType) => {
  return build.query<NftTransactionResponse, any>({
    query: ({ query }) => ({
      url: getNftTransactionByIdUrl(query),
      method: 'GET',
    }),
  });
};
