import { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';

import { CURRENCIES } from '@/enums/Currencies';
import { PAYMENT_TYPES } from '@/enums/PaymentTypes';
import { PRODUCT_TYPE_ENUM } from '@/enums/ProductType';
import { Nullable } from '@/shared/types/Nullable';
import { BondResponse, CurrencyResponse, ShareResponse } from '@/shared/types/api';
import { useGetBondByIdQuery, useGetShareByIdQuery } from '@/store/service';

type IPaymentContext = {
  loadResource: (type: Nullable<PRODUCT_TYPE_ENUM>, id: string) => Promise<void>;
  setQuantity: (quantity: number) => void;
  resource: Nullable<BondResponse | ShareResponse>;
  resourceType: Nullable<PRODUCT_TYPE_ENUM>;
  currency: Nullable<CurrencyResponse>;
  setCurrency: (currency: Nullable<CurrencyResponse>) => void;
  quantity: number;
  isCrypto: Nullable<boolean>;
  paymentMethod: Nullable<PAYMENT_TYPES>;
  setPaymentMethod: (paymentType: Nullable<PAYMENT_TYPES>) => void;
  closeModal: (() => void) | undefined;
  setTransactionId: (transactionId: string) => void;
  transactionId: string;
};

export const PaymentContext = createContext({} as IPaymentContext);

export const usePaymentContext = () => useContext<IPaymentContext>(PaymentContext);

const CRYPTO_CURRENCIES = [CURRENCIES.BTC, CURRENCIES.ETH];

interface Props {
  closeModal?: () => void;
}

export const PaymentContextProvider: FC<PropsWithChildren<Props>> = ({ children, closeModal }) => {
  const [resourceType, setResourceType] = useState<Nullable<PRODUCT_TYPE_ENUM>>();
  const [quantity, setQuantity] = useState<number>(0);
  const [resource, setResource] = useState<Nullable<BondResponse | ShareResponse>>();
  const [resourceId, setResourceId] = useState<string>('');
  const [currency, setCurrency] = useState<Nullable<CurrencyResponse>>();
  const { data: bond } = useGetBondByIdQuery(
    { query: { id: resourceId && resourceId } },
    { skip: !resourceId || resourceType !== 'BOND_PAPERS' }
  );
  const { data: share } = useGetShareByIdQuery(
    { query: { id: resourceId && resourceId } },
    { skip: !resourceId || resourceType !== 'ACTIONS' }
  );
  const [paymentMethod, setPaymentMethod] = useState<Nullable<PAYMENT_TYPES>>(PAYMENT_TYPES.CARD);
  const [transactionId, setTransactionId] = useState('');

  useEffect(() => {
    if (bond && resourceType === PRODUCT_TYPE_ENUM.BOND_PAPERS) {
      setResource(bond);
    } else if (share && resourceType === PRODUCT_TYPE_ENUM.ACTIONS) {
      setResource(share);
    }
  }, [bond, share]);

  const loadResource = async (type: Nullable<PRODUCT_TYPE_ENUM>, id: string): Promise<void> => {
    setResourceType(type);
    setResourceId(id);
  };

  const isCrypto = useMemo(() => {
    if (!currency) {
      return undefined;
    }

    return CRYPTO_CURRENCIES.some((cryptoCurrencyCode) => cryptoCurrencyCode === currency?.currencyCode);
  }, [currency]);

  return (
    <PaymentContext.Provider
      value={{
        paymentMethod,
        setPaymentMethod,
        quantity,
        isCrypto,
        loadResource,
        setQuantity,
        resource,
        resourceType,
        currency,
        setCurrency,
        closeModal,
        transactionId,
        setTransactionId,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
