import { AssetResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const getNftProductAssetPath = 'nft-product';

const getNftProductAssetUrl = (query: { id: string }): string => `${getNftProductAssetPath}/${query.id}/asset`;

export const getNftProductAsset = (build: BuildType) => {
  return build.query<AssetResponse, any>({
    query: ({ query }) => ({
      url: getNftProductAssetUrl(query),
      method: 'GET',
    }),
  });
};
