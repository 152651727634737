import { UserRoleType } from '@/shared/types/UserType';

export const useUserType = (items: UserRoleType[]): { userTypeOptions: UserRoleType[] } => {
  const userTypeOptions: { label: string; value: string }[] = [];

  items.forEach((item) => {
    userTypeOptions.push({ label: item.label.charAt(0).toUpperCase() + item.label.slice(1), value: item.value });
  });
  return { userTypeOptions };
};
