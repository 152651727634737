import { Text } from 'issuix-ui-kit';
import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  margin-left: auto;
  gap: 2rem;
`;

export const StyledText = styled(Text)`
  text-align: left;
`;
