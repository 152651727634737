import React, { createContext, FC, useContext } from 'react';

import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { PageableItems } from '@/shared/types/api';
import { useGetSharesQuery } from '@/store/service';

interface ISmartSharePendingRequestsContext {
  data: (string | PageableItems)[];
  fetch: () => void;
  setFilters: (filters: { [x: string]: any } | undefined) => void;
  resetData: () => void;
}

interface SmartSharePendingContextProviderProps {
  children: React.ReactNode;
}

export const SmartSharesPendingContext = createContext({} as ISmartSharePendingRequestsContext);

export const useSmartSharesPendingContext = () => useContext(SmartSharesPendingContext);

export const SmartSharePendingContextProvider: FC<SmartSharePendingContextProviderProps> = ({ children }) => {
  const { fetch, resetData, data, setFilters } = useTableWithPagination({
    query: useGetSharesQuery,
    skipIfNoFilters: true,
  });

  return (
    <SmartSharesPendingContext.Provider
      value={{
        data,
        fetch,
        setFilters,
        resetData,
      }}
    >
      {children}
    </SmartSharesPendingContext.Provider>
  );
};
