import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseUsersUrl = 'users';

const composeGetAssetsPath = (id: string, type: string) => {
  return `${baseUsersUrl}/${id}/${type}/assets`;
};

export const getPrivateUserAssets = (build: BuildType) => {
  return build.query({
    query: (userId: string) => ({
      url: composeGetAssetsPath(userId, 'private'),
      method: 'GET',
    }),
  });
};

export const getCorporateUserAssets = (build: BuildType) => {
  return build.query({
    query: (userId: string) => ({
      url: composeGetAssetsPath(userId, 'corporate'),
      method: 'GET',
    }),
  });
};
