import { FC } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { TENANT } from '@/enums/App';
import VerifyDomain from '@/view/Verify/VerifyDomain';

const VerifyLayout: FC<{ tenant: TENANT }> = ({ tenant }) => {
  return (
    <Screen tenant={tenant}>
      <GlobalStyle />
      <VerifyDomain tenant={tenant} />
    </Screen>
  );
};

export default VerifyLayout;

const Screen = styled.div<{ tenant: string }>`
  display: flex;
  height: 100vh;
  width: 100vw;
  background: ${(props) =>
    `url(${require(`../../assets/verify/${props.tenant}/images/background.png`)}) no-repeat center`};
  background-size: cover;
`;

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${(props: any) => props.theme.typography.fontFamily.verify};
  }
`;
