export const featureDashboard = process.env.REACT_APP_FEATURE_DASHBOARD === 'true';

export const featureBonds = process.env.REACT_APP_FEATURE_BONDS === 'true';

export const featureShares = process.env.REACT_APP_FEATURE_SHARES === 'true';

export const featureTransactionsToFrom = process.env.REACT_APP_FEATURE_TRANSACTIONS_TO_FROM === 'true';

export const featureTransactions = process.env.REACT_APP_FEATURE_TRANSACTIONS === 'true';

export const featureRegistration = process.env.REACT_APP_FEATURE_REGISTRATION === 'true';

export const featureLogin = process.env.REACT_APP_FEATURE_LOGIN === 'true';

export const featureViewProfile = process.env.REACT_APP_FEATURE_VIEW_PROFILE === 'true';

export const featureChat = process.env.REACT_APP_FEATURE_CHAT === 'true';

export const featurePushNotifications = process.env.REACT_APP_FEATURE_PUSH_NOTIFICATIONS === 'true';

export const featureHashColumn = process.env.REACT_APP_FEATURE_HASH_COLUMN === 'true';
