import { LegalFormResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const legalFormPath = 'legal-form';

export const getLegalForms = (build: BuildType) => {
  return build.query<LegalFormResponse[], void>({
    query: () => legalFormPath,
  });
};
