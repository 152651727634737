import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseInvestedAmountUrl = 'transactions/invested';

const getInvestedAmountUrl = (query: { id: string }): string => `${baseInvestedAmountUrl}/${query.id}`;

export const getInvestedAmount = (build: BuildType) => {
  return build.query<number, any>({
    query: ({ query }) => ({
      url: getInvestedAmountUrl(query),
      method: 'GET',
    }),
  });
};
