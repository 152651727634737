import { useMemo } from 'react';
import { TFunction } from 'react-i18next';

import { ShareTypeOptionResponse } from '@/shared/types/api';
import { useGetShareTypeOptionsQuery } from '@/store/service';

export const useShareTypeOptions = (translate: TFunction) => {
  const { data: shareTypeOptions } = useGetShareTypeOptionsQuery();

  const shareTypeOptionsAsDropdown = useMemo(
    () =>
      shareTypeOptions?.map((shareType: ShareTypeOptionResponse) => {
        return {
          value: shareType.value,
          label: translate(shareType.label.toLowerCase() as keyof TFunction),
        };
      }),
    [shareTypeOptions, translate]
  );

  return {
    shareTypeOptions: shareTypeOptions || [],
    shareTypeOptionsAsDropdown: shareTypeOptionsAsDropdown || [],
  };
};
