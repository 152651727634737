import { GetS3SignedUrlRequest } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const unassignedAssetUrlPath = 'assets/unassigned-url';

export const unassignedAssetUrl = (build: BuildType) => {
  return build.mutation<any, GetS3SignedUrlRequest>({
    query: (assetId) => ({
      url: unassignedAssetUrlPath,
      method: 'POST',
      body: assetId,
    }),
  });
};
