import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ROLE_TYPE } from '@/enums/RoleType';
import { AssetResponse } from '@/shared/types/api';
import {
  useApproveUserMutation,
  useAssignedAssetUrlMutation,
  useGetPrivateUserRequestQuery,
  useGetCorporateUserRequestQuery,
  useRejectUserMutation,
} from '@/store/service';
import { CorporateRequestView } from '@/view/Admin/Request/CorporateRequestView';
import { PrivateRequestView } from '@/view/Admin/Request/PrivateRequestView';

const Request = () => {
  const params = useParams();
  const [skipCorporate, setSkipCorporate] = useState(true);
  const [skipPrivate, setSkipPrivate] = useState(true);

  const { data: corporateUser } = useGetCorporateUserRequestQuery(params.id, { skip: skipCorporate });
  const { data: privateUser } = useGetPrivateUserRequestQuery(params.id, { skip: skipPrivate });

  const [getAssetUrl, { data: assetUrl }] = useAssignedAssetUrlMutation();

  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState<boolean>(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState<boolean>(false);
  const [reason, setReason] = useState<string>('');
  const [rejectUser, { isSuccess: isRejectSuccess }] = useRejectUserMutation();
  const [approveUser, { isSuccess: isApproveSuccess }] = useApproveUserMutation();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const role = queryParams.get('role');
    if (role === ROLE_TYPE.PRIVATE) {
      setSkipPrivate(!skipPrivate);
    }
    if (role === ROLE_TYPE.CORPORATE) {
      setSkipCorporate(!skipCorporate);
    }
  }, []);

  const onAcceptModalClose = () => {
    setIsAcceptModalOpen(false);
  };

  const onRejectModalClose = () => {
    setIsRejectModalOpen(false);
    setReason('');
  };

  const openAccept = () => {
    setIsAcceptModalOpen(true);
  };

  const openReject = () => {
    setIsRejectModalOpen(true);
  };

  const onAccept = () => {
    approveUser({ id: params.id });
    setIsAcceptModalOpen(false);
  };

  const onReject = () => {
    rejectUser({ reason, id: params.id });
    setIsRejectModalOpen(false);
  };

  useEffect(() => {
    if (isRejectSuccess) {
      setIsRejectModalOpen(false);
      setReason('');
    }
  }, [isRejectSuccess]);

  useEffect(() => {
    if (isApproveSuccess) {
      setIsAcceptModalOpen(false);
    }
  }, [isApproveSuccess]);

  const getAssetByType = (assets: AssetResponse[], type: string) => {
    if (isEmpty(assets)) {
      return { id: '', fileName: '' };
    }
    const data = assets?.find((asset: AssetResponse) => asset.type === type);
    return data;
  };

  const viewDocument = async (assets: AssetResponse[], assetType: string) => {
    const asset = getAssetByType(assets, assetType);
    if (asset) {
      getAssetUrl({ assetId: asset.id });
    }
  };

  useEffect(() => {
    if (assetUrl) {
      window.open(assetUrl.url, '_blank', 'noopener,noreferrer');
    }
  }, [assetUrl]);

  if (!privateUser && !corporateUser) return null;

  return (
    <>
      {!isEmpty(corporateUser) && (
        <CorporateRequestView
          user={corporateUser}
          viewDocument={viewDocument}
          getAssetByType={getAssetByType}
          openAccept={openAccept}
          openReject={openReject}
          isAcceptModalOpen={isAcceptModalOpen}
          isRejectModalOpen={isRejectModalOpen}
          onAcceptModalClose={onAcceptModalClose}
          onAccept={onAccept}
          onRejectModalClose={onRejectModalClose}
          setReason={setReason}
          reason={reason}
          onReject={onReject}
        />
      )}
      {!isEmpty(privateUser) && (
        <PrivateRequestView
          user={privateUser}
          viewDocument={viewDocument}
          getAssetByType={getAssetByType}
          openAccept={openAccept}
          openReject={openReject}
          isAcceptModalOpen={isAcceptModalOpen}
          isRejectModalOpen={isRejectModalOpen}
          onAcceptModalClose={onAcceptModalClose}
          onAccept={onAccept}
          onRejectModalClose={onRejectModalClose}
          setReason={setReason}
          reason={reason}
          onReject={onReject}
        />
      )}
    </>
  );
};

export default Request;
