import { BuildType } from '@/shared/types/redux/ReduxAction';

const getShareStatusUrl = `shares/status`;

export const getShareStatus = (build: BuildType) => {
  return build.query<any, any>({
    query: () => ({
      url: getShareStatusUrl,
      method: 'GET',
    }),
  });
};
