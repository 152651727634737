import { Card } from 'issuix-ui-kit';
import styled from 'styled-components';

export const SmartShareLedgerContainer = styled(Card).attrs(() => ({
  notFullHeight: false,
}))`
  width: 100%;
  padding: 4rem 2rem;
  position: relative;
`;
