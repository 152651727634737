import { ShareResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseSharesUrl = 'shares';

const getShareByIdUrl = (query: { id: string }): string => `${baseSharesUrl}/${query.id}`;

export const getShareById = (build: BuildType) => {
  return build.query<ShareResponse, any>({
    query: ({ query }) => ({
      url: getShareByIdUrl(query),
      method: 'GET',
    }),
  });
};
