import { Sector } from 'recharts';

import { ShapeProperty } from '@/shared/types/ShapeProperty';

export const renderActiveShape = (props: ShapeProperty) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, formatedValue } =
    props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <svg x={cx - 70} y={cy - 70} width="140" height="140" viewBox="0 0 140 140" fill="none">
        <g filter="url(#filter0_d_3005_10488)">
          <circle cx="70" cy="70" r="70" fill="url(#paint0_linear_3005_10488)" />
        </g>
        <defs>
          <filter
            id="filter0_d_3005_10488"
            x="0"
            y="0"
            width="140"
            height="140"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1" dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3005_10488" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3005_10488" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear_3005_10488"
            x1="127.457"
            y1="1"
            x2="48.1536"
            y2="155.321"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.106823" stopColor="#EEEEEE" stopOpacity="0.8" />
            <stop offset="1" stopColor="white" stopOpacity="0.4" />
          </linearGradient>
        </defs>
      </svg>

      <text x={cx} y={cy} dy={8} fontSize="2rem" textAnchor="middle" fill="white">
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="white" fontSize="1.5rem">
        {formatedValue}
      </text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="white" fontSize="1.5rem">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};
