import { FC } from 'react';

import { APPS } from '@/enums/App';
import { getTenant } from '@/utils/getTenant/getTenant';
import AdminLayout from '@/view/Admin/AdminLayout';
import CoreLayout from '@/view/Core/CoreLayout';
import VerifyLayout from '@/view/Verify/VerifyLayout';

const Medusa: FC<{ application: APPS }> = ({ application }) => {
  const tenant = getTenant();

  switch (application) {
    case APPS.ADMIN:
      return <AdminLayout tenant={tenant} />;
    case APPS.SALES:
      return <AdminLayout tenant={tenant} />;
    case APPS.CORE:
      return <CoreLayout tenant={tenant} />;
    case APPS.VERIFY:
      return <VerifyLayout tenant={tenant} />;
  }
};

export default Medusa;
