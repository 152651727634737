import { Button, IColumn, Table, Text } from 'issuix-ui-kit';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ControlledForm from '@/components/Form/ControlledForm';
import PaymentModal from '@/components/Payment/PaymentModal/PaymentModal';
import RoleProtected from '@/components/RoleProtected/RoleProtected';
import { PRODUCT_TYPE_ENUM } from '@/enums/ProductType';
import { ROLE_TYPE } from '@/enums/RoleType';
import { STATUS_ENUM } from '@/enums/Status';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useAuth } from '@/hooks/useAuth/useAuth';
import { useRoundOptions } from '@/hooks/useRoundOptions/useRoundOptions';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { USER_STATUS_TYPE_ENUM } from '@/shared/types/UserType';
import { RoundOptionResponse, ShareResponse } from '@/shared/types/api';
import { composePath } from '@/utils/composePath/ComposePath';
import { formatAmount } from '@/utils/formatAmount/FormatAmount';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';
import SmartShareListingFilterForm from '@/view/Shared/SmartShareListing/FilterForm/SmartShareListingFilterForm';
import { smartShareListingFilterSchema } from '@/view/Shared/SmartShareListing/FilterForm/SmartShareListingSchema';
import {
  ActionWrapper,
  CardHeader,
  FiltersContainer,
  SmartShareListingCard,
  SmartShareListingContainer,
  TableContainer,
} from '@/view/Shared/SmartShareListing/SmartShareListing.styles';
import {
  SmartShareListingContextProvider,
  useSmartShareListingContext,
} from '@/view/Shared/SmartShareListing/SmartShareListingContext';

const SmartShareListing = () => {
  return (
    <SmartShareListingContextProvider>
      <SmartShareListingView />
    </SmartShareListingContextProvider>
  );
};

const SmartShareListingView: FC = () => {
  const { translate } = useTranslation();
  const { data, fetch, setFilters } = useSmartShareListingContext();
  const { roundOptions } = useRoundOptions();
  const currency = useAppSelector((state) => state.global.currency);
  const navigate = useNavigate();
  const [buyShareId, setBuyShareId] = useState<string>('');
  const { user } = useAuth();

  useEffect(() => {
    setFilters({ currencyId: currency?.id, status: STATUS_ENUM.APPROVED });
  }, [currency]);

  const getRoundOption = (item: string): RoundOptionResponse => {
    return roundOptions.find((round: RoundOptionResponse) => round.value === item);
  };

  const onAction = (id: string) => {
    navigate(composePath(ClientRoutes.Shares.viewSmartShare, { id }));
  };

  const onBuyClick = (id: string) => {
    setBuyShareId(id);
  };

  const tableDef: IColumn[] = [
    {
      name: 'code',
      width: 1,
      header: {
        label: translate('code').toUpperCase(),
      },
    },
    {
      name: 'userName',
      width: 1.5,
      header: {
        label: translate('companyName').toUpperCase(),
      },
      column: {
        render: (item: ShareResponse) => <p>{item.user.name}</p>,
      },
    },
    {
      name: 'country',
      width: 1,
      header: {
        label: translate('country').toUpperCase(),
      },
      column: {
        render: (item: ShareResponse) => <p>{item.user.country?.name}</p>,
      },
    },
    {
      name: 'price',
      width: 1,
      header: {
        label: translate('sharePrice').toUpperCase(),
      },
    },
    {
      name: 'nominalValue',
      width: 2,
      header: {
        label: translate('nominalValue').toUpperCase(),
      },
      column: {
        render: (item: ShareResponse) => {
          const nominalValue = item.shareCapital / item.totalAmount;
          return (
            <p>
              {formatAmount(nominalValue, currency?.decimals ?? 0, currency?.rate ?? 0)} {currency?.currencyCode}
            </p>
          );
        },
      },
    },
    {
      name: 'totalAmount',
      width: 2,
      header: {
        label: translate('totalAmount').toUpperCase(),
      },
      column: {
        render: (item: ShareResponse) => <p>{item.totalAmount}</p>,
      },
    },
    {
      name: 'availableForSaleInRound',
      width: 2,
      header: {
        label: translate('sharesOffered').toUpperCase(),
      },
    },
    {
      name: 'round',
      width: 1,
      header: {
        label: translate('round').toUpperCase(),
      },
      column: {
        render: (item: ShareResponse) => <p>{getRoundOption(item.round)?.label}</p>,
      },
    },
    {
      name: 'availableForSale',
      width: 2,
      header: {
        label: translate('sharesOutstanding').toUpperCase(),
      },
    },
    {
      name: 'actions',
      width: 2,
      header: {
        label: translate('actions').toUpperCase(),
      },
      column: {
        render: (item: ShareResponse) => (
          <ActionWrapper>
            <Button fullyRounded onClick={() => onAction(item.id)} text={translate('view')} size={'medium'} />
            <RoleProtected allowedRoles={[ROLE_TYPE.CORPORATE, ROLE_TYPE.PRIVATE]}>
              {user!.isFullyRegistered && user!.status === USER_STATUS_TYPE_ENUM.APPROVED && (
                <Button fullyRounded onClick={() => onBuyClick(item.id)} text={translate('buy')} size={'medium'} />
              )}
            </RoleProtected>
          </ActionWrapper>
        ),
      },
    },
  ];

  return (
    <>
      <PaymentModal
        isOpen={!!buyShareId}
        id={buyShareId}
        type={PRODUCT_TYPE_ENUM.ACTIONS}
        onClose={() => setBuyShareId('')}
      />
      <SmartShareListingContainer>
        <SmartShareListingCard>
          <CardHeader>
            <Text content={translate('smartSharesListing')} size={'large'} />
          </CardHeader>
          <TableContainer>
            <Table data={data} fetchTrigger={() => fetch()} tableDef={tableDef} />
          </TableContainer>
        </SmartShareListingCard>
        <FiltersContainer>
          <Text content={translate('filter')} size={'large'} />
          <ControlledForm schema={smartShareListingFilterSchema()}>
            <SmartShareListingFilterForm />
          </ControlledForm>
        </FiltersContainer>
      </SmartShareListingContainer>
    </>
  );
};
export default SmartShareListing;
