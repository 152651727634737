import { Button, ModalDialog, Text } from 'issuix-ui-kit';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { ButtonsContainer } from '@/components/TransactionModal/AcceptBankTransactionModal/AcceptBankTransactionModal.styles';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useAcceptTransactionMutation } from '@/store/service';

interface AcceptBankTransactionModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const AcceptBankTransactionModal: FC<AcceptBankTransactionModalProps> = ({ isOpen, setIsOpen }) => {
  const { translate } = useTranslation();
  const params = useParams();
  const [acceptTransaction, { isLoading }] = useAcceptTransactionMutation();

  const onSubmitHandler = async () => {
    await acceptTransaction({ query: { id: params.id && params.id } }, { skip: !params.id });
    setIsOpen(false);
  };
  return (
    <ModalDialog isOpen={isOpen} close={() => setIsOpen(false)}>
      <Text content={translate('approve')} size={'large'} />
      <Text content={translate('approveTransaction')} size={'medium'} />
      <ButtonsContainer>
        <Button text={translate('cancel')} onClick={() => setIsOpen(false)} buttonType={'secondary'} />
        <Button text={translate('approve')} onClick={onSubmitHandler} isLoading={isLoading} />
      </ButtonsContainer>
    </ModalDialog>
  );
};

export default AcceptBankTransactionModal;
