import styled from 'styled-components';

export const SmartBondFeatureFormContainer = styled.div`
  position: relative;
  padding: 2rem;
  margin-top: 2rem;
  gap: 3rem;
`;

export const RowFirst = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin: 2.2rem 0;
  flex-wrap: wrap;
`;

export const Column = styled.div`
  width: 15%;
`;
