import styled from 'styled-components';

export const SendNotificationModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 4rem 2rem;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
`;

export const ActionContainer = styled.div`
  right: 2rem;
  bottom: 2rem;
  display: flex;
  gap: 2rem;
  position: absolute;
`;

export const InputsContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
`;
