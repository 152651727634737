import { Button } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import ControlledInput from '@/components/Form/ControlledInput';
import ControlledRadioButtonInput from '@/components/Form/ControlledRadioButtonInput';
import ControlledSelect from '@/components/Form/ControlledSelect';
import { useAvailabilityOptions } from '@/hooks/useAvailabilityOptions/useAvailabilityOptions';
import { useCountries } from '@/hooks/useCountries/UseCountries';
import { useRatingOptions } from '@/hooks/useRatingOptions/useRatingOptions';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { InsuranceOption } from '@/shared/constants/InsuranceOption';
import { useSmartBondPendingContext } from '@/view/Admin/SmartBondsPending/SmartBondsPendingContext';

const SmartBondsPendingFilterForm: FC = () => {
  const { translate } = useTranslation();
  const { countriesAsDropdown } = useCountries();
  const { availabilityOptionsAsDropdown } = useAvailabilityOptions(translate);
  const { ratingOptionsAsDropdown } = useRatingOptions(translate);

  const {
    reset,
    watch,
    formState: { isDirty },
  } = useFormContext();

  const { setFilters, resetData } = useSmartBondPendingContext();

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    watch((value) => {
      resetData();
      setFilters(value);
    });
  }, [watch]);

  return (
    <div>
      <FilterContainer>
        <InputContainer>
          <ControlledInput placeholder={translate('code')} name={'code'} />
        </InputContainer>
        <InputContainer>
          <ControlledSelect items={ratingOptionsAsDropdown} placeholder={translate('rating')} name={'rating'} />
        </InputContainer>
        <InputContainer>
          <ControlledSelect items={countriesAsDropdown} placeholder={translate('country')} name={'country'} />
        </InputContainer>
        <RangeInputContainer>
          <div>
            <ControlledInput placeholder={translate('priceMinimum')} name={'minPrice'} />
          </div>
          {'-'}
          <div>
            <ControlledInput placeholder={translate('priceMaximum')} name={'maxPrice'} />
          </div>
        </RangeInputContainer>
        <InputContainer>
          <ControlledRadioButtonInput
            name={'insurance'}
            options={InsuranceOption(translate)}
            defaultValue=""
            description={translate('insuranceOption')}
          />
        </InputContainer>
      </FilterContainer>
      <FilterContainer>
        <InputContainer>
          <ControlledInput placeholder={translate('companyName')} name={'Name'} />
        </InputContainer>
        <InputContainer>
          <ControlledInput placeholder={translate('maturity')} name={'maturity'} />
        </InputContainer>
        <RangeInputContainer>
          <div>
            <ControlledInput placeholder={translate('minInterest')} name={'minInterest'} />
          </div>
          {'-'}
          <div>
            <ControlledInput placeholder={translate('maxInterest')} name={'maxInterest'} />
          </div>
        </RangeInputContainer>
        <InputContainer>
          <ControlledSelect
            items={availabilityOptionsAsDropdown}
            placeholder={translate('availability')}
            name={'availability'}
          />
        </InputContainer>
        <ButtonContainer>
          <Button
            onClick={() => {
              isDirty && reset();
            }}
            text={translate('reset')}
          />
        </ButtonContainer>
      </FilterContainer>
    </div>
  );
};

export default SmartBondsPendingFilterForm;

const ButtonContainer = styled.div`
  position: absolute;
  right: 2rem;
  bottom: 2rem;
`;

const FilterContainer = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  gap: 4rem;
`;

const InputContainer = styled.div`
  width: 20%;
`;

const RangeInputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  gap: 2rem;
`;
