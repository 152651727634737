import { BuildType } from '@/shared/types/redux/ReduxAction';

const checkNftCollectionNamePath = (query: { name: string }): string =>
  `core/nft-collection/check-name?name=${query.name}`;

export const checkNftCollectionName = (build: BuildType) => {
  return build.mutation<boolean, any>({
    query: ({ query }) => ({
      url: checkNftCollectionNamePath(query),
      method: 'GET',
    }),
  });
};
