import { useMemo } from 'react';
import { TFunction } from 'react-i18next';

import { CouponPaidOptionResponse } from '@/shared/types/api';
import { useGetCouponPaidOptionsQuery } from '@/store/service';

export const useCouponPaidOptions = (translate: TFunction) => {
  const { data: couponPaidOptions } = useGetCouponPaidOptionsQuery();

  const couponPaidOptionsAsDropdown = useMemo(
    () =>
      couponPaidOptions?.map((coupon: CouponPaidOptionResponse) => {
        return {
          value: coupon.value,
          label: translate(coupon.value.split('_').join('').toLowerCase() as keyof TFunction),
        };
      }),
    [couponPaidOptions, translate]
  );

  return {
    couponPaidOptions: (couponPaidOptions as CouponPaidOptionResponse[]) || [],
    couponPaidOptionsAsDropdown: couponPaidOptionsAsDropdown || [],
  };
};
