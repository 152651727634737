import { Textarea } from 'issuix-ui-kit';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface Props {
  name: string;
  label: string;
  errors: any;
  className?: string;
  disabled?: boolean;
}

const ControlledTextarea: FC<Props> = ({ name, label, errors, className, disabled }) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field: { onChange, value } }) => (
        <Textarea
          onChange={onChange}
          value={value}
          label={label}
          name={name}
          errors={errors}
          className={className}
          disabled={disabled}
        />
      )}
    />
  );
};

export default ControlledTextarea;
