import { EndUserRoleOptionResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const endUserRoleOptionPath = 'end-user-role-option';

export const getEndUserRoleOptions = (build: BuildType) => {
  return build.query<EndUserRoleOptionResponse[], void>({
    query: () => endUserRoleOptionPath,
  });
};
