export enum PAGES {
  ROOT = '/',
  BOND_DASHBOARD = 'bond-dashboard',
  NFT_DASHBOARD = 'nft-dashboard',
  SMART_SHARES_LISTING = 'smart-shares',
  SMART_BONDS_LISTING = 'smart-bonds',
  SMART_SHARE_CREATION = 'smart-share-creation',
  SMART_BOND_CREATION = 'smart-bond-creation',
  SMART_BONDS_PENDING = 'smart-bonds-pending',
  SMART_SHARES_PENDING = 'smart-shares-pending',
  USERS = 'users',
  REQUESTS = 'requests',
  TRANSACTIONS_TO_BUYERS = 'transactions-to-buyers',
  TRANSACTIONS_FROM_BUYERS = 'transactions-from-buyers',
  ACCOUNT = 'my-account',
}
