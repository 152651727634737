import { colors, GlobalSpinner } from 'issuix-ui-kit';
import { FC } from 'react';

import { ROLE_TYPE } from '@/enums/RoleType';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useNav } from '@/hooks/useNav/UseNav';
import { getUserSelector } from '@/store/global/selectors';
import { useGetBondAssetsQuery, useGetBondByIdQuery, useGetCorporateUserAssetsQuery } from '@/store/service';
import CompanyInfo from '@/view/Shared/ViewBond/CompanyInfo/CompanyInfo';
import SmartBondFeatures from '@/view/Shared/ViewBond/SmartBondFeatures/SmartBondFeatures';
import Transactions from '@/view/Shared/ViewBond/Transactions/Transactions';
import { ViewBondWrapper } from '@/view/Shared/ViewBond/ViewBond.styles';

const ViewBond: FC = () => {
  const { params } = useNav();
  const user = useAppSelector((state) => getUserSelector(state));
  const { data: bond } = useGetBondByIdQuery({ query: { id: params.id && params.id } }, { skip: !params.id });
  const { data: assets } = useGetBondAssetsQuery({ query: { id: params.id && params.id } }, { skip: !params.id });
  const { data: userAssets } = useGetCorporateUserAssetsQuery(bond?.userId, { skip: !bond?.userId });

  return (
    <GlobalSpinner isActive={!bond && !userAssets && !assets} background={colors.blue.primary}>
      <ViewBondWrapper>
        <SmartBondFeatures bond={bond} assets={assets} hasEdit={false} />
        <CompanyInfo user={bond?.user} assets={userAssets} />
        <Transactions
          itemId={params.id ?? ''}
          canViewTransaction={user?.id === bond?.user.id || user?.role === ROLE_TYPE.ADMIN}
        />
      </ViewBondWrapper>
    </GlobalSpinner>
  );
};

export default ViewBond;
