import { Button } from 'issuix-ui-kit';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { FullWidthRow, ActionContainer, RequestChangesModalContainer } from './RequestChangesModalForm.style';

import ControlledTextarea from '@/components/Form/ControlledTextarea';
import { Row } from '@/components/Layout/Row/Row.styled';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useRequestChangesMutation } from '@/store/service';

interface RequestChangesModalFormProps {
  setIsOpen: (isOpen: boolean) => void;
}

const RequestChangesModalForm: FC<RequestChangesModalFormProps> = ({ setIsOpen }) => {
  const { translate } = useTranslation();
  const params = useParams();
  const {
    getValues,
    formState: { isValid, errors },
  } = useFormContext();
  const [requestChanges, { isLoading }] = useRequestChangesMutation();

  const onRequestChangesClickHandler = () => {
    requestChanges({ caseId: params.caseId, message: getValues().message });
    setIsOpen(false);
  };

  return (
    <RequestChangesModalContainer>
      <FullWidthRow>
        <ControlledTextarea name={'message'} label={translate('message')} errors={errors} />
      </FullWidthRow>
      <Row>
        <ActionContainer>
          <Button text={translate('cancel')} onClick={() => setIsOpen(false)} buttonType={'secondary'} />
          <Button
            text={translate('requestChanges')}
            onClick={() => onRequestChangesClickHandler()}
            disabled={!isValid}
            buttonType={'primary'}
            isLoading={isLoading}
          />
        </ActionContainer>
      </Row>
    </RequestChangesModalContainer>
  );
};

export default RequestChangesModalForm;
