import { Button, Card } from 'issuix-ui-kit';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1700px) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  > div {
    max-height: 100%;
    height: 100%;
  }
`;

export const ItemValueWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: 0.5rem;
`;

export const FirstButton = styled(Button)`
  margin-right: 1.5rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

export const ListWrapper = styled.div<{ isBondListing: boolean }>`
  flex: 1;
  display: grid;
  grid-gap: 1.6rem;
  grid-column-start: span 5;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 2fr));

  @media only screen and (max-width: 1431px) {
    ${({ isBondListing }) => isBondListing && 'grid-template-columns: repeat(auto-fit, minmax(32%, 2fr))'};
  }

  @media only screen and (max-width: 900px) {
    ${({ isBondListing }) => isBondListing && 'grid-template-columns: repeat(auto-fit, minmax(32%, 2fr))'};
  }
`;

export const GreenStateIcon = styled.img`
  width: 80%;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const TenantBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.25rem;
`;

export const TenantBoxValue = styled.div`
  display: flex;
  align-items: center;
`;

export const RadioCircle = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background: #9bfdca;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  margin-right: 0.5rem;
`;
