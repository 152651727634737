import { TFunction } from 'i18next';
import { Text } from 'issuix-ui-kit';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { StyledNavLink } from '@/components/BreadCrumbs/BreadCrumbs.styled';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';

const routes = (translate: TFunction) => [
  { path: ClientRoutes.Root, breadcrumb: translate('mainPage') },
  {
    path: `${ClientRoutes.Root}${ClientRoutes.Bonds.smartBondPending}`,
    breadcrumb: translate('bondsPending'),
  },
  {
    path: `${ClientRoutes.Root}${ClientRoutes.Bonds.root}`,
    breadcrumb: translate('smartBondsListing'),
  },
  {
    path: `${ClientRoutes.Root}${ClientRoutes.Shares.smartSharePending}`,
    breadcrumb: translate('sharesPending'),
  },
  {
    path: `${ClientRoutes.Root}${ClientRoutes.Shares.root}`,
    breadcrumb: translate('smartSharesListing'),
  },
  {
    path: `${ClientRoutes.Root}${ClientRoutes.Users.account}`,
    breadcrumb: translate('account'),
  },
  {
    path: `${ClientRoutes.Root}${ClientRoutes.Users.account}/${ClientRoutes.Users.cases}`,
    breadcrumb: translate('caseDetails'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.Requests.viewRequest}`,
    breadcrumb: translate('request'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.Shares.smartShareCreation}`,
    breadcrumb: translate('smartShareCreation'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.Bonds.smartBondCreation}`,
    breadcrumb: translate('smartBondCreation'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.Users.root}`,
    breadcrumb: translate('users'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.Requests.root}`,
    breadcrumb: translate('requests'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.Transactions.toBuyers}`,
    breadcrumb: translate('transactionsToBuyers'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.Transactions.fromBuyers}`,
    breadcrumb: translate('transactionsFromBuyers'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.transactions}`,
    breadcrumb: translate('transactions'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.transactions}/${ClientRoutes.Transactions.shareSingleView}`,
    breadcrumb: translate('transaction'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.transactions}/${ClientRoutes.Transactions.bondSingleView}`,
    breadcrumb: translate('transaction'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.smartSharesPortfolio}/${ClientRoutes.Transactions.shareSingleView}`,
    breadcrumb: translate('transaction'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.smartBondsPortfolio}/${ClientRoutes.Transactions.bondSingleView}`,
    breadcrumb: translate('transaction'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.smartBondCreated}/${ClientRoutes.Bonds.editSmartBond}`,
    breadcrumb: translate('smartBondCreated'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.smartShareLedger}`,
    breadcrumb: translate('smartShareLedger'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.smartBondsPortfolio}`,
    breadcrumb: translate('smartBondsPortfolio'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.smartSharesPortfolio}`,
    breadcrumb: translate('smartSharesPortfolio'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.shareholdersLedger}/${ClientRoutes.Transactions.share}/${ClientRoutes.Transactions.singleView}`,
    breadcrumb: translate('transaction'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.Transactions.share}/${ClientRoutes.Transactions.singleView}`,
    breadcrumb: translate('transaction'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.MyAccount.transactions}/${ClientRoutes.Transactions.shareSingleView}`,
    breadcrumb: translate('transaction'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.MyAccount.transactions}/${ClientRoutes.Transactions.bondSingleView}`,
    breadcrumb: translate('transaction'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.MyAccount.transactions}/${ClientRoutes.Transactions.nftSingleView}`,
    breadcrumb: translate('transaction'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.Users.nftPortfolio}/${ClientRoutes.Transactions.nftSingleView}`,
    breadcrumb: translate('transaction'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.Users.smartBondCreated}/${ClientRoutes.Bonds.editSmartBond}`,
    breadcrumb: translate('bond'),
  },
  {
    path: `${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.Users.smartBondCreated}/${ClientRoutes.Bonds.editSmartBond}/${ClientRoutes.Transactions.bondSingleView}`,
    breadcrumb: translate('transaction'),
  },
  {
    path: `${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.MyAccount.shareholdersLedger}/${ClientRoutes.Transactions.shareSingleView}`,
    breadcrumb: translate('transaction'),
  },
  {
    path: `${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.MyAccount.smartSharesPortfolio}/${ClientRoutes.Transactions.shareSingleView}`,
    breadcrumb: translate('transaction'),
  },
  {
    path: `${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.MyAccount.smartBondsPortfolio}/${ClientRoutes.Transactions.bondSingleView}`,
    breadcrumb: translate('transaction'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.Transactions.fromBuyers}/${ClientRoutes.Transactions.fromBuyersId}/${ClientRoutes.Transactions.shareSingleView}`,
    breadcrumb: translate('transaction'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.Transactions.fromBuyers}/${ClientRoutes.Transactions.fromBuyersId}/${ClientRoutes.Transactions.bondSingleView}`,
    breadcrumb: translate('transaction'),
  },
  {
    path: `${ClientRoutes.Root}/${ClientRoutes.MyAccount.root}/${ClientRoutes.Users.smartBondCreated}/${ClientRoutes.Bonds.editSmartBond}/${ClientRoutes.Transactions.bondSingleView}`,
    breadcrumb: translate('transaction'),
  },
];

const exclude = [
  ClientRoutes.Root,
  ClientRoutes.Dashboard.bond,
  ClientRoutes.Dashboard.nft,
  `${ClientRoutes.Root}/${ClientRoutes.Users.account}/:id`,
  `${ClientRoutes.Root}/${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.transactions}/${ClientRoutes.Transactions.share}`,
  `${ClientRoutes.Root}/${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.transactions}/${ClientRoutes.Transactions.bond}`,
  `${ClientRoutes.Root}/${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.smartSharesPortfolio}/${ClientRoutes.Transactions.share}`,
  `${ClientRoutes.Root}/${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.smartBondsPortfolio}/${ClientRoutes.Transactions.bond}`,
  `${ClientRoutes.Root}/${ClientRoutes.Transactions.fromBuyers}/${ClientRoutes.Transactions.singleView}/${ClientRoutes.Transactions.share}`,
  `${ClientRoutes.Root}/${ClientRoutes.Transactions.fromBuyers}/${ClientRoutes.Transactions.singleView}/${ClientRoutes.Transactions.bond}`,
  `${ClientRoutes.Root}/${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.smartBondCreated}/${ClientRoutes.Bonds.viewSmartBond}`,
  `${ClientRoutes.Root}/${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.Users.smartBondsPortfolio}/${ClientRoutes.Transactions.bond}`,
  `${ClientRoutes.Root}/${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.Users.smartSharesPortfolio}/${ClientRoutes.Transactions.share}`,
  `${ClientRoutes.Root}/${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.Users.transactions}/${ClientRoutes.Transactions.bond}`,
  `${ClientRoutes.Root}/${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.Users.transactions}/${ClientRoutes.Transactions.share}`,
  `${ClientRoutes.Root}/${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.smartShareLedger}/${ClientRoutes.Shares.root}/${ClientRoutes.Shares.viewSmartShare}`,
  `${ClientRoutes.Root}/${ClientRoutes.MyAccount.root}/${ClientRoutes.MyAccount.shareholdersLedger}/${ClientRoutes.Transactions.share}`,
  `${ClientRoutes.Root}/${ClientRoutes.Transactions.share}`,
  `${ClientRoutes.Root}/${ClientRoutes.Transactions.bond}`,
  `${ClientRoutes.Root}/${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.Users.smartBondCreated}/${ClientRoutes.Bonds.viewSmartBond}`,
  `${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.Users.smartBondCreated}/${ClientRoutes.Bonds.editSmartBond}/${ClientRoutes.Transactions.bond}`,
  `${ClientRoutes.Users.root}/${ClientRoutes.Users.account}/${ClientRoutes.MyAccount.shareholdersLedger}/${ClientRoutes.Transactions.share}`,
  `${ClientRoutes.Root}/${ClientRoutes.MyAccount.root}/${ClientRoutes.Users.smartBondCreated}/${ClientRoutes.Bonds.editSmartBond}/${ClientRoutes.Transactions.bond}`,
];

export const BreadCrumbs = () => {
  const { translate } = useTranslation();

  const breadcrumbs = useBreadcrumbs(routes(translate), { excludePaths: exclude });

  return (
    <>
      {breadcrumbs.map(({ breadcrumb, match }, index) => {
        return (
          <div key={index}>
            <StyledNavLink to={match.pathname}>{breadcrumb}</StyledNavLink>
            {breadcrumbs.length - 1 !== index && <Text size={'large'} content=">" />}
          </div>
        );
      })}
    </>
  );
};
