import { CaseDetailsResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseCasesUrl = 'case';

const getCasesDetails = (id: string) => `${baseCasesUrl}/${id}`;

export const getCaseDetails = (build: BuildType) => {
  return build.query<CaseDetailsResponse, any>({
    providesTags: ['GetCaseDetails'],
    query: (id) => ({
      url: getCasesDetails(id),
      method: 'GET',
    }),
  });
};
