import { BuildType } from '@/shared/types/redux/ReduxAction';

const getNftCollectionStatisticsUrl = (id: string) => `/nft-collection/${id}/statistics`;

export const getNftCollectionStatistics = (build: BuildType) => {
  return build.query<{ total: number }, any>({
    providesTags: ['GetNftCollectionStatistics'],
    query: ({ id }) => ({
      url: getNftCollectionStatisticsUrl(id),
      method: 'GET',
    }),
  });
};
