import { GreenStateTheme, MetaSwissTheme, UIKitThemeProvider } from 'issuix-ui-kit';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import 'issuix-ui-kit/build';
import './App.css';

import { AuthContextProvider } from '@/components/Auth/AuthContext';
import { ErrorPage } from '@/components/ErrorPage/ErrorPage';
import NotAvailableMobile from '@/components/NotAvailableMobile/NotAvailableMobile';
import { APPS, TENANT } from '@/enums/App';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useAuth } from '@/hooks/useAuth/useAuth';
import { useBrowserLanguage } from '@/hooks/useBrowserLanguage/useBrowserLanguage';
import { useScreenResize } from '@/hooks/useScreenResize/useScreenResize';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { isErrorSelector, isTenantErrorSelector, isServerErrorSelector } from '@/store/global/selectors';
import { getTenant } from '@/utils/getTenant/getTenant';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';
import { getSubDomain } from '@/utils/routes/getSubDomain/GetSubDomain';
import Medusa from '@/view';

const App = () => {
  const { width } = useScreenResize();
  const navigate = useNavigate();
  const { translate } = useTranslation();

  const isError = useAppSelector((state) => isErrorSelector(state));
  const isTenantError = useAppSelector((state) => isTenantErrorSelector(state));
  const isServerError = useAppSelector((state) => isServerErrorSelector(state));
  const { isAuthenticated } = useAuth();
  const { setTranslationLanguage } = useTranslation();
  const language = useBrowserLanguage();

  useEffect(() => {
    setTranslationLanguage(language);
  }, [language]);

  const { application, tenant }: { application: APPS; tenant: TENANT } = useMemo(() => {
    return {
      application: getSubDomain(),
      tenant: getTenant(),
    };
  }, [window.location.hostname]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(ClientRoutes.Root);
    }
  }, [isAuthenticated]);

  const themeBasedOnTenant = useMemo(() => {
    return tenant === TENANT.META_SWISS ? MetaSwissTheme : GreenStateTheme;
  }, [tenant]);

  if (isError || isServerError) {
    return <ErrorPage text={translate('error')} />;
  }

  if (application !== APPS.VERIFY && width < 767) {
    return <NotAvailableMobile />;
  }

  return (
    <ThemeProvider theme={themeBasedOnTenant}>
      {isTenantError && <ErrorPage text={translate('badTenantError')} />}
      <UIKitThemeProvider theme={themeBasedOnTenant}>
        <AuthContextProvider>
          <Medusa application={application} />
        </AuthContextProvider>
      </UIKitThemeProvider>
    </ThemeProvider>
  );
};

export default App;
