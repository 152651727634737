import { BuildType } from '@/shared/types/redux/ReduxAction';
import { api } from '@/store/service';
import { getTenant } from '@/utils/getTenant/getTenant';

const baseBondUrl = 'bonds';

const getBondUrl = (bondId: string) => {
  return `${baseBondUrl}/${bondId}/update`;
};

export const updateBond = (build: BuildType) => {
  return build.mutation<void, any>({
    query: (bondData: any) => ({
      url: getBondUrl(bondData.bondId),
      method: 'PATCH',
      body: bondData.body,
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
      try {
        const { data: updatePost } = await queryFulfilled;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { files, ...rest } = updatePost;

        dispatch(
          api.util.updateQueryData('getBondsTenant', { query: { tenant: getTenant() } }, (draft: any[]) => {
            draft.pop();
            draft.unshift(rest);
          })
        );

        dispatch(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          api.util.updateQueryData('getBondAssets', { query: { id: updatedPost.id } }, (draft: any[]) => {
            draft.length = 0;
            files.map((file: any) => {
              draft.push(file);
              return;
            });
          })
        );
      } catch (e) {
        // TODO: Handle
      }
    },
  });
};
