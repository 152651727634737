import { Text } from 'issuix-ui-kit';
import { FC } from 'react';

import ControlledForm from '@/components/Form/ControlledForm';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { AssetResponse, UserFullResponse } from '@/shared/types/api';
import CompanyInfoForm from '@/view/Shared/ViewBond/CompanyInfo/CompanyInfoForm/CompanyInfoForm';
import { companyInfoSchema } from '@/view/Shared/ViewBond/CompanyInfo/CompanyInfoForm/CompanyInfoSchema';
import { CardHeader, SmartBondContainer } from '@/view/Shared/ViewBond/ViewBond.styles';

interface CompanyInfoProps {
  user: UserFullResponse;
  assets: AssetResponse[];
}

const CompanyInfo: FC<CompanyInfoProps> = ({ user, assets }) => {
  const { translate } = useTranslation();
  const [isEditMode] = useToggle(false);

  return (
    <SmartBondContainer>
      <CardHeader>
        <Text content={translate('companyInfo')} size={'large'} />
      </CardHeader>
      <ControlledForm schema={companyInfoSchema()}>
        <CompanyInfoForm user={user} assets={assets} isEditMode={isEditMode} />
      </ControlledForm>
    </SmartBondContainer>
  );
};

export default CompanyInfo;
