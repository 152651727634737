import { BondResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const getTenantBondsPath = 'bonds/tenant';

export const getTenantBonds = (build: BuildType) => {
  return build.query<BondResponse[], void>({
    query: () => ({
      url: getTenantBondsPath,
      method: 'GET',
    }),
  });
};
