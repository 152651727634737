import React, { createContext, FC, useContext } from 'react';

import { useTableWithPagination } from '@/hooks/useTableWithPagination/useTableWithPagination';
import { PageableItems } from '@/shared/types/api';
import { useGetAllIssuedCouponsQuery } from '@/store/service';

interface ITransactionsToBuyersContext {
  data: (string | PageableItems)[];
  fetch: () => void;
  setFilters: (filters: { [x: string]: any } | undefined) => void;
  resetData: () => void;
}

interface TransactionsToBuyersContextProviderProps {
  children: React.ReactNode;
}

export const TransactionsToBuyersContext = createContext({} as ITransactionsToBuyersContext);

export const useTransactionsToBuyersContext = () => useContext(TransactionsToBuyersContext);

export const TransactionsToBuyersContextProvider: FC<TransactionsToBuyersContextProviderProps> = ({ children }) => {
  const { fetch, resetData, data, setFilters } = useTableWithPagination({
    query: useGetAllIssuedCouponsQuery,
    skipIfNoFilters: false,
  });

  return (
    <TransactionsToBuyersContext.Provider
      value={{
        data,
        fetch,
        setFilters,
        resetData,
      }}
    >
      {children}
    </TransactionsToBuyersContext.Provider>
  );
};
