import { ModalDialog, Text } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';
import { TFunction } from 'react-i18next';

import Download from '@/assets/core/icons/download.svg';
import {
  ActionWrapper,
  DocumentClose,
  Label,
  LabelWrapper,
} from '@/components/DocumentsModal/DownloadDocumentsModal.style';
import { DownloadButton } from '@/components/UserTransactions/UserTransactions.styles';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { AssetResponse } from '@/shared/types/api';
import { getSelectedLanguage } from '@/store/global/selectors';
import { useAssignedAssetUrlMutation } from '@/store/service';
import { redirectWithinNewTab } from '@/utils/redirectWithinNewTab/RedirectWithinNewTab';

interface Props {
  isModalOpen: boolean;
  onModalClose: () => void;
  transaction: any;
}

const DownloadDocumentsModal: FC<Props> = ({ transaction, isModalOpen, onModalClose }) => {
  const [getImageUrl, { data }] = useAssignedAssetUrlMutation();
  const { translate } = useTranslation();
  const language = useAppSelector((state) => getSelectedLanguage(state));

  const onClickHandler = (asset: AssetResponse) => {
    getImageUrl({ assetId: asset.id });
  };

  const filterAssetsByPreferredLang = () => {
    if (transaction.assets) {
      return transaction.assets.filter((asset: AssetResponse) => {
        const lang = asset.fileName.split('_')[1];
        return lang === language;
      });
    }
  };

  useEffect(() => {
    if (data && transaction.id) {
      redirectWithinNewTab(data.url);
    }
  }, [data, transaction.id]);

  return (
    <ModalDialog isOpen={isModalOpen} close={onModalClose}>
      <Text content={translate('downloadDocuments')} size={'large'} />
      {filterAssetsByPreferredLang().map((asset: AssetResponse) => (
        <ActionWrapper key={asset.id}>
          <LabelWrapper>
            <Label content={translate(asset.type as keyof TFunction)} size={'medium'} />
          </LabelWrapper>
          <DownloadButton src={Download} alt={'download'} onClick={() => onClickHandler(asset)} />
        </ActionWrapper>
      ))}
      <DocumentClose onClick={onModalClose} text={translate('close')} size={'medium'} buttonType={'secondary'} />
    </ModalDialog>
  );
};

export default DownloadDocumentsModal;
