import { pagingLimit } from '@/shared/constants/Paging';
import { PageableItems } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const getTransactionsByUserIdPath = 'transactions/user';

const getTransactionsByUserIdUrl = (offset: number, query: { id: string }) => {
  return `${getTransactionsByUserIdPath}/${query.id}?offset=${offset}&limit=${pagingLimit}`;
};

export const getTransactionsByUserId = (build: BuildType) => {
  return build.query<PageableItems, any>({
    query: ({ offset, query }) => ({
      url: getTransactionsByUserIdUrl(offset, query),
      method: 'GET',
    }),
  });
};
