import { stringify } from 'qs';

import { pagingLimit } from '@/shared/constants/Paging';
import { PageableItems } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseBondsUrl = 'bonds';

const getBondsUrl = (
  offset: number,
  query: { country: string; showMine: boolean; status: string; currencyId: string }
) => `${baseBondsUrl}?offset=${offset}&limit=${pagingLimit}&${stringify(query)}`;

export const getBonds = (build: BuildType) => {
  return build.query<PageableItems, any>({
    providesTags: ['GetBonds'],
    query: ({ offset, query }) => ({
      url: getBondsUrl(offset, query),
      method: 'GET',
    }),
  });
};
