import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';

import { browserService } from '@/service/browser/BrowserService';
import { BROWSER_SERVICE_KEYS } from '@/service/browser/BrowserServiceKeys';
import { setIsTenantError, setUser, setIsServerError } from '@/store/global/slice';
import { getTenant } from '@/utils/getTenant/getTenant';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { endpoint }) => {
    const token = browserService.get<string>(BROWSER_SERVICE_KEYS.ACCESS_TOKEN);
    const tenant = getTenant();

    headers.set('Tenant', tenant);

    if (token && endpoint !== 'refresh') {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const refreshMutex = new Mutex();

export const baseQueryInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  Record<string, unknown>,
  FetchBaseQueryMeta
> = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: Record<string, unknown>) => {
  // Wait if we're currently refreshing the token
  await refreshMutex.waitForUnlock();

  let result: any = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.data.payload.code === 420) {
    api.dispatch(setIsTenantError(true));
  }

  if (result.error && result.error.status === 404) {
    api.dispatch(setIsServerError(true));
  }

  if (result.error && result.error.data.payload.code === 401) {
    if (!refreshMutex.isLocked()) {
      // The first request that gets a 401 blocks all others
      const release = await refreshMutex.acquire();

      const updatedAccessToken: any = await refreshToken(api, extraOptions);

      if (updatedAccessToken.error && updatedAccessToken.error.data.payload.code === 401) {
        await logOut(api, extraOptions);
      } else {
        browserService.store(BROWSER_SERVICE_KEYS.ACCESS_TOKEN, updatedAccessToken.data.accessToken);
      }

      // Once we're logged out or we have a new token, release the lock
      release();
    } else {
      // This is for the requests that came in at the same time as the first
      // request that failed: they will get to this BlockList, but they will
      // get into the else block because the first request would have already
      // locked the mutex
      await refreshMutex.waitForUnlock();
    }

    // Finally, we retrigger all the requests that failed here
    result = await baseQuery(args, api, extraOptions);
  }

  return result;
};

const logOut = async (api: BaseQueryApi, extraOptions: Record<string, unknown>) => {
  await baseQuery(
    {
      url: 'auth/logout',
      method: 'POST',
    },
    api,
    extraOptions
  );

  browserService.clear(BROWSER_SERVICE_KEYS.ACCESS_TOKEN);
  browserService.clear(BROWSER_SERVICE_KEYS.USER);
  browserService.clear(BROWSER_SERVICE_KEYS.REFRESH_TOKEN);

  api.dispatch(setUser(null));
};

const refreshToken = async (api: BaseQueryApi, extraOptions: Record<string, unknown>) => {
  const refreshToken = browserService.get(BROWSER_SERVICE_KEYS.REFRESH_TOKEN);
  const updatedAccessToken: any = await baseQuery(
    {
      url: 'auth/refresh',
      method: 'POST',
      body: { refreshToken },
    },
    api,
    extraOptions
  );

  return updatedAccessToken;
};
