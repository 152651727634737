import { Button, Text, useWizardContext } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { PaymentDoneIcon } from '@/assets/core/icons';
import { usePaymentContext } from '@/components/Payment/PaymentModal/context/PaymentContext';
import { useResourcePrice } from '@/components/Payment/PaymentModal/useResourcePrice';
import { PAYMENT_TYPES } from '@/enums/PaymentTypes';
import { PRODUCT_TYPE_ENUM } from '@/enums/ProductType';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { composePath } from '@/utils/composePath/ComposePath';
import { formatAmountWithoutRate } from '@/utils/formatAmount/FormatAmount';
import {
  generateAbsoluteBondTransactionRoute,
  generateAbsoluteShareTransactionRoute,
} from '@/utils/routes/generatedRoutes/generateTransactionRoutes';

const FourthStepFeedback: FC = () => {
  const {
    ejected: { goToPreviousPage, setOnBackClickHandler, setOnNextClickHandler },
    modifyBackButtonProps,
    modifyNextButtonProps,
  } = useWizardContext();
  const { paymentMethod, closeModal } = usePaymentContext();
  const { translate } = useTranslation();

  useEffect(() => {
    modifyBackButtonProps({ show: false, disabled: true });
    modifyNextButtonProps({ label: translate('finish') });
    setOnBackClickHandler(() => {
      goToPreviousPage();
    });
    setOnNextClickHandler(() => {
      closeModal?.();
    });
  }, []);

  return (
    <Container>
      <PaymentDoneIcon />
      <TextContainer>
        {paymentMethod === PAYMENT_TYPES.BANK && <BankSuccessText />}
        {paymentMethod === PAYMENT_TYPES.CARD && <CardSuccessText />}
        {paymentMethod === PAYMENT_TYPES.CRYPTO && <CryptoSuccessText />}
      </TextContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextContainer = styled.div`
  margin-top: 5rem;
`;

const BankSuccessTextContainer = styled(Container)`
  height: 16rem;
  justify-content: space-between;
`;

const BankSuccessText = () => {
  const { translate } = useTranslation();
  const { currency, quantity, closeModal, resourceType, transactionId, resource } = usePaymentContext();
  const navigate = useNavigate();

  const resourcePrice = useResourcePrice({ resource, resourceType, currency });

  return (
    <BankSuccessTextContainer>
      <Text
        content={`${translate('toCompleteTransaction')} ${formatAmountWithoutRate(
          resourcePrice * quantity,
          currency!.decimals
        )} ${currency?.currencyCode} ${translate('toOurAccount')}`}
      />
      <br />
      <Text content={translate('youCanFindOurBankDetails')} />
      <br />
      <Button
        text={translate('view')}
        onClick={() => {
          closeModal?.();

          const id = transactionId;
          const path =
            resourceType === PRODUCT_TYPE_ENUM.BOND_PAPERS
              ? composePath(generateAbsoluteBondTransactionRoute(), { id })
              : composePath(generateAbsoluteShareTransactionRoute(), { id });

          navigate(path);
        }}
      />
    </BankSuccessTextContainer>
  );
};

const CardSuccessText = () => {
  const { translate } = useTranslation();

  return <Text content={translate('yourPurchaseSuccessful')} />;
};

const CryptoSuccessText = () => {
  const { translate } = useTranslation();

  return (
    <>
      <Text content={translate('yourTransactionHasBeenCompleted')} />
      <br />
      <br />
      <Text content={translate('thankYou')} />
    </>
  );
};

export default FourthStepFeedback;
