import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

export const FormsContainer = styled.div`
  width: 70%;
  margin-top: 2rem;
  overflow: hidden;
`;

export const RegisterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const LogoContainer = styled.div`
  margin: 4rem 4rem 2rem 4rem;
  width: 40%;
`;

export const DropdownContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
`;

export const SeparateLine = styled.div`
  width: 71%;
  margin: auto;
  height: 1px;
  background-color: white;
`;

export const FormWrapper = styled.div`
  width: 71%;
  margin: auto;
`;
