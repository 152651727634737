import { Text, LocalSpinner, CustomTheme } from 'issuix-ui-kit';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { TENANT } from '@/enums/App';
import { QUERY_PARAMS } from '@/enums/QueryParams';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useVerifyAccountMutation } from '@/store/service';
import { VerifyContainer, VerifyIcon, VerifyLogo, WelcomeContainer } from '@/view/Verify/Verify/Verify.styles';

interface VerifyProps {
  logo: JSX.Element;
  icon: JSX.Element;
  errorIcon: JSX.Element;
  titleSuccess: string;
  subtitleSuccess: string;
  tenant: TENANT;
}

const Verify: FC<VerifyProps> = ({ logo, icon, errorIcon, titleSuccess, subtitleSuccess, tenant }) => {
  const { translate } = useTranslation();
  const [searchParams] = useSearchParams();
  const [verifyAccount, { error, isSuccess }] = useVerifyAccountMutation();
  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string | undefined>(undefined);

  const isExpiredTokenError = useMemo(() => error?.data?.payload?.code === 498, [error]);
  const isAlreadyVerifiedError = useMemo(() => error?.data?.payload?.code === 409, [error]);

  const isGlobalError = useMemo(
    () => error && !isExpiredTokenError && !isAlreadyVerifiedError,
    [error, isAlreadyVerifiedError, isExpiredTokenError]
  );

  const theme: CustomTheme = useTheme();

  useEffect(() => {
    const token = searchParams.get(QUERY_PARAMS.VERIFY_ACCOUNT_TOKEN);
    if (token) {
      verifyAccount({ verifyAccountToken: token });
      setToken(token);
    }
  }, [searchParams]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <VerifyContainer>
      <VerifyLogo>{logo}</VerifyLogo>
      <LocalSpinner isLoading={loading} color={theme.colors.white.primary} size={'extraLarge'} />
      {!loading && (
        <>
          {!token && (
            <WelcomeContainer>
              <VerifyIcon>{errorIcon}</VerifyIcon>
              <Text content={translate('error')} size={'extraLarge'} weight={'bold'} />
              <Text content={translate('somethingWentWrong')} size={'large'} />
            </WelcomeContainer>
          )}
          {token && (
            <WelcomeContainer>
              {isSuccess && <VerifyIcon>{icon}</VerifyIcon>}
              {isAlreadyVerifiedError && <VerifyIcon>{icon}</VerifyIcon>}
              {isGlobalError && <VerifyIcon>{errorIcon}</VerifyIcon>}
              {isExpiredTokenError && <VerifyIcon>{errorIcon}</VerifyIcon>}
              {isSuccess && <Text content={titleSuccess} size={'extraLarge'} weight={'bold'} />}
              {isGlobalError && <Text content={translate('error')} size={'extraLarge'} weight={'bold'} />}
              {isGlobalError && <Text content={translate('somethingWentWrong')} size={'large'} />}
              {isSuccess && <Text content={subtitleSuccess} size={'large'} />}
              {isAlreadyVerifiedError && (
                <Text
                  content={translate('alreadyAMemberOf', { tenant: tenant.charAt(0).toUpperCase() + tenant.slice(1) })}
                  size={'extraLarge'}
                  weight={'bold'}
                />
              )}
              {isAlreadyVerifiedError && <Text content={translate('accountAlreadyActivated')} size={'large'} />}
              {isExpiredTokenError && <Text content={translate('error')} size={'extraLarge'} weight={'bold'} />}
              {isExpiredTokenError && <Text content={translate('expiredToken')} size={'large'} />}
            </WelcomeContainer>
          )}
        </>
      )}
    </VerifyContainer>
  );
};

export default Verify;
