import { BuildType } from '@/shared/types/redux/ReduxAction';

const deleteNftCollectionPath = (collectionId: string) => `core/nft-collection/${collectionId}`;

export const deleteNftCollection = (build: BuildType) => {
  return build.mutation<void, any>({
    invalidatesTags: ['GetNftCollections'],
    query: ({ collectionId }) => ({
      url: deleteNftCollectionPath(collectionId),
      method: 'DELETE',
    }),
  });
};
