import { Button, ModalDialog, Text } from 'issuix-ui-kit';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import ControlledForm from '@/components/Form/ControlledForm';
import { CASE_STATUS } from '@/enums/CaseStatus';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useApproveCaseMutation, useGetCaseDetailsQuery } from '@/store/service';
import { ActionsContainer, CaseCard } from '@/view/Admin/Case/Case.styled';
import { CaseView } from '@/view/Admin/Case/CaseView';
import RequestChangesModalForm from '@/view/Admin/Case/RequestChangesModalForm/RequestChangesModalForm';
import { RequestChangesModalFormSchema } from '@/view/Admin/Case/RequestChangesModalForm/RequestChangesModalFormSchema';

const Case = () => {
  const { translate } = useTranslation();
  const params = useParams();
  const [isRequestChangesModalOpen, setIsRequestChangesModalOpen] = useState<boolean>(false);

  const { data } = useGetCaseDetailsQuery(params.caseId, { skip: !params.caseId });
  const [approveCase, { isLoading }] = useApproveCaseMutation();

  const onApproveClickHandler = () => {
    approveCase({ caseId: params.caseId });
  };

  if (!data) return null;

  return (
    <>
      <ModalDialog isOpen={isRequestChangesModalOpen} close={() => setIsRequestChangesModalOpen(false)}>
        <Text content={translate('requestChanges')} size={'large'} />
        <ControlledForm schema={RequestChangesModalFormSchema(translate)}>
          <RequestChangesModalForm setIsOpen={setIsRequestChangesModalOpen} />
        </ControlledForm>
      </ModalDialog>
      <CaseCard>
        <Text content={translate('caseDetails')} size={'large'} />
        <CaseView caseDetails={data} caseId={params.caseId} />
        <ActionsContainer>
          <Button
            onClick={() => setIsRequestChangesModalOpen(true)}
            text={translate('requestChanges')}
            buttonType="secondary"
            disabled={data.status === CASE_STATUS.APPROVED || data.status === CASE_STATUS.CHANGES_REQUESTED}
          />
          <Button
            onClick={() => onApproveClickHandler()}
            text={translate('approve')}
            disabled={data.status === CASE_STATUS.APPROVED}
            isLoading={isLoading}
          />
        </ActionsContainer>
      </CaseCard>
    </>
  );
};

export default Case;
