import React, { FC, createContext, useContext, useState, useEffect } from 'react';

import { ROLE_TYPE } from '@/enums/RoleType';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import {
  InitialRegistrationSecondStepState,
  InitialRegistrationFirstStepState,
  RegistrationFirstStepState,
  RegistrationSecondStepState,
  RegistrationThirdStepState,
  InitialRegistrationThirdStepState,
} from '@/shared/types/AuthTypes';
import { FullRegistrationCorporateUserRequest, FullRegistrationPrivateUserRequest } from '@/shared/types/api';
import { useFullRegistrationCorporateUserMutation, useFullRegistrationPrivateUserMutation } from '@/store/service';

interface IRegistrationContext {
  isSuccessfulRegistration: boolean;
  setIsSuccessfulRegistration: (isSuccessfulRegistration: boolean) => void;
  email: string | null;
  setEmail: (email: string | null) => void;
  submitFirstStepData: (data: RegistrationFirstStepState) => void;
  submitSecondStepData: (data: RegistrationSecondStepState) => void;
  submitThirdStepData: (data: RegistrationThirdStepState, submitThreeStep: boolean) => void;
  firstStepData: RegistrationFirstStepState;
  secondStepData: RegistrationSecondStepState;
  thirdStepData: RegistrationThirdStepState;
  forgotPasswordEmail: string | null;
  submitForgotPasswordData: (data: string) => void;
}

interface RegistrationContextProviderProps {
  children: React.ReactNode;
}

export const RegistrationContext = createContext({} as IRegistrationContext);

export const useRegistrationContext = () => useContext(RegistrationContext);

export const RegistrationContextProvider: FC<RegistrationContextProviderProps> = ({ children }) => {
  const [isSuccessfulRegistration, setIsSuccessfulRegistration] = useState<boolean>(false);
  const [email, setEmail] = useState<string | null>(null);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string | null>(null);
  const { currentLanguage } = useTranslation();

  const [firstStepData, setFirstStepData] = useState<RegistrationFirstStepState>(InitialRegistrationFirstStepState);
  const [secondStepData, setSecondStepData] = useState<RegistrationSecondStepState>(InitialRegistrationSecondStepState);
  const [thirdStepData, setThirdStepData] = useState<RegistrationThirdStepState>(InitialRegistrationThirdStepState);

  const [fullRegistrationCorporateUser, { isSuccess: isCorporateSuccess }] = useFullRegistrationCorporateUserMutation();

  const [fullRegistrationPrivateUser, { isSuccess: isPrivateSuccess }] = useFullRegistrationPrivateUserMutation();

  const submitFirstStepData = (data: RegistrationFirstStepState) => {
    setFirstStepData(data);
  };

  const submitSecondStepData = (data: RegistrationSecondStepState) => {
    if (data.email) {
      setEmail(data.email);
    }
    setSecondStepData(data);
  };

  const submitForgotPasswordData = (data: string) => {
    if (data) {
      setForgotPasswordEmail(data);
    }
  };

  const submitThirdStepData = (data: RegistrationThirdStepState, submitThreeStep: boolean) => {
    setThirdStepData(data);
    if (submitThreeStep) {
      const threeStepData = { ...secondStepData, ...data };
      if (firstStepData.accountType === ROLE_TYPE.CORPORATE) {
        fullRegistrationCorporateUser({
          ...threeStepData,
          preferredLanguage: currentLanguage,
          termsAndConditions: true,
          image: data.image,
        } as FullRegistrationCorporateUserRequest);
      } else {
        const threeStepData = { ...secondStepData, ...data };
        fullRegistrationPrivateUser({
          ...threeStepData,
          preferredLanguage: currentLanguage,
          image: data.image,
        } as FullRegistrationPrivateUserRequest);
      }
    }
  };

  useEffect(() => {
    if (isCorporateSuccess || isPrivateSuccess) {
      setIsSuccessfulRegistration(true);
    }
  }, [isCorporateSuccess, isPrivateSuccess]);

  return (
    <RegistrationContext.Provider
      value={{
        isSuccessfulRegistration,
        setIsSuccessfulRegistration,
        email,
        setEmail,
        firstStepData,
        secondStepData,
        thirdStepData,
        submitFirstStepData,
        submitSecondStepData,
        submitThirdStepData,
        forgotPasswordEmail,
        submitForgotPasswordData,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
};
