import { Card } from 'issuix-ui-kit';
import styled from 'styled-components';

export const SmartShareFeaturesContainer = styled(Card).attrs(() => ({
  notFullHeight: true,
}))`
  width: 100%;
  padding: 4rem 2rem;
  position: relative;
  height: 300rem !important;
`;

export const EditButtonContainer = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
`;
