import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    debug: process.env.NODE_ENV! !== 'prod',
    load: 'languageOnly', // load 'en' instead of 'en-US'
    react: {
      useSuspense: true,
    },
    detection: {
      order: ['queryString', 'cookie'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
