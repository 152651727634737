import { BuildType } from '@/shared/types/redux/ReduxAction';

const approveUserUrl = (id: string) => {
  return `/admin/users/${id}/approve`;
};

export const approveUser = (build: BuildType) => {
  return build.mutation<void, any>({
    invalidatesTags: ['GetUserRequest', 'GetUserRequests', 'GetUsers'],
    query: ({ id }) => ({
      url: approveUserUrl(id),
      method: 'PATCH',
    }),
  });
};
