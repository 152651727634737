import { BondResponse } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseBondsUrl = 'bonds';

const getBondByIdUrl = (query: { id: string }): string => `${baseBondsUrl}/${query.id}`;

export const getBondById = (build: BuildType) => {
  return build.query<BondResponse, any>({
    query: ({ query }) => ({
      url: getBondByIdUrl(query),
      method: 'GET',
    }),
  });
};
