import styled from 'styled-components';

export const SendDirectEmailWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 4rem 2rem;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
`;

export const SubjectWrapper = styled.div`
  width: 50%;
  margin-top: 4rem;
`;

export const ContentWrapper = styled.div`
  width: 100%;
`;

export const EditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ActionContainer = styled.div`
  right: 2rem;
  bottom: 2rem;
  display: flex;
  gap: 2rem;
  position: absolute;
`;

export const Footer = styled.div`
  height: 5rem;
  background: linear-gradient(0deg, rgba(27, 90, 161, 0.5) 0%, #4099e8 100%, #0486f4 100%);
  border-radius: 0 0 30px 30px;
  width: 100%;
  display: flex;
`;

export const AttachmentsWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const AddAttachmentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22rem;
  height: 14rem;
  cursor: pointer;
`;

export const AttachmentsContent = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
`;
