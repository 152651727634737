export type UserType = 'private' | 'corporate';

export type UserRoleType = { label: string; value: string };

export const ROLES: UserRoleType[] = [
  {
    label: 'private',
    value: 'private',
  },
  { label: 'corporate', value: 'corporate' },
];

export enum USER_STATUS_TYPE_ENUM {
  APPROVED = 'APPROVED',
  SUSPENDED = 'SUSPENDED',
  PENDING = 'PENDING',
}
