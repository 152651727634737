/* eslint-disable prettier/prettier */
import * as yup from 'yup';

export const SmartBondPendingFilterSchema = () =>
  yup.object().shape({
    code: yup.string(),
    companyName: yup.string(),
    rating: yup.string(),
    maturity: yup.number(),
    country: yup.string(),
    availability: yup.string(),
    price: yup.number(),
    maxPrice: yup.number(),
    interest: yup.number(),
    maxInterest: yup.number(),
  });
