import { Button, ModalDialog, Textarea } from 'issuix-ui-kit';
import React, { FC } from 'react';

import { ButtonsContainer, StyledText } from './RejectModal.styles';

import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';

interface RejectModalProps {
  isRejectModalOpen: boolean;
  onRejectModalClose: () => void;
  onReject: () => void;
  title: string;
  subtitle: string;
  reason: string;
  setReason: (reason: string) => void;
  isRejectLoading?: boolean;
}

export const RejectModal: FC<RejectModalProps> = ({
  isRejectModalOpen,
  onRejectModalClose,
  onReject,
  title,
  subtitle,
  reason,
  setReason,
  isRejectLoading,
}) => {
  const { translate } = useTranslation();

  return (
    <ModalDialog isOpen={isRejectModalOpen} close={onRejectModalClose}>
      <StyledText content={title} size={'extraLarge'} />
      <StyledText content={subtitle} size={'medium'} />
      <Textarea
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setReason(e.target.value)}
        value={reason}
        name={'reason'}
      />
      <ButtonsContainer>
        <Button
          text={translate('cancel')}
          onClick={onRejectModalClose}
          buttonType={'secondary'}
          isLoading={isRejectLoading}
        />
        <Button text={translate('reject')} onClick={onReject} buttonType={'danger'} isLoading={isRejectLoading} />
      </ButtonsContainer>
    </ModalDialog>
  );
};
