import { Card } from 'issuix-ui-kit';
import styled from 'styled-components';

export const UserDetailCard = styled(Card).attrs(() => ({
  notFullHeight: false,
}))`
  width: 100%;
  padding: 3rem;
  position: relative;
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  margin: 2rem;
  gap: 2rem;
`;
